import { dataOnly } from 'api/instance';
import { AuthLoginForm, MosRuLoginForm, SMSCodeLoginForm } from 'api/types/auth';

const api = {
  login: (params: AuthLoginForm) => dataOnly.post<null, any>('/api/v1/auth/login', null, { params }),

  code: (params: SMSCodeLoginForm) => dataOnly.post<null, any>('/api/v1/auth/code', null, { params }),

  loginMosRu: (params: MosRuLoginForm) => dataOnly.post<null, any>('/api/v1/auth/login_mosru_web', params),
};

export default api;
