import { invalidateProfile } from './../../../../../../state/profile/index';
import { ApiBoolean } from './../../../../../../api/types/index';
import { SchoolJoinResponseData, SchoolJoinForm } from './../../../../../../api/types/schools/index';
import { createEvent, createStore, createEffect } from 'effector';
import { Children } from '../../../../../../api/types/children';
import { Profile, ProfileSecond } from '../../../../../../api/types/profile';
import { Map as ImmutableMap } from 'immutable';
import { ApiResponse } from 'api/types';
import api from 'api/request/schools';
import { FetchingState, initFetchingState, storeAttachFetch } from 'state/utils';
import { FileDescr } from 'api/types/common';

export type ViewTypeSchool =
  | 'step'
  | 'profile'
  | 'requisites'
  | 'child'
  | 'email'
  | 'secondRepresentative'
  | 'familyMember';

export const changeViewType = createEvent<ViewTypeSchool>();

export const resetSteps = createEvent();

export const $viewType = createStore<ViewTypeSchool>('step')
  .on(changeViewType, (_, type) => type)
  .reset(resetSteps);

interface Step1State {
  profile: Profile | undefined;
  needSave: boolean;
}
interface Step1StateRep {
  profileRep: ProfileSecond | undefined;
}

interface Step2State {
  child_id?: number;
  needSave: boolean;
  childrenList?: Children[];
  childrenMap?: ImmutableMap<number, Children>;
}

interface Step3State {
  files: FileDescr[];
}

export interface FamilyMember {
  id: number;
  first_name: string | null;
  last_name: string | null;
  patronymic: string | null;
  classOrGroup: string | null;
}
export interface familyMembersForRequest {
  first_name: string | null;
  last_name: string | null;
  patronymic: string | null;
  class: string | null;
}
interface Step4State {
  familyMembers: FamilyMember[];
}

export const nextStep = createEvent();
export const prevStep = createEvent();
export const $step = createStore(0)
  .on(nextStep, (step) => step + 1)
  .on(prevStep, (step) => step - 1)
  .reset(resetSteps);

export const setCurrentParallelId = createEvent<number>();
export const $currentParallelId = createStore(-1).on(setCurrentParallelId, (state, id) => (state = id));

export const setCurrentProgrammId = createEvent<number>();
export const $currentProgrammId = createStore(-1).on(setCurrentProgrammId, (state, id) => (state = id));

export const setCurrentPlaceId = createEvent<number>();
export const $currentPlaceId = createStore(-1).on(setCurrentPlaceId, (state, id) => (state = id));

//----------step1

export const step1SetProfile = createEvent<Profile>();
export const step1ToggleSaver = createEvent();
export const $step1 = createStore<Step1State>({ profile: undefined, needSave: true })
  .on(step1SetProfile, (state, profile) => ({
    ...state,
    profile,
  }))
  .on(step1ToggleSaver, (state) => ({
    ...state,
    needSave: !state.needSave,
  }))
  .reset(resetSteps);

export const step1SetProfileRep = createEvent<ProfileSecond | undefined>();

export const $step1Rep = createStore<Step1StateRep>({ profileRep: undefined }).on(
  step1SetProfileRep,
  (state, profileRep) => ({
    ...state,
    profileRep,
  }),
);

//----------step2
export const step2SetChild = createEvent<number | undefined>();
export const addChild = createEvent<Children>();
export const updateChild = createEvent<Children>();
export const initChild = createEvent<Children[]>();
export const step2ToggleSaver = createEvent();
export const $step2 = createStore<Step2State>({ needSave: true })
  .on(step2SetChild, (state, child_id) => ({ ...state, child_id }))
  .on(initChild, (state, a) => ({
    ...state,
    childrenList: a,
    childrenMap: ImmutableMap(a.map((c) => [c.id, c])),
  }))
  .on(addChild, (state, child) => ({
    ...state,
    childrenList: [...state.childrenList!, child],
    childrenMap: state.childrenMap!.set(child.id, child),
  }))
  .on(updateChild, (state, child) => ({
    ...state,
    childrenList: state.childrenList!.map((c) => (c.id === child.id ? child : c)),
    childrenMap: state.childrenMap!.set(child.id, child),
  }))
  .on(step2ToggleSaver, (state) => ({
    ...state,
    needSave: !state.needSave,
  }))
  .reset(resetSteps);
export const setEditChild = createEvent<Children | null>();

export const $editChild = createStore<Children | null>(null).on(setEditChild, (_, c) => c);

//----------step3
export const step3Set = createEvent<Step3State>();
export const $step3 = createStore<Step3State>({ files: [] })
  .on(step3Set, (_, newState) => newState)
  .reset(resetSteps);

//----------step4
export const addMember = createEvent<FamilyMember>();
export const updateMember = createEvent<FamilyMember>();
export const deleteMember = createEvent<number | undefined>();
export const $step4 = createStore<Step4State>({ familyMembers: [] })
  .on(addMember, (state, familyMember) => ({
    ...state,
    familyMembers: [...state.familyMembers!, familyMember],
  }))
  .on(updateMember, (state, member) => ({
    ...state,
    familyMembers: state.familyMembers!.map((elem) => (elem.id === member.id ? member : elem)),
  }))
  .on(deleteMember, (state, id) => ({
    familyMembers: state.familyMembers!.filter((elem) => elem.id !== id),
  }))

  .reset(resetSteps);

export const setEditMember = createEvent<FamilyMember | null>();
export const $editMember = createStore<FamilyMember | null>(null).on(setEditMember, (_, c) => c);

export const schoolJoinFx = createEffect<void, ApiResponse<SchoolJoinResponseData>>({
  handler: async () => {
    const { familyMembers } = $step4.getState();
    const familyMembersForRequest: familyMembersForRequest[] = [];
    familyMembers.forEach((element) => {
      familyMembersForRequest.push({
        first_name: element.first_name,
        last_name: element.last_name,
        patronymic: element.patronymic,
        class: element.classOrGroup,
      });
    });

    const { files } = $step3.getState();
    const { child_id, childrenMap, needSave: save_child_data } = $step2.getState();
    const { profileRep } = $step1Rep.getState();
    const { needSave: save_user_data, profile } = $step1.getState();
    const {
      first_name: user_first_name,
      last_name: user_last_name,
      patronymic: user_patronymic,
      dob: user_dob,
      requisites,
    } = profile!;

    const {
      first_name_rep: user_first_name_rep,
      last_name_rep: user_last_name_rep,
      adult_type_id_rep,
      patronymic_rep: user_patronymic_rep,
      dob_rep: user_dob_rep,
      phone_rep: user_phone_rep,
      email_rep: user_email_rep,
    } = profileRep! || {};

    const {
      passport_number: user_passport_number,
      passport_issued_date: user_passport_issued_date,
      passport_issued_by: user_passport_issued_by,
      address: user_address,
      actual_address: user_actual_address,
    } = requisites;

    const {
      first_name: child_first_name,
      last_name: child_last_name,
      patronymic: child_patronymic,
      dob: child_dob,
      document_type,
      document_number: child_document_number,
      actual_address: child_actual_address,
      document_issued_by: child_document_issued_by,
      document_issued_date: child_document_issued_date,
      address: child_address,
      inila: child_inila,
      phone: child_phone,
      citizenship: citizen_ship,
      adult_type_id,
    } = childrenMap!.get(child_id!)!;

    const form: SchoolJoinForm = {
      parallel_id: $currentParallelId.getState() as number,
      program_id: $currentProgrammId.getState() as number,
      place_id: $currentPlaceId.getState() as number,
      file_name: files,
      save_user_data: +save_user_data as ApiBoolean,
      child_id: child_id,
      user_last_name: user_last_name!,
      user_first_name: user_first_name!,
      user_patronymic: user_patronymic!,
      user_dob: user_dob!,
      user_passport_number: user_passport_number!,
      user_passport_issued_by: user_passport_issued_by!,
      user_passport_issued_date: user_passport_issued_date!,
      user_address: user_address!,
      user_actual_address: user_actual_address!,
      save_child_data: +save_child_data as ApiBoolean,
      // child_photo? : File
      child_last_name: child_last_name!,
      child_first_name: child_first_name!,
      child_patronymic: child_patronymic!,
      child_actual_address: child_actual_address!,
      child_dob: child_dob!,
      citizenship: citizen_ship!,
      document_type: document_type!,
      child_document_number: child_document_number!,
      child_document_issued_by: child_document_issued_by!,
      child_document_issued_date: child_document_issued_date!,
      child_address: child_address!,
      child_inila: child_inila!,
      child_phone: child_phone!,
      adult_type_id: adult_type_id,
      partner_last_name: user_last_name_rep!,
      partner_first_name: user_first_name_rep!,
      partner_patronymic: user_patronymic_rep!,
      partner_dob: user_dob_rep!,
      partner_phone: user_phone_rep!,
      partner_email: user_email_rep!,
      partner_adult_type_id: +adult_type_id_rep!,
      relatives: JSON.stringify(familyMembersForRequest),
    };

    return await api.join(form);
    //     return Promise.resolve();
  },
});
export interface SchoolJoinState extends FetchingState<SchoolJoinResponseData> {}

export const $schoolJoin = createStore<SchoolJoinState>(initFetchingState());

storeAttachFetch($schoolJoin, [schoolJoinFx]);

export const sendJoinCodeFx = createEffect<{ id: number; code: string }, ApiResponse>({
  handler: async ({ id, code }) => await api.code(id, code),
});

sendJoinCodeFx.doneData.watch(() => {
  invalidateProfile();
});
