import React, { useCallback, useMemo, useState } from 'react';
import { Step } from 'ui/edits/Step';
import { ButtonItem } from 'ui/list/ButtonItem';
import cl from './Step4.module.scss';
import Icon from 'ui/Icon';
import cn from 'classnames';
import { Club } from 'api/types/clubs';
import { $step4, clubJoinFx, nextStep, prevStep, step4Set } from '../../model';
import { useStore } from 'effector-react';
import { showAlert, showErrorAlert } from 'ui/dialogs/Alert';
import { useClubs } from 'hooks/clubs';
import { $currentClub } from 'state/club';
import { UPLOAD_FILE_SIZE } from 'constants/config';
import { ApiUnprocessableError } from 'api/errors';
import { Backdrop } from 'ui/feedback/Backdrop';
import { FileLoadPanel } from 'ui/panels/FileLoadPanel';
import { handleFiles } from 'utils/helpers';

interface Props {}

interface DataType {
  id: number;
  name: string;
}

const facilities: DataType[] = [
  {
    id: 1,
    name: 'Сироты и дети, оставшиеся без попечения родителей',
  },
  {
    id: 2,
    name: 'Дети-инвалиды',
  },
  {
    id: 3,
    name: 'Дети из многодетных семей',
  },
];

export const Step4: React.FC<Props> = () => {
  const loading = useStore(clubJoinFx.pending);

  const [isFetching, setFetching] = useState(false);

  const { map: clubsMap } = useClubs();
  const clubId = useStore($currentClub);
  const { privileges: hasPrivileges, maternal_capital: hasMaternalCapital } = clubsMap.get(clubId!) as Club;
  const step4Form = useStore($step4);

  const handleNext = useCallback(async () => {
    try {
      await clubJoinFx();
      nextStep();
    } catch (e) {
      if (e instanceof ApiUnprocessableError) await showErrorAlert();
      else
        await showAlert(
          'error',
          'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
          'Ошибка',
        );
    }
  }, []);

  const handlePrevStep = useCallback(() => {
    prevStep();
  }, []);

  const handleRemoveFile = useCallback(
    (typeOfFile: string) => {
      let files = step4Form.files;
      files = files.filter((element) => element.name !== typeOfFile);
      if (files.length === 0) {
        step4Set({
          ...step4Form,
          files,
          isPrivileges: true,
        });
      } else {
        let isPriv = false;
        for (let i = 0; i < files.length; i++) {
          if (files[i].name === 'Использовать материнский капитал') {
            isPriv = true;
          }
        }
        if (isPriv === true && files.length === 1) {
          step4Set({
            ...step4Form,
            files,
            isPrivileges: true,
          });
        } else {
          step4Set({
            ...step4Form,
            files,
          });
        }
      }
    },
    [step4Form],
  );

  const handleSizeError = useCallback(() => {
    showAlert(
      'error',
      `Максимальный размер файлов для загрузки составляет ${(UPLOAD_FILE_SIZE / 1024 / 1024).toFixed(
        1,
      )}Mb. Файлы большего размера не будут загружены.`,
      'Ошибка',
    );
  }, []);

  const mainTitle = useMemo(
    () =>
      hasPrivileges && hasMaternalCapital
        ? 'Льготы и материнский капитал'
        : hasPrivileges
        ? 'Льготы'
        : hasMaternalCapital
        ? 'Материнский капитал'
        : '',
    [hasPrivileges, hasMaternalCapital],
  );

  return (
    <Step title={mainTitle} onClickNext={handleNext} prevStep={handlePrevStep}>
      <Backdrop open={loading || isFetching} />

      <>
        {hasPrivileges && (
          <>
            <div className={cl.subtitle}>Выбор основания льготы</div>
            <CheckedItem label="Льготы отсутсвуют" selected={step4Form.isPrivileges} />
            <div className={cl.files}>
              {facilities.map((element) => (
                <div key={element.id} className={cl.file}>
                  <FileLoadPanel
                    data={step4Form.files}
                    handleRemoveFile={handleRemoveFile}
                    name={element.name}
                    onClick={handleFiles.bind(null, setFetching, step4Form, step4Set)}
                    onSizeError={handleSizeError}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </>
      {hasMaternalCapital && (
        <>
          <div className={cl.subtitle}>Материнский капитал</div>
          <div className={cl.files}>
            <div className={cl.file}>
              <FileLoadPanel
                data={step4Form.files}
                handleRemoveFile={handleRemoveFile}
                name={'Использовать материнский капитал'}
                onClick={handleFiles.bind(null, setFetching, step4Form, step4Set)}
                onSizeError={handleSizeError}
              />
            </div>
          </div>
        </>
      )}
    </Step>
  );
};

interface CheckedItemProps {
  label: string;
  selected?: boolean;
}

const CheckedItem: React.FC<CheckedItemProps> = ({ label, selected = false }) => {
  return (
    <div className={cl.itemWrapper}>
      <ButtonItem className={cn(cl.checkedItem, cl.checkedItemWidth, selected && cl.selected)}>
        <div className={cl.check}>
          <Icon type={selected ? 'CheckCircle' : 'Circle'} />
        </div>
        <span>{label}</span>
      </ButtonItem>
    </div>
  );
};
