import { createEffect, createEvent, createStore, sample, guard } from 'effector';
import { ApiResponse } from 'api/types';
import { ApiError } from 'api/errors/apiError';
import api from 'api/request/clubs';
import { FetchingListState, initFetchingListState, storeAttachFetch, storeAttachFetchList } from '../utils';
import { Club } from 'api/types/clubs';
import { logout } from '../auth';

export interface ClubsState extends FetchingListState<Club> {}

export const getClubListFx = createEffect<void, ApiResponse<Club[]>, ApiError<any>>({
  handler: async () => await api.list(),
});

export const getActPreviewFx = createEffect<number, ApiResponse<any>, ApiError<any>>({
  handler: async (id: number) => await api.actPreview(id),
});

export const $actPreview = createStore<any>('');

storeAttachFetch($actPreview, [getActPreviewFx]);

export const fetchClubListIfNeed = createEvent();

export const $clubs = createStore<ClubsState>(initFetchingListState<Club>()).reset(logout);

guard({
  source: sample($clubs, fetchClubListIfNeed),
  filter: $clubs.map((state) => !state.isFetching && !state.didFetched),
  target: getClubListFx,
});

storeAttachFetchList($clubs, [getClubListFx]);

// storeAttachSending($profile, [updateProfileFx, updateRequisitesFx]);

export const setCurrentClub = createEvent<number | null>();
export const $currentClub = createStore<number | null>(null).on(setCurrentClub, (_, id) => id);

export const setActiveClub = createEvent<number>();
export const $activeClub = createStore<number>(-1).on(setActiveClub, (_, id) => id);
