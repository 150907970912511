import React from 'react';

const Yandex = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 19.4002C8.1058 19.2993 12.6625 18.7449 15.7037 15.7037C18.7448 12.6625 19.2992 8.10576 19.4002 5.47329e-08C8.82632 0.31128 0.311278 8.82632 0 19.4002ZM20.5998 0C20.7008 8.10576 21.2552 12.6625 24.2963 15.7037C27.3375 18.7449 31.8942 19.2992 40 19.4002C39.6887 8.82632 31.1737 0.311279 20.5998 0ZM40 20.5998C31.8942 20.7008 27.3375 21.2552 24.2963 24.2963C21.2552 27.3375 20.7008 31.8942 20.5998 40C31.1737 39.6887 39.6887 31.1737 40 20.5998ZM19.4002 40C19.2992 31.8942 18.7448 27.3375 15.7037 24.2963C12.6625 21.2552 8.1058 20.7008 5.5893e-09 20.5998C0.311278 31.1737 8.82632 39.6887 19.4002 40Z"
        fill="#69488D"
      />
    </svg>
  );
};

export default Yandex;
