import { createEvent, createStore } from 'effector';
import { Set as ImmutableSet } from 'immutable';

type CategoryFilter = ImmutableSet<number>;

export const toggleSelectCategory = createEvent<number>();
export const resetCategoryFilter = createEvent();

export const $categoryFilter = createStore<CategoryFilter>(ImmutableSet())
  .on(toggleSelectCategory, (state, id) => (state.has(id) ? state.delete(id) : state.add(id)))
  .reset(resetCategoryFilter);
