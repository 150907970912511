import React from 'react';

interface Props {}

const Calendar: React.FC<Props> = () => {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.22222 2.04761H10.7778C11.45 2.04761 12 2.59761 12 3.26983V11.8254C12 12.4976 11.45 13.0476 10.7778 13.0476H2.22222C1.55 13.0476 1 12.4976 1 11.8254V3.26983C1 2.59761 1.55 2.04761 2.22222 2.04761Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.85669 1V3.09524" stroke="#8E8E93" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.14307 1V3.09524" stroke="#8E8E93" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 5.71411H12" stroke="#8E8E93" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Calendar;
