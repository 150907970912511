import React, { ComponentType, FC } from 'react';
import { MainRedirect } from 'components/page';
import {useStore} from "effector-react";
import {$auth} from "state/auth";

const guestOnly = <P,>(Component: ComponentType<P>): FC<P> => (props) => {
  const {token} = useStore($auth);
  const isAuthenticated = Boolean(token);

  if (isAuthenticated) {
    return <MainRedirect />;
  }

  return <Component {...props} />;
};

export default guestOnly;
