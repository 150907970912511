import React, { HTMLAttributes } from 'react';
import cn from 'classnames';
import classes from './IconContainer.module.scss';

interface IProps extends HTMLAttributes<HTMLElement> {}

const IconContainer: React.FC<IProps> = ({ className, children, ...rest }) => (
  <i className={cn(classes.IconContainer, className)} {...rest}>
    {children}
  </i>
);

export default IconContainer;
