import React, { ReactNode } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, TooltipProps } from '@material-ui/core';

interface PropsTool extends TooltipProps {}
interface Props {
  isOpenToolTip: boolean;
  title: ReactNode;
}

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    position: 'relative',
    bottom: '15px',
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 600,
  },
  arrow: {
    color: 'black',
  },
  popper: {
    opacity: 0.7,
  },
}))(Tooltip);

const ToolTipDoc: React.FC<Props & PropsTool> = ({ children, isOpenToolTip, title }) => {
  return (
    <HtmlTooltip
      arrow
      placement="bottom"
      disableHoverListener={!isOpenToolTip}
      disableFocusListener
      disableTouchListener
      title={title}
    >
      {children}
    </HtmlTooltip>
  );
};

export default ToolTipDoc;
