import React, { useCallback, useMemo, useState } from 'react';
import cl from './Code.module.scss';
import { LoginContent } from 'components/layout/LoginLayout/LoginContent';
import { Redirect, useHistory } from 'react-router';
import PinInput from 'react-pin-input';
import { Button } from 'ui/button/Button';
import { Timer } from './Timer';
import { useStore } from 'effector-react';
import { $auth, loginFx, sendCodeFx } from 'state/auth';
import { SMS_TIMEOUT } from 'constants/config';
import { conformedValue, MASK_PHONE } from 'constants/format/masks';
import { LoginLocationState } from '../Default/Default';
import { ApiUnprocessableError } from '../../../../api/errors';
import { showAlert, showErrorAlert, showErrorNetAlert } from '../../../../ui/dialogs/Alert';
import { fnVoid } from 'utils/fn';

interface Props {}

const Code: React.FC<Props> = () => {
  const loading = useStore(sendCodeFx.pending);
  const { phone = '', userId = 0 } = useStore($auth);
  const history = useHistory<LoginLocationState | undefined>();
  const [code, setCode] = useState<string>('');
  const [showTimer, setShowTimer] = useState(true);
  const [isError, setIsError] = useState(false);
  const [firstError, setFirstError] = useState(false);

  const handleClose = useCallback(() => {
    history.replace('/login');
  }, [history]);

  const handleEndTimer = useCallback(() => {
    setShowTimer(false);
  }, []);

  const sendCode = useCallback(
    (code: string) => {
      const { state } = history.location;
      const redirect = (state?.redirect as string)?.split('?redirect=');
      sendCodeFx({ code, id: userId })
        .then(() => {
          history.push(redirect[1] ?? '/');
        })
        .catch((e) => {
          setFirstError(true);
          if (e instanceof ApiUnprocessableError) {
            const { code } = e.data.errors;
            if (code) {
              if ((code as string[]).includes('Code expired'))
                showAlert('error', 'Время жизни отправленного кода истекло. Запросите код повторно.', 'Ошибка').then(
                  fnVoid,
                );
              else {
                setIsError(true);
              }
            } else {
              showErrorAlert().then(fnVoid);
            }
          } else {
            showErrorNetAlert().then(fnVoid);
          }
        });
    },
    [userId, history],
  );

  const handleChangeCode = useCallback(
    (value: string) => {
      setIsError(false);
      setCode(value);
      if (value.length === 6 && !firstError) sendCode(value);
    },
    [firstError, sendCode],
  );

  const handleRequestCode = useCallback(() => {
    loginFx({ phone })
      .catch(() => {})
      .finally(() => {
        setShowTimer(true);
      });
  }, [phone]);

  const handleClickNext = useCallback(() => {
    sendCode(code);
  }, [code, sendCode]);

  const formattedPhone = useMemo(() => {
    return conformedValue(phone, MASK_PHONE);
  }, [phone]);

  if (!phone && !userId) return <Redirect to="/login/phone" />;

  return (
    <LoginContent showFrame onClose={handleClose} className={cl.root}>
      <div className={cl.title}>Код активации</div>
      <div className={cl.text}>
        Пожалуйста введите код активации. Мы отправили его вам на <span>{formattedPhone}</span>
      </div>
      <div className={cl.pinWrapper}>
        <PinInput
          type="numeric"
          inputMode="number"
          length={6}
          onChange={handleChangeCode}
          focus
          // @ts-ignore
          disabled={loading}
        />
        {isError && <div className={cl.error}>Неверный код</div>}
      </div>
      <div className={cl.code}>
        {showTimer ? (
          <>
            <div className={cl.text}>Не получили код? Подождите</div>
            <Timer length={+SMS_TIMEOUT} onEnd={handleEndTimer} />
          </>
        ) : (
          <Button variant="text" onClick={handleRequestCode}>
            Запросить код снова
          </Button>
        )}
      </div>
      <Button className={cl.btnMobile} onClick={handleClickNext} size="large" disabled={code?.length < 6 || loading}>
        Продолжить
      </Button>
    </LoginContent>
  );
};

export default Code;
