import React from 'react';
import cl from './Avatar.module.scss';
import noPhoto from 'assets/icons/noPhoto.svg';

interface Props {
  url?: string | null;
  size?: number;
  className?: string;
}

export const Avatar: React.FC<Props> = ({ url = '', size = 56, className }) => {
  return (
    <div
      className={`${cl.root} ${className}`}
      style={{
        background: `url(${url}) center/cover, url(${noPhoto}) center/58%`,
        height: size,
        width: size,
      }}
    />
  );
};
