import React from "react";

const ArrowForward: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
              d="M6.16499 20.1301L7.93499 21.9001L17.835 12.0001L7.93499 2.1001L6.16499 3.8701L14.295 12.0001L6.16499 20.1301H6.16499Z"
              fill="currentColor"/>
    </svg>)

export default ArrowForward;
