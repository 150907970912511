import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import cl from './RequisitesEdit.module.scss';
import { EditInStep } from 'ui/panels/EditInStep';
import { ProfileRequisitesForm, ProfileRequisites, Profile } from 'api/types/profile';
import { DateMask } from 'ui/masks';
import { toISODate, toLocalDate } from 'constants/date/format';
import { dateValidator, isValid, maxValidator } from 'utils/validators';
import { TextField } from 'ui/input/TextField';
import { showErrorAlert } from 'ui/dialogs/Alert';

interface StepState {
  profile: Profile | undefined;
  needSave: boolean;
}

type pageType = 'school' | 'garten' | 'club';

interface Props {
  state: StepState;
  changeViewType: any;
  stepSetProfile: (state: Profile) => {};
  type?: pageType;
}

interface Form extends ProfileRequisitesForm {}

const initForm = (requisites?: ProfileRequisites): Form => ({
  passport_number: requisites?.passport_number || '',
  passport_issued_by: requisites?.passport_issued_by || '',
  passport_issued_date: toLocalDate(requisites?.passport_issued_date) || '',
  address: requisites?.address || '',
  actual_address: requisites?.actual_address || '',
});

export const RequisitesEdit: React.FC<Props> = (props) => {
  const { state, changeViewType, stepSetProfile } = props;
  const { profile } = state;
  const { requisites } = profile!;

  const [form, setFrom] = useState<Form>(initForm(requisites));
  const [errors, setErrors] = useState<Partial<Form>>({});

  useEffect(() => {
    setFrom(initForm(requisites));
  }, [requisites]);

  const handleChangeForm = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFrom((form) => ({
      ...form,
      [name]: value,
    }));

    setErrors((e) => ({
      ...e,
      [name]: undefined,
    }));
  }, []);

  const validate = useCallback(() => {
    const errors: Partial<Form> = {
      passport_number: maxValidator(form.passport_number, 20, true),
      passport_issued_by: maxValidator(form.passport_issued_by, 500, true),
      address: maxValidator(form.address, 500, true),
      passport_issued_date: dateValidator(form.passport_issued_date, true, true),
      actual_address:
        props.type === 'school' || props.type === 'garten' ? maxValidator(form.actual_address, 500, true) : undefined,
    };
    setErrors(errors);
    return isValid(errors);
  }, [form, props.type]);

  const handleSave = useCallback(async () => {
    if (!validate()) return;
    try {
      stepSetProfile({
        ...profile!,
        requisites: { ...requisites, ...form, passport_issued_date: toISODate(form.passport_issued_date) },
      });
      changeViewType('step');
    } catch (e) {
      await showErrorAlert();
    }
  }, [validate, form, profile, requisites, changeViewType, stepSetProfile]);

  return (
    <EditInStep
      title="Добавить реквизиты"
      onClickBack={changeViewType.bind(null, 'step')}
      className={cl.root}
      onClickNext={handleSave}
    >
      <form autoComplete="off" noValidate>
        <TextField
          label="Серия и номер документа"
          fullWidth
          value={form.passport_number}
          name="passport_number"
          onChange={handleChangeForm}
          error={!!errors.passport_number}
          helperText={errors.passport_number}
          // InputProps={{ inputComponent: PassportMask }}
        />
        <TextField
          label="Кем выдан"
          fullWidth
          value={form.passport_issued_by}
          name="passport_issued_by"
          onChange={handleChangeForm}
          error={!!errors.passport_issued_by}
          helperText={errors.passport_issued_by}
        />
        <TextField
          label="Дата выдачи"
          fullWidth
          value={form.passport_issued_date}
          name="passport_issued_date"
          onChange={handleChangeForm}
          error={!!errors.passport_issued_date}
          helperText={errors.passport_issued_date}
          InputProps={{ inputComponent: DateMask }}
        />
        <TextField
          label="Адрес регистрации"
          fullWidth
          value={form.address}
          name="address"
          onChange={handleChangeForm}
          error={!!errors.address}
          helperText={errors.address}
        />
        {(props.type === 'garten' || props.type === 'school') && (
          <TextField
            label="Адрес фактического проживания"
            fullWidth
            value={form.actual_address}
            name="actual_address"
            onChange={handleChangeForm}
            error={!!errors.actual_address}
            helperText={errors.actual_address}
          />
        )}
      </form>
    </EditInStep>
  );
};
