import cl from './Timer.module.scss';
import React, { useEffect, useState } from 'react';

interface Props {
  length: number;
  onEnd?: () => void;
}

const Timer: React.FC<Props> = ({ length, onEnd }) => {
  const [leftTime, setLeftTime] = useState(length);

  useEffect(() => {
    let leftTimeTmp = length;
    const timerId = setInterval(() => {
      if (leftTimeTmp <= 0) {
        clearInterval(timerId);
        onEnd?.();
        return;
      }
      leftTimeTmp--;
      setLeftTime(leftTimeTmp);
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [length, onEnd]);

  const m = String(Math.floor(leftTime / 60)).padStart(2, '0');
  const s = String(leftTime % 60).padStart(2, '0');
  return (
    <div className={cl.root}>
      {m}:{s}
    </div>
  );
};

export default Timer;
