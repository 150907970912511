import React, { useCallback } from 'react';
import { LeftSide } from 'components/layout/MainLayout/LeftSide';
import { SelectSection } from './SelectSection';
import { useHistory } from 'react-router';
import { ProfileMenu } from 'components/layout/ProfileMenu';
import { ProfileChildrenMenu } from 'components/layout/ProfileChildrenMenu';

interface Props {
  location: string;
  isRootPath: boolean;
}

const ProfileLeftSide: React.FC<Props> = ({ location, isRootPath }) => {
  const history = useHistory();
  const handleChangeSection = useCallback(
    (i: number) => {
      if (i === 1) history.push('/profile/children');
      else history.push('/profile');
    },
    [history],
  );

  const isChildrenRoute = location.indexOf('/profile/children') > -1;

  return (
    <LeftSide
      title="Профиль"
      topComponent={<SelectSection onChange={handleChangeSection} isChildrenRoute={isChildrenRoute} />}
      isRootPath={isRootPath}
    >
      {isChildrenRoute ? <ProfileChildrenMenu /> : <ProfileMenu />}
    </LeftSide>
  );
};

export default ProfileLeftSide;
