import React from 'react';

const Documents = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M134 101.84H-15V101.915H134V101.84Z" fill="#EBEBEB" />
        <path
          d="M55.626 88.5494H-1.91185C-2.36314 88.5494 -2.79594 88.3701 -3.11505 88.051C-3.43416 87.7319 -3.61343 87.2991 -3.61343 86.8478V5.96168C-3.61343 5.51039 -3.43416 5.07759 -3.11505 4.75848C-2.79594 4.43937 -2.36314 4.2601 -1.91185 4.2601H55.626C56.0773 4.2601 56.5101 4.43937 56.8292 4.75848C57.1483 5.07759 57.3276 5.51039 57.3276 5.96168V86.8478C57.3276 87.0713 57.2835 87.2925 57.198 87.499C57.1125 87.7054 56.9872 87.893 56.8292 88.051C56.6712 88.209 56.4836 88.3344 56.2771 88.4199C56.0707 88.5054 55.8494 88.5494 55.626 88.5494ZM-1.91185 4.3346C-2.34314 4.33539 -2.75654 4.50707 -3.0615 4.81203C-3.36647 5.117 -3.53815 5.53039 -3.53893 5.96168V86.8478C-3.53815 87.2791 -3.36647 87.6925 -3.0615 87.9975C-2.75654 88.3024 -2.34314 88.4741 -1.91185 88.4749H55.626C56.0573 88.4741 56.4707 88.3024 56.7756 87.9975C57.0806 87.6925 57.2523 87.2791 57.2531 86.8478V5.96168C57.2523 5.53039 57.0806 5.117 56.7756 4.81203C56.4707 4.50707 56.0573 4.33539 55.626 4.3346H-1.91185Z"
          fill="#EBEBEB"
        />
        <path
          d="M120.086 88.5494H62.5426C62.0915 88.5486 61.6592 88.3691 61.3402 88.0501C61.0213 87.7312 60.8418 87.2989 60.841 86.8478V5.96168C60.8418 5.51064 61.0213 5.07829 61.3402 4.75935C61.6592 4.44042 62.0915 4.26089 62.5426 4.2601H120.086C120.537 4.26168 120.968 4.44165 121.286 4.76058C121.604 5.07952 121.782 5.51142 121.782 5.96168V86.8478C121.782 87.2981 121.604 87.73 121.286 88.0489C120.968 88.3679 120.537 88.5478 120.086 88.5494ZM62.5426 4.3346C62.1113 4.33539 61.6979 4.50707 61.3929 4.81203C61.0879 5.117 60.9163 5.53039 60.9155 5.96168V86.8478C60.9163 87.2791 61.0879 87.6925 61.3929 87.9975C61.6979 88.3024 62.1113 88.4741 62.5426 88.4749H120.086C120.518 88.4741 120.931 88.3024 121.236 87.9975C121.541 87.6925 121.713 87.2791 121.713 86.8478V5.96168C121.713 5.53039 121.541 5.117 121.236 4.81203C120.931 4.50707 120.518 4.33539 120.086 4.3346H62.5426Z"
          fill="#EBEBEB"
        />
        <path d="M8.0294 104.677H0.281403V104.752H8.0294V104.677Z" fill="#EBEBEB" />
        <path d="M41.6737 103.87H28.2547V103.944H41.6737V103.87Z" fill="#EBEBEB" />
        <path d="M26.4428 107.446H8.32742V107.52H26.4428V107.446Z" fill="#EBEBEB" />
        <path d="M118.719 106.945H112.988V107.019H118.719V106.945Z" fill="#EBEBEB" />
        <path d="M110.333 106.945H93.8564V107.019H110.333V106.945Z" fill="#EBEBEB" />
        <path d="M83.793 105.684H61.6009V105.759H83.793V105.684Z" fill="#EBEBEB" />
        <path d="M35.5527 75.9916H19.2879L18.3015 64.6021H34.5663L35.5527 75.9916Z" fill="#E6E6E6" />
        <path d="M36.396 75.9916H20.1312L19.1418 64.6021H35.4067L36.396 75.9916Z" fill="#F0F0F0" />
        <path d="M22.3543 73.5481L21.8119 67.0487H33.1836L33.726 73.5481H22.3543Z" fill="white" />
        <path d="M45.9738 75.9917H43.6524V101.837H45.9738V75.9917Z" fill="#E6E6E6" />
        <path d="M46.7039 75.9917H45.0917V101.837H46.7039V75.9917Z" fill="#F0F0F0" />
        <path d="M24.2317 78.8465H46.7039V75.9946H24.2317V78.8465Z" fill="#F0F0F0" />
        <path d="M25.0274 78.8465H22.7059V101.84H25.0274V78.8465Z" fill="#E6E6E6" />
        <path d="M25.7604 78.8465H24.1483V101.84H25.7604V78.8465Z" fill="#F0F0F0" />
        <path d="M8.50025 78.8465H6.17883V101.84H8.50025V78.8465Z" fill="#E6E6E6" />
        <path d="M9.23336 78.8465H7.62119V101.84H9.23336V78.8465Z" fill="#F0F0F0" />
        <path d="M31.2794 78.8465H28.958V101.84H31.2794V78.8465Z" fill="#E6E6E6" />
        <path d="M32.0124 78.8465H30.4003V101.84H32.0124V78.8465Z" fill="#F0F0F0" />
        <path d="M24.2317 75.9917H6.17883V78.8435H24.2317V75.9917Z" fill="#E6E6E6" />
        <path d="M72.9815 12.4431H5.56497V51.1176H72.9815V12.4431Z" fill="#E6E6E6" />
        <path d="M74.7814 12.4431H6.4053V51.1176H74.7814V12.4431Z" fill="#F0F0F0" />
        <path d="M72.9815 51.1176H5.56497V54.4671H72.9815V51.1176Z" fill="#E6E6E6" />
        <path d="M77.7823 51.1176H9.40619V54.4671H77.7823V51.1176Z" fill="#F0F0F0" />
        <path d="M72.2603 48.5965V14.9672L8.92939 14.9672V48.5965L72.2603 48.5965Z" fill="#FAFAFA" />
        <path d="M13.5812 48.5965L28.3858 14.9672H35.3769L20.5693 48.5965H13.5812Z" fill="white" />
        <path
          d="M10.0975 46.0844C10.0738 46.0848 10.0502 46.0804 10.0282 46.0716C10.0062 46.0628 9.9861 46.0497 9.96918 46.0331C9.95226 46.0164 9.93883 45.9966 9.92966 45.9747C9.92048 45.9528 9.91576 45.9293 9.91577 45.9056V16.6092C9.91247 16.5836 9.91466 16.5575 9.92221 16.5328C9.92976 16.5081 9.94249 16.4853 9.95955 16.4659C9.97661 16.4465 9.99761 16.431 10.0211 16.4203C10.0447 16.4097 10.0702 16.4042 10.0961 16.4042C10.1219 16.4042 10.1474 16.4097 10.171 16.4203C10.1945 16.431 10.2155 16.4465 10.2326 16.4659C10.2496 16.4853 10.2623 16.5081 10.2699 16.5328C10.2774 16.5575 10.2796 16.5836 10.2763 16.6092V45.9056C10.2763 45.953 10.2575 45.9985 10.224 46.032C10.1904 46.0655 10.145 46.0844 10.0975 46.0844Z"
          fill="#F0F0F0"
        />
        <path d="M22.0712 48.5965L36.8788 14.9672H39.6025L24.7979 48.5965H22.0712Z" fill="white" />
        <path d="M72.2574 48.5965V14.9672H71.9355V48.5965H72.2574Z" fill="#E6E6E6" />
        <path opacity="0.6" d="M75.3775 18.7697H7.96094L7.73148 15.9476H75.145L75.3775 18.7697Z" fill="#EBEBEB" />
        <path opacity="0.6" d="M75.3775 23.4006H7.96094L7.73148 20.5786H75.145L75.3775 23.4006Z" fill="#EBEBEB" />
        <path opacity="0.6" d="M75.3775 28.0315H7.96094L7.73148 25.2095H75.145L75.3775 28.0315Z" fill="#EBEBEB" />
        <path opacity="0.6" d="M75.3775 32.6624H7.96094L7.73148 29.8374H75.145L75.3775 32.6624Z" fill="#EBEBEB" />
        <path opacity="0.6" d="M75.3775 37.2904H7.96094L7.73148 34.4683H75.145L75.3775 37.2904Z" fill="#EBEBEB" />
        <path opacity="0.6" d="M75.3775 41.9213H7.96094L7.73148 39.0992H75.145L75.3775 41.9213Z" fill="#EBEBEB" />
        <path d="M93.8952 30.6003L112.049 30.6003V12.4461L93.8952 12.4461V30.6003Z" fill="#EBEBEB" />
        <path d="M93.8951 12.4431H93.0905V30.5973H93.8951V12.4431Z" fill="#E0E0E0" />
        <path d="M96.3566 28.1388H109.591V14.9046L96.3566 14.9046V28.1388Z" fill="white" />
        <path d="M102.974 25.9507L107.403 21.5214L102.974 17.0921L98.5444 21.5214L102.974 25.9507Z" fill="#F5F5F5" />
        <path d="M87.6401 54.4671H105.794V36.313H87.6401V54.4671Z" fill="#EBEBEB" />
        <path d="M87.6402 36.3129H86.8356V54.4671H87.6402V36.3129Z" fill="#E0E0E0" />
        <path d="M90.1016 52.0056H103.336V38.7714H90.1016V52.0056Z" fill="white" />
        <path
          d="M95.7755 48.2121H97.6589C98.6974 48.2121 99.5392 47.3702 99.5392 46.3317V44.4484C99.5392 43.4099 98.6974 42.568 97.6589 42.568H95.7755C94.737 42.568 93.8951 43.4099 93.8951 44.4484V46.3317C93.8951 47.3702 94.737 48.2121 95.7755 48.2121Z"
          fill="#F5F5F5"
        />
        <path d="M76.7005 63.2879H72.1739V100.621H76.7005V63.2879Z" fill="#F0F0F0" />
        <path d="M116.269 100.621H72.8027V101.84H116.269V100.621Z" fill="#F0F0F0" />
        <path d="M76.7005 100.621H116.895V63.2879L76.7005 63.2879V100.621Z" fill="#FAFAFA" />
        <path
          d="M109.028 85.1045H84.5708C83.4161 85.1045 82.3087 84.6458 81.4922 83.8293C80.6757 83.0128 80.217 81.9054 80.217 80.7507C80.217 79.5961 80.6757 78.4887 81.4922 77.6722C82.3087 76.8557 83.4161 76.397 84.5708 76.397H109.028C110.182 76.397 111.29 76.8557 112.106 77.6722C112.923 78.4887 113.381 79.5961 113.381 80.7507C113.381 81.9054 112.923 83.0128 112.106 83.8293C111.29 84.6458 110.182 85.1045 109.028 85.1045ZM84.5708 76.689C84.0283 76.6743 83.4884 76.7685 82.9829 76.9659C82.4775 77.1633 82.0167 77.4601 81.6278 77.8385C81.239 78.217 80.9299 78.6696 80.7188 79.1695C80.5078 79.6695 80.399 80.2066 80.399 80.7493C80.399 81.2919 80.5078 81.8291 80.7188 82.329C80.9299 82.8289 81.239 83.2815 81.6278 83.66C82.0167 84.0385 82.4775 84.3352 82.9829 84.5326C83.4884 84.7301 84.0283 84.8242 84.5708 84.8095H109.028C109.57 84.8242 110.11 84.7301 110.615 84.5326C111.121 84.3352 111.582 84.0385 111.971 83.66C112.359 83.2815 112.669 82.8289 112.88 82.329C113.091 81.8291 113.199 81.2919 113.199 80.7493C113.199 80.2066 113.091 79.6695 112.88 79.1695C112.669 78.6696 112.359 78.217 111.971 77.8385C111.582 77.4601 111.121 77.1633 110.615 76.9659C110.11 76.7685 109.57 76.6743 109.028 76.689H84.5708Z"
          fill="#F0F0F0"
        />
        <path
          d="M109.028 95.9338H84.5708C83.4161 95.9338 82.3087 95.4751 81.4922 94.6586C80.6757 93.8421 80.217 92.7347 80.217 91.58C80.217 90.4253 80.6757 89.3179 81.4922 88.5015C82.3087 87.685 83.4161 87.2263 84.5708 87.2263H109.028C110.182 87.2263 111.29 87.685 112.106 88.5015C112.923 89.3179 113.381 90.4253 113.381 91.58C113.381 92.7347 112.923 93.8421 112.106 94.6586C111.29 95.4751 110.182 95.9338 109.028 95.9338ZM84.5708 87.5183C83.4935 87.5183 82.4604 87.9462 81.6987 88.708C80.937 89.4697 80.509 90.5028 80.509 91.58C80.509 92.6573 80.937 93.6904 81.6987 94.4521C82.4604 95.2138 83.4935 95.6418 84.5708 95.6418H109.028C110.105 95.6418 111.138 95.2138 111.9 94.4521C112.661 93.6904 113.089 92.6573 113.089 91.58C113.089 90.5028 112.661 89.4697 111.9 88.708C111.138 87.9462 110.105 87.5183 109.028 87.5183H84.5708Z"
          fill="#F0F0F0"
        />
        <path
          d="M100.49 79.812H93.1084C92.8702 79.8073 92.6433 79.7096 92.4762 79.5397C92.3091 79.3699 92.2152 79.1414 92.2144 78.9031C92.219 78.6674 92.3147 78.4427 92.4814 78.276C92.648 78.1093 92.8728 78.0137 93.1084 78.0091H100.49C100.728 78.0098 100.957 78.1038 101.127 78.2709C101.296 78.4379 101.394 78.6649 101.399 78.9031C101.397 79.1437 101.301 79.3739 101.131 79.5441C100.961 79.7142 100.73 79.8104 100.49 79.812Z"
          fill="#EBEBEB"
        />
        <path
          d="M109.028 74.2722H84.5708C83.4161 74.2722 82.3087 73.8135 81.4922 72.997C80.6757 72.1805 80.217 71.0731 80.217 69.9184C80.217 68.7637 80.6757 67.6563 81.4922 66.8398C82.3087 66.0233 83.4161 65.5646 84.5708 65.5646H109.028C110.182 65.5646 111.29 66.0233 112.106 66.8398C112.923 67.6563 113.381 68.7637 113.381 69.9184C113.381 71.0731 112.923 72.1805 112.106 72.997C111.29 73.8135 110.182 74.2722 109.028 74.2722ZM84.5708 65.8567C84.0374 65.8567 83.5092 65.9617 83.0164 66.1659C82.5236 66.37 82.0758 66.6692 81.6987 67.0463C81.3215 67.4235 81.0223 67.8713 80.8182 68.3641C80.6141 68.8568 80.509 69.385 80.509 69.9184C80.509 70.4518 80.6141 70.98 80.8182 71.4728C81.0223 71.9656 81.3215 72.4133 81.6987 72.7905C82.0758 73.1677 82.5236 73.4669 83.0164 73.671C83.5092 73.8751 84.0374 73.9802 84.5708 73.9802H109.028C110.105 73.9802 111.138 73.5522 111.9 72.7905C112.661 72.0288 113.089 70.9957 113.089 69.9184C113.089 68.8412 112.661 67.8081 111.9 67.0463C111.138 66.2846 110.105 65.8567 109.028 65.8567H84.5708Z"
          fill="#F0F0F0"
        />
        <path
          d="M100.49 68.9797H93.1084C92.8702 68.975 92.6433 68.8773 92.4762 68.7075C92.3091 68.5376 92.2152 68.3091 92.2144 68.0708C92.219 67.8351 92.3147 67.6104 92.4814 67.4437C92.648 67.2771 92.8728 67.1814 93.1084 67.1768H100.49C100.728 67.1776 100.957 67.2715 101.127 67.4386C101.296 67.6057 101.394 67.8326 101.399 68.0708C101.398 68.3116 101.302 68.5424 101.132 68.7126C100.961 68.8829 100.731 68.9789 100.49 68.9797Z"
          fill="#EBEBEB"
        />
        <path
          d="M100.49 90.6413H93.1084C92.8702 90.6366 92.6433 90.5389 92.4762 90.3691C92.3091 90.1992 92.2152 89.9707 92.2144 89.7324C92.2183 89.4965 92.3137 89.2713 92.4805 89.1045C92.6474 88.9377 92.8725 88.8422 93.1084 88.8384H100.49C100.728 88.8391 100.957 88.9331 101.127 89.1002C101.296 89.2673 101.394 89.4942 101.399 89.7324C101.398 89.9732 101.302 90.2039 101.132 90.3742C100.961 90.5445 100.731 90.6405 100.49 90.6413Z"
          fill="#EBEBEB"
        />
        <path d="M117.723 61.3896H76.7036V63.2879H117.723V61.3896Z" fill="#F0F0F0" />
        <path d="M71.2859 63.2909H76.7006V61.3926H71.2859V63.2909Z" fill="#E6E6E6" />
        <path
          d="M59.5 115.298C91.4106 115.298 117.279 113.788 117.279 111.925C117.279 110.061 91.4106 108.551 59.5 108.551C27.5894 108.551 1.72079 110.061 1.72079 111.925C1.72079 113.788 27.5894 115.298 59.5 115.298Z"
          fill="#F5F5F5"
        />
        <path
          d="M108.265 32.0576H63.3502C63.3502 32.0576 55.2595 39.5076 55.2595 57.62C55.2595 73.1965 61.3953 94.3157 59.0917 102.961C57.3544 109.484 48.9299 110.604 44.892 110.759H87.6431C87.6431 110.759 101.703 111.609 104.006 102.961C106.31 94.3127 100.174 73.1965 100.174 57.62C100.174 39.4956 108.265 32.0576 108.265 32.0576Z"
          fill="#69488D"
        />
        <path
          opacity="0.7"
          d="M108.265 32.0576H63.3502C63.3502 32.0576 55.2595 39.5076 55.2595 57.62C55.2595 73.1965 61.3953 94.3157 59.0917 102.961C57.3544 109.484 48.9299 110.604 44.892 110.759H87.6431C87.6431 110.759 101.703 111.609 104.006 102.961C106.31 94.3127 100.174 73.1965 100.174 57.62C100.174 39.4956 108.265 32.0576 108.265 32.0576Z"
          fill="white"
        />
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M96.0467 51.1593H60.1258C61.142 43.1907 63.8806 38.5539 65.3914 36.5275H100.028C98.3294 40.0737 96.7262 44.9042 96.0467 51.1593Z"
            fill="#69488D"
          />
          <path
            opacity="0.3"
            d="M95.7458 55.3849H59.7742C59.804 54.6608 59.8457 53.9575 59.9053 53.2781H95.856C95.8063 53.9635 95.7696 54.6658 95.7458 55.3849Z"
            fill="#69488D"
          />
          <path
            opacity="0.3"
            d="M71.0535 59.6076H59.7622C59.7424 58.9341 59.7314 58.2716 59.7295 57.6199V57.5037H71.0237V57.6199C71.0237 58.2755 71.0326 58.9401 71.0535 59.6076Z"
            fill="#69488D"
          />
          <path
            opacity="0.3"
            d="M95.9603 63.8302H60.0155C59.953 63.121 59.9023 62.4177 59.8636 61.7263H95.8232C95.859 62.4237 95.9047 63.125 95.9603 63.8302Z"
            fill="#69488D"
          />
          <path
            opacity="0.3"
            d="M96.3566 68.0559H60.4417C60.3582 67.3486 60.2807 66.6464 60.2092 65.949H96.154C96.2046 66.6493 96.2791 67.3526 96.3566 68.0559Z"
            fill="#69488D"
          />
          <path
            opacity="0.3"
            d="M96.8752 72.2786H60.99C60.8917 71.5753 60.7993 70.872 60.7099 70.1747H96.6069C96.6904 70.874 96.7798 71.5753 96.8752 72.2786Z"
            fill="#69488D"
          />
          <path
            opacity="0.3"
            d="M97.4682 76.5012H61.586C61.4827 75.7979 61.3834 75.0966 61.288 74.3973H97.1672C97.2656 75.0976 97.3669 75.8009 97.4682 76.5012Z"
            fill="#69488D"
          />
          <path
            opacity="0.3"
            d="M98.1029 80.7268H62.2327C62.1761 80.3424 62.1165 79.952 62.0569 79.5616L61.9138 78.62H97.7841C97.8914 79.3232 97.9957 80.0265 98.1029 80.7268Z"
            fill="#69488D"
          />
          <path
            opacity="0.3"
            d="M62.8585 84.9495C62.7592 84.2542 62.6598 83.5529 62.5605 82.8456H98.4248C98.5291 83.5489 98.6364 84.2492 98.7377 84.9495H62.8585Z"
            fill="#69488D"
          />
          <path
            opacity="0.3"
            d="M63.4276 89.1722C63.3442 88.4838 63.2518 87.7805 63.1565 87.0683H93.0757C93.177 87.7775 93.2724 88.4808 93.3737 89.1722H63.4276Z"
            fill="#69488D"
          />
          <path
            opacity="0.3"
            d="M63.8687 93.3978C63.8111 92.7084 63.7455 92.0062 63.672 91.291H99.587C99.6674 92.0121 99.7419 92.7154 99.8045 93.3978H63.8687Z"
            fill="#69488D"
          />
          <path
            opacity="0.3"
            d="M64.0743 97.6205C64.0743 96.941 64.0475 96.2377 64.0088 95.5166H82.9586C83.0063 96.2526 83.0331 96.9559 83.042 97.6205H64.0743Z"
            fill="#69488D"
          />
        </g>
        <path
          d="M91.088 108.48C88.8083 108.894 86.4988 108.578 84.2071 108.465C83.1081 108.395 82.0049 108.425 80.9113 108.554C79.8385 108.697 78.7716 108.977 77.6899 108.882C78.0468 108.689 78.3832 108.46 78.6941 108.199C78.7078 108.186 78.7177 108.168 78.7227 108.15C78.7278 108.131 78.728 108.111 78.7232 108.092C78.7184 108.073 78.7088 108.056 78.6954 108.042C78.6819 108.028 78.6651 108.017 78.6465 108.012C75.282 106.82 72.2186 110.047 68.8661 109.293C69.8167 108.59 71.1547 108.578 71.9653 107.654C71.9803 107.638 71.9903 107.618 71.9942 107.597C71.9981 107.575 71.9956 107.553 71.9871 107.533C71.9786 107.512 71.9644 107.495 71.9462 107.483C71.9281 107.471 71.9068 107.464 71.8848 107.463C68.884 107.416 66.05 108.581 63.0819 108.84C62.9389 108.84 62.9389 109.076 63.0819 109.064C65.9576 108.81 68.7141 107.714 71.6137 107.69C70.7465 108.462 69.4234 108.492 68.5413 109.272C68.5275 109.286 68.5175 109.303 68.5124 109.321C68.5072 109.34 68.5071 109.36 68.5119 109.378C68.5167 109.397 68.5264 109.414 68.5399 109.428C68.5534 109.442 68.5703 109.452 68.589 109.457C71.9683 110.39 75.0436 107.168 78.3812 108.164C78.0325 108.436 77.6535 108.667 77.2518 108.852C77.233 108.863 77.2179 108.88 77.2084 108.9C77.1988 108.92 77.1953 108.942 77.1981 108.964C77.2009 108.985 77.2101 109.006 77.2244 109.023C77.2387 109.039 77.2575 109.052 77.2786 109.058C78.3783 109.24 79.454 109.007 80.5388 108.834C81.7532 108.659 82.9826 108.61 84.2071 108.688C86.5226 108.801 88.847 109.111 91.1476 108.688C91.2936 108.667 91.234 108.453 91.088 108.48Z"
          fill="#69488D"
        />
        <path
          d="M30.2603 43.0537C30.448 43.2921 30.6894 43.5782 30.9099 43.8405C31.1304 44.1027 31.3718 44.3679 31.6102 44.6242C32.09 45.1368 32.5757 45.6404 33.1002 46.1142C34.0728 47.0377 35.1776 47.811 36.3782 48.4088C36.5242 48.4714 36.6762 48.534 36.8103 48.5846C36.8761 48.6132 36.9438 48.6371 37.0129 48.6561C37.0774 48.6785 37.1431 48.6974 37.2096 48.7128C37.4959 48.7848 37.7882 48.8307 38.0828 48.8498C38.7425 48.8789 39.4035 48.841 40.0555 48.7366C40.7477 48.631 41.4332 48.4858 42.1087 48.3015C42.7941 48.1227 43.4944 47.8992 44.1471 47.6698L44.7431 48.8618C43.4182 49.6888 41.9837 50.3258 40.4817 50.7541C39.69 50.9832 38.8735 51.1153 38.05 51.1474C37.6041 51.1643 37.1576 51.1343 36.7179 51.058C36.6023 51.0413 36.4879 51.0175 36.3752 50.9865C36.256 50.9597 36.1428 50.9299 36.0444 50.8971C35.8358 50.8375 35.6332 50.766 35.4484 50.6915C33.9426 50.0723 32.5553 49.1972 31.348 48.1048C30.7567 47.5924 30.1927 47.0493 29.6583 46.4778C29.3931 46.1798 29.1338 45.8996 28.8835 45.6046C28.6332 45.3096 28.3948 45.0086 28.1355 44.6659L30.2603 43.0537Z"
          fill="#FFB573"
        />
        <path
          d="M27.2296 42.2104C27.164 43.8792 27.8256 46.886 27.8256 46.886L31.9559 46.3854C31.9559 46.3854 32.1079 41.9839 31.3062 40.9201C29.9503 39.1261 27.2922 40.634 27.2296 42.2104Z"
          fill="#69488D"
        />
        <path
          d="M43.5928 48.0959L45.5268 46.3258L45.7741 48.233C45.7741 48.233 44.9725 49.3058 44.0636 48.8558L43.5928 48.0959Z"
          fill="#FFB573"
        />
        <path d="M46.4983 46.4301L47.3178 47.5029L45.7741 48.233L45.5268 46.3258L46.4983 46.4301Z" fill="#FFB573" />
        <path
          d="M46.0096 41.6055C46.1644 41.5964 46.3196 41.5964 46.4744 41.6055C46.5369 41.6014 46.5997 41.6014 46.6622 41.6055L46.8171 41.6263C46.9509 41.6442 47.0779 41.6955 47.1867 41.7753C47.2404 41.8367 47.2756 41.9122 47.288 41.9929L47.3565 42.2193L47.6337 43.1133L48.1879 44.9222L49.2935 48.5429C50.0236 50.9567 50.7835 53.3616 51.4868 55.7843C52.9321 60.6179 54.2999 65.4693 55.6439 70.3297C56.9879 75.1901 58.2514 80.0683 59.5507 84.9317C60.85 89.795 62.0122 94.7091 62.4264 99.8078C62.4414 100.149 62.3387 100.485 62.1357 100.759C61.9327 101.034 61.6415 101.23 61.311 101.316C60.9805 101.401 60.6306 101.371 60.3199 101.229C60.0093 101.087 59.7566 100.843 59.6043 100.538C57.4885 95.8802 56.1058 91.0317 54.8959 86.1386C53.686 81.2454 52.4017 76.3672 51.2156 71.468C50.0296 66.5689 48.8704 61.6609 47.7856 56.7349C47.2284 54.2764 46.7218 51.803 46.1884 49.3356L45.3987 45.6821L45.0023 43.8315L44.8056 42.9047L44.755 42.6753C44.7281 42.5978 44.7219 42.5145 44.7371 42.4339C44.796 42.3163 44.8817 42.2142 44.9874 42.1359L45.1156 42.0435C45.164 42.005 45.2158 41.9711 45.2705 41.9422C45.4013 41.8592 45.5366 41.7836 45.6758 41.7157C45.7782 41.6562 45.8918 41.6186 46.0096 41.6055Z"
          fill="#263238"
        />
        <path
          d="M62.1403 100.577C62.1179 100.496 62.0648 100.427 61.9923 100.386C61.9199 100.344 61.8339 100.332 61.7529 100.353L61.0973 100.523L60.4417 100.693C60.3606 100.714 60.2911 100.766 60.2481 100.838C60.2052 100.91 60.1923 100.996 60.2122 101.077C60.6205 102.952 60.2122 104.299 60.6473 105.95C61.0973 107.687 63.0551 108.575 63.0551 108.575L62.9926 107.836L63.2906 108.515C63.2906 108.515 64.569 106.787 64.119 105.047C63.6958 103.399 62.6945 102.415 62.1403 100.577Z"
          fill="#69488D"
        />
        <path
          opacity="0.3"
          d="M62.1403 100.577C62.1179 100.496 62.0648 100.427 61.9923 100.386C61.9199 100.344 61.8339 100.332 61.7529 100.353L61.0973 100.523L60.4417 100.693C60.3606 100.714 60.2911 100.766 60.2481 100.838C60.2052 100.91 60.1923 100.996 60.2122 101.077C60.6205 102.952 60.2122 104.299 60.6473 105.95C61.0973 107.687 63.0551 108.575 63.0551 108.575L62.9926 107.836L63.2906 108.515C63.2906 108.515 64.569 106.787 64.119 105.047C63.6958 103.399 62.6945 102.415 62.1403 100.577Z"
          fill="white"
        />
        <path
          d="M62.6499 104.886C62.6691 104.964 62.6648 105.046 62.6374 105.122C62.61 105.198 62.5608 105.264 62.496 105.312C62.4312 105.36 62.3537 105.387 62.2732 105.391C62.1927 105.395 62.1128 105.375 62.0437 105.334C61.9746 105.292 61.9192 105.231 61.8847 105.158C61.8501 105.086 61.8378 105.004 61.8494 104.924C61.861 104.845 61.896 104.77 61.9499 104.71C62.0037 104.65 62.0742 104.608 62.1522 104.588C62.2577 104.562 62.3693 104.578 62.4625 104.634C62.5558 104.69 62.6232 104.78 62.6499 104.886Z"
          fill="#263238"
        />
        <path
          d="M63.0164 107.925C63.0047 107.929 62.9924 107.929 62.9804 107.928C62.9683 107.926 62.9567 107.922 62.9462 107.916C62.9357 107.91 62.9265 107.902 62.9191 107.892C62.9117 107.883 62.9063 107.872 62.9032 107.86L62.1641 105.011C62.1586 104.987 62.1622 104.962 62.1744 104.941C62.1866 104.92 62.2064 104.905 62.2297 104.898C62.2416 104.895 62.2541 104.894 62.2663 104.896C62.2785 104.897 62.2902 104.902 62.3008 104.908C62.3113 104.914 62.3205 104.923 62.3277 104.933C62.335 104.943 62.3401 104.954 62.3429 104.966L63.082 107.812C63.0851 107.824 63.0859 107.836 63.0843 107.848C63.0827 107.86 63.0788 107.872 63.0727 107.882C63.0666 107.893 63.0585 107.902 63.0488 107.909C63.0392 107.917 63.0282 107.922 63.0164 107.925Z"
          fill="#263238"
        />
        <path
          d="M60.6026 90.3404L56.5855 91.3804C56.4761 91.4106 56.3591 91.3962 56.2602 91.3404C56.1614 91.2845 56.0887 91.1918 56.0581 91.0824C56.0295 90.973 56.0446 90.8567 56.1002 90.7583C56.1558 90.6598 56.2476 90.5869 56.3561 90.5549L60.394 89.503C60.5034 89.4744 60.6196 89.4895 60.7181 89.5451C60.8166 89.6007 60.8895 89.6925 60.9214 89.801C60.937 89.8574 60.9411 89.9163 60.9334 89.9743C60.9257 90.0323 60.9064 90.0881 60.8767 90.1385C60.8469 90.1888 60.8073 90.2326 60.7602 90.2673C60.7131 90.302 60.6595 90.3269 60.6026 90.3404Z"
          fill="#69488D"
        />
        <path
          d="M40.2105 35.8034C40.139 35.9822 40.1777 36.167 40.2939 36.2206C40.4101 36.2742 40.5651 36.167 40.6366 35.9852C40.7081 35.8034 40.6724 35.6216 40.5532 35.571C40.434 35.5203 40.285 35.6246 40.2105 35.8034Z"
          fill="#263238"
        />
        <path d="M40.4995 35.5501L40.9793 35.6127C40.9793 35.6127 40.6217 35.8481 40.4995 35.5501Z" fill="#263238" />
        <path
          d="M40.2939 36.0418C40.2894 36.5215 40.3495 36.9996 40.4727 37.4633C40.3538 37.4878 40.2311 37.4864 40.1128 37.4592C39.9945 37.4319 39.8835 37.3794 39.7873 37.3053L40.2939 36.0418Z"
          fill="#ED893E"
        />
        <path
          d="M40.9316 34.7127C40.913 34.7091 40.8956 34.7009 40.881 34.6889C40.7743 34.5901 40.6459 34.5176 40.5062 34.4773C40.3665 34.4369 40.2193 34.4298 40.0764 34.4564C40.0617 34.4604 40.0463 34.4614 40.0313 34.4594C40.0162 34.4573 40.0017 34.4523 39.9886 34.4445C39.9755 34.4367 39.9642 34.4263 39.9552 34.4141C39.9462 34.4018 39.9398 34.3878 39.9363 34.373C39.929 34.3442 39.9334 34.3136 39.9484 34.288C39.9635 34.2623 39.988 34.2436 40.0168 34.2359C40.1958 34.2004 40.3806 34.2073 40.5566 34.2559C40.7325 34.3045 40.8946 34.3936 41.03 34.516C41.0405 34.5263 41.0489 34.5386 41.0546 34.5521C41.0603 34.5657 41.0633 34.5803 41.0633 34.595C41.0633 34.6097 41.0603 34.6243 41.0546 34.6379C41.0489 34.6514 41.0405 34.6637 41.03 34.674C41.0183 34.6884 41.0031 34.6995 40.9859 34.7063C40.9687 34.7131 40.95 34.7153 40.9316 34.7127Z"
          fill="#263238"
        />
        <path
          d="M33.8929 35.711C34.021 37.1593 33.5383 39.5373 32.4416 40.3449C32.4416 40.3449 33.2671 41.8707 35.9103 42.2253C38.8158 42.6157 37.451 40.9797 37.451 40.9797C35.9044 40.3837 36.0713 39.2274 36.4616 38.1665L33.8929 35.711Z"
          fill="#FFB573"
        />
        <path
          d="M41.8108 43.4531C42.0402 44.809 42.3233 46.2125 42.6184 47.5774C42.7763 48.2628 42.9342 48.9452 43.119 49.6187C43.3037 50.2922 43.4885 50.9597 43.6941 51.6183C43.8074 51.9401 43.9087 52.2709 44.0279 52.5897L44.2067 53.0665L44.2484 53.1828V53.2185C44.2484 53.2364 44.2484 53.2573 44.2693 53.2722C44.3307 53.3784 44.4312 53.4565 44.5494 53.4897C44.7859 53.5708 45.0327 53.6179 45.2825 53.6298C45.9221 53.6479 46.5617 53.5949 47.1897 53.4718C47.8482 53.3556 48.5426 53.1947 49.1892 53.0129L49.7346 54.2228C49.3919 54.4284 49.0671 54.5923 48.7214 54.7592C48.3757 54.9261 48.03 55.0572 47.6694 55.2062C46.9189 55.4983 46.1318 55.6858 45.3301 55.7634C44.8592 55.8094 44.384 55.7772 43.9236 55.6681C43.3332 55.5348 42.8014 55.2151 42.4068 54.7562C42.3047 54.6318 42.213 54.4993 42.1326 54.3599C42.0909 54.2913 42.0581 54.2228 42.0224 54.1513L41.9657 54.0201L41.7333 53.4986C41.5813 53.15 41.4353 52.8013 41.3071 52.4497C41.0509 51.7464 40.8065 51.0401 40.5949 50.3309C40.3833 49.6217 40.1926 48.9035 39.9989 48.1913C39.6443 46.7549 39.3552 45.3334 39.1407 43.8464L41.8108 43.4531Z"
          fill="#FFB573"
        />
        <path
          d="M48.9031 53.0963L50.0534 52.1844L50.5719 53.9724C50.5719 53.9724 48.9985 55.1108 48.5783 53.7728L48.9031 53.0963Z"
          fill="#FFB573"
        />
        <path d="M51.0934 52.5003L51.5046 53.7907L50.5719 53.9575L50.0564 52.1844L51.0934 52.5003Z" fill="#FFB573" />
        <path d="M29.6017 109.293L32.0065 109.469L32.7187 103.947L30.3139 103.771L29.6017 109.293Z" fill="#FFB573" />
        <path d="M14.201 102.278L16.284 103.545L19.7498 99.2178L17.6668 97.9513L14.201 102.278Z" fill="#FFB573" />
        <path
          d="M17.7383 104.183C17.7086 104.186 17.6786 104.186 17.6489 104.183C17.3509 104.183 16.9963 103.715 16.8175 103.44C16.8109 103.431 16.8074 103.42 16.8074 103.409C16.8074 103.398 16.8109 103.387 16.8175 103.378C16.8239 103.369 16.8325 103.362 16.8425 103.358C16.8525 103.354 16.8634 103.353 16.8741 103.354C16.9724 103.375 17.8337 103.554 17.9469 103.843C17.959 103.874 17.9627 103.909 17.9574 103.942C17.9522 103.976 17.9383 104.007 17.9171 104.034C17.8761 104.102 17.8131 104.155 17.7383 104.183ZM16.9933 103.506C17.2466 103.864 17.488 104.075 17.6519 104.078C17.6873 104.077 17.722 104.068 17.7527 104.05C17.7834 104.032 17.8092 104.007 17.8277 103.977C17.8357 103.964 17.8399 103.95 17.8399 103.935C17.8399 103.92 17.8357 103.906 17.8277 103.893C17.78 103.741 17.336 103.589 16.9933 103.494V103.506Z"
          fill="#69488D"
        />
        <path
          d="M17.6101 103.437C17.3626 103.506 17.1031 103.518 16.8502 103.473C16.8408 103.471 16.8321 103.466 16.8252 103.46C16.8183 103.453 16.8136 103.444 16.8115 103.434C16.8068 103.426 16.8043 103.417 16.8043 103.408C16.8043 103.398 16.8068 103.389 16.8115 103.381C16.8294 103.36 17.3032 102.874 17.6519 102.889C17.6966 102.889 17.7408 102.9 17.781 102.919C17.8212 102.939 17.8566 102.967 17.8843 103.002C17.9168 103.035 17.9375 103.077 17.9429 103.122C17.9483 103.168 17.9381 103.214 17.9141 103.253C17.8353 103.345 17.7287 103.41 17.6101 103.437ZM16.9843 103.384C17.2823 103.414 17.7413 103.33 17.8336 103.19C17.8336 103.169 17.8575 103.134 17.8098 103.077C17.7922 103.054 17.7697 103.035 17.7439 103.023C17.7181 103.01 17.6897 103.003 17.6608 103.002C17.4008 103.055 17.1641 103.189 16.9843 103.384Z"
          fill="#69488D"
        />
        <path
          d="M16.9098 103.08L14.9341 101.179C14.9015 101.145 14.8579 101.125 14.8113 101.121C14.7648 101.117 14.7184 101.13 14.6808 101.158L12.9941 102.46C12.9535 102.496 12.9209 102.54 12.8986 102.59C12.8763 102.64 12.8648 102.693 12.8648 102.748C12.8648 102.802 12.8763 102.856 12.8986 102.905C12.9209 102.955 12.9535 102.999 12.9941 103.035C13.6944 103.688 14.0639 103.968 14.9311 104.805C15.4675 105.318 16.1946 106.125 16.9337 106.835C17.6727 107.544 18.3939 106.876 18.1495 106.516C17.0469 104.901 17.2108 104.293 17.1125 103.467C17.0915 103.319 17.0199 103.182 16.9098 103.08Z"
          fill="#263238"
        />
        <path
          d="M33.0496 109.549C32.7952 109.543 32.5419 109.514 32.2926 109.463C32.2812 109.461 32.2707 109.455 32.2627 109.446C32.2547 109.438 32.2495 109.427 32.2479 109.415C32.2464 109.404 32.2484 109.392 32.2537 109.381C32.259 109.37 32.2674 109.362 32.2777 109.356C32.388 109.299 33.3654 108.807 33.6515 108.971C33.6788 108.986 33.7017 109.008 33.7179 109.035C33.7341 109.062 33.7431 109.092 33.7439 109.123C33.7498 109.175 33.7428 109.227 33.7235 109.276C33.7043 109.324 33.6734 109.367 33.6336 109.4C33.4614 109.515 33.2555 109.567 33.0496 109.549ZM32.4834 109.383C33.0138 109.469 33.4101 109.445 33.5591 109.314C33.5856 109.292 33.6061 109.264 33.6186 109.232C33.6312 109.201 33.6353 109.166 33.6307 109.132C33.6315 109.12 33.6289 109.108 33.6231 109.098C33.6173 109.087 33.6085 109.078 33.5979 109.073C33.437 108.983 32.8857 109.192 32.4834 109.383Z"
          fill="#69488D"
        />
        <path
          d="M32.3045 109.454C32.294 109.458 32.2823 109.458 32.2718 109.454C32.2632 109.449 32.2564 109.441 32.2522 109.432C32.248 109.423 32.2465 109.413 32.2479 109.403C32.2479 109.359 32.3641 108.378 32.9035 108.378H32.9482C33.1061 108.396 33.1509 108.474 33.1598 108.536C33.2045 108.81 32.6204 109.329 32.3284 109.46C32.32 109.461 32.3114 109.459 32.3045 109.454ZM32.9005 108.48C32.5549 108.48 32.4178 109.049 32.376 109.293C32.674 109.114 33.0704 108.697 33.0436 108.542C33.0436 108.542 33.0436 108.492 32.9333 108.48H32.9005Z"
          fill="#69488D"
        />
        <path
          d="M32.1555 109.126H29.4527C29.4054 109.126 29.3598 109.143 29.3237 109.174C29.2877 109.204 29.2637 109.247 29.256 109.293L28.8924 111.43C28.8852 111.483 28.8895 111.538 28.9051 111.59C28.9206 111.641 28.9471 111.689 28.9827 111.73C29.0182 111.77 29.0621 111.803 29.1113 111.825C29.1605 111.847 29.2139 111.859 29.2679 111.859C30.2096 111.841 30.6655 111.787 31.8545 111.787C32.5846 111.787 34.0955 111.862 35.1057 111.862C36.1159 111.862 36.1964 110.864 35.7762 110.774C33.9018 110.369 33.1747 109.809 32.5548 109.284C32.4445 109.186 32.3031 109.13 32.1555 109.126Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M32.7187 103.95L32.3224 107.019L29.9443 106.62L30.3139 103.774L32.7187 103.95Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M17.6638 97.9513L19.7498 99.2178L18.3134 101.009L16.1857 99.7959L17.6638 97.9513Z"
          fill="black"
        />
        <path
          d="M28.3888 54.8337L38.1245 56.1419C41.8971 42.4875 40.7856 42.1061 40.7856 42.1061C40.3572 41.9003 39.9139 41.727 39.4595 41.5876C39.0006 41.4207 38.5367 41.2687 38.0678 41.1316C35.305 40.3143 32.4214 39.9816 29.545 40.1482C29.3555 40.1609 29.1713 40.2163 29.0061 40.3102C28.841 40.4042 28.6992 40.5342 28.5914 40.6906C28.5509 40.7498 28.516 40.8127 28.4871 40.8783C28.3812 41.1051 28.3399 41.3567 28.3679 41.6055C29.1993 48.7932 28.9341 51.1564 28.3888 54.8337Z"
          fill="#69488D"
        />
        <path
          opacity="0.2"
          d="M39.0721 46.3019C38.881 47.7148 39.0628 49.1531 39.5996 50.4739C40.2463 47.8098 40.5979 46.0039 40.7707 44.7523C40.0287 44.7106 39.2569 44.9937 39.0721 46.3019Z"
          fill="black"
        />
        <path
          d="M40.6992 42.0733C42.368 43.0269 43.5272 45.3007 43.8759 46.6208L40.3237 49.875C40.3237 49.875 38.4821 45.5927 38.6311 44.2606C38.7801 42.9286 40.0704 41.7098 40.6992 42.0733Z"
          fill="#69488D"
        />
        <path
          d="M34.4203 32.7131C33.6723 34.972 33.3088 35.9107 33.9644 37.5228C34.9537 39.9456 37.8831 40.4552 39.2896 38.4884C40.5561 36.7004 41.5664 33.3181 39.7635 31.5092C39.3846 31.1063 38.9072 30.8092 38.3784 30.6472C37.8496 30.4852 37.2877 30.4639 36.7481 30.5855C36.2086 30.7071 35.7101 30.9673 35.3019 31.3404C34.8936 31.7135 34.5898 32.1867 34.4203 32.7131Z"
          fill="#FFB573"
        />
        <path
          d="M38.1245 34.7187C37.9673 35.133 37.9423 35.5858 38.0529 36.015C38.2019 36.611 38.6489 36.6586 38.9261 36.2742C39.2032 35.8898 39.391 35.1984 39.093 34.6978C38.795 34.1972 38.3182 34.2627 38.1245 34.7187Z"
          fill="#FFB573"
        />
        <path
          d="M35.2309 29.903C33.6217 29.009 30.7251 31.6254 29.998 35.8958C29.4765 38.9533 36.0057 40.4313 40.13 40.3658C39.8558 39.6476 38.1423 37.4782 39.236 35.3892C40.0317 33.8724 40.9376 34.221 40.9972 32.4569C41.0717 30.2487 38.1274 28.7587 35.2309 29.903Z"
          fill="#263238"
        />
        <path
          d="M38.8933 30.8626C40.7648 30.6808 42.6451 32.5194 41.2505 34.3521C40.9099 34.079 40.5134 33.884 40.089 33.781C39.6647 33.678 39.2229 33.6695 38.795 33.7561C37.9397 33.9469 38.8933 30.8626 38.8933 30.8626Z"
          fill="#263238"
        />
        <path
          d="M35.5557 55.7962C35.5557 55.7962 31.786 71.775 29.998 80.9563C28.1325 90.6413 19.1895 100.729 19.1895 100.729L16.1619 99.0241C16.1619 99.0241 21.7881 89.8129 23.6119 80.7716C27.319 62.4148 24.8039 58.7196 28.3799 54.8217L35.5557 55.7962Z"
          fill="#263238"
        />
        <path d="M19.4995 101.015L15.7059 98.9019L16.2602 97.7785L20.5157 99.7363L19.4995 101.015Z" fill="#69488D" />
        <path
          opacity="0.2"
          d="M33.5442 59.268C30.6059 61.8188 30.9248 70.89 31.2317 74.9577C32.4654 69.1556 33.9465 62.695 34.8077 58.9551C34.5067 58.7226 34.0865 58.7941 33.5442 59.268Z"
          fill="black"
        />
        <path
          d="M37.9815 56.1479C37.9815 56.1479 39.683 75.1185 38.7533 82.357C37.7878 89.8844 32.8767 106.26 32.8767 106.26L29.402 105.813C29.402 105.813 32.2211 89.5835 32.7218 82.1305C33.2671 74.007 30.8444 55.1913 30.8444 55.1913L37.9815 56.1479Z"
          fill="#263238"
        />
        <path d="M33.6127 106.456L28.6719 105.819L28.7464 104.358L34.1104 104.88L33.6127 106.456Z" fill="#69488D" />
        <path
          d="M28.3024 54.2466L27.772 55.0512C27.7303 55.1138 27.8047 55.1943 27.915 55.2092L38.1275 56.58C38.2169 56.58 38.2943 56.5591 38.3033 56.5055L38.4851 55.6502C38.4851 55.5936 38.4255 55.531 38.3301 55.5191L28.4693 54.196C28.4395 54.1882 28.4082 54.1888 28.3787 54.1977C28.3493 54.2067 28.3228 54.2236 28.3024 54.2466Z"
          fill="#69488D"
        />
        <path
          opacity="0.5"
          d="M28.3024 54.2466L27.772 55.0512C27.7303 55.1138 27.8047 55.1943 27.915 55.2092L38.1275 56.58C38.2169 56.58 38.2943 56.5591 38.3033 56.5055L38.4851 55.6502C38.4851 55.5936 38.4255 55.531 38.3301 55.5191L28.4693 54.196C28.4395 54.1882 28.4082 54.1888 28.3787 54.1977C28.3493 54.2067 28.3228 54.2236 28.3024 54.2466Z"
          fill="black"
        />
        <path
          d="M29.5123 55.5161L29.2471 55.4804C29.1934 55.4804 29.1577 55.4416 29.1636 55.4088L29.408 54.3003C29.408 54.2675 29.4646 54.2466 29.5153 54.2556L29.7805 54.2913C29.8341 54.2913 29.8699 54.3301 29.8639 54.3629L29.6196 55.4684C29.6136 55.5161 29.5629 55.5161 29.5123 55.5161Z"
          fill="#263238"
        />
        <path
          d="M36.1815 56.428L35.9163 56.3952C35.8656 56.3952 35.8269 56.3535 35.8329 56.3207L36.0772 55.2151C36.0772 55.1824 36.1338 55.1615 36.1875 55.1675L36.4497 55.2032C36.5033 55.2032 36.5391 55.242 36.5331 55.2747L36.2888 56.3833C36.2828 56.4101 36.2322 56.4369 36.1815 56.428Z"
          fill="#263238"
        />
      </g>
    </svg>
  );
};

export default Documents;
