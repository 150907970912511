import React, { useEffect, useState, useCallback, ChangeEvent, useMemo } from 'react';
import api from 'api/request/schools';
import { useStore } from 'effector-react';
import { getSchoolsHistoryFx } from 'state/school/history';
import { SchoolsHistoryItem } from 'api/types/schools/histoty';
import HistoryPanel from 'ui/panels/HistoryPanel/HistoryPanel';

interface Props {}

const HistorySchools: React.FC<Props> = () => {
  const [history, setHistory] = useState<SchoolsHistoryItem[]>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getSchoolsHistoryFx().then(({ data }) => {
      setHistory(data);
    });
  }, []);

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  const searchedHistory = useMemo(
    () =>
      history.filter(
        ({ place, program, parallel }) =>
          place.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
          program.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
          `${parallel.number} класс`.indexOf(searchValue) > -1,
      ),
    [searchValue, history],
  );

  return (
    <HistoryPanel
      application={api.application}
      test_application={api.test_application}
      regime={api.regime}
      type={'school'}
      loading={useStore(getSchoolsHistoryFx.pending)}
      searchedHistory={searchedHistory}
      handleChangeSearch={handleChangeSearch}
      applicationType={'Заявление'}
      titleSM="История заявлений"
    />
  );
};

export default HistorySchools;
