import React from 'react';

const Close: React.FC = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.6297 20.752C19.1299 21.2522 20.2574 21.4299 21.148 21.149C22.0387 20.8681 22.3552 20.2348 21.8549 19.7346L18.6297 20.752ZM13.3683 11.2479C12.8681 10.7477 11.7406 10.5699 10.8499 10.8509C9.95931 11.1318 9.64281 11.7651 10.143 12.2653L13.3683 11.2479ZM10.143 19.7345C9.64275 20.2347 9.95925 20.868 10.8499 21.1489C11.7405 21.4299 12.868 21.2521 13.3682 20.7519L10.143 19.7345ZM21.8549 12.2652C22.3551 11.765 22.0386 11.1318 21.148 10.8508C20.2574 10.5699 19.1298 10.7476 18.6296 11.2478L21.8549 12.2652ZM21.8549 19.7346L13.3683 11.2479L10.143 12.2653L18.6297 20.752L21.8549 19.7346ZM13.3682 20.7519L21.8549 12.2652L18.6296 11.2478L10.143 19.7345L13.3682 20.7519Z" fill="currentColor"/>
      <path d="M30.5 16C30.5 24.0081 24.0081 30.5 16 30.5C7.99187 30.5 1.5 24.0081 1.5 16C1.5 7.99187 7.99187 1.5 16 1.5C24.0081 1.5 30.5 7.99187 30.5 16Z" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default Close;

