import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import React from 'react';
import { Default } from './components/Default';
import { Edit } from './components/Edit';

interface Props extends RouteComponentProps {}

const Profile: React.FC<Props> = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/add`} component={Edit} />
      <Route path={`${match.url}/:id`} component={Edit} />
      <Route path={match.url} component={Default} />
    </Switch>
  );
};

export default Profile;
