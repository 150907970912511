import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { API_BASE_URL } from 'constants/config';

const createAxios = (config?: AxiosRequestConfig): AxiosInstance =>
  axios.create({
    baseURL: API_BASE_URL,
    ...config,
  });

export default createAxios;
