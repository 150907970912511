import React, { useCallback, useMemo } from 'react';
import cl from './ProfileMenu.module.scss';
import { ExtraLabel, ProfileMenuItem, ProfileMenuItemType } from 'ui/list/ProfileMenuItem';
import { useHistory, useLocation } from 'react-router-dom';
import { useProfile } from 'hooks/profile';
import { Button } from 'ui/button/Button';
import { logout } from 'state/auth';
import { showPrompt } from 'ui/dialogs/Prompt';
import { MOSRU_BASE_URL, WEB_BASE_URL } from 'constants/config';
import { checkRequisites } from 'state/profile';

interface Props {}

const MENU: ProfileMenuItemType[] = [
  {
    title: 'Учетная запись',
    subtitle: 'Данные и реквизиты',
    iconType: 'Profile',
    path: '/profile/profile',
  },
  {
    title: 'Реквизиты',
    subtitle: 'Для оформления договора',
    iconType: 'User',
    path: '/profile/requisites',
  },
  {
    title: '',
    subtitle: 'E-mail',
    iconType: 'Mail',
    path: '/profile/mail',
  },
  {
    title: 'Документы',
    subtitle: 'Запись в кружок',
    iconType: 'History',
    path: '/profile/history/clubs',
  },
  {
    title: 'История заявлений',
    subtitle: 'Заявления в школу',
    iconType: 'History',
    path: '/profile/history/schools',
  },
  {
    title: 'История заявлений',
    subtitle: 'Заявления в сад',
    iconType: 'History',
    path: '/profile/history/gartens',
  },
  {
    title: 'Прочие документы',
    subtitle: 'Подписанные документы',
    iconType: 'History',
    path: '/profile/history/documents',
  },
  {
    title: 'Оставить обратную связь',
    subtitle: 'Связь со школой',
    iconType: 'Chat',
    path: '/profile/feedback',
  },
  {
    title: 'О сайте',
    subtitle: 'Информация',
    iconType: 'About',
    path: '/profile/about',
  },
];

export const ProfileMenu: React.FC<Props> = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { profile } = useProfile();

  const handleClickItem = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  const extraLabel: ExtraLabel = useMemo(() => {
    return profile.verified ? { label: 'Подтверждена', active: true } : { label: 'На рассмотрении', active: false };
  }, [profile]);

  const extraLabelForEmail: ExtraLabel = useMemo(() => {
    return profile.email === null
      ? { label: 'Не заполнено', active: false }
      : profile.email_verified
      ? { label: 'Заполнено', active: true }
      : { label: 'Подтвердите почту', active: false };
  }, [profile]);

  const extraLabelForRequisites: ExtraLabel = useMemo(() => {
    return checkRequisites(profile.requisites)
      ? { label: 'Заполнено', active: true }
      : { label: 'Не заполнено', active: false };
  }, [profile]);

  const fullMenu = MENU.map((elem) =>
    elem.subtitle === 'E-mail' ? { ...elem, title: profile.email_verified ? 'Указан' : 'Не указан' } : elem,
  );

  const handleLogout = useCallback(async () => {
    const q = await showPrompt('Внимание! Вы желаете выйти из аккаунта?');
    if (q) {
      if (localStorage.getItem('mosru_state')) {
        window.location.href = `${MOSRU_BASE_URL}/sps/login/logout?post_logout_redirect_uri=${WEB_BASE_URL}/sudir/logout`;
      } else {
        logout();
      }
    }
  }, []);

  return (
    <ul className={cl.root}>
      {fullMenu.map((item, i) => (
        <ProfileMenuItem
          key={item.path}
          {...item}
          onClick={handleClickItem}
          isActive={(item.path === '/profile/profile' && pathname === '/profile') || item.path === pathname}
          extraLabel={
            i === 0
              ? extraLabel
              : undefined || i === 1
              ? extraLabelForRequisites
              : undefined || i === 2
              ? extraLabelForEmail
              : undefined
          }
        />
      ))}
      <li>
        <Button variant="text" fullWidth onClick={handleLogout}>
          Выход
        </Button>
      </li>
    </ul>
  );
};
