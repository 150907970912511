import React from 'react';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import Carousel, { CommonProps, Modal, ModalGateway } from 'react-images';
import cl from './ImageGalleryModal.module.scss';
import cn from 'classnames';

interface Props {
  open: boolean;
  startIndex?: number;
  items: any;
  onClose: () => void;
}

export const ImageGalleryModal: React.FC<Props> = ({ open, items, onClose, startIndex }) => {
  const screenWidth = window.innerWidth;

  const dialogSizeWidth = screenWidth > 768 ? '50vw' : '80vw';
  const viewSizeHeight = screenWidth > 768 ? '50vh' : '40vh';
  const viewBorderRadius = screenWidth > 768 ? '0px' : '10px';

  const StylesForModal = {
    blanket: (base: any) => ({
      ...base,
    }),
    dialog: (base: any) => ({
      ...base,
      width: dialogSizeWidth,
    }),
  };
  const StylesForCarousel = {
    header: (base: any) => ({
      ...base,
      background: 'white !important',
      backgroundColor: 'white !important',
      padding: 0,
      paddingBottom: 20,
      width: dialogSizeWidth,
      height: '35px',
      position: 'static',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    }),
    headerClose: (base: any) => ({
      ...base,
      marginTop: '20px',
      color: '#666',

      ':hover': { color: 'gray', outline: 'none' },
    }),
    navigationPrev: (base: any) => ({
      ...base,
      backgroundColor: 'black',
      ':hover': { outline: 'none', backgroundColor: 'gray' },
    }),
    navigationNext: (base: any) => ({
      ...base,
      backgroundColor: 'black',
      ':hover': { outline: 'none', backgroundColor: 'gray' },
    }),
    view: (base: any) => ({
      ...base,
      verticalAlign: 'center',
      height: viewSizeHeight,
      width: '100%',
      overflow: 'hidden',
      backgroundColor: 'white',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      borderTopLeftRadius: viewBorderRadius,
      borderTopRightRadius: viewBorderRadius,

      '& > img': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        objectFit: 'cover',
      },
    }),
    footer: (base: any) => ({
      ...base,
      width: '100vw',
    }),
  };
  return (
    <ModalGateway>
      {open ? (
        <Modal styles={StylesForModal} allowFullscreen={false} onClose={onClose}>
          <Carousel
            hideControlsWhenIdle={false}
            styles={StylesForCarousel}
            components={{ Footer }}
            currentIndex={startIndex}
            views={items}
          />
        </Modal>
      ) : null}
    </ModalGateway>
  );
};
interface Footer {
  items: any;
}
const Footer: React.FC<CommonProps> = ({ views, currentIndex }) => {
  return (
    <div className={cl.root}>
      {views?.map((item, key) => (
        <div key={key} className={cn(cl.imgContainer, key === currentIndex && cl.active)}>
          <img src={item.source as string} alt="" />
        </div>
      ))}
    </div>
  );
};
