import { createEffect } from 'effector';
import api from 'api/request/clubs';
import { ApiResponse } from '../../api/types';
import { ClubHistoryItem } from 'api/types/clubs/history';

export const getClubsHistoryFx = createEffect<void, ApiResponse<ClubHistoryItem[]>>({
  handler: async () => await api.history(),
});

export const postHistoryActRejectFx = createEffect<{ id: number; reason: string }, ApiResponse<any>>({
  handler: async ({ id, reason }) => await api.historyRejectAct(id, reason),
});

export const sendActCodeFx = createEffect<{ id: number; code: string }, ApiResponse<ClubHistoryItem[]>>({
  handler: async ({ id, code }) => await api.codeAct(id, code),
});
