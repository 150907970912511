import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import cl from './Programms.module.scss';
import Campus from './Campus';
import { useSchools } from 'hooks/schools';
import { IconButton } from '@material-ui/core';
import Icon from 'ui/Icon';

interface Props extends RouteComponentProps<{ id: string; parallel: string }> {}

export const Programms: React.FC<Props> = ({
  match: {
    params: { parallel, id },
  },
}) => {
  const { data: schoolsData } = useSchools();

  const currentSchool = useMemo(() => schoolsData.find((element) => element.number === +parallel), [
    parallel,
    schoolsData,
  ]);
  const currentProgramm = useMemo(() => currentSchool?.programs.find((element) => element.id === +id), [
    id,
    currentSchool,
  ]);

  const history = useHistory();

  const handleClickBack = useCallback(() => {
    history.push(`/schools/${+parallel}`);
  }, [history, parallel]);

  return (
    <div>
      <div className={cl.header}>
        <IconButton className={cl.buttonBack} onClick={handleClickBack}>
          <Icon type="Back" />
        </IconButton>
        <div className={cl.title}>Выберите предпочитаемый корпус</div>
      </div>
      <div className={cl.campusContainer}>
        {currentProgramm?.places.map((element) => (
          <Campus
            parallel={+parallel}
            programmId={+id}
            id={element.id}
            key={element.id}
            address={element.address}
            name={element.title}
            photos={element.photos}
            placeholder={element.placeholder_uri}
          />
        ))}
      </div>
    </div>
  );
};

export default Programms;
