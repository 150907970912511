import { FileDescr } from './../../api/types/common/index';
import imgDoc from 'assets/icons/doc.svg';
import imgJpg from 'assets/icons/jpg.svg';
import imgPdf from 'assets/icons/pdf.svg';
import imgXls from 'assets/icons/xls.svg';
import imgOther from 'assets/icons/other.svg';
import imageCompression from 'browser-image-compression';

export const shortFio = (lastName: string = '', firstName: string = '', patronymic?: string): string => {
  let ret = `${lastName} ${firstName.substr(0, 1)}.`;
  if (patronymic) ret = `${ret} ${patronymic.substr(0, 1)}.`;
  return ret;
};

export const shortFioFromObj = (obj: any): string =>
  obj ? shortFio(obj.last_name, obj.first_name, obj.patronymic) : '';

// interface FIO {
//   last_name: string;
//   first_name: string;
// }
export const fioFromObj = (obj: any): string =>
  obj ? `${obj.last_name || ''} ${obj.first_name || ''} ${obj.patronymic || ''}`.trim() : '';

export const fileExt = (fileName: string): string => fileName?.split('.')?.pop() || '';

interface StepForm {
  files: FileDescr[];
}
export const handleFiles = async (
  setFetching: (isFetching: boolean) => void,
  stepForm: StepForm,
  stepSet: (file: any) => void,
  fileName: string,
  newFiles: File[] | null,
) => {
  if (JSON.stringify(newFiles) !== '[]') {
    if (!newFiles) return;
    let originFile: File = newFiles[0];

    const imageFile = newFiles[0];
    if (
      imageFile.name.split('.')[1] === 'jpg' ||
      imageFile.name.split('.')[1] === 'png' ||
      imageFile.name.split('.')[1] === 'jpeg'
    ) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
        // exifOrientation: 1,
      };

      try {
        const compressedFile = await imageCompression(imageFile, options);
        setFetching(true);
        originFile = compressedFile as File;
        setFetching(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log('!!! FILE WILL NOT BE COMPRESSED')
    }
    let files = stepForm.files;
    files = [...files, { name: fileName, file: [originFile] }];
    if (fileName === 'Использовать материнский капитал') {
      stepSet({
        ...stepForm,
        files,
      });
    } else {
      stepSet({
        ...stepForm,
        files,
        isPrivileges: false,
      });
    }
  }
};

export const getFileImg = (ext: string) => {
  switch (ext) {
    case 'doc':
    case 'docx':
      return imgDoc;
    case 'jpg':
    case 'jpeg':
      return imgJpg;
    case 'pdf':
      return imgPdf;
    case 'xls':
    case 'xlsx':
      return imgXls;
    default:
      return imgOther;
  }
};
