import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import cl from './MainPanel.module.scss';
import { Field, Header, PanelEditable } from 'ui/panels/PanelEditable';
import { Input, MenuItem } from '@material-ui/core';
import { Children, ChildrenForm, ChildrenUpdateForm } from 'api/types/children';
import { childrenCreateFX, childrenUpdateFX } from 'state/children';
import { useHistory } from 'react-router';
import { dateValidator, isValid, requiredValidator, maxValidator } from 'utils/validators';
import { toISODate, toLocalDate } from 'constants/date/format';
import { DateMask } from 'ui/masks';
import { showErrorAlert } from 'ui/dialogs/Alert';
import { useAdultTypes } from 'hooks/catalog/adultTypes';
import { TextField } from 'ui/input/TextField';
import { ProfileAvatar } from 'ui/avatar/ProfileAvatar';
import { useChildAvatar } from 'hooks/children/useChildAvatar';

type type = 'add' | 'edit';

interface Props {
  disabled?: boolean;
  data?: Children;
  onChangeMode?: (editMode: boolean) => void;
  type: type;
}

interface Form extends Partial<Omit<ChildrenUpdateForm, 'adult_type_id'>> {
  adult_type_id: number | '';
}

interface Errors extends Partial<Record<keyof Children, string | undefined>> {}

const initForm = (data?: Children): Form => ({
  ...data,
  photo: undefined,
  dob: toLocalDate(data?.dob || ''),
  adult_type_id: data?.adult_type_id || '',
  patronymic: data?.patronymic || '',
});

export const MainPanel: React.FC<Props> = ({ onChangeMode, disabled, data, type }) => {
  const avatar = useChildAvatar(data?.photo);
  const { data: adultTypeList, map: adultTypeMap } = useAdultTypes();
  const [editMode, setEditMode] = useState(false);
  const [form, setForm] = useState<Form>(initForm(data));
  const [errors, setErrors] = useState<Errors>({});
  const history = useHistory();

  useEffect(() => {
    setEditMode(false);
    onChangeMode?.(false);
  }, [data, onChangeMode]);

  useEffect(() => {
    setForm(initForm(data));
  }, [data, editMode]);

  const handleClickEdit = useCallback(() => {
    setEditMode(true);
    onChangeMode?.(true);
  }, [onChangeMode]);

  const handleClickCancel = useCallback(() => {
    setEditMode(false);
    onChangeMode?.(false);
    setForm(initForm(data));
    setErrors({});
  }, [onChangeMode, data]);

  const handleChangeField = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));
    setErrors((errors) => ({
      ...errors,
      [name]: undefined,
    }));
  }, []);

  const validate = useCallback(() => {
    const errors: Errors = {
      last_name: maxValidator(form.last_name, 100, true),
      first_name: maxValidator(form.first_name, 100, true),
      patronymic: maxValidator(form.patronymic, 100),
      dob: dateValidator(form.dob, true, true),
      adult_type_id: requiredValidator(form.adult_type_id),
    };
    setErrors(errors);
    return isValid(errors);
  }, [form]);

  const handleClickSave = useCallback(async () => {
    if (!validate()) return;
    try {
      if (data) await childrenUpdateFX(form as ChildrenUpdateForm);
      else {
        const ret = await childrenCreateFX(form as ChildrenForm);
        history.push('/profile/children/' + ret.data.id);
      }
      setEditMode(false);
      onChangeMode?.(false);
    } catch (e) {
      await showErrorAlert();
    }
  }, [form, onChangeMode, data, history, validate]);

  const handleUploadPhoto = useCallback(
    (photo: File) => {
      if (data) childrenUpdateFX({ ...data, photo });
    },
    [data],
  );

  const adultType = useMemo(() => {
    return form.adult_type_id ? adultTypeMap.get(form.adult_type_id)?.title : '';
  }, [adultTypeMap, form]);

  const setEditModeForMobile = useCallback(() => {
    if (
      (type === 'edit' &&
        (form.last_name !== data?.last_name ||
          form.first_name !== data?.first_name ||
          (form.patronymic !== data?.patronymic && data?.patronymic !== null) ||
          (form.patronymic !== '' && data?.patronymic === null) ||
          toISODate(form.dob) !== data?.dob ||
          form.adult_type_id !== data?.adult_type_id)) ||
      (type === 'add' &&
        ((form.last_name !== undefined && form.last_name !== '') ||
          (form.first_name !== undefined && form.first_name !== '') ||
          form.patronymic !== '' ||
          form.dob !== '' ||
          form.adult_type_id !== ''))
    ) {
      return true;
    } else return false;
  }, [form, data, type]);

  return (
    <PanelEditable
      classname={cl.root}
      onClickCancel={handleClickCancel}
      type="children"
      editMode={editMode || setEditModeForMobile()}
      onClickSave={handleClickSave}
    >
      <ProfileAvatar onUpload={handleUploadPhoto} src={avatar} />
      <Header
        title="Основные данные"
        classname={cl.header}
        onClickEdit={handleClickEdit}
        showEditBtn={!editMode}
        disabled={disabled}
      />
      <Field
        className={cl.inputDesctop}
        label="Фамилия"
        value={form?.last_name === '' || form?.last_name === undefined ? '-' : form?.last_name}
        editMode={editMode}
        error={errors.last_name}
      >
        <Input
          fullWidth
          value={form.last_name}
          name="last_name"
          onChange={handleChangeField}
          error={!!errors.last_name}
        />
      </Field>
      <TextField
        className={cl.inputMobile}
        label="Фамилия"
        fullWidth
        value={form?.last_name || ''}
        name="last_name"
        onChange={handleChangeField}
        error={!!errors.last_name}
      />
      <Field
        className={cl.inputDesctop}
        label="Имя"
        value={form.first_name === '' || form.first_name === undefined ? '-' : form.first_name}
        editMode={editMode}
        error={errors.first_name}
      >
        <Input
          fullWidth
          value={form.first_name}
          name="first_name"
          onChange={handleChangeField}
          error={!!errors.first_name}
        />
      </Field>
      <TextField
        className={cl.inputMobile}
        label="Имя"
        value={form.first_name || ''}
        error={!!errors.first_name}
        fullWidth
        name="first_name"
        onChange={handleChangeField}
      />
      <Field
        className={cl.inputDesctop}
        label="Отчество"
        value={form?.patronymic === '' ? '-' : form?.patronymic}
        editMode={editMode}
        error={errors.patronymic}
      >
        <Input
          fullWidth
          value={form.patronymic}
          name="patronymic"
          onChange={handleChangeField}
          error={!!errors.patronymic}
        />
      </Field>
      <TextField
        className={cl.inputMobile}
        label="Отчество"
        value={form?.patronymic || ''}
        error={!!errors.patronymic}
        fullWidth
        name="patronymic"
        onChange={handleChangeField}
      />
      <Field
        className={cl.inputDesctop}
        label="Дата рождения"
        value={form?.dob === '' ? '-' : form?.dob}
        editMode={editMode}
        error={errors.dob}
      >
        <Input
          fullWidth
          value={form.dob}
          name="dob"
          onChange={handleChangeField}
          error={!!errors.dob}
          inputComponent={DateMask}
        />
      </Field>
      <TextField
        className={cl.inputMobile}
        label="Дата рождения"
        fullWidth
        value={form?.dob || ''}
        name="dob"
        onChange={handleChangeField}
        error={!!errors.dob}
        InputProps={{ inputComponent: DateMask }}
      />
      <Field
        className={cl.inputDesctop}
        label="Статус законного представителя"
        value={adultType === '' ? '-' : adultType}
        editMode={editMode}
        error={errors.adult_type_id}
      >
        <TextField
          fullWidth
          value={form.adult_type_id || ''}
          name="adult_type_id"
          onChange={handleChangeField}
          select
          noHelper
          error={!!errors.adult_type_id}
        >
          {adultTypeList.map(({ id, title }) => (
            <MenuItem key={id} value={id}>
              {title}
            </MenuItem>
          ))}
        </TextField>
      </Field>
      <TextField
        className={cl.inputMobile}
        label="Статус законного представителя"
        fullWidth
        value={form.adult_type_id || ''}
        name="adult_type_id"
        onChange={handleChangeField}
        select
        noHelper
        error={!!errors.adult_type_id}
      >
        {adultTypeList.map(({ id, title }) => (
          <MenuItem key={id} value={id}>
            {title}
          </MenuItem>
        ))}
      </TextField>
    </PanelEditable>
  );
};
