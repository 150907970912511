import moment from 'moment';
import { DATE_LOCAL } from '../../constants/date/format';
import { SnilsError, validateSnils } from './snils';

export const isValid = (obj: any) => !Object.values(obj).some((c) => !!c);

export const emailValidator = (email: string, required: boolean = false) => {
  if (required && !email.trim().length) return 'E-mail не может быть пустым';

  const input = document.createElement('input');
  input.type = 'email';
  input.value = email;

  return input.validity.valid ? undefined : 'Некорректный формат почты';
};

export const requiredValidator = (val: any) => (!val ? 'Необходимо указать значение' : undefined);

export const dateValidator = (date: string | undefined, required: boolean = false, notFeature: boolean = false) => {
  let err;
  if (required && (err = requiredValidator(date))) return err;

  if (!date) return undefined;

  const mDate = moment(date, DATE_LOCAL, true);

  if (!mDate.isValid() || mDate.year() < 1900 || (notFeature && mDate.isAfter(moment(), 'day')))
    return 'Некорректная дата';

  return undefined;
};

export const onlyDigit = (val: string) => val.replace(/[^\d]+/g, '');

export const lengthValidator = (val: string | undefined, length: number, required: boolean = false) => {
  if (!val && !required) return undefined;
  const str = onlyDigit(val || '');

  let err;
  if (required && (err = requiredValidator(str))) return err;

  if (str.length !== length) return 'Неверный формат';

  return undefined;
};

export const passportValidator = (val: string | undefined, required: boolean = false) =>
  lengthValidator(val, 10, required);

export const inilaValidator = (val: string | undefined, required: boolean = false) => {
  let err;
  if (required && (err = requiredValidator(val))) return err;
  if (!required && !val) return undefined;

  const snilsError: Partial<SnilsError> = {};
  if (!validateSnils(onlyDigit(val!), snilsError)) return snilsError.message;

  return undefined;
};

export const maxValidator = (val: string | undefined = '', length: number, required: boolean = false) => {
  let err;
  if (required && (err = requiredValidator(val.trim()))) return err;

  if (val?.length > length) return 'Максимальное количество символов: ' + length;

  return undefined;
};

export const classValidator = (val: string | undefined = '', required: boolean = false) => {
  if (!val && !required) return undefined;

  let err;
  if (required && (err = requiredValidator(val))) return err;

  let done = false;
  const { length } = val;
  if (length === 3 && /\d\s[а-яА-Я]/.test(val)) done = true;
  else if (length === 4 && /\d\d\s[а-яА-Я]/.test(val)) done = true;

  return done ? undefined : 'Неверный формат';
};
