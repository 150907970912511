import React, { useMemo, useState } from 'react';
import { MainPanel } from './MainPanel';
import { RequisitesPanel } from './RequisitesPanel';
import { RouteComponentProps } from 'react-router-dom';
import { useChildren } from 'hooks/children';
import cl from './Edit.module.scss';
import { PageContent } from 'components/layout/PageContent';

interface Props extends RouteComponentProps<Params> {}

interface Params {
  id?: string;
}

export const Edit: React.FC<Props> = ({ match }) => {
  const { map: childrenMap } = useChildren();
  const [changeMode, setChangeMode] = useState(false);
  const { id } = match.params;
  const data = useMemo(() => (id ? childrenMap.get(+id) : undefined), [id, childrenMap]);

  return (
    <PageContent type={'children'} childId={id} className={cl.root} titleSM="Данные ребенка">
      <MainPanel type={id ? 'edit' : 'add'} data={data} disabled={changeMode} onChangeMode={setChangeMode} />
      <RequisitesPanel
        data={data}
        type={id ? 'edit' : 'add'}
        disabled={!data || changeMode}
        onChangeMode={setChangeMode}
      />
    </PageContent>
  );
};
