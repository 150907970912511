import api from 'api/request/documents';
import { useStore } from 'effector-react';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getDocumentsHistoryFx } from 'state/documents';
import HistoryPanel from 'ui/panels/HistoryPanel/HistoryPanel';

const HistoryDocuments = () => {
  const [history, setHistory] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getDocumentsHistoryFx().then(({ data }) => {
      setHistory(data);
    });
  }, []);

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  const searchedHistory = useMemo(() => history.filter((el) => el.freedoc.title.includes(searchValue)), [
    searchValue,
    history,
  ]);

  return (
    <HistoryPanel
      application={api.contract}
      type={'documents'}
      loading={useStore(getDocumentsHistoryFx.pending)}
      searchedHistory={searchedHistory}
      handleChangeSearch={handleChangeSearch}
      applicationType={'Договор'}
      titleSM="История записи"
    />
  );
};

export default HistoryDocuments;
