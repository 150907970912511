import React, { useEffect } from 'react';
import { loginMosRuFx } from '../../state/auth';
import { Backdrop } from 'ui/feedback/Backdrop';
import { useStore } from 'effector-react';
import { useHistory } from 'react-router';
import { showErrorNetAlert } from 'ui/dialogs/Alert';

interface Props {}

export const Sudir: React.FC<Props> = () => {
  const loading = useStore(loginMosRuFx.pending);
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state');
    if (!code || state !== localStorage.getItem('mosru_state')) {
      history.replace('/login');
      return;
    }

    loginMosRuFx({ code })
      .then(() => {
        // const redirect = (history.location.state as string)?.split('?redirect=')?? [];
        history.replace('/');
      })
      .catch(() => {
        showErrorNetAlert().then(() => history.replace('/login'));
      });
  }, [history]);

  return <Backdrop open={loading} />;
};
