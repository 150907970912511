import React, { useState } from 'react';
import { PanelMini } from '../PanelMini';
import cl from './PhotosPanel.module.scss';
import { PhotoBase } from 'api/types/common';
import { ImageGalleryModal } from 'ui/imageGalery/ImageGaleryModal';
interface Props {
  data: PhotoBase[];
}
export const PhotosPanel: React.FC<Props> = ({ data }) => {
  const [startImgGallery, setStartImgGallery] = useState<number>();
  const imgItems = data.map((c) => ({ source: c.photo }));

  return (
    <>
      <PanelMini title="Фотографии" classname={cl.photosPanel}>
        <div className={cl.photos}>
          {data.map((item, i) => (
            <div className={cl.photo} key={i} onClick={setStartImgGallery.bind(null, i)}>
              <img key={item.id} src={item.photo} alt="" />
            </div>
          ))}
        </div>
      </PanelMini>
      <ImageGalleryModal
        open={startImgGallery !== undefined}
        items={imgItems}
        onClose={setStartImgGallery.bind(null, undefined)}
        startIndex={startImgGallery}
      />
    </>
  );
};
