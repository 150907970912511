import cl from './LoginLayout.module.scss';
import React, { lazy, useCallback, useMemo, Suspense } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import Icon from 'ui/Icon';
import LoadingPage from '../../page/LoadingPage';
import logo_kurchat_gif from 'assets/img/logo.gif';

const PrivacyPage = lazy(() => import('routes/privacy/components/Page'));
const AgreementPage = lazy(() => import('routes/agreement/components/Page'));

interface Props {}

const LoginLayout: React.FC<Props> = ({ children }) => {
  const history = useHistory();

  const handleClickPrivacy = useCallback(() => {
    history.push('/privacy');
  }, [history]);

  const [showPrivacy, showAgreement] = useMemo(() => {
    const qs = new URLSearchParams(history.location.search);
    return [Boolean(+(qs.get('showPrivacy') || 0)), Boolean(+(qs.get('showAgreement') || 0))];
  }, [history.location.search]);

  const pathname = useLocation();

  const handleCloseExtraText = useCallback(() => {
    const qs = new URLSearchParams(history.location.search);
    console.log('qs', qs, history.location.search, qs.has('showPrivacy'));
    qs.delete('showPrivacy');
    qs.delete('showAgreement');
    history.push(`?${qs.toString()}`, history.location.state);
  }, [history]);

  return (
    <div className={cl.root}>
      <img
        src={logo_kurchat_gif}
        alt=""
        className={pathname.pathname.split('/').length > 2 ? cl.noneImg : cl.gifStyle}
      />
      {showPrivacy || showAgreement ? (
        <div className={cl.extraText}>
          <IconButton onClick={handleCloseExtraText} className={cl.closeBtn}>
            <Icon type="Close" />
          </IconButton>
          <div className={cl.extraTextContent}>
            <Suspense fallback={<LoadingPage />}>{showPrivacy ? <PrivacyPage /> : <AgreementPage />}</Suspense>
          </div>
        </div>
      ) : (
        <>
          {children}
          <Button
            color="primary"
            className={pathname.pathname.split('/').length > 2 ? cl.politic : cl.politicGray}
            onClick={handleClickPrivacy}
          >
            Политика конфиденциальности
          </Button>
        </>
      )}
    </div>
  );
};

export default LoginLayout;
