import moment from 'moment';

export const DATE_ISO = 'YYYY-MM-DD';

export const DATE_MONTH = 'DD MMM YYYY';

export const DATE_LOCAL = 'DD.MM.YYYY';

export const toLocalDate = (date?: string | null) => (!date ? '' : moment(date).format(DATE_LOCAL));

export const toISODate = (date?: string | null) => (!date ? '' : moment(date, DATE_LOCAL).format(DATE_ISO));
