import { createEffect, createStore, createEvent, guard, sample } from 'effector';
import { ApiResponse } from 'api/types';
import { ApiError } from 'api/errors/apiError';
import api from 'api/request/schools';
import { FetchingListState, initFetchingListState, storeAttachFetchList } from '../utils';
import { Schools } from 'api/types/schools';
import { logout } from 'state/auth';

export interface SchoolState extends FetchingListState<Schools> {}

export const getSchoolsListFx = createEffect<void, ApiResponse<Schools[]>, ApiError<any>>({
  handler: async () => await api.list(),
});

export const fetchSchoolListIfNeed = createEvent();

export const $schools = createStore<SchoolState>(initFetchingListState<Schools>()).reset(logout);

guard({
  source: sample($schools, fetchSchoolListIfNeed),
  filter: $schools.map((state) => !state.isFetching && !state.didFetched),
  target: getSchoolsListFx,
});

storeAttachFetchList($schools, [getSchoolsListFx]);

export const setActiveProgramm = createEvent<number>();

export const $activeProgramm = createStore(-1).on(setActiveProgramm, (state, id) => (state = id));
