import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { LeftSide } from 'components/layout/MainLayout/LeftSide';
import { useClubs } from 'hooks/clubs';
import cl from './ClubsLeftSide.module.scss';
import { ClubItem } from '../ClubItem';
import { useHistory, useLocation } from 'react-router-dom';
import { setCurrentClub } from 'state/club';
import { resetSteps } from '../ClubJoin/model';
import { $clubsCategories, fetchClubCategoriesIfNeed } from 'state/club/category';
import { useStore } from 'effector-react';
import { ClubCategoryItem } from '../ClubCategoryItem';
import { $categoryFilter, resetCategoryFilter, toggleSelectCategory } from 'state/club/categoryFilter';
import { SearchInput } from 'ui/input/SearchInput';

interface Props {
  isRootPath: boolean;
}

const ClubsLeftSide: React.FC<Props> = ({ isRootPath }) => {
  const { data: categoryList } = useStore($clubsCategories);
  const { data: clubList } = useClubs();
  const history = useHistory();
  const { pathname } = useLocation();
  const [filter, setFilter] = useState<string>('');
  const [showFilter, setShowFilter] = useState(false);
  const categoryFilter = useStore($categoryFilter);

  useEffect(() => {
    if (showFilter) fetchClubCategoriesIfNeed();
  }, [showFilter]);

  const clubListFiltered = useMemo(
    () =>
      clubList.filter(
        (c) =>
          (categoryFilter.size === 0 || categoryFilter.has(c.category_id)) &&
          (c.title.toLowerCase().includes(filter.toLowerCase()) || c.code.includes(filter)),
      ),
    [filter, clubList, categoryFilter],
  );
  const handleChangeFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  }, []);

  const handleClickItem = useCallback(
    (clubId: number) => {
      history.push(`/clubs/${clubId}`);
    },
    [history],
  );

  const clubId = useMemo(() => {
    return +pathname.split('/')[2];
  }, [pathname]);

  useEffect(() => {
    resetSteps();
    setCurrentClub(clubId);
  }, [clubId]);

  const handleClickCategory = useCallback((id: number) => {
    toggleSelectCategory(id);
  }, []);

  const handleResetFilter = useCallback(() => {
    resetCategoryFilter();
  }, []);

  return (
    <LeftSide
      isRootPath={isRootPath}
      title="Кружок"
      withFilter
      onClickFilter={setShowFilter.bind(null, true)}
      onCloseFilter={setShowFilter.bind(null, false)}
      onResetFilter={handleResetFilter}
      showFilter={showFilter}
      activeFilter={!!categoryFilter.size}
      topComponent={
        !showFilter ? (
          <SearchInput onChange={handleChangeFilter} className={cl.filter} />
        ) : (
          <div className={cl.filter}>Фильтр по категориям</div>
        )
      }
    >
      <ul className={cl.menu}>
        {!showFilter ? (
          clubListFiltered.length > 0 ? (
            clubListFiltered.map((c) => (
              <ClubItem key={c.id} data={c} onClick={handleClickItem} isActive={clubId === c.id} />
            ))
          ) : (
            <div className={cl.emptyFilter}>
              Ничего не найдено :( <br />
              Попробуйте изменить запрос <br />
              или обновить страницу.
            </div>
          )
        ) : (
          categoryList.map((c) => (
            <ClubCategoryItem
              key={c.id}
              data={c}
              checked={categoryFilter.has(c.id)}
              onClick={handleClickCategory.bind(null, c.id)}
            />
          ))
        )}
      </ul>
    </LeftSide>
  );
};

export default ClubsLeftSide;
