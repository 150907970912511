import { createEffect } from 'effector';

import api from 'api/request/profile';
import { $profile } from './index';
import { ApiResponse } from 'api/types';

export const updateProfileAvatarFx = createEffect<File, ApiResponse<{ photo: string }>>({
  handler: async (file: File) => await api.updatePhoto(file),
});

$profile.on(updateProfileAvatarFx.doneData, (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    photo: data.photo,
  },
}));
