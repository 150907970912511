import React, { useCallback, useMemo, useState } from 'react';
import { Step } from 'ui/edits/Step';
import { ButtonItem } from 'ui/list/ButtonItem';
import cl from './Step4.module.scss';
import Icon from 'ui/Icon';
import cn from 'classnames';
import { $step4, gartenJoinFx, nextStep, prevStep, step4Set } from '../../model';
import { useStore } from 'effector-react';
import { showAlert, showErrorAlert } from 'ui/dialogs/Alert';
import { UPLOAD_FILE_SIZE } from 'constants/config';
import { ApiUnprocessableError } from 'api/errors';
import { FileLoadPanel } from 'ui/panels/FileLoadPanel';
import { handleFiles } from 'utils/helpers';
import { Backdrop } from 'ui/feedback/Backdrop';
import { useGartens } from 'hooks/gartens';
import { $activeGarten } from 'state/garten';
import { Gartens } from 'api/types/gartens';

interface Props {}

interface DataType {
  id: number;
  name: string;
}

const facilities: DataType[] = [
  {
    id: 1,
    name: 'Многодетные семьи',
  },
  {
    id: 2,
    name: 'Иные льготы',
  },
];

export const Step4: React.FC<Props> = () => {
  const step4Form = useStore($step4);

  const loading = useStore(gartenJoinFx.pending);

  const { data } = useGartens();

  const [isFetching, setFetching] = useState(false);

  const handleNext = useCallback(async () => {
    try {
      await gartenJoinFx();
      nextStep();
    } catch (e) {
      if (e instanceof ApiUnprocessableError) await showErrorAlert();
      else
        await showAlert(
          'error',
          'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
          'Ошибка',
        );
    }
  }, []);

  const handlePrevStep = useCallback(() => {
    prevStep();
  }, []);

  const handleRemoveFile = useCallback(
    (typeOfFile: string) => {
      let files = step4Form.files;
      files = files.filter((element) => element.name !== typeOfFile);
      if (files.length === 0) {
        step4Set({
          ...step4Form,
          files,
          isPrivileges: true,
        });
      } else {
        step4Set({
          ...step4Form,
          files,
        });
      }
    },
    [step4Form],
  );

  const handleSizeError = useCallback(() => {
    showAlert(
      'error',
      `Максимальный размер файлов для загрузки составляет ${(UPLOAD_FILE_SIZE / 1024 / 1024).toFixed(
        1,
      )}Mb. Файлы большего размера не будут загружены.`,
      'Ошибка',
    );
  }, []);

  const currentId = useStore($activeGarten);

  const currentGarten: Gartens | undefined = data.find((i) => i.id === +currentId);

  const currentTitle = useMemo(() => {
    if (currentGarten?.maternal_capital) {
      return 'Льготы и материнский капитал';
    } else {
      return 'Льготы';
    }
  }, [currentGarten]);

  return (
    <Step isFileLoader={true} title={currentTitle} onClickNext={handleNext} prevStep={handlePrevStep}>
      <Backdrop open={loading || isFetching} />
      <div className={cl.section}>
        <div className={cl.subtitle}>Выбор основания льготы</div>
        <CheckedItem label="Льготы отсутсвуют" selected={step4Form.isPrivileges} />
        <div className={cl.files}>
          {facilities.map((element) => (
            <div key={element.id} className={cl.file}>
              <FileLoadPanel
                data={step4Form.files}
                handleRemoveFile={handleRemoveFile}
                key={element.id}
                name={element.name}
                onClick={handleFiles.bind(null, setFetching, step4Form, step4Set)}
                onSizeError={handleSizeError}
              />
            </div>
          ))}
        </div>
      </div>

      {currentGarten?.maternal_capital && (
        <>
          <div className={cl.subtitle}>Материнский капитал</div>
          <CheckedItem
            label="Использовать материнский капитал"
            selected={step4Form.isMaternalCapital}
            onClick={step4Set.bind(null, { ...step4Form, isMaternalCapital: !step4Form.isMaternalCapital })}
          />
        </>
      )}
    </Step>
  );
};

interface CheckedItemProps {
  label: string;
  selected?: boolean;
  onClick?: () => void;
}

const CheckedItem: React.FC<CheckedItemProps> = ({ label, selected = false, onClick }) => {
  return (
    <div className={cl.itemWrapper}>
      <ButtonItem className={cn(cl.checkedItem, selected && cl.selected)} onClick={onClick}>
        <div className={cl.check}>
          <Icon type={selected ? 'CheckCircle' : 'Circle'} />
        </div>
        <span>{label}</span>
      </ButtonItem>
    </div>
  );
};
