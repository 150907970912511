import React from 'react';
import cl from './About.module.scss';
import { APP_VERSION } from 'constants/config';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import logo from 'assets/img/logoBig.png';
import { PageContent } from 'components/layout/PageContent';

interface Props {}

export const About: React.FC<Props> = () => {
  return (
    <PageContent className={cl.root} titleSM="О сайте">
      <img alt="" src={logo} />
      <div className={cl.txt}>Официальное приложение ГБОУ Курчатовская школа</div>
      <div className={cl.txt}>Новости, запись в кружки, достижения ваших детей и многое другое.</div>
      <div className={cl.version}>Версия приложения: {APP_VERSION}</div>
      <Button color="primary" className={cl.link} component={Link} to="/privacy">
        Политика конфиденциальности
      </Button>
      <Button color="primary" className={cl.link} component={Link} to="/agreement">
        Пользовательское соглашение
      </Button>
    </PageContent>
  );
};
