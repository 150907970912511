import React from 'react';
import { DefaultContent } from 'components/layout';

interface Props {}

const EmptyProgramm: React.FC<Props> = () => {
  return (
    <DefaultContent>
      Для выбранной программы и параллели отсутствуют места обучения. Попробуйте выбрать другую карточку.
    </DefaultContent>
  );
};

export default EmptyProgramm;
