import { useEffect, useState } from 'react';
import api from '../../api/request/children';

export const useChildAvatar = (fileName: string | null | undefined) => {
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    if (fileName)
      api.getPhoto(fileName).then((e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          setAvatar(reader.result);
        };
      });
    else setAvatar(undefined);
  }, [fileName]);

  return avatar;
};
