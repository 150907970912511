import React from 'react';

const Edit: React.FC = () => {
  return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.6888 19.8266C28.2761 19.8266 27.9416 20.1628 27.9416 20.5775V27.245C27.9402 28.4887 26.9376 29.4966 25.7002 29.4978H4.73577C3.4983 29.4966 2.49577 28.4887 2.49431 27.245V7.67652C2.49577 6.43309 3.4983 5.4252 4.73577 5.42373H11.3697C11.7824 5.42373 12.1168 5.08758 12.1168 4.6728C12.1168 4.25833 11.7824 3.92188 11.3697 3.92188H4.73577C2.67351 3.92422 1.00233 5.60384 1 7.67652V27.2453C1.00233 29.3179 2.67351 30.9976 4.73577 30.9999H25.7002C27.7624 30.9976 29.4336 29.3179 29.4359 27.2453V20.5775C29.4359 20.1628 29.1015 19.8266 28.6888 19.8266Z"
            fill="currentColor" stroke="currentColor"/>
        <path
            d="M29.14 1.98977C27.827 0.670075 25.6981 0.670075 24.3851 1.98977L11.0554 15.3868C10.964 15.4786 10.8981 15.5924 10.8636 15.7174L9.11073 22.0777C9.03864 22.3385 9.1119 22.6177 9.30219 22.8093C9.49277 23.0005 9.77062 23.0742 10.0301 23.002L16.3584 21.24C16.4828 21.2053 16.596 21.1391 16.6873 21.0472L30.0168 7.6499C31.3278 6.32932 31.3278 4.19152 30.0168 2.87094L29.14 1.98977ZM12.6834 15.8749L23.5927 4.91018L27.111 8.4463L16.2014 19.411L12.6834 15.8749ZM11.9806 17.2923L14.7914 20.1177L10.9033 21.2004L11.9806 17.2923ZM28.9602 6.58804L28.1678 7.38444L24.6492 3.84803L25.4419 3.05164C26.1712 2.3186 27.3538 2.3186 28.0832 3.05164L28.9602 3.9328C29.6884 4.66672 29.6884 5.85442 28.9602 6.58804Z"
            fill="currentColor" stroke="currentColor"/>
      </svg>
  );
};

export default Edit;
