import { createEffect, createEvent, createStore } from 'effector';
import moment, { Moment } from 'moment';
import { ClubJoinForm, ClubJoinResponseData } from 'api/types/clubs';
import api from 'api/request/clubs';
import { $currentClub } from 'state/club';
import { DATE_ISO } from 'constants/date/format';
import { ApiBoolean, ApiResponse } from 'api/types';
import { FetchingState, initFetchingState, storeAttachFetch } from 'state/utils';
import { Children } from '../../../../../../api/types/children';
import { Profile } from '../../../../../../api/types/profile';
import { Map as ImmutableMap } from 'immutable';
import { invalidateProfile } from '../../../../../../state/profile';
import { FileDescr } from 'api/types/common';

export type ViewTypeClub = 'step' | 'profile' | 'requisites' | 'child' | 'email';

export const changeViewType = createEvent<ViewTypeClub>();

export const resetSteps = createEvent();

export const $viewType = createStore<ViewTypeClub>('step')
  .on(changeViewType, (_, type) => type)
  .reset(resetSteps);

interface Step1State {
  date: Moment;
}

interface Step2State {
  profile: Profile | undefined;
  needSave: boolean;
}

interface Step3State {
  child_id?: number;
  needSave: boolean;
  childrenList?: Children[];
  childrenMap?: ImmutableMap<number, Children>;
}

interface Step4State {
  isPrivileges: boolean;
  files: FileDescr[];
}

export const nextStep = createEvent();
export const prevStep = createEvent();
export const $step = createStore(0)
  .on(nextStep, (step) => step + 1)
  .on(prevStep, (step) => step - 1)
  .reset(resetSteps);

//----------step1
export const step1SetDate = createEvent<Moment>();
export const $step1 = createStore<Step1State>({ date: moment() })
  .on(step1SetDate, (_, date) => ({ date }))
  .reset(resetSteps);

//----------step2

export const step2SetProfile = createEvent<Profile>();
export const step2ToggleSaver = createEvent();
export const $step2 = createStore<Step2State>({ profile: undefined, needSave: true })
  .on(step2SetProfile, (state, profile) => ({
    ...state,
    profile,
  }))
  .on(step2ToggleSaver, (state) => ({
    ...state,
    needSave: !state.needSave,
  }))
  .reset(resetSteps);

//----------step3
export const step3SetChild = createEvent<number | undefined>();
export const addChild = createEvent<Children>();
export const updateChild = createEvent<Children>();
export const initChild = createEvent<Children[]>();
export const step3ToggleSaver = createEvent();
export const $step3 = createStore<Step3State>({ needSave: true })
  .on(step3SetChild, (state, child_id) => ({ ...state, child_id }))
  .on(initChild, (state, a) => ({
    ...state,
    childrenList: a,
    childrenMap: ImmutableMap(a.map((c) => [c.id, c])),
  }))
  .on(addChild, (state, child) => ({
    ...state,
    childrenList: [...state.childrenList!, child],
    childrenMap: state.childrenMap!.set(child.id, child),
  }))
  .on(updateChild, (state, child) => ({
    ...state,
    childrenList: state.childrenList!.map((c) => (c.id === child.id ? child : c)),
    childrenMap: state.childrenMap!.set(child.id, child),
  }))
  .on(step3ToggleSaver, (state) => ({
    ...state,
    needSave: !state.needSave,
  }))
  .reset(resetSteps);

//----------step4
export const step4Set = createEvent<Step4State>();
export const $step4 = createStore<Step4State>({ files: [], isPrivileges: true })
  .on(step4Set, (_, newState) => newState)
  .reset(resetSteps);

export const clubJoinFx = createEffect<void, ApiResponse<ClubJoinResponseData>>({
  handler: async () => {
    const { files, isPrivileges } = $step4.getState();
    const { child_id, childrenMap, needSave: save_child_data } = $step3.getState();
    const { needSave: save_user_data, profile } = $step2.getState();
    const {
      first_name: user_first_name,
      last_name: user_last_name,
      patronymic: user_patronymic,
      dob: user_dob,
      requisites,
    } = profile!;
    const {
      passport_number: user_passport_number,
      passport_issued_date: user_passport_issued_date,
      passport_issued_by: user_passport_issued_by,
      address: user_address,
    } = requisites;

    const {
      first_name: child_first_name,
      last_name: child_last_name,
      patronymic: child_patronymic,
      dob: child_dob,
      document_type,
      document_number: child_document_number,
      document_issued_by: child_document_issued_by,
      document_issued_date: child_document_issued_date,
      address: child_address,
      inila: child_inila,
      phone: child_phone,
      adult_type_id,
    } = childrenMap!.get(child_id!)!;

    const form: ClubJoinForm = {
      club_id: $currentClub.getState() as number,
      child_id: child_id! > 0 ? child_id : undefined,
      start_date: $step1.getState().date.format(DATE_ISO),
      handicapped: +!(JSON.stringify(files.filter((elem) => elem.name === 'Дети-инвалиды')) === '[]') as ApiBoolean,
      orphan: +!(
        JSON.stringify(files.filter((elem) => elem.name === 'Сироты и дети, оставшиеся без попечения родителей')) ===
        '[]'
      ) as ApiBoolean,
      multi_children: +!(
        JSON.stringify(files.filter((elem) => elem.name === 'Дети из многодетных семей')) === '[]'
      ) as ApiBoolean,
      maternal_capital: +!(
        JSON.stringify(files.filter((elem) => elem.name === 'Использовать материнский капитал')) === '[]'
      ) as ApiBoolean,
      isPrivileges: isPrivileges,
      file_name: files,

      save_user_data: +save_user_data as ApiBoolean,
      user_last_name: user_last_name!,
      user_first_name: user_first_name!,
      user_patronymic: user_patronymic!,
      user_dob: user_dob!,
      user_passport_number: user_passport_number!,
      user_passport_issued_by: user_passport_issued_by!,
      user_passport_issued_date: user_passport_issued_date!,
      user_address: user_address!,

      save_child_data: +save_child_data as ApiBoolean,
      // child_photo? : File
      child_last_name: child_last_name!,
      child_first_name: child_first_name!,
      child_patronymic: child_patronymic!,
      child_dob: child_dob!,
      document_type: document_type!,
      child_document_number: child_document_number!,
      child_document_issued_by: child_document_issued_by!,
      child_document_issued_date: child_document_issued_date!,
      child_address: child_address!,
      child_inila: child_inila!,
      child_phone: child_phone,

      adult_type_id: adult_type_id,
    };

    return await api.join(form);
    //     return Promise.resolve();
  },
});

export interface ClubJoinState extends FetchingState<ClubJoinResponseData> {}

export const $clubJoin = createStore<ClubJoinState>(initFetchingState());

storeAttachFetch($clubJoin, [clubJoinFx]);

/*clubJoinFx.doneData.watch(() => {
  invalidateProfile();
});*/

// ---------

export const setEditChild = createEvent<Children | null>();

export const $editChild = createStore<Children | null>(null).on(setEditChild, (_, c) => c);

//---------
export const sendJoinCodeFx = createEffect<{ id: number; code: string }, ApiResponse>({
  handler: async ({ id, code }) => await api.code(id, code),
});

sendJoinCodeFx.doneData.watch(() => {
  invalidateProfile();
});
