import React, { ChangeEvent, useCallback, useMemo } from 'react';
import cl from './RequisitesPanel.module.scss';
import { PanelEditable } from 'ui/panels';
import { Field, Header } from 'ui/panels/PanelEditable';
import { Input } from '@material-ui/core';
import { ProfileRequisites, ProfileRequisitesForm } from 'api/types/profile';
import { checkRequisitesFilled, updateRequisitesFx } from 'state/profile';
import { toISODate } from 'constants/date/format';
import { DateMask } from 'ui/masks';
import { dateValidator, isValid, maxValidator } from 'utils/validators';
import { showErrorAlert } from 'ui/dialogs/Alert';
import cn from 'classnames';
import { TextField } from 'ui/input/TextField';

interface Errors extends Partial<Form> {}

interface Props {
  form: Form;
  editMode: boolean;
  setForm: (form: any) => void;
  requisites: ProfileRequisites | undefined;
  errors: Errors;
  setEditMode: (mode: boolean) => void;
  setErrors: (errors: any) => void;
  setEditModeForMobile: boolean;
  handleClickCancel: () => void;
}

interface Form extends ProfileRequisitesForm {}

export const RequisitesPanel: React.FC<Props> = ({
  handleClickCancel,
  setEditModeForMobile,
  requisites,
  setErrors,
  form,
  errors,
  editMode,
  setForm,
  setEditMode,
}) => {
  const onClickEdit = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const validate = useCallback(() => {
    const errors: Partial<ProfileRequisitesForm> = {
      passport_number: maxValidator(form.passport_number, 20),
      passport_issued_date: dateValidator(form.passport_issued_date, false, true),
      passport_issued_by: maxValidator(form.passport_issued_by, 500),
      address: maxValidator(form.address, 500),
      actual_address: maxValidator(form.actual_address, 500),
    };
    setErrors(errors);
    return isValid(errors);
  }, [form, setErrors]);

  const handleClickSave = useCallback(async () => {
    if (!validate()) {
      return;
    }
    try {
      await updateRequisitesFx({
        passport_number: form.passport_number,
        passport_issued_by: form.passport_issued_by,
        passport_issued_date: toISODate(form.passport_issued_date),
        address: form.address,
        actual_address: form.actual_address,
      } as ProfileRequisitesForm);
      setEditMode(false);
    } catch (e) {
      await showErrorAlert();
    }
  }, [form, validate, setEditMode]);

  const handleChangeForm = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.currentTarget;
      setForm((form: Form) => ({
        ...form,
        [name]: value,
      }));
      setErrors((errors: Error) => ({
        ...errors,
        [name]: undefined,
      }));
    },
    [setErrors, setForm],
  );

  const filled = useMemo(() => checkRequisitesFilled(requisites), [requisites]);

  return (
    <>
      <Header
        title="Реквизиты"
        status={!editMode && !filled ? 'Не заполнено' : undefined}
        onClickEdit={onClickEdit}
        onClickCancel={handleClickCancel}
        showEditBtn={!editMode}
        classname={cl.header}
        type="profile"
      />
      <PanelEditable
        onClickSave={handleClickSave}
        editMode={editMode || setEditModeForMobile}
        classname={cn(cl.root)}
        footerText="*Мы храним ваши данные на серверах, соответствующих Федеральному Закону №152"
      >
        <div className={cl.fields}>
          <Field
            className={cl.inputDesctop}
            label="Серия и номер паспорта"
            value={form.passport_number === '' ? '-' : form.passport_number}
            editMode={editMode}
            error={errors.passport_number}
          >
            <Input
              fullWidth
              name="passport_number"
              onChange={handleChangeForm}
              value={form.passport_number}
              // inputComponent={PassportMask}
              error={!!errors.passport_number}
            />
          </Field>
          <TextField
            className={cl.inputMobile}
            label="Серия и номер паспорта"
            value={form.passport_number || ''}
            error={!!errors.passport_number}
            fullWidth
            name="passport_number"
            onChange={handleChangeForm}
          />
          <Field
            className={cl.inputDesctop}
            label="Кем выдан"
            value={form.passport_issued_by === '' ? '-' : form.passport_issued_by}
            editMode={editMode}
            error={errors.passport_issued_by}
          >
            <Input
              fullWidth
              name="passport_issued_by"
              onChange={handleChangeForm}
              value={form.passport_issued_by}
              error={!!errors.passport_issued_by}
            />
          </Field>
          <TextField
            className={cl.inputMobile}
            label="Кем выдан"
            value={form.passport_issued_by || ''}
            error={!!errors.passport_issued_by}
            fullWidth
            name="passport_issued_by"
            onChange={handleChangeForm}
          />
          <Field
            className={cl.inputDesctop}
            label="Дата выдачи"
            value={form.passport_issued_date === '' ? '-' : form.passport_issued_date}
            editMode={editMode}
            error={errors.passport_issued_date}
          >
            <Input
              fullWidth
              name="passport_issued_date"
              onChange={handleChangeForm}
              value={form.passport_issued_date}
              inputComponent={DateMask}
              error={!!errors.passport_issued_date}
            />
          </Field>
          <TextField
            className={cl.inputMobile}
            name="passport_issued_date"
            label="Дата выдачи"
            error={!!errors.passport_issued_date}
            fullWidth
            value={form.passport_issued_date || ''}
            InputProps={{ inputComponent: DateMask }}
            onChange={handleChangeForm}
          />
          <Field
            label="Адрес регистрации"
            className={cl.inputDesctop}
            value={form.address === '' ? '-' : form.address}
            editMode={editMode}
            error={errors.address}
          >
            <Input fullWidth name="address" onChange={handleChangeForm} value={form.address} error={!!errors.address} />
          </Field>
          <TextField
            name="address"
            className={cl.inputMobile}
            label="Адрес регистрации"
            value={form.address || ''}
            error={!!errors.address}
            fullWidth
            onChange={handleChangeForm}
          />
          <Field
            label="Адрес фактического проживания"
            className={cl.inputDesctop}
            value={form.actual_address === '' ? '-' : form.actual_address}
            editMode={editMode}
            error={errors.actual_address}
          >
            <Input
              fullWidth
              name="actual_address"
              onChange={handleChangeForm}
              value={form.actual_address}
              error={!!errors.actual_address}
            />
          </Field>
          <TextField
            name="actual_address"
            className={cl.inputMobile}
            label="Адрес фактического проживания"
            value={form.actual_address || ''}
            error={!!errors.actual_address}
            fullWidth
            onChange={handleChangeForm}
          />
        </div>
      </PanelEditable>
    </>
  );
};
