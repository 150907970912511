import React, { HTMLAttributes } from 'react';
import Icon from '../../Icon';
import cl from './SearchInput.module.scss';
import cn from 'classnames';

interface Props extends HTMLAttributes<HTMLInputElement> {}

export const SearchInput: React.FC<Props> = ({ className, ...props }) => {
  return (
    <div className={cn(cl.root, className)}>
      <Icon type="Search" />
      <input {...props} placeholder="Поиск" />
    </div>
  );
};
