import React from 'react';
import cl from './BaseMenuItem.module.scss';
import cn from 'classnames';
import { ButtonBase } from '@material-ui/core';

interface Props {
  // path: string;
  isActive: boolean;
  onClick?: () => void;
  classname?: string;
  filled?: boolean;
  disable?: boolean;
}

export const BaseMenuItem: React.FC<Props> = ({ children, isActive, onClick, classname, filled, disable = false }) => {
  return (
    <ButtonBase
      className={cn(cl.root, isActive && cl.active, classname)}
      component="li"
      disabled={disable}
      onClick={onClick}
    >
      {filled !== undefined && (
        <div className={cn(cl.extra, filled && cl.filled)}>{filled ? 'Заполнено' : 'Не заполнено'}</div>
      )}
      {children}
    </ButtonBase>
  );
};
