import React from 'react';

interface Props {}

const BookOnMfc: React.FC<Props> = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M10.7837 10.5156C10.6412 10.5156 10.5256 10.6312 10.5256 10.7737V15.4839H2.92571V13.3333C2.92571 13.1908 2.81016 13.0753 2.66764 13.0753H0.517106V0.516129H10.5256V5.22632C10.5256 5.36884 10.6412 5.48439 10.7837 5.48439C10.9262 5.48439 11.0418 5.36884 11.0418 5.22632V0.258065C11.0418 0.115544 10.9262 0 10.7837 0H0.259041C0.116521 0 0.000976562 0.115544 0.000976562 0.258065V13.3333C0.000976562 13.342 0.00142387 13.3506 0.00228409 13.3591C0.00290344 13.3654 0.00397011 13.3715 0.00503678 13.3776C0.00541527 13.3798 0.00562172 13.382 0.00603463 13.3842C0.00747979 13.3914 0.00933785 13.3985 0.011368 13.4055C0.0116776 13.4065 0.0118497 13.4076 0.0121594 13.4086C0.0143271 13.4157 0.0169077 13.4227 0.0196604 13.4295C0.0200389 13.4304 0.0203142 13.4314 0.0206927 13.4323C0.0233766 13.4388 0.0264733 13.4451 0.0296733 13.4513C0.0303271 13.4526 0.0308432 13.4539 0.0315314 13.4552C0.0345938 13.4609 0.0380346 13.4664 0.0415443 13.4719C0.0425766 13.4735 0.0435056 13.4752 0.0445723 13.4769C0.0480475 13.482 0.0519013 13.487 0.0557207 13.4919C0.0570626 13.4936 0.0582325 13.4955 0.0596088 13.4971C0.0649766 13.5036 0.0706196 13.5099 0.0765723 13.5159L2.48507 15.9244C2.49102 15.9303 2.49729 15.936 2.50382 15.9414C2.50551 15.9427 2.50733 15.9439 2.50902 15.9453C2.51394 15.9491 2.51889 15.9529 2.52409 15.9564C2.52571 15.9575 2.52743 15.9584 2.52908 15.9594C2.53455 15.9629 2.54006 15.9663 2.5458 15.9694C2.54704 15.9701 2.54838 15.9706 2.54966 15.9713C2.55588 15.9745 2.56218 15.9776 2.56865 15.9803C2.56958 15.9807 2.57054 15.9809 2.57144 15.9813C2.57828 15.984 2.58523 15.9866 2.59236 15.9888C2.59339 15.9891 2.59446 15.9893 2.59549 15.9896C2.60247 15.9916 2.60956 15.9935 2.61679 15.9949C2.61896 15.9954 2.62119 15.9956 2.62336 15.9959C2.62948 15.997 2.63557 15.9981 2.64184 15.9987C2.65037 15.9996 2.65897 16 2.66761 16H10.7837C10.9262 16 11.0417 15.8845 11.0417 15.7419V10.7737C11.0418 10.6312 10.9262 10.5156 10.7837 10.5156ZM2.40958 15.1189L0.882043 13.5914H2.40958V15.1189Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M9.06236 13.9354C9.20488 13.9354 9.32043 13.8199 9.32043 13.6774C9.32043 13.5349 9.20488 13.4193 9.06236 13.4193H6.84641C6.70389 13.4193 6.58835 13.5349 6.58835 13.6774C6.58835 13.8199 6.70389 13.9354 6.84641 13.9354H9.06236Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M9.06238 2.40863H1.97858C1.83606 2.40863 1.72052 2.52417 1.72052 2.66669C1.72052 2.80921 1.83606 2.92476 1.97858 2.92476H9.06238C9.2049 2.92476 9.32045 2.80921 9.32045 2.66669C9.32045 2.52417 9.2049 2.40863 9.06238 2.40863Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M9.06238 3.78497H1.97858C1.83606 3.78497 1.72052 3.90052 1.72052 4.04304C1.72052 4.18556 1.83606 4.3011 1.97858 4.3011H9.06238C9.2049 4.3011 9.32045 4.18556 9.32045 4.04304C9.32045 3.90052 9.2049 3.78497 9.06238 3.78497Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M9.06238 5.16119H1.97858C1.83606 5.16119 1.72052 5.27674 1.72052 5.41926C1.72052 5.56178 1.83606 5.67732 1.97858 5.67732H9.06238C9.2049 5.67732 9.32045 5.56178 9.32045 5.41926C9.32045 5.27674 9.2049 5.16119 9.06238 5.16119Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M9.32045 6.79566C9.32045 6.65314 9.2049 6.5376 9.06238 6.5376H1.97858C1.83606 6.5376 1.72052 6.65314 1.72052 6.79566C1.72052 6.93818 1.83606 7.05373 1.97858 7.05373H9.06238C9.2049 7.05373 9.32045 6.93818 9.32045 6.79566Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M8.09513 8.17201C8.09513 8.02949 7.97958 7.91394 7.83706 7.91394H1.97858C1.83606 7.91394 1.72052 8.02949 1.72052 8.17201C1.72052 8.31453 1.83606 8.43007 1.97858 8.43007H7.8371C7.97962 8.43007 8.09513 8.31453 8.09513 8.17201Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M1.97858 9.29034C1.83606 9.29034 1.72052 9.40589 1.72052 9.54841C1.72052 9.69093 1.83606 9.80647 1.97858 9.80647H5.5205C5.66302 9.80647 5.77857 9.69093 5.77857 9.54841C5.77857 9.40589 5.66302 9.29034 5.5205 9.29034H1.97858Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M15.6528 3.12994C15.4289 2.90601 15.1308 2.78265 14.8134 2.78265C14.496 2.78265 14.1979 2.90597 13.974 3.12991L13.1711 3.93283H13.1711H13.1711L12.4412 4.66267H12.4412C12.4411 4.66271 12.4411 4.66271 12.4411 4.66271L7.22435 9.8796C7.21709 9.88683 7.21052 9.8945 7.20433 9.90234C7.20274 9.90437 7.2013 9.90651 7.19975 9.90857C7.19511 9.91483 7.19073 9.92124 7.18674 9.92777C7.18602 9.92894 7.18516 9.92997 7.18447 9.93114L5.66465 12.5124C5.60047 12.5894 5.56558 12.6856 5.56558 12.7871C5.56558 12.902 5.61031 13.01 5.69155 13.0912C5.77279 13.1725 5.8808 13.2172 5.99569 13.2172C6.0972 13.2172 6.19333 13.1823 6.27037 13.1181L8.85164 11.5983C8.85284 11.5976 8.85387 11.5967 8.85508 11.596C8.86162 11.592 8.86795 11.5877 8.87418 11.5831C8.87627 11.5815 8.87841 11.58 8.88047 11.5784C8.88832 11.5722 8.89596 11.5657 8.90318 11.5584L14.1199 6.3416C14.1199 6.3416 14.12 6.3416 14.12 6.34157C14.12 6.34154 14.12 6.34154 14.12 6.34154L14.8499 5.61166H14.8499H14.85L15.6528 4.80877C16.1157 4.34591 16.1157 3.59277 15.6528 3.12994ZM13.9375 5.79413L12.9886 4.84521L13.3536 4.48027L14.3025 5.42916L13.9375 5.79413ZM8.72068 11.011L7.77179 10.0621L12.6237 5.21018L13.5726 6.15907L8.72068 11.011ZM7.23051 11.9539L6.82893 11.5523L7.46009 10.4803L8.30248 11.3227L7.23051 11.9539ZM6.25393 12.5289L6.55844 12.0117L6.77112 12.2243L6.25393 12.5289ZM15.2879 4.44376L14.6674 5.06418L13.7185 4.1153L14.339 3.49484C14.4654 3.36839 14.6339 3.29875 14.8134 3.29875C14.993 3.29875 15.1614 3.36839 15.2879 3.49484C15.4144 3.62129 15.484 3.78979 15.484 3.9693C15.484 4.14881 15.4143 4.31735 15.2879 4.44376Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BookOnMfc;
