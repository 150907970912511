import React, { useCallback } from 'react';
import cl from './ProfileChildrenMenu.module.scss';
import { AddChildrenMenuItem, ProfileChildrenMenuItem } from 'ui/list/ProfileChildrenMenuItem';
import { useHistory, useLocation } from 'react-router-dom';
import { useProfile } from 'hooks/profile';
import { checkChildrenRequisites } from 'state/children';

interface Props {}

const PATH = '/profile/children';

export const ProfileChildrenMenu: React.FC<Props> = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const {
    profile: { children },
  } = useProfile();

  const handleClickAdd = useCallback(() => {
    history.push('/profile/children/add');
  }, [history]);

  const handleClickChildren = useCallback(
    (id: number) => {
      history.push(`/profile/children/${id}`);
    },
    [history],
  );

  return (
    <ul className={cl.root}>
      {children?.map((item) => (
        <ProfileChildrenMenuItem
          key={item.id}
          data={item}
          isActive={`${PATH}/${item.id}` === pathname}
          onClick={handleClickChildren}
          filled={checkChildrenRequisites(item)}
        />
      ))}
      <AddChildrenMenuItem onClick={handleClickAdd} isActive={`${PATH}/add` === pathname} path={'add'} />
    </ul>
  );
};
