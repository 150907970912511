import { createEffect, createEvent, createStore, guard, sample } from 'effector';
import { ApiResponse } from 'api/types';
import { ClubCategory } from 'api/types/clubs';
import { ApiError } from 'api/errors/apiError';
import api from 'api/request/clubs';
import { FetchingListState, initFetchingListState, storeAttachFetchList } from '../utils';
import { logout } from '../auth';

export interface ClubsCategoriesState extends FetchingListState<ClubCategory> {}

export const getClubCategoriesFx = createEffect<void, ApiResponse<ClubCategory[]>, ApiError<any>>({
  handler: async () => await api.categoryList(),
});

export const fetchClubCategoriesIfNeed = createEvent();

export const $clubsCategories = createStore<ClubsCategoriesState>(initFetchingListState()).reset(logout);

guard({
  source: sample($clubsCategories, fetchClubCategoriesIfNeed),
  filter: $clubsCategories.map((state) => !state.isFetching && !state.didFetched),
  target: getClubCategoriesFx,
});

storeAttachFetchList($clubsCategories, [getClubCategoriesFx]);
