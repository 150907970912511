import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import Default from './components/Default';
import Phone from './components/Phone';
import { LoginLayout } from 'components/layout/LoginLayout';
import { Code } from './components/Code';
import { Privacy } from './components/Privacy';

interface Props extends RouteComponentProps {}

const Login: React.FC<Props> = ({ match }) => {
  return (
    <LoginLayout>
      <Switch>
        <Route path={`${match.url}/privacy`} component={Privacy} />
        <Route path={`${match.url}/phone`} component={Phone} />
        <Route path={`${match.url}/code`} component={Code} />
        <Route path={match.url} component={Default} />
      </Switch>
    </LoginLayout>
  );
};

export default Login;
