import React, { useCallback, useMemo, useState } from 'react';
import { showAlert } from 'ui/dialogs/Alert';
import { UPLOAD_FILE_SIZE } from 'constants/config';
import cl from './Step3.module.scss';
import { Step } from 'ui/edits/Step';
import { FileLoadPanel } from 'ui/panels/FileLoadPanel';
import { Children } from 'api/types/children';
import { Map as ImmutableMap } from 'immutable';
import { FileDescr } from 'api/types/common';
import { Backdrop } from 'ui/feedback/Backdrop';
import { handleFiles } from 'utils/helpers';

interface ChildrenInfo {
  child_id?: number;
  needSave: boolean;
  childrenList?: Children[];
  childrenMap?: ImmutableMap<number, Children>;
}
interface Step3Form {
  files: FileDescr[];
}
interface DataType {
  id: number;
  name: string;
}
type currentStep = 'schools' | 'gartens';

interface Props {
  childrenInfo: ChildrenInfo;
  step3Form: Step3Form;
  nextStep: any;
  prevStep: any;
  step3Set: (file: Step3Form) => void;
  needableFiles: DataType[];
  needableFilesOlder?: DataType[] | undefined;
  facilities: DataType[];
  extraFacilities: DataType[];
  type: currentStep;
}

export const Step3: React.FC<Props> = ({
  childrenInfo,
  nextStep,
  prevStep,
  step3Form,
  step3Set,
  needableFiles,
  needableFilesOlder,
  facilities,
  extraFacilities,
  type,
}) => {
  const { child_id, childrenList } = childrenInfo;
  const [isFetching, setFetching] = useState(false);

  const currentChild = childrenList?.find((element) => element.id === child_id);

  const diffYears =
    Math.ceil(Math.abs(new Date().getTime() - new Date(currentChild?.dob || '01.01.1970').getTime())) /
    (1000 * 3600 * 24);

  const diffNeeds = 365 * 14 + 30 * 2;

  const fileAmountChecker = useCallback(
    (name: string) => {
      return JSON.stringify(step3Form.files.filter((element) => element.name === name)) !== '[]';
    },
    [step3Form],
  );

  const isDisabledButton = useMemo(() => {
    return !(
      fileAmountChecker('Свидетельство о рождении') &&
      fileAmountChecker('Регистрация в г. Москве') &&
      fileAmountChecker('Мед.полис') &&
      fileAmountChecker('СНИЛС')
    );
  }, [fileAmountChecker]);

  const handleNext = async () => {
    try {
      nextStep();
    } catch (e) {
      showAlert(
        'error',
        'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
        'Ошибка',
      );
    }
  };

  const handlePrevStep = useCallback(() => {
    prevStep();
  }, [prevStep]);

  const handleRemoveFile = useCallback(
    (typeOfFile: string) => {
      let files = step3Form.files;
      files = files.filter((element) => element.name !== typeOfFile);
      step3Set({
        ...step3Form,
        files,
      });
    },
    [step3Form, step3Set],
  );

  const handleSizeError = useCallback(() => {
    showAlert(
      'error',
      `Максимальный размер файлов для загрузки составляет ${(UPLOAD_FILE_SIZE / 1024 / 1024).toFixed(
        1,
      )}Mb. Файлы большего размера не будут загружены.`,
      'Ошибка',
    );
  }, []);

  return (
    <Step
      disabledNext={isDisabledButton}
      title={'Основые документы и льготы'}
      onClickNext={handleNext}
      prevStep={handlePrevStep}
      isFileLoader={true}
    >
      <Backdrop open={isFetching} />
      <div className={cl.subtitle}>Обязательные документы ребенка</div>
      <div className={cl.files}>
        {(type === 'schools' ? (diffNeeds > diffYears ? needableFiles : needableFilesOlder) : needableFiles)?.map(
          (element: DataType) => (
            <div key={element.id} className={cl.file}>
              <FileLoadPanel
                data={step3Form.files}
                isMain={true}
                handleRemoveFile={handleRemoveFile}
                name={element.name}
                onClick={handleFiles.bind(null, setFetching, step3Form, step3Set)}
                onSizeError={handleSizeError}
              />
            </div>
          ),
        )}
      </div>
      <div className={cl.subtitleNoNeedable}>Выбор основания льготы</div>
      <div className={cl.files}>
        {facilities.map((element) => (
          <div key={element.id} className={cl.file}>
            <FileLoadPanel
              isOpenToolTip={element.name === 'Справка с места работы' ? true : false}
              data={step3Form.files}
              handleRemoveFile={handleRemoveFile}
              name={element.name}
              onClick={handleFiles.bind(null, setFetching, step3Form, step3Set)}
              onSizeError={handleSizeError}
            />
          </div>
        ))}
      </div>
      <div className={cl.subtitleNoNeedable}>{type === 'schools' ? 'Дополнительные документы' : 'Для детей с ОВЗ'}</div>
      <div className={cl.files}>
        {extraFacilities.map((element) => (
          <div key={element.id} className={cl.file}>
            <FileLoadPanel
              data={step3Form.files}
              handleRemoveFile={handleRemoveFile}
              name={element.name}
              onClick={handleFiles.bind(null, setFetching, step3Form, step3Set)}
              onSizeError={handleSizeError}
            />
          </div>
        ))}
      </div>
    </Step>
  );
};
