import { createEffect, createEvent, createStore } from 'effector';
import apiAuth from 'api/request/auth';
import { AuthLoginForm, MosRuLoginForm, SMSCodeLoginForm } from 'api/types/auth';
import { ApiError } from 'api/errors/apiError';
import { ApiResponse } from 'api/types';
import { authorize, unauthorize } from 'api';
// import {ApiFailure} from "api/types/api-status";
// import {authorize} from "api/instance";
// import {fetchAuthInfoFx} from "state/auth/info";
// import {fetchCompanyListFx} from "state/company";

export interface AuthState {
  token: string;
  userId?: number;
  phone?: string;
  // tokenExp: number;
  // refreshToken: string;
  // refreshTokenExp: number;
  // isFetching: boolean;
}

const initStore: AuthState = {
  token: '',
  // tokenExp: 0,
  // refreshToken: '',
  // refreshTokenExp: 0,
  // isFetching: false,
};

const setPhone = createEvent<string>();
export const setToken = createEvent<string>();
export const logout = createEvent();

export const loginFx = createEffect<AuthLoginForm, ApiResponse<{ userId: number }>, ApiError<any>>({
  handler: async (data: AuthLoginForm) => {
    setPhone(data.phone);
    return await apiAuth.login(data);
  },
});

export const sendCodeFx = createEffect<SMSCodeLoginForm, ApiResponse<{ token: string }>>({
  handler: async (data: SMSCodeLoginForm) => await apiAuth.code(data),
});

export const $auth = createStore<AuthState>(initStore)
  .on(setToken, (state, token) => ({
    ...state,
    token,
  }))
  .on(setPhone, (state, phone) => ({
    ...state,
    phone,
  }))
  .on(loginFx.pending, (state) => ({ ...state }))
  .on(loginFx.doneData, (state, { data: { userId } }) => {
    return {
      ...state,
      userId,
    };
  })
  .on(sendCodeFx.doneData, (state, { data: { token } }) => ({
    ...state,
    token,
  }));

sendCodeFx.doneData.watch(({ data: { token } }) => {
  authorize(token);
  localStorage.setItem('accessToken', token);
});

logout.watch(() => {
  unauthorize();
  localStorage.clear();
  // @ts-ignore
  window.location = '/';
});

///-----------------------

export const loginMosRuFx = createEffect({
  handler: async (params: MosRuLoginForm) => await apiAuth.loginMosRu(params),
});

loginMosRuFx.doneData.watch(({ data: { token } }) => {
  authorize(token);
  setToken(token);
  localStorage.setItem('accessToken', token);
});

$auth.reset(logout);
