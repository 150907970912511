import React from 'react';
import { Panel } from '../Panel';
import cl from './PanelEditable.module.scss';
import cn from 'classnames';
import { Button } from 'ui/button/Button';

type type = 'children' | 'profile';

interface Props {
  classname?: string;
  editMode?: boolean;
  footerText?: string;
  onClickSave?: () => void;
  onClickCancel?: () => void;
  type?: type;
}

export const PanelEditable: React.FC<Props> = ({
  children,
  classname,
  editMode = false,
  onClickSave,
  onClickCancel,
  footerText,
  type = 'profile',
}) => {
  return (
    <Panel classname={cn(cl.root, classname)}>
      {children}
      {editMode && (
        <div className={cl.editFooter}>
          {footerText && (
            <div className={cl.footerText}>
              <span>{footerText}</span>
            </div>
          )}
          {type === 'children' && (
            <Button variant="text" className={cl.cancelBtn} onClick={onClickCancel}>
              Отмена
            </Button>
          )}
          <Button className={cl.saveBtn} onClick={onClickSave}>
            Сохранить
          </Button>
        </div>
      )}
    </Panel>
  );
};
