import { Profile, ProfileSecond } from 'api/types/profile';
import { useProfile } from 'hooks/profile';
import React, { useCallback, useEffect } from 'react';
import { Step } from 'ui/edits/Step';
import { checkProfile, checkRequisitesFilled, checkSecondRep } from 'state/profile';
import Icon, { IconType } from 'ui/Icon';
import { IconButton } from '@material-ui/core';
import { Item } from 'ui/list/Item';
import cn from 'classnames';
import { ViewTypeSchool } from '../../../routes/main/routes/schools/components/ProgrammJoin/model';
import { ViewTypeGarten } from '../../../routes/main/routes/gartens/components/GartenJoin/model';
import cl from './Step1.module.scss';

interface Props {
  needSave: boolean;
  profile: Profile | undefined;
  profileRep: ProfileSecond | undefined;
  changeViewType: (step: ViewTypeGarten | ViewTypeSchool) => void;
  nextStep: any;
  step1SetProfile: (profile: Profile) => void;
  step1ToggleSaver: any;
  isActualAddressNeeded?:boolean;
}

export const Step1: React.FC<Props> = ({
  needSave,
  profile,
  profileRep,
  changeViewType,
  nextStep,
  step1SetProfile,
  step1ToggleSaver,
  isActualAddressNeeded = true
}) => {
  const { profile: globalProfile } = useProfile();

  useEffect(() => {
    if (JSON.stringify(profile) === '{}' || profile === undefined) {
      step1SetProfile(globalProfile as Profile);
    }
  }, [globalProfile, profile, step1SetProfile]);

  const handleNext = useCallback(() => {
    nextStep();
  }, [nextStep]);

  const handleToggleSaver = useCallback(() => {
    step1ToggleSaver();
  }, [step1ToggleSaver]);

  const filledProfile = checkProfile(profile);
  const filledRequisites = checkRequisitesFilled(profile?.requisites, isActualAddressNeeded);
  const filledEmail = !!profile?.email && !!profile?.email_verified;
  const filledSecondRepresentative = checkSecondRep(profileRep);

  return (
    <Step
      title="Реквизиты законного представителя"
      onClickNext={handleNext}
      disabledNext={!filledProfile || !filledRequisites || !filledEmail}
      withSaver={true}
      hasPrev={false}
      onClickSaver={handleToggleSaver}
      needSave={needSave}
    >
      <div className={cl.items}>
        <DataItem
          title="Учетная запись"
          subtitle="Данные"
          icon="NoPhoto"
          filled={filledProfile}
          onClick={changeViewType.bind(null, 'profile')}
        />
        <DataItem
          title="Реквизиты паспорта"
          subtitle="Данные для договора"
          icon="User"
          filled={filledRequisites}
          onClick={changeViewType.bind(null, 'requisites')}
        />
        <DataItem
          title={profile?.email || ''}
          subtitle="E-mail"
          icon="Mail"
          filled={filledEmail}
          unFilledText={!!profile?.email && !profile?.email_verified ? 'Не подтвержден' : undefined}
          onClick={changeViewType.bind(null, 'email')}
        />
        <DataItem
          title="Контакты"
          subtitle="Второй представитель"
          icon="Contacts"
          filled={filledSecondRepresentative}
          onClick={changeViewType.bind(null, 'secondRepresentative')}
        />
      </div>
    </Step>
  );
};

interface ItemProps {
  title: string;
  subtitle: string;
  icon: IconType;
  filled: boolean;
  unFilledText?: string;
  onClick: () => void;
}

const DataItem: React.FC<ItemProps> = ({ title, subtitle, icon, filled, onClick, unFilledText = 'Не заполнено' }) => {
  return (
    <Item classname={cl.item}>
      <div className={cn(cl.extra, filled && cl.filled)}>{filled ? 'Заполнено' : unFilledText}</div>
      <Icon type={icon} />
      <div className={cl.text}>
        <div>{subtitle}</div>
        <div className={cl.title}>{title}&nbsp;</div>
      </div>
      <IconButton className={cl.editBtn} onClick={onClick}>
        <Icon type="Edit" />
      </IconButton>
    </Item>
  );
};
