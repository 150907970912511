import React from 'react';

interface Props {}

const AskQuestion: React.FC<Props> = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M10.8125 9.96875C10.6399 9.96875 10.5 10.1087 10.5 10.2812V12.4375C10.5 13.2991 9.79906 14 8.9375 14H5.5635C5.39097 14 5.25112 14.1398 5.251 14.3123L5.25056 14.9336L4.50469 14.1877C4.45641 14.0769 4.34591 14 4.21809 14H2.1875C1.32594 14 0.625 13.2991 0.625 12.4375V7.625C0.625 6.76344 1.32594 6.0625 2.1875 6.0625H5.34375C5.51631 6.0625 5.65625 5.92259 5.65625 5.75C5.65625 5.57741 5.51631 5.4375 5.34375 5.4375H2.1875C0.981313 5.4375 0 6.41881 0 7.625V12.4375C0 13.6437 0.981313 14.625 2.1875 14.625H4.05806L5.34153 15.9085C5.40131 15.9683 5.48122 16 5.56256 16C5.60278 16 5.64337 15.9922 5.68197 15.9762C5.79875 15.9279 5.87491 15.8141 5.875 15.6877L5.87575 14.625H8.9375C10.1437 14.625 11.125 13.6437 11.125 12.4375V10.2812C11.125 10.1087 10.9851 9.96875 10.8125 9.96875Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M11.458 0H10.6671C8.16256 0 6.125 2.03756 6.125 4.54206C6.125 7.04656 8.16256 9.08409 10.667 9.08409H11.4579C11.8488 9.08409 12.2358 9.03444 12.6108 8.93631L13.7791 10.104C13.8389 10.1637 13.9188 10.1955 14.0001 10.1955C14.0403 10.1955 14.081 10.1877 14.1196 10.1717C14.2364 10.1233 14.3125 10.0093 14.3125 9.88297V8.07497C14.8072 7.67437 15.2181 7.16834 15.5064 6.60303C15.834 5.96097 16 5.26756 16 4.54206C16 2.03756 13.9624 0 11.458 0ZM13.812 7.67297C13.7336 7.732 13.6875 7.82444 13.6875 7.92259V9.12884L12.9228 8.36453C12.8633 8.30509 12.7835 8.27306 12.7018 8.27306C12.6712 8.27306 12.6403 8.27759 12.6101 8.28687C12.2381 8.40119 11.8505 8.45912 11.458 8.45912H10.6671C8.50716 8.45912 6.75 6.70194 6.75 4.54206C6.75 2.38219 8.50719 0.625 10.6671 0.625H11.458C13.6178 0.625 15.375 2.38219 15.375 4.54206C15.375 5.78366 14.8053 6.92484 13.812 7.67297Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M12.4699 3.41939C12.4248 2.76517 11.8974 2.23779 11.2432 2.1927C10.8723 2.16723 10.5183 2.29232 10.2474 2.54523C9.98025 2.79464 9.82703 3.1472 9.82703 3.51254C9.82703 3.68514 9.96696 3.82504 10.1395 3.82504C10.3121 3.82504 10.452 3.68514 10.452 3.51254C10.452 3.31695 10.5308 3.13567 10.6739 3.00214C10.8168 2.86873 11.0037 2.8027 11.2002 2.81626C11.5448 2.84001 11.8226 3.11779 11.8464 3.46239C11.8704 3.81042 11.642 4.11798 11.3035 4.19373C11.0292 4.2551 10.8376 4.49348 10.8376 4.77339V5.52417C10.8376 5.69676 10.9775 5.83667 11.1501 5.83667C11.3227 5.83667 11.4626 5.69676 11.4626 5.52417V4.79835C12.0922 4.64623 12.5147 4.07004 12.4699 3.41939Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M11.371 6.53932C11.3128 6.48119 11.2322 6.44775 11.15 6.44775C11.0678 6.44775 10.9872 6.48119 10.9291 6.53932C10.871 6.59744 10.8375 6.67807 10.8375 6.76025C10.8375 6.84275 10.871 6.92338 10.9291 6.9815C10.9872 7.03963 11.0678 7.07275 11.15 7.07275C11.2322 7.07275 11.3128 7.03963 11.371 6.9815C11.4291 6.92307 11.4625 6.84275 11.4625 6.76025C11.4625 6.67807 11.4291 6.59744 11.371 6.53932Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M8.59375 9.6875H2C1.82744 9.6875 1.6875 9.82741 1.6875 10C1.6875 10.1726 1.82744 10.3125 2 10.3125H8.59375C8.76634 10.3125 8.90625 10.1726 8.90625 10C8.90625 9.82741 8.76631 9.6875 8.59375 9.6875Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M8.81466 11.5291C8.75656 11.4709 8.67594 11.4375 8.59375 11.4375C8.51156 11.4375 8.43094 11.4709 8.37281 11.5291C8.31466 11.5872 8.28125 11.6678 8.28125 11.75C8.28125 11.8322 8.31469 11.9128 8.37281 11.9709C8.43094 12.0291 8.51156 12.0625 8.59375 12.0625C8.67594 12.0625 8.75656 12.0291 8.81466 11.9709C8.87281 11.9128 8.90625 11.8325 8.90625 11.75C8.90625 11.6678 8.87278 11.5872 8.81466 11.5291Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M7.36459 11.4375H2C1.82744 11.4375 1.6875 11.5774 1.6875 11.75C1.6875 11.9226 1.82744 12.0625 2 12.0625H7.36459C7.53719 12.0625 7.67709 11.9226 7.67709 11.75C7.67709 11.5774 7.53716 11.4375 7.36459 11.4375Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M6.5625 7.9375H2C1.82744 7.9375 1.6875 8.07741 1.6875 8.25C1.6875 8.42259 1.82744 8.5625 2 8.5625H6.5625C6.73509 8.5625 6.875 8.42259 6.875 8.25C6.875 8.07741 6.73506 7.9375 6.5625 7.9375Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AskQuestion;
