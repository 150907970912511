import React from 'react';
import { Panel } from '../Panel';
import cl from './PanelMini.module.scss';
import cn from 'classnames';

interface Props {
  title?: string;
  classname?: string;
}

export const PanelMini: React.FC<Props> = ({ children, classname, title }) => {
  return (
    <Panel classname={cn(cl.root, classname)}>
      {!!title && <div className={cl.title}>{title}</div>}
      {children}
    </Panel>
  );
};
