import React, { useCallback, SyntheticEvent, useState } from 'react';
import { Button } from 'ui/button/Button';
import { Dialog, IconButton } from '@material-ui/core';
import Icon from 'ui/Icon';
import cl from './Agreement.module.scss';
import { Conditions } from 'api/types/schools';
import { Check } from 'ui/input/Check';
import { ExtraAgreement } from './ExtraAgreement/ExtraAgreement';
import cn from 'classnames';
import PrivacyPage from 'routes/privacy/components/Page';
import AgreementPage from 'routes/agreement/components/Page';

type type = 'schools' | 'gartens';

interface Props {
  onClickContinue: () => void;
  conditions?: Conditions[];
  setOpenAgreement: (signUp: boolean) => void;
  isOpenAgreement: boolean;
  type: type;
}
interface Agreement {
  title: string;
  url_text: string;
  isRedirect?: boolean;
  url_redirect?: string;
}

interface AgreementForRender {
  first_text: string;
  second_text: string;
  third_text: string;
  isRedirect: boolean;
  url_redirect?: string | undefined;
}

const DefaultAgreementsForSchools: Agreement[] = [
  {
    title: 'Я даю согласие на обработку моих персональных данных',
    url_text: 'персональных данных',
    isRedirect: false,
  },
  {
    title: 'Я принимаю условия пользовательского соглашения',
    url_text: 'пользовательского соглашения',
    isRedirect: false,
  },
  {
    title: 'Я даю согласие на то, что ознакомлен с уставом, лицензией на осуществление образовательной деятельности',
    url_text: 'уставом, лицензией на осуществление образовательной деятельности',
    isRedirect: true,
    url_redirect: 'https://kurchat.mskobr.ru/info_edu/all_docs/',
  },
];

const DefaultAgreementsForGartens: Agreement[] = [
  {
    title: 'Я даю согласие на обработку моих персональных данных',
    url_text: 'персональных данных',
    isRedirect: false,
  },
  {
    title: 'Я принимаю условия пользовательского соглашения',
    url_text: 'пользовательского соглашения',
    isRedirect: false,
  },
  {
    title: 'Выбор группы соответствует направлению с портала mos.ru',
    url_text: 'направлению с портала mos.ru',
    isRedirect: true,
    url_redirect: 'https://www.mos.ru/',
  },
  {
    title: 'Я даю согласие на то, что ознакомлен с уставом, лицензией на осуществление образовательной деятельности',
    url_text: 'уставом, лицензией на осуществление образовательной деятельности',
    isRedirect: true,
    url_redirect: 'https://kurchat.mskobr.ru/info_edu/all_docs/',
  },
];

const Agreement: React.FC<Props> = (props) => {
  const { onClickContinue, conditions, setOpenAgreement, isOpenAgreement, type } = props;

  const [currentAgreement, setCurrentAgreement] = useState(-1);
  const [agree, setAgree] = useState<number[]>([]);

  const openAgreementPage = useCallback(
    (id: number) => (e: SyntheticEvent) => {
      e.stopPropagation();
      setCurrentAgreement(id);
    },
    [setCurrentAgreement],
  );

  const acceptedAgreements = (id: number) => {
    if (agree.indexOf(id) !== -1) {
      let arr = agree;
      const index = arr.indexOf(id);
      arr.splice(index, 1);
      setAgree([...arr]);
    } else {
      setAgree([...agree, id]);
    }
  };

  const preventDefaultForA = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const onClickExit = useCallback(() => {
    setOpenAgreement(false);
  }, [setOpenAgreement]);

  const handleClosePrivacyDialog = useCallback(() => {
    setCurrentAgreement(-1);
  }, []);

  const setDefaultAgreements = useCallback((agreements: Agreement[]) => {
    let defaultAgreementsForRender: AgreementForRender[] = [];
    for (let i = 0; i < agreements.length; i++) {
      let index = agreements[i].title.indexOf(agreements[i].url_text);
      let currentAgree: AgreementForRender = { first_text: '', second_text: '', third_text: '', isRedirect: false };
      currentAgree.first_text = agreements[i].title.slice(0, index);
      currentAgree.second_text = agreements[i].title.slice(index, index + agreements[i].url_text.length);
      currentAgree.third_text = agreements[i].title.slice(
        index + agreements[i].url_text.length,
        agreements[i].title.length,
      );
      currentAgree.isRedirect = agreements[i].isRedirect || false;
      currentAgree.url_redirect = agreements[i].url_redirect || '';
      defaultAgreementsForRender.push(currentAgree);
    }
    return defaultAgreementsForRender;
  }, []);

  return (
    <Dialog
      open={isOpenAgreement}
      className={cl.root}
      classes={{ paper: cn(cl.paper, currentAgreement !== -1 && cl.paperAgreement) }}
    >
      <IconButton onClick={currentAgreement === -1 ? onClickExit : handleClosePrivacyDialog} className={cl.closeBtn}>
        <Icon type="Close" />
      </IconButton>

      {currentAgreement === -1 ? (
        <>
          <div className={cl.content}>
            <div className={cl.title}>
              Необходимо <br /> принять условия
            </div>
            <div className={cl.text}>
              {setDefaultAgreements(type === 'schools' ? DefaultAgreementsForSchools : DefaultAgreementsForGartens).map(
                (element, key: number) => (
                  <Check
                    key={element.first_text}
                    onClick={acceptedAgreements.bind(null, key)}
                    label={
                      <span>
                        <span> {element.first_text}</span>
                        {element.isRedirect ? (
                          <span className={cl.link}>
                            <a
                              onClick={preventDefaultForA}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={element.url_redirect}
                            >
                              {element.second_text}
                            </a>
                          </span>
                        ) : (
                          <span className={cl.link} onClick={openAgreementPage(key)}>
                            {element.second_text}
                          </span>
                        )}
                        <span> {element.third_text}</span>
                      </span>
                    }
                    checked={agree.indexOf(key) !== -1}
                  />
                ),
              )}
              {setDefaultAgreements(conditions || [])?.map((element, key: number) => (
                <Check
                  key={element.first_text}
                  onClick={acceptedAgreements.bind(
                    null,
                    key + (type === 'schools' ? DefaultAgreementsForSchools : DefaultAgreementsForGartens).length,
                  )}
                  label={
                    <span>
                      <span> {element.first_text}</span>
                      <span
                        className={cl.link}
                        onClick={openAgreementPage(
                          key + (type === 'schools' ? DefaultAgreementsForSchools : DefaultAgreementsForGartens).length,
                        )}
                      >
                        {element.second_text}
                      </span>
                      <span> {element.third_text}</span>
                    </span>
                  }
                  checked={
                    agree.indexOf(
                      key + (type === 'schools' ? DefaultAgreementsForSchools : DefaultAgreementsForGartens).length,
                    ) !== -1
                  }
                />
              ))}
            </div>
          </div>

          <Button
            onClick={() => onClickContinue()}
            className={cl.btnContinue}
            disabled={
              !(
                agree.length ===
                (type === 'schools' ? DefaultAgreementsForSchools : DefaultAgreementsForGartens).length +
                  (conditions?.length ? conditions.length : 0)
              )
            }
          >
            Продолжить
          </Button>
        </>
      ) : (
        <>
          {type === 'schools' ? (
            currentAgreement === 0 ? (
              <PrivacyPage />
            ) : currentAgreement === 1 ? (
              <AgreementPage />
            ) : (
              <ExtraAgreement text="Дополнительные соглашения" />
            )
          ) : currentAgreement === 0 ? (
            <PrivacyPage />
          ) : currentAgreement === 1 ? (
            <AgreementPage />
          ) : currentAgreement === 2 ? (
            <ExtraAgreement text="Выбор группы соответствует направлению с портала mos.ru" />
          ) : (
            <ExtraAgreement text="Я даю согласие на то, что ознакомлен с уставом, лицензией на осуществление образовательной деятельности" />
          )}
        </>
      )}
    </Dialog>
  );
};

export default Agreement;
