import React from 'react';

interface Props {}

const Logo: React.FC<Props> = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="40" cy="40" r="40" fill="white" />
      <path
        d="M33.2617 29.6593C33.2617 29.6593 29.3476 29.8405 27.3395 28.0978C26.9716 27.7789 25.3679 26.3868 24.988 24.4539C24.9182 24.099 24.8477 23.5125 24.5959 22.5891C24.4245 21.9378 24.2067 21.2998 23.9441 20.6801C23.5601 19.784 23.0939 18.6971 22.1588 17.7302C21.0343 16.5678 20.0686 16.0829 20.0686 16.0829C20.0686 16.0829 20.4223 15.8505 23.8557 16.1252C26.0226 16.299 26.5969 16.391 27.0334 16.4721C27.8215 16.6182 28.7325 16.7875 29.7331 17.1661C30.6024 17.4953 32.2882 18.156 33.7393 19.812C34.1613 20.2934 35.7075 22.1648 35.9166 24.9742C35.9827 25.8634 35.7859 27.2297 35.7859 27.2297C35.7859 27.2297 35.2561 26.4083 34.4362 25.3649C33.7201 24.4533 32.9765 23.9495 31.5184 22.9791C30.777 22.4846 28.0785 21.1572 28.0785 21.1572C28.0785 21.1572 29.1109 22.4281 30.6478 24.4974C32.1848 26.5668 33.2617 29.6593 33.2617 29.6593Z"
        fill="#3A9545"
      />
      <path
        d="M42.0577 29.5566C42.0577 29.5566 41.547 26.5312 41.8544 24.8137C42.0948 23.4716 42.5903 20.8975 44.6993 18.6252C45.8139 17.4236 46.9418 16.7774 47.7185 16.3404C49.5282 15.3222 51.0957 14.9707 52.5955 14.6341C54.1606 14.2832 55.277 14.1943 57.2696 14.0414C59.3472 13.8829 63.3079 14.2307 63.3079 14.2307C63.3079 14.2307 62.0435 14.8296 60.695 16.3706C60.1893 16.9487 59.8838 17.5175 59.2739 18.6553C58.741 19.6541 58.2755 20.6878 57.8804 21.7494C57.5333 22.6559 57.2424 23.5831 57.0095 24.5259C56.7667 25.5173 56.7976 25.6867 56.5739 26.3478C56.3002 27.1624 55.909 27.9322 55.4126 28.6322C54.446 29.9687 53.2977 30.6553 52.8 30.9459C51.1732 31.8954 49.7725 32.025 47.3716 32.2472C45.3104 32.3019 45.02 32.0735 45.02 32.0735C45.02 32.0735 45.1652 31.0904 47.2264 27.8226C48.4695 25.9245 49.0951 24.9706 49.2875 24.7282C51.2403 22.2657 52.8288 20.9109 52.8288 20.9109C52.8288 20.9109 50.5189 21.6437 50.2452 21.7783C49.3095 22.2386 48.046 22.8714 46.5872 24.1498C43.4021 26.9402 42.0577 29.5566 42.0577 29.5566Z"
        fill="#3A9545"
      />
      <path
        d="M31.9183 24.6968C31.9183 24.6968 33.4685 26.2931 35.0533 29.1864C35.3103 29.6554 36.1021 31.1423 37.0128 34.0661C37.897 36.905 38.3255 39.2733 38.7119 41.8089C39.7712 48.7753 40.1488 52.3493 40.1488 52.3493C40.2235 51.7106 40.3375 50.753 40.4833 49.5912C41.0212 45.3086 41.3585 42.621 41.9353 39.567C42.5958 36.0645 42.9279 34.3038 43.812 31.9165C44.6139 29.7538 45.6412 27.6826 46.8769 25.7377C45.422 27.4398 44.0902 29.2446 42.8917 31.1383C41.9841 32.5782 41.5297 33.4848 41.167 34.3682C40.7159 35.467 40.3744 36.6082 40.1475 37.7749C40.1475 37.7749 39.842 35.4473 38.6452 32.6998C37.9498 31.1022 37.116 30.0479 36.6205 29.3816C35.6851 28.1228 34.1831 26.3826 31.9183 24.6968Z"
        fill="#3A9545"
      />
      <path
        d="M45.0676 65.3284C45.0676 65.3284 46.2978 63.6631 48.5276 61.7498C50.9218 59.6984 53.2542 58.6634 54.5366 58.1062C55.6129 57.6399 57.3111 56.913 59.6311 56.4797C63.7726 55.7061 66.1628 55.6337 66.1628 55.6337C66.1628 55.6337 65.5088 53.067 64.8563 48.5417C64.5702 46.5556 64.6061 45.0339 64.6604 42.751C64.7411 39.3611 65.1418 36.8289 65.2474 36.1793C65.6249 33.8794 65.9845 31.6879 67.0763 29.0224C68.0692 26.5981 69 24.9884 69 24.9884C69 24.9884 67.4368 26.6453 66.227 28.632C65.6611 29.5616 64.5051 31.5847 63.4837 34.6178C62.7695 36.741 61.7402 39.7987 61.7204 43.727C61.6941 48.9122 61.9817 52.5757 61.9817 52.5757C60.0705 52.9138 58.2065 53.4818 56.43 54.2674C55.5002 54.6775 54.5984 55.1492 53.7306 55.6794C53.3633 55.9056 52.0268 56.7442 50.4868 58.1062C50.2462 58.3187 49.9379 58.5895 49.5492 58.9808C48.7182 59.8171 48.1022 60.6153 47.4822 61.4243C46.0936 63.2407 45.0676 65.3284 45.0676 65.3284Z"
        fill="#69488D"
      />
      <path
        d="M44.1593 62.2185C44.1593 62.2185 45.3101 60.6166 46.8643 58.6442C48.3799 56.7216 49.9891 55.6648 51.417 54.696C52.7788 53.7737 54.2401 53.0095 55.7729 52.4181C57.1932 51.8693 58.6681 51.4757 60.172 51.2439C60.0107 49.9773 59.8229 48.2654 59.6879 46.2265C59.5755 44.5258 59.489 43.2633 59.5297 41.5467C59.5946 38.8072 59.9187 36.7603 60.0046 36.2363C60.3098 34.3767 60.6005 32.6063 61.4825 30.4521C62.2857 28.494 63.0373 27.1924 63.0373 27.1924C63.0373 27.1924 61.7741 28.5313 60.7963 30.1367C60.3388 30.8879 59.4062 32.5218 58.5795 34.9744C57.5952 37.8988 57.354 40.2739 57.1544 42.3355C56.9317 44.6456 56.8939 46.9699 57.0413 49.2861C55.6118 49.6781 54.2398 50.2577 52.9607 51.0102C52.4772 51.2939 50.3629 52.5679 48.2227 55.0724C46.0203 57.6484 44.8198 60.3226 44.1593 62.2185Z"
        fill="#69488D"
      />
      <path
        d="M42.9156 66.0001C40.1689 65.9861 40.1689 65.9861 37.4198 66.0001C37.4198 64.4882 38.6499 63.2626 40.1692 63.2626C41.6885 63.2626 42.9156 64.4882 42.9156 66.0001Z"
        fill="#69488D"
      />
      <path
        d="M34.9355 65.2272C34.9355 65.2272 33.7063 63.5622 31.4736 61.6485C29.0795 59.5972 26.7471 58.5622 25.4647 58.005C24.3884 57.5387 22.6902 56.8118 20.3702 56.3785C16.2287 55.6049 13.8388 55.5325 13.8388 55.5325C13.8388 55.5325 14.4928 52.9658 15.145 48.4405C15.4313 46.4544 15.3952 44.933 15.3408 42.6498C15.2605 39.2599 14.8598 36.728 14.7539 36.0784C14.376 33.7785 14.0168 31.5867 12.925 28.9212C11.9312 26.4966 11.0001 24.8872 11.0001 24.8872C11.0001 24.8872 12.5636 26.5441 13.7733 28.5311C14.3396 29.4607 15.4953 31.4838 16.5166 34.5169C17.2311 36.6398 18.2599 39.6975 18.28 43.6258C18.3065 48.811 18.0189 52.4745 18.0189 52.4745C19.9302 52.8126 21.7941 53.3806 23.5707 54.1662C24.5004 54.5763 25.4021 55.048 26.2698 55.5782C26.6371 55.8044 27.9738 56.643 29.5135 58.005C29.7542 58.2179 30.0625 58.4886 30.4511 58.8796C31.2821 59.7163 31.8984 60.5141 32.5181 61.3234C33.9095 63.1395 34.9355 65.2272 34.9355 65.2272Z"
        fill="#69488D"
      />
      <path
        d="M35.8437 62.117C35.8437 62.117 34.6933 60.5148 33.1388 58.5427C31.6229 56.6197 30.014 55.563 28.5861 54.5954C27.2243 53.6731 25.763 52.9089 24.2302 52.3175C22.8098 51.7688 21.335 51.3752 19.8311 51.1433C19.992 49.8771 20.1799 48.1651 20.3149 46.1259C20.4273 44.4252 20.5141 43.1627 20.4733 41.4464C20.4082 38.707 20.0844 36.66 19.9982 36.1357C19.693 34.2764 19.4026 32.5057 18.5203 30.3519C17.7171 28.3938 16.9658 27.0918 16.9658 27.0918C16.9658 27.0918 18.2287 28.4308 19.2064 30.0364C19.664 30.7876 20.5966 32.4212 21.4233 34.8738C22.4075 37.7982 22.6488 40.1734 22.8484 42.2349C23.0711 44.545 23.1089 46.8694 22.9614 49.1855C24.3915 49.5773 25.764 50.1568 27.0436 50.9093C27.5271 51.1933 29.6411 52.467 31.7816 54.9715C33.9843 57.5465 35.1832 60.2207 35.8437 62.117Z"
        fill="#69488D"
      />
    </svg>
  );
};

export default Logo;
