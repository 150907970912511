import React from 'react';

const OK = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20ZM19.9864 20.3211C22.9356 20.3211 25.333 18.0066 25.333 15.1613C25.333 12.3151 22.9356 10 19.9864 10C17.0378 10 14.6398 12.3151 14.6398 15.1613C14.6398 18.0066 17.0378 20.3211 19.9864 20.3211ZM25.268 23.2925C24.3012 23.8786 23.2516 24.2943 22.1633 24.5336L25.1522 27.419C25.7645 28.0088 25.7645 28.9667 25.1522 29.5572C24.5405 30.1476 23.5491 30.1476 22.9381 29.5572L19.9995 26.7218L17.0633 29.5572C16.7572 29.8521 16.356 29.9997 15.9549 29.9997C15.5543 29.9997 15.1538 29.8521 14.8477 29.5572C14.236 28.9667 14.236 28.0095 14.847 27.419L17.8362 24.5336C16.7479 24.2943 15.6983 23.878 14.7315 23.2925C13.9999 22.8472 13.7801 21.9143 14.2411 21.2075C14.7008 20.4997 15.6676 20.2866 16.4005 20.7319C18.5893 22.061 21.409 22.0613 23.599 20.7319C24.332 20.2866 25.2984 20.4997 25.7591 21.2075C26.22 21.9137 25.9996 22.8472 25.268 23.2925Z"
        fill="#69488D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.9864 13.0248C21.2072 13.0248 22.2002 13.983 22.2002 15.1614C22.2002 16.3388 21.2072 17.2977 19.9864 17.2977C18.7666 17.2977 17.7726 16.3388 17.7726 15.1614C17.7726 13.983 18.7666 13.0248 19.9864 13.0248Z"
        fill="#69488D"
      />
    </svg>
  );
};
export default OK;
