import cl from './LoginContent.module.scss';
import React from 'react';
import { IconButton } from '@material-ui/core';
import Icon from 'ui/Icon';
import cn from 'classnames';

interface Props {
  showFrame?: boolean;
  className?: string;
  contentClassName?: string;
  onClose?: () => void;
}

const LoginContent: React.FC<Props> = ({ children, showFrame, className, onClose, contentClassName }) => {
  return (
    <div className={cn(cl.root, className && className, showFrame && cl.showFrame)}>
      {showFrame && (
        <IconButton onClick={onClose} className={cl.closeBtn}>
          <Icon type="Close" />
        </IconButton>
      )}
      <div className={cn(cl.wrapper, contentClassName)}>{children}</div>
    </div>
  );
};

export default LoginContent;
