const {
  REACT_APP_API_BASE_URL: API_BASE_URL = 'https://api.kurchat.ru',
  REACT_APP_WEB_BASE_URL: WEB_BASE_URL = 'https://kurchat-www-qa.it-cron.ru',
  REACT_APP_API_SMS_TIMEOUT: SMS_TIMEOUT = 60,
  REACT_APP_YANDEX_MAP_API_KEY: YANDEX_MAP_API_KEY,
  REACT_APP_VERSION: APP_VERSION = '1.0',
  REACT_APP_AVATAR_FILE_SIZE: AVATAR_FILE_SIZE_TMP = 5242880,
  REACT_APP_UPLOAD_FILE_SIZE: UPLOAD_FILE_SIZE_TMP = 5242880,
  REACT_APP_MOSRU_BASE_URL: MOSRU_BASE_URL = 'https://login-tech.mos.ru',
  REACT_APP_MOSRU_CLIENT_ID: MOSRU_CLIENT_ID = 'kurchat-www-qa.it-cron.ru',
} = process.env;

const AVATAR_FILE_SIZE: number = +AVATAR_FILE_SIZE_TMP;
const UPLOAD_FILE_SIZE: number = +UPLOAD_FILE_SIZE_TMP;

export {
  API_BASE_URL,
  SMS_TIMEOUT,
  YANDEX_MAP_API_KEY,
  APP_VERSION,
  AVATAR_FILE_SIZE,
  UPLOAD_FILE_SIZE,
  MOSRU_BASE_URL,
  MOSRU_CLIENT_ID,
  WEB_BASE_URL,
};
