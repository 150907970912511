import { AxiosError, AxiosResponse } from 'axios';

/**
 * Таблица конверсий ошибок по HTTP статусу
 *
 * HTTP статус указывается либо как есть `'422'` или паттерн вида `'4xx'`
 */
export type ErrorsMap = {
  [httpCode: string]: (response: AxiosResponse) => Error;
};

export function matchStatusPattern(pattern: string, status: number | string): boolean {
  // шаблон '4xx' ?
  if (3 !== pattern.length) {
    throw new Error('Pattern length must be 3');
  }

  const statusStr = String(status);
  if (pattern === statusStr) {
    return true;
  }

  if (!/^[\dx]+$/i.test(pattern)) {
    throw new Error('Invalid pattern: must be digits and `x` only');
  }

  const regexp = new RegExp(`^${pattern.replace(/x/gi, '\\d')}$`);

  return regexp.test(statusStr);
}

/**
 * Конверсия ошибки axios в другую ошибку по HTTP статусу
 *
 * ```
 * try {
 *   const response = await api();
 * } catch (e) {
 *   throw convertResponseError(e, {
 *     401: response => new ApiUnauthorizedError(...),
 *     404: response => new ApiNotFoundError(...),
 *   });
 * }
 * ```
 *
 * @param e Исходная ошибка axios
 * @param map Таблица конверсий по HTTP статусам
 */
export default function convertResponseError<E>(e: E, map: ErrorsMap): E | Error {
  if (!e || !((e as any) as AxiosError).isAxiosError) {
    return e;
  }

  const { response } = (e as any) as AxiosError;
  if (!response) {
    return e;
  }

  const { status } = response;

  const convert = map[String(status)];
  if (convert) {
    return convert(response);
  }

  for (let code of Object.keys(map).sort()) {
    if (matchStatusPattern(code, status)) {
      return map[code](response);
    }
  }

  return e;
}
