import { AxiosResponse } from 'axios';
import ApiClientError from './4xx';
import { isAlias422Fields } from 'api/utils/alias422Fields';

export interface ValidationError {
  message: string;
  field?: string;
}

export type ValidationErrors = ReadonlyArray<ValidationError>;

export type ValidationErrorsMap<K extends keyof any> = Partial<Record<K, string>>;

const isValidationErrors = (data: any): data is ValidationErrors =>
  Array.isArray(data) &&
  data.every(
    (item) =>
      Boolean(item) &&
      typeof item.message === 'string' &&
      (!item.hasOwnProperty('field') || typeof item.field === 'string'),
  );

export default class ApiUnprocessableError extends ApiClientError {
  readonly errors: ValidationErrors;

  constructor(response: AxiosResponse) {
    super(response);

    this.errors = isValidationErrors(response.data) ? response.data : [];

    if (isAlias422Fields(response.config)) {
      const aliases = response.config._$app_422Aliases;
      const newErrors: ValidationError[] = [];
      for (let error of this.errors) {
        const { field, message } = error;
        newErrors.push({
          field: field && aliases.hasOwnProperty(field) ? aliases[field] : field,
          message,
        });
      }

      this.errors = newErrors;
    }
  }

  getErrorsMap<K extends keyof any>(): ValidationErrorsMap<K> {
    const map: Partial<Record<keyof any, string>> = {};
    for (let { field, message } of this.errors) {
      map[field ?? ''] = message;
    }
    return map;
  }
}
