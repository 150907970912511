import React, { useCallback, useState } from 'react';
import { ButtonBase } from '@material-ui/core';
import Icon from '../../Icon';
import { Upload } from 'ui/button/Upload';
import cl from './ProfileAvatar.module.scss';
import cn from 'classnames';
import { showAlert } from '../../dialogs/Alert';
import { AVATAR_FILE_SIZE } from '../../../constants/config';

interface Props {
  src?: string;
  onUpload?: (file: File) => void;
}

export const ProfileAvatar: React.FC<Props> = ({ onUpload, src }) => {
  const [isError, setIsError] = useState(false);

  const handleError = useCallback(() => {
    setIsError(true);
  }, []);

  const handleErrorSize = useCallback(() => {
    showAlert('error', `Максимальный размер файла: ${(AVATAR_FILE_SIZE / 1024 / 1024).toFixed(1)} Mb`, 'Ошибка');
  }, []);

  return (
    <ButtonBase className={cl.root}>
      <div className={cl.avatarWrapper}>
        <div className={cn(cl.avatar, !!src && !isError && cl.withImg)}>
          {!!src && !isError ? (
            <img src={src} alt="" onError={handleError} />
          ) : (
            <Icon className={cl.noPhoto} type="NoPhoto" />
          )}
        </div>
        <span>Добавить фото</span>
      </div>
      <Upload onUpload={onUpload} onErrorSize={handleErrorSize} />
    </ButtonBase>
  );
};
