import React from 'react';

interface Props {}

const AppStore: React.FC<Props> = () => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.9292 0.941162H3.14352C1.42812 0.941162 0.036377 2.33855 0.036377 4.06091V26.9391C0.036377 28.6614 1.42812 30.0588 3.14352 30.0588H25.9292C27.6446 30.0588 29.0364 28.6614 29.0364 26.9391V4.06091C29.0364 2.33855 27.6446 0.941162 25.9292 0.941162ZM8.25736 23.8518C7.90133 24.4758 7.10513 24.6837 6.49017 24.3263C5.86874 23.9688 5.6616 23.1694 6.01763 22.5519L6.9433 20.9465C7.98548 20.6281 8.83995 20.8751 9.50669 21.6875L8.25736 23.8518ZM17.2487 20.3486H5.47388C4.76182 20.3486 4.17923 19.7636 4.17923 19.0487C4.17923 18.3338 4.76182 17.7488 5.47388 17.7488H8.77522L13.0087 10.3914L11.6817 8.09058C11.3257 7.46663 11.5393 6.6737 12.1542 6.31623C12.7757 5.95876 13.5654 6.17324 13.9214 6.79069L14.4975 7.79161L15.0737 6.79069C15.4297 6.16674 16.2259 5.95876 16.8408 6.31623C17.4623 6.6737 17.6694 7.47313 17.3134 8.09058L11.7594 17.7488H15.7792C17.0868 17.7488 17.8183 19.2892 17.2487 20.3486V20.3486ZM23.5989 20.3486H21.7216L22.9904 22.5519C23.3464 23.1759 23.1328 23.9688 22.5178 24.3263C21.8964 24.6837 21.1067 24.4693 20.7507 23.8518C18.621 20.1536 17.0286 17.3718 15.9605 15.526C14.8795 13.6411 15.6498 11.7563 16.4201 11.1193C17.2681 12.5947 18.5368 14.8045 20.2328 17.7488H23.5989C24.3109 17.7488 24.8935 18.3338 24.8935 19.0487C24.8935 19.7701 24.3109 20.3486 23.5989 20.3486V20.3486Z"
        fill="white"
      />
    </svg>
  );
};

export default AppStore;
