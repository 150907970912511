import { RightSide } from 'components/layout/MainLayout/RightSide';
import React, { useMemo } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import SummerLeftSide from './components/SummerLeftSide/SummerLeftSide';
import Default from './components/Default/Default';
import CurrentSummer from './components/Summer';
import SummerSign from './components/SummerSign/SummerSign';

interface Props extends RouteComponentProps {}

const Summer: React.FC<Props> = ({ match, location }) => {
  const isRootPath = useMemo(() => {
    const { pathname } = location;
    const partsQty = pathname.split('/').length;
    if (partsQty <= 2) return true;
    else return false;
  }, [location]);
  return (
    <>
      <SummerLeftSide isRootPath={isRootPath} />
      <RightSide isRootPath={isRootPath} useScroll={location.pathname !== match.url}>
        <Switch>
          <Route path={`${match.url}/:id/sign`} component={SummerSign} />
          <Route path={`${match.url}/:id`} component={CurrentSummer} />
          <Route path={match.url} component={Default} />
        </Switch>
      </RightSide>
    </>
  );
};

export default Summer;
