import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import cl from './GartenJoin.module.scss';
import { Step, Stepper } from 'ui/stepper/Stepper';
import { Button } from 'ui/button/Button';
import { useStore } from 'effector-react';
import {
  $step,
  $step1,
  $viewType,
  changeViewType,
  resetSteps,
  step1SetProfile,
  step1SetProfileRep,
  $step1Rep,
  nextStep,
  step1ToggleSaver,
  $step2,
  initChild,
  setEditChild,
  step2ToggleSaver,
  prevStep,
  step2SetChild,
  addChild,
  updateChild,
  $editChild,
  $step3,
  step3Set,
  setCurrentId,
} from './model';
import { ProfileEdit } from 'ui/edits/ProfileEdit/ProfileEdit';
import { EmailEdit } from 'ui/edits/EmailEdit/EmailEdit';
import { RequisitesEdit } from 'ui/edits/RequisitesEdit/RequisitesEdit';
import { SecondRepresentative } from 'ui/edits/SecondRepresentativeEdit/SecondRepresentative';
import { Step1 } from 'ui/commonSteps/Step1/Step1';
import { Step2 } from 'ui/commonSteps/Step2/Step2';
import { ChildAdd } from 'ui/edits/ChildAdd';
import { Step3 } from 'ui/commonSteps/Step3/Step3';
import { extraFacilitiesForGartens, facilitiesForGartens, needableFiles } from 'ui/commonSteps/utils/NeedableFiles';
import { Step4 } from './steps/Step4/Step4';
import { setActiveGarten } from 'state/garten';
import { Step5 } from './steps/Step5/Step5';

interface Props extends RouteComponentProps<{ id: string }> {}

const GartenJoin: React.FC<Props> = ({
  match: {
    params: { id },
  },
}) => {
  const { needSave, profile } = useStore($step1);

  const { profileRep } = useStore($step1Rep);

  const children = useStore($step2);

  const step3Form = useStore($step3);

  const STEPS: Step[] = [
    {
      label: 'Реквизиты',
      component: (
        <Step1
          needSave={needSave}
          profile={profile}
          profileRep={profileRep}
          changeViewType={changeViewType}
          nextStep={nextStep}
          step1SetProfile={step1SetProfile}
          step1ToggleSaver={step1ToggleSaver}
        />
      ),
    },
    {
      label: 'Выбор ребенка',
      component: (
        <Step2
          childrenInfo={children}
          changeViewType={changeViewType}
          initChild={initChild}
          nextStep={nextStep}
          setEditChild={setEditChild}
          step2ToggleSaver={step2ToggleSaver}
          step2SetChild={step2SetChild}
          prevStep={prevStep}
          type={'garten'}
        />
      ),
    },
    {
      label: 'Загрузка документов',
      component: (
        <Step3
          needableFiles={needableFiles}
          facilities={facilitiesForGartens}
          extraFacilities={extraFacilitiesForGartens}
          type="gartens"
          childrenInfo={children}
          step3Form={step3Form}
          nextStep={nextStep}
          prevStep={prevStep}
          step3Set={step3Set}
        />
      ),
    },
    {
      label: 'Подача документов',
      component: <Step4 />,
    },
    {
      label: 'Подписание договора',
      component: <Step5 />,
    },
  ];

  const step = useStore($step);

  const profileStore = useStore($step1);

  const profileRepStore = useStore($step1Rep);

  const viewType = useStore($viewType);

  const history = useHistory();

  const child = useStore($editChild);

  setCurrentId(+id);

  const handleClickRouteBack = useCallback(() => {
    history.push(`/gartens/${+id}`);
  }, [history, id]);

  const handleClickCancel = () => {
    handleClickRouteBack();
    setActiveGarten(-1);
  };

  const stepRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (stepRef.current) {
      const { top } = stepRef.current.getBoundingClientRect();
      setOffset(top + 46);
    }
  }, [stepRef]);

  useEffect(() => {
    setActiveGarten(+id);
  }, [id]);

  useEffect(() => {
    return () => {
      resetSteps();
    };
  }, []);

  return (
    <div className={cl.root}>
      <div className={cl.header}>
        <h1>Подача документов</h1>
        <Button variant="text" onClick={handleClickCancel}>
          Отменить запись
        </Button>
      </div>
      <Stepper steps={STEPS} current={step} />
      {viewType === 'step' && (
        <div ref={stepRef} style={{ minHeight: `calc(100vh - ${offset}px)` }} className={cl.stepWrapper}>
          {STEPS[step].component}
        </div>
      )}
      {viewType === 'profile' && (
        <ProfileEdit state={profileStore} changeViewType={changeViewType} stepSetProfile={step1SetProfile} />
      )}
      {viewType === 'email' && <EmailEdit state={profileStore} changeViewType={changeViewType} />}
      {viewType === 'requisites' && (
        <RequisitesEdit
          state={profileStore}
          type={'garten'}
          changeViewType={changeViewType}
          stepSetProfile={step1SetProfile}
        />
      )}
      {viewType === 'secondRepresentative' && (
        <SecondRepresentative
          state={profileRepStore}
          changeViewType={changeViewType}
          stepSetProfile={step1SetProfileRep}
        />
      )}
      {viewType === 'child' && (
        <ChildAdd
          childInfo={child}
          addChild={addChild}
          setEditChild={setEditChild}
          updateChild={updateChild}
          changeViewType={changeViewType}
        />
      )}
    </div>
  );
};
export default GartenJoin;
