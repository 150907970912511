import React from 'react';
import cl from './ProfileChildrenMenuItem.module.scss';
import Icon from 'ui/Icon';
import { BaseMenuItem } from '../BaseMenuItem';
import { Children } from 'api/types/children';
import { shortFio } from 'utils/helpers';
import { useChildAvatar } from 'hooks/children/useChildAvatar';
import { Avatar } from 'ui/avatar/Avatar';

export interface Props {
  data: Children;
  onClick: (id: number) => void;
  isActive: boolean;
  filled?: boolean;
}

export const ProfileChildrenMenuItem: React.FC<Props> = ({
  onClick,
  isActive,
  data: { first_name, last_name, patronymic, id, photo },
  filled,
}) => {
  const avatar = useChildAvatar(photo);
  return (
    <BaseMenuItem isActive={isActive} onClick={onClick.bind(null, id)} filled={filled} classname={cl.rootBaseItem}>
      <Avatar size={54} url={avatar} />
      <div className={cl.text}>
        <div className={cl.subtitle}>Ребенок</div>
        <div className={cl.title}>{shortFio(last_name, first_name, patronymic)}</div>
      </div>
      <div className={cl.ico}>
        <Icon type="ArrowForward" />
      </div>
    </BaseMenuItem>
  );
};

interface AddChildrenMenuItemProps {
  onClick: (path: string) => void;
  isActive: boolean;
  path: string;
}

export const AddChildrenMenuItem: React.FC<AddChildrenMenuItemProps> = ({ onClick, isActive, path }) => {
  return (
    <BaseMenuItem isActive={isActive} onClick={onClick.bind(null, path)}>
      <div className={cl.text}>Добавить ребенка</div>
      <div className={cl.addIco}>
        <Icon type="PlusCircle" />
      </div>
    </BaseMenuItem>
  );
};
