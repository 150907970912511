import React from 'react';
import cl from './Panel.module.scss';
import cn from 'classnames';

interface Props {
  classname?: string;
}

export const Panel: React.FC<Props> = ({children, classname}) => {
  return (
      <div className={cn(cl.root, classname)}>
        {children}
      </div>
  );
};
