import { useStore } from 'effector-react';
import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { setActiveClub } from 'state/club';
import { setActiveGarten } from 'state/garten';
import { $activeScroll, setActiveScroll } from 'state/home';
import { Button } from 'ui/button/Button';
import Icon from 'ui/Icon';
import cl from './Home.module.scss';
import { $summer, getSummerListFx } from 'state/summer';

interface Props {}

type typeImg = 'SchoolsMenu' | 'GartenMenu' | 'ClubMenu' | 'ConnectToSchool' | 'OnlineService' | 'Documents';

type typeImgs = 'GiveDocs' | 'ShowHistory' | 'AskQuestion' | 'ReportFromSchool' | 'RegistrationOnEvent' | 'BookOnMfc';

type socialType = 'World' | 'Instagram' | 'Facebook' | 'VK' | 'YouTube' | 'Telegram' | 'OK' | 'Yandex';

interface socialNetworks {
  id: number;
  title: socialType;

  pathname: string;
}

interface ref {
  img: typeImgs;
  title: string;
  pathname: string;
}
interface Panel {
  id: number;
  title: string;
  img: typeImg;
  refs: ref[];
}

const PANELS: Panel[] = [
  {
    id: 1,
    title: 'Подача документов в школу',
    img: 'SchoolsMenu',
    refs: [
      {
        img: 'GiveDocs',
        title: 'Подача документов',
        pathname: '/schools',
      },
      {
        img: 'ShowHistory',
        title: 'История заявлений',
        pathname: '/profile/history/schools',
      },
    ],
  },
  {
    id: 2,
    title: 'Подача документов в сад',
    img: 'GartenMenu',
    refs: [
      {
        img: 'GiveDocs',
        title: 'Подача документов',
        pathname: '/gartens',
      },
      {
        img: 'ShowHistory',
        title: 'История заявлений',
        pathname: '/profile/history/gartens',
      },
    ],
  },
  {
    id: 3,
    title: 'Запись в кружок',
    img: 'ClubMenu',
    refs: [
      {
        img: 'GiveDocs',
        title: 'Подача документов',
        pathname: '/clubs',
      },
      {
        img: 'ShowHistory',
        title: 'История заявлений',
        pathname: '/profile/history/clubs',
      },
    ],
  },
  {
    id: 4,
    title: 'Подписать электронный документ',
    img: 'Documents',
    refs: [
      {
        img: 'GiveDocs',
        title: 'Подача документов',
        pathname: '/documents',
      },
      {
        img: 'ShowHistory',
        title: 'История заявлений',
        pathname: '/profile/history/documents',
      },
    ],
  },
  {
    id: 5,
    title: 'Онлайн сервисы',
    img: 'OnlineService',
    refs: [
      {
        img: 'BookOnMfc',
        title: 'Запись на прием в МФЦ',
        pathname: 'https://n547489.yclients.com',
      },
      {
        img: 'RegistrationOnEvent',
        title: 'Запись на консультацию к специалистам',
        pathname: '/registry',
      },
    ],
  },
  {
    id: 6,
    title: 'Связь со школой',
    img: 'ConnectToSchool',
    refs: [
      {
        img: 'AskQuestion',
        title: 'Задать вопрос',
        pathname: 'https://b24-k5s4q5.bitrix24.site/crm_form_p6lua/',
      },
      {
        img: 'ReportFromSchool',
        title: 'Заказать справку из школы',
        pathname: 'https://kurchat.mskobr.ru/o-nas/sluzhby-oo/zakazat-spravku-ob-obuchenii',
      },
    ],
  },
];

const socialNetwork: socialNetworks[] = [
  { id: 7, title: 'World', pathname: 'https://kurchat.mskobr.ru' },
  { id: 8, title: 'VK', pathname: 'https://vk.com/kurchschool' },
  { id: 9, title: 'Telegram', pathname: 'https://t.me/kurchat' },
  { id: 10, title: 'OK', pathname: 'https://ok.ru/group/64246602006768' },
  { id: 11, title: 'Yandex', pathname: 'https://zen.yandex.ru/id/622f241df23b3769742876d8' },
  /*{ id: 8, title: 'Instagram', pathname: 'https://www.instagram.com/kurchat_/' },
  { id: 9, title: 'Facebook', pathname: 'https://www.facebook.com/kurchatovschool' },*/
  /*{ id: 11, title: 'YouTube', pathname: 'https://www.youtube.com/channel/UCkByif8EjQJOnp3-8gPqVCg?view_as=subscriber' },*/
];

const Home: React.FC<Props> = () => {
  const onScroll = useCallback((e: SyntheticEvent<HTMLDivElement>) => {
    setActiveScroll(e.currentTarget.scrollTop);
  }, []);

  const activeScroll = useStore($activeScroll);

  const ref = useRef<HTMLDivElement>(null);

  const summer = useStore($summer);

  const [isFirstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    if (!isFirstLoad) {
      ref.current?.scrollTo(0, activeScroll || 0);
    }
  }, [activeScroll, isFirstLoad]);

  useEffect(() => {
    setFirstLoad(true);
    getSummerListFx();
  }, []);

  return (
    <div ref={ref} onScroll={onScroll} className={cl.root}>
    <div className={cl.wrapper}>
      {PANELS.map((elem) => (
        <MainPanel key={elem.id} title={elem.title} refs={elem.refs} img={elem.img} />
      ))}
      <div className={cl.container}>
        <div className={cl.panelContainer}>
          <div className={cl.leftSide}>
            <Icon className={cl.icon} type={'Social'} />
          </div>
          <div className={cl.content}>
            <div className={cl.title}>Соцсети</div>
            <div className={cl.line} />

            <div className={cl.imgContainer}>
              {socialNetwork.map((elem) => (
                <a href={elem.pathname} key={elem.id} target="_blank" rel="noopener noreferrer">
                  <Icon type={elem.title} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
     {/* {summer?.data?.length !== 0 && 
      <>
      <MainPanel title={'Запись "Это лето"'} refs={[
    {
      img: 'GiveDocs',
      title: 'Подача документов',
      pathname: '/summer',
    },
    {
      img: 'ShowHistory',
      title: 'История заявлений',
      pathname: '/profile/history/documents',
    },
  ]} img={'GartenMenu'} /> 
  <div/>
  </>
}*/}
      <div className={cl.appDownload}>
        <div className={cl.text}>
          Чтобы скачать приложение <br /> в{' '}
          <a
            href="https://apps.apple.com/ru/app/%D0%BA%D1%83%D1%80%D1%87%D0%B0%D1%82%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%D1%8F-%D1%88%D0%BA%D0%BE%D0%BB%D0%B0-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1535928039"
            target="_blank"
            rel="noopener noreferrer"
          >
            App Store
          </a>{' '}
          или{' '}
          <a
            href="https://play.google.com/store/apps/details?id=ru.kurchat&hl=ru&gl=US"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Play
          </a>
        </div>
        <Icon type={'AppQrCode'} />
      </div>

      <div className={cl.appDownloadForMobile}>
        <div className={cl.title}>Скачать приложение</div>
        <div className={cl.btns}>
          <Button>
            <a
              href="https://apps.apple.com/ru/app/%D0%BA%D1%83%D1%80%D1%87%D0%B0%D1%82%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%D1%8F-%D1%88%D0%BA%D0%BE%D0%BB%D0%B0-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1535928039"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={cl.btn}>
                <Icon type={'AppStore'} />
                App Store
              </div>
            </a>
          </Button>

          <Button>
            <a
              href="https://play.google.com/store/apps/details?id=ru.kurchat&hl=ru&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={cl.btn}>
                <Icon type={'GooglePlay'} />
                Google play
              </div>
            </a>
          </Button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Home;

interface MainPanel {
  title: string;
  img: typeImg;
  refs: ref[];
}
const MainPanel: React.FC<MainPanel> = ({ title, img, refs }) => {
  const history = useHistory();
  const handleClickItem = useCallback(
    (pathname: string) => {
      history.push(pathname);
      setActiveGarten(-1);
      setActiveClub(-1);
    },
    [history],
  );
  const localUrl = [
    '/schools',
    '/gartens',
    '/clubs',
    '/profile/history/schools',
    '/profile/history/clubs',
    '/profile/history/gartens',
    '/profile/feedback',
    '/documents',
    '/profile/history/documents',
    '/summer',
    '/registry',
  ];
  return (
    <div className={cl.container}>
      <div className={cl.panelContainer}>
        <div className={cl.leftSide}>
          <Icon className={cl.icon} type={img} />
        </div>
        <div className={cl.content}>
          <div className={cl.title}>{title}</div>
          <div className={cl.line} />
          <div className={cl.refsContainer}>
            {refs.map((elem) =>
              localUrl.indexOf(elem.pathname) !== -1 ? (
                <div className={cl.ref} onClick={() => handleClickItem(elem.pathname)}>
                  <Icon type={elem.img} />
                  <div>{elem.title}</div>
                </div>
              ) : (
                <div className={cl.ref}>
                  <Icon type={elem.img} />
                  <a href={elem.pathname} target="_blank" rel="noopener noreferrer">
                    {elem.title}
                  </a>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
