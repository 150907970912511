import React from 'react';

interface Props {}

const GiveDocs: React.FC<Props> = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M2.82341 8.45834C3.01191 8.45834 3.16473 8.30552 3.16473 8.11702C3.16473 7.92851 3.01191 7.7757 2.82341 7.7757C2.6349 7.7757 2.48209 7.92851 2.48209 8.11702C2.48209 8.30552 2.6349 8.45834 2.82341 8.45834Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.25"
        />
        <path
          d="M2.82341 10.1436C3.01191 10.1436 3.16473 9.99077 3.16473 9.80226C3.16473 9.61375 3.01191 9.46094 2.82341 9.46094C2.6349 9.46094 2.48209 9.61375 2.48209 9.80226C2.48209 9.99077 2.6349 10.1436 2.82341 10.1436Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.25"
        />
        <path
          d="M2.82341 11.8288C3.01191 11.8288 3.16473 11.676 3.16473 11.4875C3.16473 11.299 3.01191 11.1462 2.82341 11.1462C2.6349 11.1462 2.48209 11.299 2.48209 11.4875C2.48209 11.676 2.6349 11.8288 2.82341 11.8288Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.25"
        />
        <path
          d="M11.1648 0H4.08639C3.71734 0 3.37025 0.143152 3.10862 0.403207L0.555366 2.78149C0.553317 2.78341 0.551301 2.78533 0.549347 2.78731C0.287338 3.04932 0.143052 3.39767 0.143052 3.7682V7.41235C0.143052 7.54286 0.248864 7.64867 0.37938 7.64867C0.509896 7.64867 0.615708 7.54286 0.615708 7.41235V3.7682C0.615708 3.74321 0.616937 3.71838 0.618922 3.69374H3.04053C3.57617 3.69374 4.01196 3.25799 4.01196 2.72234V0.47587C4.0366 0.473885 4.0614 0.472656 4.08642 0.472656H11.1648C11.4208 0.472656 11.629 0.680939 11.629 0.93693V15.0631C11.629 15.3191 11.4208 15.5274 11.1648 15.5274H1.07995C0.823929 15.5274 0.615677 15.3191 0.615677 15.0631V8.35769C0.615677 8.22717 0.509865 8.12136 0.379349 8.12136C0.248833 8.12136 0.143021 8.22717 0.143021 8.35769V15.0631C0.143021 15.5797 0.563306 16 1.07995 16H11.1648C11.6814 16 12.1017 15.5797 12.1017 15.0631V0.93693C12.1017 0.420286 11.6814 0 11.1648 0V0ZM3.53927 2.72234C3.53927 2.99736 3.31552 3.22109 3.0405 3.22109H0.797365C0.822731 3.18728 0.85046 3.15488 0.880742 3.12435L3.43371 0.746324C3.43576 0.744401 3.43778 0.742479 3.43973 0.740494C3.47112 0.70911 3.50442 0.680372 3.5393 0.654219V2.72234H3.53927Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.25"
        />
        <path
          d="M9.76259 7.88055H4.28001C4.1495 7.88055 4.04369 7.98636 4.04369 8.11688C4.04369 8.24739 4.1495 8.3532 4.28001 8.3532H9.76259C9.89311 8.3532 9.99892 8.24739 9.99892 8.11688C9.99892 7.98636 9.89311 7.88055 9.76259 7.88055Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.25"
        />
        <path
          d="M9.76259 9.56573H4.28001C4.1495 9.56573 4.04369 9.67155 4.04369 9.80206C4.04369 9.93257 4.1495 10.0384 4.28001 10.0384H9.76259C9.89311 10.0384 9.99892 9.93257 9.99892 9.80206C9.99892 9.67155 9.89311 9.56573 9.76259 9.56573Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.25"
        />
        <path
          d="M9.76259 11.2513H4.28001C4.1495 11.2513 4.04369 11.3571 4.04369 11.4876C4.04369 11.6181 4.1495 11.7239 4.28001 11.7239H9.76259C9.89311 11.7239 9.99892 11.6181 9.99892 11.4876C9.99892 11.3571 9.89311 11.2513 9.76259 11.2513Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.25"
        />
        <path
          d="M5.41713 5.53613H2.61974C2.48922 5.53613 2.38341 5.64194 2.38341 5.77246C2.38341 5.90298 2.48922 6.00879 2.61974 6.00879H5.41713C5.54765 6.00879 5.65346 5.90298 5.65346 5.77246C5.65346 5.64194 5.54765 5.53613 5.41713 5.53613Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.25"
        />
        <path
          d="M15.6206 4.9732H14.8935V4.40438C14.8935 3.863 14.4531 3.42255 13.9117 3.42255C13.3703 3.42255 12.9299 3.863 12.9299 4.40438V7.59496C12.9299 7.72548 13.0357 7.83129 13.1662 7.83129C13.2967 7.83129 13.4025 7.72548 13.4025 7.59496V5.44583H14.4208V12.9108H13.4025V8.54028C13.4025 8.40976 13.2967 8.30395 13.1662 8.30395C13.0357 8.30395 12.9299 8.40976 12.9299 8.54028V13.1472C12.9299 13.1489 12.9301 13.1505 12.9301 13.1522C12.9302 13.1569 12.9304 13.1616 12.9308 13.1663C12.9311 13.1701 12.9315 13.1737 12.932 13.1774C12.9325 13.1812 12.9331 13.185 12.9337 13.1888C12.9346 13.1933 12.9355 13.1977 12.9366 13.2021C12.937 13.2039 12.9372 13.2056 12.9377 13.2073L13.6136 15.7746C13.6152 15.7805 13.617 15.7865 13.6191 15.7923C13.6625 15.9166 13.78 16 13.9117 16C14.0434 16 14.1609 15.9165 14.2043 15.7922C14.2063 15.7864 14.2081 15.7805 14.2097 15.7746L14.8857 13.2073C14.8862 13.2056 14.8864 13.2039 14.8868 13.2021C14.8879 13.1977 14.8888 13.1933 14.8896 13.1888C14.8903 13.185 14.8909 13.1812 14.8914 13.1774C14.8918 13.1737 14.8922 13.1701 14.8925 13.1663C14.8929 13.1616 14.8931 13.1569 14.8932 13.1522C14.8933 13.1505 14.8935 13.1489 14.8935 13.1472V5.44586H15.3842V8.85695C15.3842 8.98747 15.49 9.09328 15.6205 9.09328C15.7511 9.09328 15.8569 8.98747 15.8569 8.85695V5.20953C15.8569 5.07902 15.7511 4.9732 15.6206 4.9732ZM13.4025 4.9732V4.40438C13.4025 4.12362 13.6309 3.8952 13.9117 3.8952C14.1925 3.8952 14.4209 4.12362 14.4209 4.40438V4.9732H13.4025ZM13.9117 15.0502L13.4728 13.3835H14.3506L13.9117 15.0502Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.25"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GiveDocs;
