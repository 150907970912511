import React from 'react';
import {Header} from "./Header";
import cl from './MainLayout.module.scss'

interface Props {
}

const MainLayout: React.FC<Props> = ({children}) => {
  return (
      <div className={cl.root}>
        <Header/>
        <div className={cl.content}>
          {children}
        </div>
      </div>
  );
};

export default MainLayout;
