import { LeftSide } from 'components/layout/MainLayout/LeftSide';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SearchInput } from 'ui/input/SearchInput';
import { BaseMenuItem } from 'ui/list/BaseMenuItem';
import cl from './SummerLeftSide.module.scss';
import cn from 'classnames';
import { $summer, getSummerListFx } from 'state/summer';
import { useStore } from 'effector-react';

interface Props {
  isRootPath: boolean;
}

const SummerLeftSide: React.FC<Props> = ({ isRootPath }) => {
  const [filter, setFilter] = useState<string>('');
  const handleChangeFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  }, []);
  const summer = useStore($summer);
  useEffect(() => {
      getSummerListFx();
  }, [])
  const filteredData = useMemo(() => {
    if (filter) {
      return summer.data.filter((el) => el.title.toLowerCase().includes(filter.toLowerCase()));
    } else {
      return summer.data;
    }
  }, [summer.data, filter]);

  const history = useHistory();
  const handleClickItem = useCallback(
    (id: number) => {
      history.push(`/summer/${id}`);
    },
    [history],
  );

  const location = useLocation();

  const activeElement = location.pathname.split('/')[3];
  const disabledElement = location.pathname.includes('sign');
  return (
    <LeftSide
      isRootPath={isRootPath}
      topComponent={<SearchInput onChange={handleChangeFilter} className={cl.filter} />}
      title="Выбор услуги"
    >
      <div className={cl.container}>
        {filteredData.length > 0 ? (
          <>
            {filteredData.map((elem) => {
              return (
                <BaseMenuItem
                  disable={disabledElement && +activeElement !== elem.id}
                  key={elem.id}
                  onClick={() => handleClickItem(elem.id)}
                  classname={cn(cl.block, disabledElement && +activeElement !== elem.id && cl.disabled)}
                  isActive={+activeElement === elem.id}
                >
                  {elem.title}
                </BaseMenuItem>
              );
            })}
          </>
        ) : (
          <div className={cl.notFound}>
            Ничего не найдено :( <br />
            Попробуйте изменить запрос или обновить страницу.
          </div>
        )}
      </div>
    </LeftSide>
  );
};

export default SummerLeftSide;
