import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { fetchProfileIfNeed } from 'state/profile';
import { $children } from '../../state/children';

export const useChildren = () => {
  const children = useStore($children);

  useEffect(() => {
    fetchProfileIfNeed();
  }, [children]);
  return children;
};
