import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import cl from './SchoolProgramm.module.scss';
import Icon from 'ui/Icon';
import { PanelMini } from 'ui/panels/PanelMini';
import { Button } from 'ui/button/Button';
import { IconButton } from '@material-ui/core';
import { AddressPanel, PhotosPanel } from 'ui/panels';
import { ContactPanel } from 'ui/panels/ContactPanel';
import { useSchools } from 'hooks/schools';
import { useProfile } from 'hooks/profile';
import { EmailConfirm } from 'ui/dialogs/EmailConfirm/EmailConfirm';

import { ConfirmDialog } from 'routes/main/routes/profile/routes/email/EmailPanel/ConfirmDialog';

import Agreement from 'ui/dialogs/Agreement';

interface Props extends RouteComponentProps<{ id: string; programm: string; parallel: string }> {}

const SchoolProgrammJoin: React.FC<Props> = ({
  match: {
    params: { parallel, programm, id },
  },
}) => {
  // общие
  const history = useHistory();

  const { data: schoolsData } = useSchools();

  const { data: profile } = useProfile();

  const CurrentProgramm = useMemo(
    () => schoolsData.find((element) => element.number === +parallel)?.programs.find((elem) => elem.id === +programm),
    [parallel, programm, schoolsData],
  );

  const CurrentProgrammPlace = useMemo(() => CurrentProgramm?.places.find((elem) => elem.id === +id), [
    id,
    CurrentProgramm,
  ]);
  const { photos = [] } = CurrentProgrammPlace || {};

  const handleClickBack = useCallback(() => {
    history.push(`/schools/${+parallel}/${+programm}`);
  }, [history, parallel, programm]);

  //подтверждение соглашений

  const [isOpenAgreement, setOpenAgreement] = useState(false);

  const handleClickRouteToProgrammJoin = useCallback(() => {
    history.push(`/schools/${+parallel}/${+programm}/${+id}/join`);
  }, [history, parallel, programm, id]);

  //почта отсутсвует
  const [isOpenEmailCreate, setOpenEmailCreate] = useState(false);

  const handleCloseEmailConfirmDialog = useCallback(() => {
    setOpenEmailCreate(false);
  }, []);

  const [email, setEmail] = useState('');

  useEffect(() => {
    setEmail(profile.email || '');
  }, [profile.email]);

  //почта не подтверждена
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleDialogOk = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  //навигация по dialogs
  const handleSignUp = useCallback(() => {
    if (profile.email === null) {
      setOpenEmailCreate(true);
    } else if (!profile.email_verified) {
      setOpenConfirm(true);
    } else {
      setOpenAgreement(true);
    }
  }, [profile.email, profile.email_verified]);

  return (
    <div className={cl.root}>
      <div className={cl.header}>
        <div className={cl.title}>
          <IconButton onClick={handleClickBack}>
            <Icon type="Back" />
          </IconButton>
          <h1>{CurrentProgrammPlace?.title}</h1>
        </div>
        <Button onClick={() => handleSignUp()} className={cl.buttonForDesctop}>
          Продолжить
        </Button>
      </div>
      <div>
        <ContactPanel
          title="Отдел зачисления"
          label={`${parallel} класс`}
          data={CurrentProgrammPlace?.contacts || []}
        />
        <div className={cl.description}>
          <PanelMini classname={cl.workDays} title={'Режим работы'}>
            {CurrentProgrammPlace?.schedule}
          </PanelMini>
          <PanelMini classname={cl.workHours} title={'Приемные часы'}>
            {CurrentProgrammPlace?.work_time}
          </PanelMini>
        </div>
        {!!photos.length && <PhotosPanel data={photos} />}
        {CurrentProgrammPlace?.address ? <AddressPanel address={CurrentProgrammPlace.address} /> : ''}
        <Button onClick={() => handleSignUp()} className={cl.buttonForMobile}>
          Продолжить
        </Button>
      </div>

      <EmailConfirm
        isOpen={isOpenEmailCreate}
        handleCloseEmailConfirmDialog={handleCloseEmailConfirmDialog}
        setOpenConfirm={setOpenConfirm}
        email={email}
        setEmail={setEmail}
      />

      <Agreement
        type="schools"
        isOpenAgreement={isOpenAgreement}
        setOpenAgreement={setOpenAgreement}
        conditions={CurrentProgramm?.conditions.length ? CurrentProgramm?.conditions : []}
        onClickContinue={handleClickRouteToProgrammJoin}
      />

      <ConfirmDialog open={openConfirm} email={email} onClickOk={handleDialogOk} />
    </div>
  );
};

export default SchoolProgrammJoin;
