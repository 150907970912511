import React from 'react';
import cl from './ClubItem.module.scss';
import { BaseMenuItem } from 'ui/list/BaseMenuItem';
import { Club } from 'api/types/clubs';
import { $activeClub } from 'state/club';
import { useStore } from 'effector-react';
import cn from 'classnames';

interface Props {
  data: Club;
  isActive: boolean;
  onClick: (clubId: number) => void;
}

export const ClubItem: React.FC<Props> = ({ data, onClick, isActive }) => {
  const activeId = useStore($activeClub);

  const isDisabledClubs = (id: number) => {
    if (id === -1) {
      return false;
    } else return true;
  };
  return (
    <BaseMenuItem
      isActive={isActive}
      disable={isDisabledClubs(activeId || -1)}
      onClick={onClick.bind(null, data.id)}
      classname={cn(cl.root, activeId === -1 ? '' : activeId === data.id ? '' : cl.notActive)}
    >
      <div className={cl.title}>
        <div className={cl.name}>{data.title}</div>
        <div className={cl.code}>{data.code}</div>
      </div>
      <div className={cl.address}>{data.address.text_address}</div>
    </BaseMenuItem>
  );
};
