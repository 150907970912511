import { LeftSide } from 'components/layout/MainLayout/LeftSide';
import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import GartenItem from '../GartenItem';
import cl from './GartensLeftSide.module.scss';
import { useGartens } from 'hooks/gartens';

interface Props {
  isRootPath: boolean;
}

const GartensLeftSide: React.FC<Props> = ({ isRootPath }) => {
  const { pathname } = useLocation();

  const history = useHistory();
  const { data: gartensData } = useGartens();

  const handleClickItem = useCallback(
    (id: number) => {
      history.push(`/gartens/${id}`);
    },
    [history],
  );

  const gartenId = useMemo(() => {
    return +pathname.split('/')[2];
  }, [pathname]);

  return (
    <LeftSide isRootPath={isRootPath} title="Запись в сад">
      <ul className={cl.menu}>
        {gartensData.map((elem) => (
          <GartenItem
            key={elem.id}
            id={elem.id}
            description={elem.description}
            name={elem.title}
            onClick={handleClickItem}
            isActive={gartenId === elem.id}
          />
        ))}
      </ul>
    </LeftSide>
  );
};

export default GartensLeftSide;
