import React, { useMemo } from 'react';
import { Switch } from 'react-router';
import { Default } from './components/Default';
import { Route, RouteComponentProps } from 'react-router-dom';
import SchoolsLeftSide from './components/SchoolsLeftSide/SchoolsLeftSide';
import { RightSide } from 'components/layout/MainLayout/RightSide';
import Programms from './components/Programms';
import SchoolProgrammJoin from './components/SchoolProgramm';
import EmptyProgramm from './components/EmptyProgramm';
import ProgrammJoin from './components/ProgrammJoin';

interface Props extends RouteComponentProps {}

const Schools: React.FC<Props> = ({ match, location }) => {
  const isUseScroll = useMemo(() => {
    const locationArr = location.pathname.split('/');
    if (locationArr.length <= 3) {
      return false;
    } else return locationArr[3] !== 'empty';
  }, [location.pathname]);

  const isRootPath = useMemo(() => {
    const { pathname } = location;
    const partsQty = pathname.split('/').length;
    if (partsQty <= 3) return true;
    else return false;
  }, [location]);

  return (
    <>
      <SchoolsLeftSide isRootPath={isRootPath} />
      <RightSide isRootPath={isRootPath} useScroll={isUseScroll}>
        <Switch>
          <Route path={`${match.url}/:parallel/:programm/:placeId/join`} component={ProgrammJoin} />
          <Route path={`${match.url}/:parallel/:programm/:id`} component={SchoolProgrammJoin} />
          <Route path={`${match.url}/:parallel/empty`} component={EmptyProgramm} />
          <Route path={`${match.url}/:parallel/:id`} component={Programms} />
          <Route path={`${match.url}/:parallel?`} component={Default} />
        </Switch>
      </RightSide>
    </>
  );
};

export default Schools;
