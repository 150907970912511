
import React from 'react';
import { MainLayout } from 'components/layout/MainLayout';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import Default from './components/Default';
import Profile from './routes/profile';
import Clubs from './routes/clubs';
import Schools from './routes/schools';
import Home from './routes/home';
import Gartens from './routes/gartens';
import Documents from './routes/documents';
import { EmailConfirmation } from './routes/profile/routes/email-confirmation/components/EmailConfirmation';
import Summer from './routes/summer';
import Registry from './routes/registry/Registry';

interface Props extends RouteComponentProps {}

const Main: React.FC<Props> = ({ match }) => {
  return (
    <MainLayout>
      <Switch>
        <Route path={'/registry'} component={Registry} />
        <Route path={`/summer`} component={Summer} />
        <Route path={`/documents`} component={Documents} />
        <Route path={`/profile`} component={Profile} />
        <Route path={`/clubs`} component={Clubs} />
        <Route path={`/schools`} component={Schools} />
        <Route path={`/gartens`} component={Gartens} />
        <Route path={`/home`} component={Home} />
        <Route path={`/verification/:code`} component={EmailConfirmation} />
        <Route path={match.url} component={Default} />
      </Switch>
    </MainLayout>
  );
};

export default Main;