import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { Panel } from 'ui/panels/Panel';
import cl from './Feedback.module.scss';
import { TextField } from 'ui/input/TextField';
import { Button } from 'ui/button/Button';
import { useProfile } from 'hooks/profile';
import { FeedbackForm } from 'api/types/profile/feedback';
import { Profile } from 'api/types/profile';
import { emailValidator, isValid, requiredValidator } from 'utils/validators';
import { sendFeedbackFx } from 'state/profile/feedback';
import { showAlert, showErrorAlert } from 'ui/dialogs/Alert';
import { PageContent } from '../../../../../../../../components/layout/PageContent';

interface Props {}

interface Form extends FeedbackForm {}

interface Errors extends Partial<Record<keyof Form, string | undefined>> {}

const initForm = (profile: Partial<Profile>): Form => ({
  email: profile?.email || '',
  text: '',
  title: '',
});

const Feedback: React.FC<Props> = () => {
  const { profile } = useProfile();
  const [form, setForm] = useState<Form>(initForm(profile));
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    setForm(initForm(profile));
  }, [profile]);

  const handleChangeValue = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    let { value } = e.target;

    if (name === 'title' && value.length > 100) {
      value = value.substr(0, 100);
    }

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
    setErrors((errors) => ({
      ...errors,
      [name]: undefined,
    }));
  }, []);

  const validate = useCallback(() => {
    const errors: Errors = {
      email: emailValidator(form.email, true),
      text: requiredValidator(form.text),
      title: requiredValidator(form.title),
    };
    setErrors(errors);
    return isValid(errors);
  }, [form]);

  const handleSend = useCallback(async () => {
    if (!validate()) return;

    try {
      await sendFeedbackFx(form);
      await showAlert('success', 'Ваше сообщение успешно отправленно', 'Обратная связь');
      setForm(initForm(profile));
    } catch (e) {
      await showErrorAlert();
    }
  }, [validate, form, profile]);

  return (
    <PageContent titleSM="Обратная связь">
      <Panel classname={cl.panel}>
        <iframe src="https://b24-k5s4q5.bitrix24.site/crm_form_p6lua/" className={cl.iframe} />
      </Panel>
    </PageContent>
    /*<PageContent titleSM="Обратная связь">
      <Panel classname={cl.panel}>
        <TextField
          label="E-mail"
          name="email"
          fullWidth
          value={form.email}
          onChange={handleChangeValue}
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          label="Тема"
          name="title"
          fullWidth
          multiline
          value={form.title}
          onChange={handleChangeValue}
          error={!!errors.title}
          helperText={errors.title}
        />
        <TextField
          label="Сообщение"
          name="text"
          fullWidth
          multiline
          value={form.text}
          onChange={handleChangeValue}
          error={!!errors.text}
          helperText={errors.text}
        />
        <div className={cl.footer}>
          <Button onClick={handleSend}>Отправить</Button>
        </div>
      </Panel>
  </PageContent>*/
  );
};

export default Feedback;
