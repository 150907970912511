import React, { useEffect } from 'react';
import { logout } from 'state/auth';

interface Props {}

export const Logout: React.FC<Props> = () => {
  useEffect(() => {
    logout();
  }, []);

  return null;
};
