import React, { ChangeEvent, useCallback, useState } from 'react';
import cl from './ChildAdd.module.scss';
import { EditInStep } from 'ui/panels/EditInStep';
import { DateMask, InilaMask, PhoneMask } from 'ui/masks';
import { dateValidator, inilaValidator, isValid, onlyDigit, requiredValidator, maxValidator } from 'utils/validators';
import { TextField } from 'ui/input/TextField';
import { Children, ChildrenForm, DOCUMENT_TYPE, DocumentType } from 'api/types/children';
import { MenuItem } from '@material-ui/core';
import { showErrorAlert } from 'ui/dialogs/Alert';
import { useAdultTypes } from 'hooks/catalog/adultTypes';
import { toISODate, toLocalDate } from 'constants/date/format';

interface Props {
  childInfo: Children | null;
  addChild: (child: Children) => void;
  setEditChild: (id: Children | null) => void;
  updateChild: (child: Children) => void;
  changeViewType: (view: any) => void;
  isNeededExtraFields?: boolean;
}

interface Form extends Omit<ChildrenForm, 'adult_type_id' | 'photo'> {
  adult_type_id: number | '';
}

interface Errors extends Partial<Record<keyof Form, string | undefined>> {}

const initForm = (child: Children | null): Form => ({
  last_name: child?.last_name || '',
  first_name: child?.first_name || '',
  patronymic: child?.patronymic || '',
  dob: toLocalDate(child?.dob) || '',
  citizenship: child?.citizenship || '',
  document_type: child ? child.document_type : undefined,
  document_number: child?.document_number || '',
  document_issued_by: child?.document_issued_by || '',
  document_issued_date: toLocalDate(child?.document_issued_date) || '',
  address: child?.address || '',
  actual_address: child?.actual_address || '',
  inila: child?.inila || '',
  phone: child?.phone || '',
  adult_type_id: child?.adult_type_id || '',
});

export const ChildAdd: React.FC<Props> = (props) => {
  const { childInfo, addChild, setEditChild, updateChild, changeViewType, isNeededExtraFields = false } = props;
  // const child = useStore($editChild);
  const child = childInfo;
  const { data: adultTypeList } = useAdultTypes();
  const [form, setForm] = useState<Form>(initForm(child));
  const [errors, setErrors] = useState<Errors>({});

  const handleChangeForm = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setErrors((e) => ({
      ...e,
      [name]: undefined,
    }));
  }, []);

  const validate = useCallback(() => {
    let errors: Errors = {
      last_name: maxValidator(form.last_name, 100, true),
      first_name: maxValidator(form.first_name, 100, true),
      patronymic: maxValidator(form.patronymic, 100),
      dob: dateValidator(form.dob, true, true),
      document_type: requiredValidator(String(form.document_type)),
      document_number: maxValidator(form.document_number, 20, true),
      document_issued_by: maxValidator(form.document_issued_by, 500, true),
      document_issued_date: dateValidator(form.document_issued_date, true, true),
      address: maxValidator(form.address, 500, true),
      actual_address: isNeededExtraFields ? maxValidator(form.actual_address, 500, true) : undefined,
      inila: inilaValidator(form.inila, true),
      adult_type_id: requiredValidator(form.adult_type_id),
      citizenship: isNeededExtraFields ? maxValidator(form.citizenship, 100, true) : undefined,
      // phone: requiredValidator(form.phone),
    };

    setErrors(errors);

    return isValid(errors);
  }, [form, isNeededExtraFields]);

  const handleChangeDocType = useCallback((e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setForm((form) => ({
      ...form,
      document_type: e.target.value as DocumentType,
    }));
    setErrors((e) => ({
      ...e,
      document_type: undefined,
    }));
  }, []);

  const handleBack = useCallback(() => {
    setEditChild(null);
    changeViewType('step');
  }, [changeViewType, setEditChild]);

  const handleSave = useCallback(async () => {
    if (!validate()) return;
    try {
      const paramForm = {
        ...form,
        inila: onlyDigit(form.inila || ''),
        phone: onlyDigit(form.phone || ''),
        adult_type_id: form.adult_type_id as number,
        dob: toISODate(form.dob),
        document_issued_date: toISODate(form.document_issued_date),
      };

      if (child) await updateChild({ ...paramForm, id: child.id, photo: child.photo });
      else await addChild({ ...paramForm, id: -1, photo: null });

      setEditChild(null);
      changeViewType('step');
    } catch (e) {
      await showErrorAlert();
    }
  }, [validate, form, child, setEditChild, changeViewType, updateChild, addChild]);

  return (
    <EditInStep
      title={child ? 'Редактировать данные ребенка' : 'Добавить данные ребенка'}
      onClickBack={handleBack}
      className={cl.root}
      onClickNext={handleSave}
    >
      <form autoComplete="off" noValidate>
        <TextField
          label="Фамилия"
          fullWidth
          value={form.last_name}
          name="last_name"
          onChange={handleChangeForm}
          error={!!errors.last_name}
          helperText={errors.last_name}
        />
        <TextField
          label="Имя"
          fullWidth
          value={form.first_name}
          name="first_name"
          onChange={handleChangeForm}
          error={!!errors.first_name}
          helperText={errors.first_name}
        />
        <TextField
          label="Отчество"
          fullWidth
          value={form.patronymic}
          name="patronymic"
          onChange={handleChangeForm}
          error={!!errors.patronymic}
          helperText={errors.patronymic}
        />
        <TextField
          label="Дата рождения"
          fullWidth
          value={form.dob}
          name="dob"
          onChange={handleChangeForm}
          error={!!errors.dob}
          helperText={errors.dob}
          InputProps={{ inputComponent: DateMask }}
        />

        <TextField
          label="Гражданство"
          fullWidth
          value={form.citizenship}
          name="citizenship"
          onChange={handleChangeForm}
          error={!!errors.citizenship}
          helperText={errors.citizenship}
        />

        <TextField
          label="Тип документа"
          value={form.document_type ?? ''}
          fullWidth
          onChange={handleChangeDocType}
          error={!!errors.document_type}
          helperText={errors.document_type}
          select
        >
          <MenuItem value={0}>{DOCUMENT_TYPE[0]}</MenuItem>
          <MenuItem value={1}>{DOCUMENT_TYPE[1]}</MenuItem>
        </TextField>
        <TextField
          label="Серия и номер документа"
          fullWidth
          value={form.document_number}
          name="document_number"
          onChange={handleChangeForm}
          error={!!errors.document_number}
          helperText={errors.document_number}
          // InputProps={{ inputComponent: form.document_type === 1 ? PassportMask : undefined }}
          disabled={typeof form.document_type !== 'number'}
        />
        <TextField
          label="Кем выдан"
          fullWidth
          value={form.document_issued_by}
          name="document_issued_by"
          onChange={handleChangeForm}
          error={!!errors.document_issued_by}
          helperText={errors.document_issued_by}
        />
        <TextField
          label="Дата выдачи"
          fullWidth
          value={form.document_issued_date}
          name="document_issued_date"
          onChange={handleChangeForm}
          error={!!errors.document_issued_date}
          helperText={errors.document_issued_date}
          InputProps={{ inputComponent: DateMask }}
        />
        <TextField
          label="Адрес регистрации"
          fullWidth
          value={form.address}
          name="address"
          onChange={handleChangeForm}
          error={!!errors.address}
          helperText={errors.address}
        />

        <TextField
          label="Фактический адрес"
          fullWidth
          value={form.actual_address}
          name="actual_address"
          onChange={handleChangeForm}
          error={!!errors.actual_address}
          helperText={errors.actual_address}
        />
        <TextField
          label="СНИЛС"
          fullWidth
          name="inila"
          onChange={handleChangeForm}
          value={form.inila}
          error={!!errors.inila}
          helperText={errors.inila}
          InputProps={{ inputComponent: InilaMask }}
        />
        <TextField
          label="Телефон (для лиц старше 14 лет)"
          fullWidth
          name="phone"
          onChange={handleChangeForm}
          value={form.phone}
          error={!!errors.phone}
          helperText={errors.phone}
          InputProps={{ inputComponent: PhoneMask }}
          // placeholder="Телефон (для лиц старше 14 лет)"
        />
        <TextField
          fullWidth
          label="Статус законного представителя"
          name="adult_type_id"
          value={form.adult_type_id}
          select
          onChange={handleChangeForm}
          error={!!errors.adult_type_id}
          helperText={errors.adult_type_id}
        >
          {adultTypeList.map(({ id, title }) => (
            <MenuItem key={id} value={id}>
              {title}
            </MenuItem>
          ))}
        </TextField>
      </form>
    </EditInStep>
  );
};
