import React, { useCallback, useMemo } from 'react';
import { Step } from 'ui/edits/Step';
import { DatePicker } from '@material-ui/pickers';
import cl from './Step1.module.scss';
import { nextStep, step1SetDate, $step1 } from '../../model';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useStore } from 'effector-react';
import { useClubs } from 'hooks/clubs';
import { $currentClub } from 'state/club';

interface Props {}

export const Step1: React.FC<Props> = () => {
  const { map: clubsMap } = useClubs();
  const clubId = useStore($currentClub);

  const { date } = useStore($step1);

  const handleNext = useCallback(() => {
    step1SetDate(date);
    nextStep();
  }, [date]);

  const handleChangeDate = useCallback((date: MaterialUiPickersDate) => {
    step1SetDate(date!);
    // changeDate(date as Moment);
  }, []);

  const club = useMemo(() => clubsMap.get(clubId!), [clubId, clubsMap]);

  return (
    <Step
      title="Выберите дату начала"
      onClickNext={handleNext}
      hasPrev={false}
      disabledNext={!date.isBetween(club?.start_date, club?.end_date, 'day', '[]')}
    >
      <div className={cl.date}>
        <DatePicker
          autoOk
          variant="static"
          openTo="date"
          value={date}
          maxDate={club?.end_date}
          minDate={club?.start_date}
          onChange={handleChangeDate}
          fullWidth={false}
        />
      </div>
    </Step>
  );
};
