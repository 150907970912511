import React from 'react';

interface Props {}

const Filters: React.FC<Props> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94999 5C8.98278 4.83845 9 4.67123 9 4.5C9 4.32877 8.98278 4.16155 8.94999 4H22V5H8.94999ZM4.05001 5H2V4H4.05001C4.01722 4.16155 4 4.32877 4 4.5C4 4.67123 4.01722 4.83845 4.05001 5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94999 19C8.98278 18.8384 9 18.6712 9 18.5C9 18.3288 8.98278 18.1616 8.94999 18H22V19H8.94999ZM4.05001 19H2V18H4.05001C4.01722 18.1616 4 18.3288 4 18.5C4 18.6712 4.01722 18.8384 4.05001 19Z"
        fill="currentColor"
      />
      <circle cx="6.5" cy="4.5" r="2" stroke="currentColor" />
      <circle cx="6.5" cy="18.5" r="2" stroke="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.95 12C19.9828 11.8384 20 11.6712 20 11.5C20 11.3288 19.9828 11.1616 19.95 11H22V12H19.95ZM15.05 12H2V11H15.05C15.0172 11.1616 15 11.3288 15 11.5C15 11.6712 15.0172 11.8384 15.05 12Z"
        fill="currentColor"
      />
      <circle cx="17.5" cy="11.5" r="2" stroke="currentColor" />
    </svg>
  );
};

export default Filters;
