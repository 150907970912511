import React from 'react';
import { CircularProgress, Modal } from '@material-ui/core';
import cl from './Backdrop.module.scss';

interface Props {
  open: boolean;
}

export const Backdrop: React.FC<Props> = ({ open }) => {
  return (
    <Modal open={open} className={cl.root}>
      <CircularProgress color="primary" />
    </Modal>
  );
};
