import React from 'react';
import cl from './RightSide.module.scss';
import SimpleBar from 'simplebar-react';
import cn from 'classnames';

interface Props {
  useScroll?: boolean;
  isRootPath?: boolean;
}

const RightSide: React.FC<Props> = ({ children, useScroll = true, isRootPath }) => {
  return (
    <section className={cn(cl.root, isRootPath && cl.rootPath)}>
      {useScroll ? (
        <SimpleBar style={{ height: `calc(100vh - 117px)` }} className={cl.simpleBar}>
          <div className={cl.wrapper}>{children}</div>
        </SimpleBar>
      ) : (
        children
      )}
    </section>
  );
};

export default RightSide;
