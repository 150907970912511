import React from "react";

const Mos: React.FC = () => (
    <svg width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M0 25.6428C0 27.2595 1.3227 28.5744 2.94846 28.5744H11.0331V34L12.8183 32.1283C15.3646 29.5046 17.3899 28.5744 20.5572 28.5744H23.4578C25.0835 28.5744 26.4062 27.2595 26.4062 25.6428V0H0V25.6428ZM1.94349 1.93246H24.4627V25.6428C24.4627 26.1937 24.0119 26.642 23.4578 26.642H20.5572C17.7674 26.642 15.3592 27.101 12.6676 30.0314L12.657 26.642H2.94846C2.39429 26.642 1.94349 26.1937 1.94349 25.6428V1.93246Z"
          fill="currentColor"/>
    </svg>)

export default Mos
