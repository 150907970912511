import React, { useMemo } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import Default from './components/Default';
import HistoryClubs from './routes/history/components/History/Clubs';
import Feedback from './routes/feedback/components/Feedback';
import { RightSide } from 'components/layout/MainLayout/RightSide';
import { ProfileLeftSide } from './components/ProfileLeftSide';
import Children from './routes/children';
import { EmailConfirmation } from './routes/email-confirmation/components/EmailConfirmation';
import { About } from './routes/about/components/About';
import HistorySchools from './routes/history/components/History/Schools';
import Requisites from './routes/requisites/Requisites';
import Email from './routes/email';
import HistoryGartens from './routes/history/components/History/Gartens';
import HistoryClubSign from './routes/history/components/History/ClubsSign';
import HistoryDocuments from './routes/history/components/History/Documents';

interface Props extends RouteComponentProps {}

const Profile: React.FC<Props> = ({ match, location }) => {
  const useScroll = useMemo(() => ![`${match.url}/children`].includes(location.pathname), [match, location]);

  const isRootPath = useMemo(() => {
    const { pathname } = location;
    const partsQty = pathname.split('/').length;
    if (pathname.startsWith('/profile/children')) return partsQty === 3;
    else return partsQty === 2;
  }, [location]);

  return (
    <>
      <ProfileLeftSide location={location.pathname} isRootPath={isRootPath} />
      <RightSide useScroll={useScroll} isRootPath={isRootPath}>
        <Switch>
          <Route path={`${match.url}/children`} component={Children} />
          <Route path={`${match.url}/history/documents`} component={HistoryDocuments} />
          <Route path={`${match.url}/history/gartens`} component={HistoryGartens} />
          <Route path={`${match.url}/history/schools`} component={HistorySchools} />
          <Route path={`${match.url}/history/clubs/:code/rejected/:id`} component={HistoryClubSign} />
          <Route path={`${match.url}/history/clubs/:code/tosign/:id`} component={HistoryClubSign} />
          <Route path={`${match.url}/history/clubs`} component={HistoryClubs} />
          <Route path={`${match.url}/requisites`} component={Requisites} />
          <Route path={`${match.url}/mail`} component={Email} />
          <Route path={`${match.url}/feedback`} component={Feedback} />
          <Route path={`${match.url}/about`} component={About} />
          <Route path={`${match.url}/verification/:code`} component={EmailConfirmation} />
          <Route path={`${match.url}/profile`} component={Default} />
          <Route path={match.url} component={Default} />
        </Switch>
      </RightSide>
    </>
  );
};

export default Profile;
