export const needableFiles = [
  {
    id: 1,
    name: 'Свидетельство о рождении',
  },
  {
    id: 2,
    name: 'Регистрация в г. Москве',
  },
  {
    id: 3,
    name: 'СНИЛС',
  },
  {
    id: 4,
    name: 'Мед.полис',
  },
];

export const needableFilesOlder = [
  {
    id: 1,
    name: 'Свидетельство о рождении',
  },
  {
    id: 2,
    name: 'Паспорт',
  },
  {
    id: 3,
    name: 'Регистрация в г. Москве',
  },
  {
    id: 4,
    name: 'Мед.полис',
  },
  {
    id: 5,
    name: 'СНИЛС',
  },
];

export const facilitiesForSchools = [
  {
    id: 1,
    name: 'Дети-инвалиды - Справка МСЭ',
  },
  {
    id: 2,
    name: 'Дополнительная льгота',
  },
];
export const facilitiesForGartens = [
  {
    id: 1,
    name: 'Дети-инвалиды - Справка МСЭ',
  },
  {
    id: 2,
    name: 'Дети-инвалиды - ИПРА',
  },
];
export const extraFacilitiesForSchools = [
  {
    id: 1,
    name: 'Дети с ОВЗ - заключение ЦПМПК',
  },
  {
    id: 2,
    name: 'Дети-инвалиды - ИПРА',
  },
];
export const extraFacilitiesForGartens = [
  {
    id: 1,
    name: 'Дети с ОВЗ - заключение ЦПМПК',
  },
];
