import React from 'react';
import cl from './EditInStep.module.scss';
import Icon from '../../Icon';
import { IconButton } from '@material-ui/core';
import { Panel } from '../Panel';
import cn from 'classnames';
import { Button } from '../../button/Button';

interface Props {
  title: string;
  onClickBack?: () => void;
  className?: string;
  onClickNext?: () => void;
}

export const EditInStep: React.FC<Props> = ({ title, onClickBack, children, className, onClickNext }) => {
  return (
    <div className={cl.root}>
      <div className={cl.title}>
        <IconButton onClick={onClickBack}>
          <Icon type="Back" />
        </IconButton>
        <span>{title}</span>
      </div>
      <Panel classname={cn(cl.panel, className)}>
        {children}
        <div className={cl.footer}>
          <div className={cl.announce}>
            *Мы храним ваши данные на серверах, соответствующих Федеральному Закону №152
          </div>
          <Button onClick={onClickNext}>Сохранить</Button>
        </div>
      </Panel>
    </div>
  );
};
