import React, { useMemo } from 'react';
import { Switch } from 'react-router';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Default } from './components/Default';
import ClubsLeftSide from './components/ClubsLeftSide/ClubsLeftSide';
import { Club } from './components/Club';
import { RightSide } from 'components/layout/MainLayout/RightSide';
import { ClubJoin } from './components/ClubJoin';

interface Props extends RouteComponentProps {}

const Clubs: React.FC<Props> = ({ match, location }) => {
  const isRootPath = useMemo(() => {
    const { pathname } = location;
    const partsQty = pathname.split('/').length;
    if (partsQty <= 2) return true;
    else return false;
  }, [location]);

  return (
    <>
      <ClubsLeftSide isRootPath={isRootPath} />
      <RightSide isRootPath={isRootPath} useScroll={location.pathname !== match.url}>
        <Switch>
          <Route path={`${match.url}/:id/join`} component={ClubJoin} />
          <Route path={`${match.url}/:id`} component={Club} />
          <Route path={match.url} component={Default} />
        </Switch>
      </RightSide>
    </>
  );
};

export default Clubs;
