import { LeftSide } from 'components/layout/MainLayout/LeftSide';
import { useDocuments } from 'hooks/documents';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SearchInput } from 'ui/input/SearchInput';
import { BaseMenuItem } from 'ui/list/BaseMenuItem';
import cl from './DocumentsLeftSide.module.scss';
import cn from 'classnames';

interface Props {
  isRootPath: boolean;
}

const DocumentsLeftSide: React.FC<Props> = ({ isRootPath }) => {
  const [filter, setFilter] = useState<string>('');
  const handleChangeFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  }, []);
  const documents = useDocuments();
  const filteredData = useMemo(() => {
    if (filter) {
      return documents.data.filter((el) => el.title.toLowerCase().includes(filter.toLowerCase()));
    } else {
      return documents.data;
    }
  }, [documents.data, filter]);

  const history = useHistory();
  const handleClickItem = useCallback(
    (id: number, path: string) => {
      history.push(`/documents/${path}/${id}`);
    },
    [history],
  );

  const location = useLocation();

  const activeElement = location.pathname.split('/')[3];
  const disabledElement = location.pathname.includes('sign');
  return (
    <LeftSide
      isRootPath={isRootPath}
      topComponent={<SearchInput onChange={handleChangeFilter} className={cl.filter} />}
      title="Выбор документа"
    >
      <div className={cl.container}>
        {filteredData.length > 0 ? (
          <>
            {filteredData.map((elem) => {
              return (
                <BaseMenuItem
                  disable={disabledElement && +activeElement !== elem.id}
                  key={elem.id}
                  onClick={() => handleClickItem(elem.id, elem.type === 1 ? 'aop' :elem.type === 5 ? 'dp' : 'ds')}
                  classname={cn(cl.block, disabledElement && +activeElement !== elem.id && cl.disabled)}
                  isActive={+activeElement === elem.id}
                >
                  {elem.title}
                </BaseMenuItem>
              );
            })}
          </>
        ) : (
          <div className={cl.notFound}>
            Ничего не найдено :( <br />
            Попробуйте изменить запрос или обновить страницу.
          </div>
        )}
      </div>
    </LeftSide>
  );
};

export default DocumentsLeftSide;
