import React from 'react';
import { Redirect } from 'react-router';

interface Props {}

const Default: React.FC<Props> = () => {
  return <Redirect to="/home" />;
  // return (
  //     <div>Default</div>
  // );
};

export default Default;
