import { AxiosInstance, AxiosRequestConfig } from 'axios';
// import delay from 'delay';
// import { API_CLIENT_ID } from 'constants/config';
// import { getItem } from 'store/persistent';
// import { samePendingPromise } from 'utils/promise';
// import { ApiClientError, ApiUnauthorizedError } from './errors';
// import apiToken from './request/oauth';
import { createAxios, onFulfillGetData, onRejectCatch, onRejectConverter } from './utils';
import { createPool } from './utils/AxiosInstancePool';
// import { OAuthTokens } from './types/oauth';

import onFulfillDownload from './utils/on-fulfill-download';

// interface GotTokensCallback {
//   (tokens: OAuthTokens, requestedAt: Date): void;
// }

// interface ClearTokensCallback {
//   (): void;
// }

// let handleGotTokens: GotTokensCallback | null = null;
// let handleClearTokens: ClearTokensCallback | null = null;

// export const onGotTokens = (handler: GotTokensCallback | null) => {
//   handleGotTokens = handler;
// };
// export const onClearTokens = (handler: ClearTokensCallback | null) => {
//   handleClearTokens = handler;
// };

const pool = createPool();

export const authorize = pool.authorize;
export const unauthorize = pool.unauthorize;
export const setLanguage = pool.setLanguage;

const createAndRegister = (config?: AxiosRequestConfig): AxiosInstance => pool.add(createAxios(config));

const defaultInstance = createAndRegister();

export default defaultInstance;

const instanceNoAutoRefresh = createAxios();
instanceNoAutoRefresh.interceptors.response.use(onFulfillGetData, onRejectConverter);
instanceNoAutoRefresh.interceptors.response.use(undefined, onRejectCatch);

// const instanceDownloadNoAutoRefresh = createAxios();
// instanceDownloadNoAutoRefresh.interceptors.response.use(onFulfillDownload, onRejectedConverter);

/**
 * Реальное обновление токенов
 *
 * @param successAsyncDelay Интервал ожидание в мс. после обновления токенов перед следующим запросом
 * @param requestedAt
 * @return Промис резолвится со значением true, когда можно продолжать.
 */
// export async function refreshTokensReally(
//   successAsyncDelay = 500,
//   requestedAt = new Date(),
// ): Promise<boolean> {
//   const refreshToken = getItem('refreshToken');
//
//   // данные есть и актуальны?
//   if (undefined !== refreshToken) {
//     try {
//       // пытаемся обновить токен
//       const tokens = await apiToken.token(instanceNoAutoRefresh, {
//         grant_type: 'refresh_token',
//         client_id: API_CLIENT_ID,
//         refresh_token: refreshToken,
//       });
//       handleGotTokens?.(tokens, requestedAt);
//       pool.authorize(tokens.access_token);
//       // ожидание на возможную асинхронность токенов
//       await delay(successAsyncDelay);
//
//       return true;
//     } catch (e) {
//       if (process.env.NODE_ENV !== 'test') {
//         console.log('API Refresh Token failed:', e);
//       }
//       if (e instanceof ApiClientError) {
//         pool.unauthorize();
//         handleClearTokens?.();
//       }
//     }
//   } else {
//     if (process.env.NODE_ENV === 'development') {
//       console.warn('Cannot refresh tokens', { refreshToken });
//     }
//     pool.unauthorize();
//     handleClearTokens?.();
//   }
//
//   return false;
// }
//
// const refreshTokens = samePendingPromise(refreshTokensReally);

// const onRejectedForRefresh = (
//   instance: AxiosInstance,
//   noAutoRefreshInstance: AxiosInstance,
// ) => async (error: any) => {
//   if (error instanceof ApiUnauthorizedError) {
//     if (await refreshTokens()) {
//       const config = error.response.config;
//       const headers = { ...config.headers };
//       delete headers['Authorization'];
//       // повторяем оригинальный запрос, но уже без повторных попыток обновить токен
//       return await noAutoRefreshInstance.request({
//         ...config,
//         headers: {
//           ...headers,
//           ...instance.defaults.headers.common,
//         },
//       });
//     }
//   }
//
//   return Promise.reject(error);
// };

export const dataOnly = createAndRegister();
dataOnly.interceptors.response.use(onFulfillGetData, onRejectConverter);
// dataOnly.interceptors.response.use(
//   undefined,
//   onRejectedForRefresh(dataOnly, instanceNoAutoRefresh),
// );

export const downloadInstance = createAndRegister({ responseType: 'blob' });
downloadInstance.interceptors.response.use(onFulfillDownload, onRejectConverter);
// downloadInstance.interceptors.response.use(
//   undefined,
//   onRejectedForRefresh(downloadInstance, instanceDownloadNoAutoRefresh),
// );
