import { RightSide } from 'components/layout/MainLayout/RightSide';
import React, { useMemo } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import Default from './components/Default/Default';
import Document from './components/Document';
import DocumentSign from './components/DocumentSign';
import DocumentSignDp from './components/DocumentSignDp';
import DocumentSignDs from './components/DocumentSignDs';
import DocumentsLeftSide from './components/DocumentsLeftSide/DocumentsLeftSide';

interface Props extends RouteComponentProps {}

const Documents: React.FC<Props> = ({ match, location }) => {
  const isRootPath = useMemo(() => {
    const { pathname } = location;
    const partsQty = pathname.split('/').length;
    if (partsQty <= 2) return true;
    else return false;
  }, [location]);
  return (
    <>
      <DocumentsLeftSide isRootPath={isRootPath} />
      <RightSide isRootPath={isRootPath} useScroll={location.pathname !== match.url}>
        <Switch>
          <Route path={`${match.url}/dp/:id/sign`} component={DocumentSignDp} />
          <Route path={`${match.url}/ds/:id/sign`} component={DocumentSignDs} />
          <Route path={`${match.url}/aop/:id/sign`} component={DocumentSign} />
          <Route path={`${match.url}/dp/:id`} component={Document} />
          <Route path={`${match.url}/ds/:id`} component={Document} />
          <Route path={`${match.url}/aop/:id`} component={Document} />
          <Route path={match.url} component={Default} />
        </Switch>
      </RightSide>
    </>
  );
};

export default Documents;
