import React from 'react';

interface Props {}

const ActSign: React.FC<Props> = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.86197 0.861949C9.12232 0.6016 9.54443 0.6016 9.80478 0.861949L12.4714 3.52862C12.7318 3.78897 12.7318 4.21108 12.4714 4.47142L5.13811 11.8048C5.01309 11.9298 4.84352 12 4.66671 12H2.00004C1.63185 12 1.33337 11.7015 1.33337 11.3334V8.66669C1.33337 8.48988 1.40361 8.32031 1.52864 8.19528L8.86197 0.861949ZM2.66671 8.94283V10.6667H4.39057L11.0572 4.00002L9.33337 2.27616L2.66671 8.94283Z"
        fill="#69488D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.33337 14.6667C1.33337 14.2985 1.63185 14 2.00004 14H14C14.3682 14 14.6667 14.2985 14.6667 14.6667C14.6667 15.0349 14.3682 15.3333 14 15.3333H2.00004C1.63185 15.3333 1.33337 15.0349 1.33337 14.6667Z"
        fill="#69488D"
      />
    </svg>
  );
};

export default ActSign;
