import React from 'react';
import cl from './Default.module.scss';
import Icon from 'ui/Icon';
import { DefaultContent } from 'components/layout/DefaultContent';

interface Props {}

export const Default: React.FC<Props> = () => {
  return (
    <DefaultContent>
      <div>
        <Icon type="Children" />
        <div className={cl.title}>Выберите ребенка или добавьте данные для отображения данных.</div>
      </div>
    </DefaultContent>
  );
};
