import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $gartens, fetchGartensListIfNeed } from 'state/garten';

export const useGartens = () => {
  const gartens = useStore($gartens);
  useEffect(() => {
    fetchGartensListIfNeed();
  }, []);

  return gartens;
};
