import { ApiUnprocessableError } from 'api/errors';
import api from 'api/request/clubs';
import { useStore } from 'effector-react';
import { useProfile } from 'hooks/profile';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import { $actPreview, getActPreviewFx } from 'state/club';
import { getClubsHistoryFx, postHistoryActRejectFx, sendActCodeFx } from 'state/club/history';
import { getNotificationFx } from 'state/profile';
import { Button } from 'ui/button/Button';
import { Alert, showAlert, showErrorAlert } from 'ui/dialogs/Alert';
import { CodeDialog } from 'ui/dialogs/CodeDialog';
import DeclineActConfirm from 'ui/dialogs/DeclineActConfirm';
import { Backdrop } from 'ui/feedback/Backdrop';
import { Panel } from 'ui/panels';
import cl from './HistorySign.module.scss';

interface Props extends RouteComponentProps<{ code: string; id: string }> {}

const HistoryClubSign: React.FC<Props> = ({
  match: {
    params: { code, id },
  },
}) => {
  const { data } = useStore($actPreview);
  const refIframe = useRef<HTMLIFrameElement>(null);
  const refWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getActPreviewFx(+id);
  }, [id]);

  useEffect(() => {
    const { current: iFrame } = refIframe;
    if (data) {
      if (!iFrame || !iFrame.contentDocument || !data.act) {
        return;
      }
      iFrame.contentDocument.write(data.act);
      iFrame.contentDocument.write(
        '<style>html{overflow-x: hidden;scrollbar-color: #f5f5f5;scrollbar-width: thin;} *::-webkit-scrollbar{width: 6px;height: 6px;background-color: #f5f5f5;border-radius: 3px;} *::-webkit-scrollbar-thumb {background-color: #b0b4b8;border-radius: 3px;}</style>',
      );
      iFrame.contentDocument.close();
    }
  }, [refIframe, data]);

  const [name, setName] = useState('');

  const loadingPreview = useStore(getActPreviewFx.pending);

  const location = useLocation();

  useEffect(() => {
    getClubsHistoryFx().then(({ data }) => {
      const currentName = data.find((el) => el.club.code === code)?.club.title;
      setName(currentName ? currentName : '');
    });
  });

  const [isDeclineClick, setDeclineClick] = useState(false);
  const [isSign, setSign] = useState(false);
  const [isFirstCodeError, setIsFirstCodeError] = useState(false);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [openAlertDeclineSuccess, setOpenAlertDeclineSuccess] = useState(false);

  const history = useHistory();

  const { profile } = useProfile();

  const handleSuccess = useCallback(() => {
    setOpenAlertSuccess(false);
    setOpenAlertDeclineSuccess(false);
    history.goBack();
  }, [history]);

  const handleNext = useCallback(async () => {
    setLoading(true);
    try {
      await api.approveAct(+id);
      setSign(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e instanceof ApiUnprocessableError)
        await showAlert(
          'default',
          'Код с предыдущей операции еще активен. Просьба повторить подписание через 1 минуту.',
          'Ошибка',
        );
      else
        await showAlert(
          'error',
          'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
          'Ошибка',
        );
    }
  }, [id]);

  const handleClickBtn = useCallback(async () => {
    setSign(true);
    await api.approveAct(+id);
  }, [id]);

  const handleClickCodeNext = useCallback(
    async (code: string) => {
      setLoading(true);
      try {
        await sendActCodeFx({ id: +id, code }).then(() => {
          getNotificationFx();
        });
        setLoading(false);
        setSign(false);
        setOpenAlertSuccess(true);
      } catch (e) {
        setLoading(false);
        if (e instanceof ApiUnprocessableError) {
          setIsFirstCodeError(true);
          const { code } = e.data.errors;
          if (!code) {
            await showErrorAlert();
            return;
          }
          if ((code as string[]).includes('Code expired'))
            await showAlert('error', 'Время жизни отправленного кода истекло. Запросите код повторно.', 'Ошибка');
          else await showAlert('error', 'Проверьте правильность введенного кода', 'Неверный код');
        } else {
          await showAlert(
            'error',
            'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
            'Ошибка',
          );
        }
      }
    },
    [id],
  );

  const handleDeclineAct = async () => {
    setLoading(true);
    try {
      setDeclineClick(false);
      setLoading(false);
      await postHistoryActRejectFx({ id: +id, reason }).then(() => getNotificationFx());
      setOpenAlertDeclineSuccess(true);
    } catch {
      setLoading(false);
      await showAlert(
        'error',
        'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
        'Ошибка',
      );
    }
  };

  const handleClickBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <div className={cl.root}>
      <Backdrop open={loading || loadingPreview} />
      <div className={cl.content}>
        <div className={cl.titleContainer}>
          <div className={cl.title}>{name}</div>
          {location.pathname.split('/')[location.pathname.split('/').length - 2] === 'rejected' && (
            <Button onClick={handleClickBack} className={cl.btnClose} variant="text">
              Назад
            </Button>
          )}
        </div>
        {location.pathname.split('/')[location.pathname.split('/').length - 2] !== 'rejected' ? (
          <div className={cl.subTitle}>Подписание акта</div>
        ) : (
          <div className={cl.subTitle}>Просмотр акта</div>
        )}
        <div className={cl.wrapper} ref={refWrapper}>
          <Panel classname={cl.page}>
            {/*<SimpleBar style={{ height: `calc(100vh - ${offset}px)`, minHeight: 500 }}>*/}
            {/*  <div style={{ height: docHeight + 100 }}>*/}
            <iframe ref={refIframe} title="doc" /*style={{ height: docHeight }}*/ />
            {/*</div>*/}
            {/*</SimpleBar>*/}
          </Panel>
        </div>
      </div>
      {location.pathname.split('/')[location.pathname.split('/').length - 2] !== 'rejected' && (
        <div className={cl.footer}>
          <Button onClick={handleClickBack} className={cl.btnClose} variant="text">
            Назад
          </Button>
          <Button onClick={setDeclineClick.bind(null, true)} className={cl.btnDecline} size="large" variant="text">
            Отказ
          </Button>

          <Button className={cl.btnNext} onClick={() => handleClickBtn()} size="large">
            Подписать
          </Button>
        </div>
      )}
      <DeclineActConfirm
        handleDeclineAct={handleDeclineAct}
        reason={reason}
        setReason={setReason}
        isOpen={isDeclineClick}
        handleClickClose={setDeclineClick.bind(null, false)}
      />
      <CodeDialog
        open={isSign}
        onClickClose={setSign.bind(null, false)}
        onClickNext={handleClickCodeNext}
        onClickRequest={handleNext}
        firstCodeError={isFirstCodeError}
      />
      <Alert open={openAlertSuccess} type="success" title="Спасибо! Акт успешно подписан." onClickClose={handleSuccess}>
        Копия акта отправлена Вам на почту
        <br />
        <b>{profile.email}</b>
        <br />
        Также подписанный акт можно скачать в разделе «История записи».
      </Alert>
      <Alert open={openAlertDeclineSuccess} type="success" title="Отказ принят!" onClickClose={handleSuccess}>
        Причина отказа будет рассмотрена в ближайшее время
      </Alert>
    </div>
  );
};

export default HistoryClubSign;
