import React from 'react';

interface Props {}

const YouTube: React.FC<Props> = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0ZM28.3347 13.1244C29.2526 13.3763 29.9755 14.1185 30.2208 15.061C30.6667 16.7692 30.6667 20.3333 30.6667 20.3333C30.6667 20.3333 30.6667 23.8973 30.2208 25.6056C29.9755 26.5482 29.2526 27.2903 28.3347 27.5423C26.6712 28 20 28 20 28C20 28 13.3288 28 11.6652 27.5423C10.7473 27.2903 10.0244 26.5482 9.77903 25.6056C9.33333 23.8973 9.33333 20.3333 9.33333 20.3333C9.33333 20.3333 9.33333 16.7692 9.77903 15.061C10.0244 14.1185 10.7473 13.3763 11.6652 13.1244C13.3288 12.6667 20 12.6667 20 12.6667C20 12.6667 26.6712 12.6667 28.3347 13.1244ZM18.0001 17.3333V24L23.3334 20.6668L18.0001 17.3333Z"
        fill="#69488D"
      />
    </svg>
  );
};

export default YouTube;
