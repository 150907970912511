import React, { ChangeEvent, useMemo } from 'react';
import cl from './Upload2.module.scss';
import { fileExt } from '../../../utils/helpers';

interface Props {
  multiple?: boolean;
  acceptList?: string[];
  maxSize?: number;
  onUpload: (file: File[] | null) => void;
  onSizeError?: () => void;
}

export const Upload2: React.FC<Props> = ({ onUpload, multiple = false, acceptList, maxSize, onSizeError }) => {
  const accept = useMemo(() => acceptList?.join(','), [acceptList]);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { files } = e.target;
    if (!files) return;

    let isSizeError = false;
    const aFiles = Array.from(files).filter((f) => {
      if (!(acceptList?.includes('.' + fileExt(f.name).toLowerCase()) ?? true)) return false;

      if (maxSize && f.size > maxSize!) {
        isSizeError = true;
        return false;
      }

      return true;
    });

    if (isSizeError) onSizeError?.();
    onUpload(aFiles);
  };

  return <input type="file" onChange={handleChange} className={cl.root} multiple={multiple} title="" accept={accept} />;
};
