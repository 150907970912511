import React from 'react';
import cl from './ButtonItem.module.scss';
import cn from 'classnames';
import { ButtonBase } from '@material-ui/core';

interface Props {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const ButtonItem: React.FC<Props> = ({ children, className, onClick, disabled = false }) => {
  return (
    <ButtonBase
      disabled={disabled}
      className={cn(cl.root, disabled && cl.disableRoot, className)}
      onClick={onClick}
      component="div"
    >
      {children}
    </ButtonBase>
  );
};
