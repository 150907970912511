import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $documents, fetchDocumentsListIfNeed } from 'state/documents';

export const useDocuments = () => {
  const documents = useStore($documents);

  useEffect(() => {
    fetchDocumentsListIfNeed();
  }, []);

  return documents;
};
