import React from 'react';
import { ClubCategory } from 'api/types/clubs';
import { ButtonBase } from '@material-ui/core';
import cl from './ClubCategoryItem.module.scss';
import Icon from 'ui/Icon';
import cn from 'classnames';

interface Props {
  data: ClubCategory;
  checked: boolean;
  onClick: () => void;
}

export const ClubCategoryItem: React.FC<Props> = ({ data, checked, onClick }) => {
  return (
    <ButtonBase component="li" className={cn(cl.root, checked && cl.checked)} onClick={onClick}>
      <div>{data.title}</div>
      <Icon type={checked ? 'CheckCircle' : 'Circle'} />
    </ButtonBase>
  );
};
