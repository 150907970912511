import React from 'react';
import cl from './Item.module.scss';
import cn from 'classnames';

interface Props {
  classname?: string;
}

export const Item: React.FC<Props> = ({ children, classname }) => {
  return <div className={cn(cl.root, classname)}>{children}</div>;
};
