import React from 'react';

interface Props {}

const Version: React.FC<Props> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="mdi_version_info">
        <path
          id="Vector"
          d="M13 7H11V9H13V7ZM13 11H11V17H13V11ZM17 1.01L7 1C5.9 1 5 1.9 5 3V21C5 22.1 5.9 23 7 23H17C18.1 23 19 22.1 19 21V3C19 1.9 18.1 1.01 17 1.01ZM17 19H7V5H17V19Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default Version;
