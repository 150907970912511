import React from 'react';

interface Props {}

const Phone: React.FC<Props> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33333 0H6C6.73333 0 7.33333 0.6 7.33333 1.33333C7.33333 3 7.6 4.6 8.09333 6.09333C8.24 6.56 8.13333 7.08 7.76 7.45333L4.82667 10.3867C6.74667 14.16 9.84 17.24 13.6133 19.1733L16.5467 16.24C16.8133 15.9867 17.1467 15.8533 17.4933 15.8533C17.6267 15.8533 17.7733 15.8667 17.9067 15.92C19.4 16.4133 21.0133 16.68 22.6667 16.68C23.4 16.68 24 17.28 24 18.0133V22.6667C24 23.4 23.4 24 22.6667 24C10.1467 24 0 13.8533 0 1.33333C0 0.6 0.6 0 1.33333 0ZM4.72008 2.66667C4.80008 3.85333 5.00008 5.01333 5.32008 6.12L3.72008 7.72C3.17341 6.12 2.82675 4.42667 2.70675 2.66667H4.72008ZM17.8667 18.6933C19.0001 19.0133 20.1601 19.2133 21.3334 19.2933V21.28C19.5734 21.16 17.8801 20.8133 16.2667 20.28L17.8667 18.6933Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Phone;
