import React, { useMemo } from 'react';
import cn from 'classnames';
import cl from './FileLoadPanel.module.scss';
import { ButtonItem } from 'ui/list/ButtonItem';
import { UPLOAD_FILE_SIZE } from 'constants/config';
import { Upload2 } from 'ui/button/Upload2';
import clipPng from 'assets/icons/clip.png';
import Icon from 'ui/Icon';
import { fileExt, getFileImg } from 'utils/helpers';
import { IconButton } from '@material-ui/core';
import { FileDescr } from 'api/types/common';
import ToolTipDoc from 'ui/tooltip/TooltipDoc/TooltipDoc';

interface Props {
  onClick: (fileName: string, files: File[] | null) => void;
  onSizeError: () => void;
  isMain?: boolean;
  isOpenToolTip?: boolean;
  name: string;
  handleRemoveFile: (name: string) => void;
  data: FileDescr[];
  disabled?: boolean;
}

const ACCEPT_TYPES = ['.jpg', '.jpeg', '.png', '.pdf'];

export const FileLoadPanel: React.FC<Props> = ({
  onClick,
  onSizeError,
  name,
  isMain = false,
  isOpenToolTip = false,
  handleRemoveFile,
  data,
  disabled = false,
}) => {
  const showImgUrl = useMemo(
    () => getFileImg(fileExt(data.filter((elem) => elem.name === name)[0]?.file[0].name)) || '',
    [data, name],
  );

  return (
    <>
      {JSON.stringify(data.filter((elem) => elem.name === name)) === '[]' ? (
        <ToolTipDoc
          isOpenToolTip={isOpenToolTip}
          title={
            <>
              <div>Справка с места работы родителя ребенка,</div>
              <div> имеющего право на первоочередной прием </div>
            </>
          }
        >
          <div className={cl.itemWrapper}>
            <ButtonItem disabled={disabled} className={cn(cl.checkedItem, isMain && cl.checkItemNeeded)}>
              <Upload2
                acceptList={ACCEPT_TYPES}
                onUpload={onClick.bind(null, name)}
                onSizeError={onSizeError}
                maxSize={UPLOAD_FILE_SIZE}
              />
              <div className={cn(cl.check, cl.img)}>
                <img src={clipPng} alt="" />
              </div>
              <span>{name}</span>
            </ButtonItem>
          </div>
        </ToolTipDoc>
      ) : (
        <div className={cl.itemFilledWrapper}>
          <div className={cl.itemFilled}>
            <div className={cl.itemName}>
              <div>
                <Icon className={cl.checkCircle} type={'CheckCircle'} />
              </div>
              <div className={cl.name}>{name}</div>
            </div>
            <div className={cl.line} />
            <div className={cl.loadFile}>
              <img className={cl.formatImg} alt="" src={showImgUrl} />
              {data
                .filter((elem) => elem.name === name)
                .map((elem) =>
                  elem.file.map((elem, key) => (
                    <div key={key} className={cl.fileName}>
                      {elem.name}
                    </div>
                  )),
                )}
              <IconButton className={cl.deleteFile} onClick={() => handleRemoveFile(name)}>
                <Icon type="CloseFilled" />
              </IconButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
