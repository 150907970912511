import React from 'react';

interface Props {}

const LogoSmall: React.FC<Props> = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="32" fill="white" />
      <path
        d="M26.6093 23.7274C26.6093 23.7274 23.478 23.8724 21.8716 22.4782C21.5773 22.2231 20.2943 21.1094 19.9904 19.5631C19.9345 19.2792 19.8782 18.81 19.6767 18.0712C19.5396 17.5502 19.3653 17.0399 19.1553 16.544C18.8481 15.8272 18.4751 14.9577 17.727 14.1842C16.8274 13.2542 16.0549 12.8663 16.0549 12.8663C16.0549 12.8663 16.3378 12.6803 19.0846 12.9001C20.818 13.0391 21.2775 13.1127 21.6267 13.1776C22.2572 13.2945 22.986 13.43 23.7865 13.7329C24.4819 13.9962 25.8306 14.5248 26.9914 15.8496C27.329 16.2347 28.5659 17.7318 28.7333 19.9793C28.7862 20.6907 28.6287 21.7838 28.6287 21.7838C28.6287 21.7838 28.2049 21.1266 27.549 20.2919C26.9761 19.5626 26.3812 19.1596 25.2147 18.3833C24.6216 17.9877 22.4628 16.9257 22.4628 16.9257C22.4628 16.9257 23.2887 17.9424 24.5183 19.5979C25.7478 21.2534 26.6093 23.7274 26.6093 23.7274Z"
        fill="#3A9545"
      />
      <path
        d="M33.6462 23.6452C33.6462 23.6452 33.2376 21.2249 33.4835 19.8509C33.6758 18.7772 34.0722 16.7179 35.7595 14.9001C36.6512 13.9388 37.5535 13.4219 38.1748 13.0723C39.6225 12.2577 40.8765 11.9765 42.0764 11.7072C43.3284 11.4265 44.2216 11.3554 45.8157 11.2331C47.4777 11.1063 50.6463 11.3845 50.6463 11.3845C50.6463 11.3845 49.6348 11.8636 48.556 13.0964C48.1514 13.5589 47.907 14.0139 47.4191 14.9242C46.9928 15.7232 46.6204 16.5502 46.3043 17.3995C46.0266 18.1247 45.7939 18.8665 45.6076 19.6206C45.4133 20.4138 45.4381 20.5493 45.2591 21.0782C45.0402 21.7299 44.7272 22.3457 44.3301 22.9057C43.5568 23.9749 42.6382 24.5242 42.24 24.7567C40.9386 25.5163 39.818 25.62 37.8973 25.7977C36.2483 25.8415 36.016 25.6587 36.016 25.6587C36.016 25.6587 36.1322 24.8723 37.7811 22.258C38.7756 20.7395 39.2761 19.9764 39.43 19.7825C40.9922 17.8125 42.263 16.7286 42.263 16.7286C42.263 16.7286 40.4151 17.3149 40.1962 17.4226C39.4476 17.7909 38.4368 18.2971 37.2698 19.3198C34.7217 21.5521 33.6462 23.6452 33.6462 23.6452Z"
        fill="#3A9545"
      />
      <path
        d="M25.5346 19.7574C25.5346 19.7574 26.7748 21.0345 28.0426 23.3491C28.2482 23.7243 28.8817 24.9139 29.6102 27.2529C30.3176 29.524 30.6603 31.4187 30.9695 33.4471C31.817 39.0203 32.119 41.8795 32.119 41.8795C32.1788 41.3685 32.27 40.6024 32.3866 39.673C32.8169 36.2469 33.0868 34.0968 33.5482 31.6537C34.0766 28.8517 34.3423 27.4431 35.0496 25.5332C35.6911 23.803 36.513 22.1461 37.5015 20.5902C36.3376 21.9519 35.2721 23.3957 34.3133 24.9106C33.5872 26.0626 33.2237 26.7879 32.9336 27.4946C32.5727 28.3736 32.2995 29.2866 32.118 30.22C32.118 30.22 31.8736 28.3579 30.9161 26.1598C30.3598 24.8818 29.6928 24.0384 29.2964 23.5053C28.548 22.4983 27.3464 21.1061 25.5346 19.7574Z"
        fill="#3A9545"
      />
      <path
        d="M36.0541 52.2627C36.0541 52.2627 37.0382 50.9305 38.8221 49.3998C40.7375 47.7587 42.6034 46.9307 43.6293 46.4849C44.4903 46.112 45.8488 45.5304 47.7049 45.1838C51.0181 44.5649 52.9302 44.5069 52.9302 44.5069C52.9302 44.5069 52.407 42.4536 51.8851 38.8333C51.6562 37.2445 51.6849 36.0271 51.7284 34.2008C51.7929 31.4888 52.1134 29.4631 52.1979 28.9435C52.4999 27.1035 52.7876 25.3503 53.661 23.2179C54.4553 21.2785 55.2 19.9907 55.2 19.9907C55.2 19.9907 53.9494 21.3163 52.9816 22.9056C52.5289 23.6493 51.6041 25.2677 50.787 27.6943C50.2156 29.3928 49.3921 31.8389 49.3763 34.9816C49.3553 39.1297 49.5854 42.0605 49.5854 42.0605C48.0564 42.331 46.5652 42.7854 45.144 43.4139C44.4001 43.742 43.6787 44.1194 42.9845 44.5435C42.6906 44.7245 41.6215 45.3954 40.3895 46.4849C40.197 46.655 39.9503 46.8716 39.6394 47.1846C38.9746 47.8537 38.4818 48.4922 37.9858 49.1395C36.8749 50.5925 36.0541 52.2627 36.0541 52.2627Z"
        fill="#69488D"
      />
      <path
        d="M35.3275 49.7748C35.3275 49.7748 36.2481 48.4933 37.4914 46.9154C38.7039 45.3772 39.9913 44.5318 41.1336 43.7568C42.223 43.0189 43.392 42.4076 44.6183 41.9345C45.7546 41.4955 46.9344 41.1805 48.1376 40.9951C48.0086 39.9819 47.8583 38.6123 47.7503 36.9812C47.6603 35.6206 47.5911 34.6106 47.6238 33.2374C47.6757 31.0458 47.9349 29.4082 48.0036 28.989C48.2478 27.5014 48.4804 26.0851 49.186 24.3617C49.8285 22.7952 50.4298 21.7539 50.4298 21.7539C50.4298 21.7539 49.4193 22.8251 48.6371 24.1093C48.271 24.7103 47.5249 26.0174 46.8636 27.9795C46.0762 30.319 45.8831 32.2192 45.7235 33.8684C45.5453 35.7165 45.5151 37.576 45.633 39.4289C44.4894 39.7424 43.3918 40.2062 42.3685 40.8081C41.9818 41.0351 40.2903 42.0543 38.5781 44.0579C36.8162 46.1187 35.8558 48.2581 35.3275 49.7748Z"
        fill="#69488D"
      />
      <path
        d="M34.3325 52.8001C32.1351 52.7889 32.1351 52.7889 29.9358 52.8001C29.9358 51.5906 30.9199 50.6101 32.1354 50.6101C33.3508 50.6101 34.3325 51.5906 34.3325 52.8001Z"
        fill="#69488D"
      />
      <path
        d="M27.9484 52.1818C27.9484 52.1818 26.965 50.8498 25.1789 49.3189C23.2636 47.6778 21.3976 46.8498 20.3717 46.404C19.5107 46.031 18.1522 45.4494 16.2961 45.1028C12.9829 44.4839 11.071 44.426 11.071 44.426C11.071 44.426 11.5942 42.3727 12.116 38.7524C12.3451 37.1636 12.3161 35.9464 12.2726 34.1199C12.2084 31.4079 11.8878 29.3824 11.8031 28.8628C11.5008 27.0228 11.2134 25.2693 10.34 23.137C9.54494 21.1973 8.80005 19.9098 8.80005 19.9098C8.80005 19.9098 10.0508 21.2353 11.0186 22.8249C11.4717 23.5686 12.3962 25.187 13.2133 27.6136C13.7849 29.3118 14.6079 31.758 14.624 34.9006C14.6452 39.0488 14.4151 41.9796 14.4151 41.9796C15.9441 42.2501 17.4353 42.7045 18.8565 43.333C19.6003 43.6611 20.3216 44.0384 21.0158 44.4626C21.3096 44.6436 22.379 45.3144 23.6108 46.404C23.8033 46.5743 24.05 46.7909 24.3609 47.1037C25.0257 47.773 25.5187 48.4113 26.0145 49.0588C27.1276 50.5116 27.9484 52.1818 27.9484 52.1818Z"
        fill="#69488D"
      />
      <path
        d="M28.675 49.6936C28.675 49.6936 27.7546 48.4118 26.511 46.8342C25.2983 45.2958 24.0111 44.4504 22.8689 43.6764C21.7794 42.9385 20.6104 42.3271 19.3842 41.854C18.2479 41.4151 17.068 41.1001 15.8648 40.9147C15.9936 39.9017 16.1439 38.5321 16.2519 36.9008C16.3418 35.5402 16.4113 34.5302 16.3787 33.1572C16.3265 30.9656 16.0675 29.328 15.9985 28.9086C15.7544 27.4212 15.5221 26.0046 14.8162 24.2815C14.1737 22.715 13.5726 21.6735 13.5726 21.6735C13.5726 21.6735 14.5829 22.7446 15.3651 24.0291C15.7311 24.6301 16.4773 25.937 17.1386 27.8991C17.926 30.2386 18.119 32.1387 18.2787 33.788C18.4569 35.636 18.4871 37.4955 18.3691 39.3484C19.5132 39.6618 20.6112 40.1255 21.6349 40.7274C22.0217 40.9547 23.7128 41.9736 25.4253 43.9772C27.1874 46.0373 28.1466 48.1766 28.675 49.6936Z"
        fill="#69488D"
      />
    </svg>
  );
};

export default LogoSmall;
