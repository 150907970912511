import React, { useCallback, useMemo, useState } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import cl from './CodeDialog.module.scss';
import PinInput from 'react-pin-input';
import { SMS_TIMEOUT } from 'constants/config';
import { Button } from 'ui/button/Button';
import { conformedValue, MASK_PHONE } from 'constants/format/masks';
import { useProfile } from 'hooks/profile';
import Icon from 'ui/Icon';
import { Timer } from 'routes/login/components/Code/Timer';

interface Props {
  open: boolean;
  loading?: boolean;
  firstCodeError: boolean;
  onClickNext: (code: string) => void;
  onClickClose: () => void;
  onClickRequest: () => void;
}

export const CodeDialog: React.FC<Props> = ({
  open,
  onClickNext,
  onClickClose,
  loading = false,
  onClickRequest,
  firstCodeError,
}) => {
  const { profile } = useProfile();
  const [code, setCode] = useState<string>('');
  const [showTimer, setShowTimer] = useState(true);
  const [isError, setIsError] = useState(false);

  const formattedPhone = useMemo(() => {
    return conformedValue(profile.phone!, MASK_PHONE);
  }, [profile]);

  const handleChangeCode = useCallback(
    (value: string) => {
      setIsError(false);
      setCode(value);
      if (value.length === 6 && !firstCodeError) onClickNext(value);
    },
    [onClickNext, firstCodeError],
  );

  const handleEndTimer = useCallback(() => {
    setShowTimer(false);
  }, []);

  const handleNewCode = useCallback(() => {
    onClickRequest();
    setShowTimer(true);
  }, [onClickRequest]);

  return (
    <Dialog open={open} className={cl.root} classes={{ paper: cl.paper }}>
      <IconButton onClick={onClickClose} className={cl.closeBtn} disabled={loading}>
        <Icon type="Close" />
      </IconButton>
      <div className={cl.content}>
        <div className={cl.title}>Код активации</div>
        <div className={cl.text}>
          Пожалуйста введите код активации. Мы отправили его вам на <span>{formattedPhone}</span>
        </div>
        <div className={cl.pinWrapper}>
          <PinInput type="numeric" inputMode="number" length={6} onChange={handleChangeCode} focus />
          {isError && <div className={cl.error}>Неверный код</div>}
        </div>
        <div className={cl.code}>
          {showTimer ? (
            <>
              <div className={cl.text}>Не получили код? Подождите</div>
              <Timer length={+SMS_TIMEOUT} onEnd={handleEndTimer} />
            </>
          ) : (
            <Button variant="text" onClick={handleNewCode} disabled={loading}>
              Запросить код снова
            </Button>
          )}
        </div>
        <Button onClick={onClickNext.bind(null, code)} size="large" disabled={code?.length < 6 || loading}>
          Продолжить
        </Button>
      </div>
    </Dialog>
  );
};
