import React from 'react';
import { Button } from 'ui/button/Button';
import cl from './CookieDialog.module.scss';

interface Props {
  handleClickCloseCookie: () => void;
}

const CookieDialog: React.FC<Props> = ({ handleClickCloseCookie }) => {
  return (
    <div className={cl.container}>
      <div className={cl.text}>
        Нажимая на «Принять», вы соглашаетесь с использованием аналитических cookie-файлов (они нужны для анализа
        использования сайта, улучшения сайта и сервисов), а также отслеживающих cookie-файлов.
      </div>
      <div className={cl.button}>
        <Button className={cl.buttonText} onClick={handleClickCloseCookie}>
          Принять
        </Button>
      </div>
    </div>
  );
};

export default CookieDialog;
