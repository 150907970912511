// Document type (birth certificate - 0, passport - 1)
export type DocumentType = 0 | 1;

export interface ChildrenForm {
  photo?: File;
  last_name: string;
  first_name: string;
  patronymic?: string;
  dob: string;
  document_type?: DocumentType;
  document_number?: string;
  document_issued_by?: string;
  document_issued_date?: string;
  citizenship?: string;
  actual_address?: string;
  address?: string;
  /** СНИЛС */
  inila?: string;
  phone?: string;
  adult_type_id: number;
}

export interface ChildrenUpdateForm extends ChildrenForm {
  id: number;
}

export interface Children extends Omit<ChildrenUpdateForm, 'photo'> {
  id: number;
  photo: string | null;
}

export const DOCUMENT_TYPE: Record<DocumentType, string> = {
  0: 'Свидетельство о рождении',
  1: 'Паспорт',
};
