import React from 'react';

interface Props {
}

const Chat: React.FC<Props> = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g >
          <path id="icon/communication/chat_24px" fillRule="evenodd" clipRule="evenodd" d="M4 2C2.9 2 2.01 2.9 2.01 4L2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2H4ZM4 4H20V16H5.17L4 17.17V4ZM14 12H6V14H14V12ZM6 9H18V11H6V9ZM18 6H6V8H18V6Z" fill="currentColor"/>
        </g>
      </svg>
  );
};

export default Chat;


