import React, { useCallback, MouseEvent, useEffect, useMemo } from 'react';
import { Step } from 'ui/edits/Step';
import { useChildren } from 'hooks/children';
import cl from './Step3.module.scss';
import Icon from 'ui/Icon';
import { ButtonItem } from 'ui/list/ButtonItem';
import { shortFioFromObj } from 'utils/helpers';
import cn from 'classnames';
import {
  $step3,
  changeViewType,
  clubJoinFx,
  initChild,
  nextStep,
  setEditChild,
  step3ToggleSaver,
  step3SetChild,
  prevStep,
} from '../../model';
import { useStore } from 'effector-react';
import { checkChildrenRequisites } from 'state/children';
import { showAlert, showErrorAlert } from 'ui/dialogs/Alert';
import { useChildAvatar } from 'hooks/children/useChildAvatar';
import { Avatar } from 'ui/avatar/Avatar';
import { useClubs } from 'hooks/clubs';
import { $currentClub } from 'state/club';
import { IconButton } from '@material-ui/core';
import { Children } from 'api/types/children';

interface Props {}

export const Step3: React.FC<Props> = () => {
  const { map: clubsMap } = useClubs();
  const clubId = useStore($currentClub);
  const club = clubsMap.get(clubId!);

  const { child_id, childrenList, childrenMap, needSave } = useStore($step3);
  const { data: globalChildrenList } = useChildren();

  useEffect(() => {
    if (!childrenList) {
      initChild(globalChildrenList || []);
    }
  }, [globalChildrenList, childrenList]);

  const handleNext = useCallback(async () => {
    if (!checkChildrenRequisites(childrenMap!.get(child_id!))) {
      await showAlert('error', 'Необходимо заполнить все реквизиты для ребенка');
      return;
    }
    try {
      if (!club?.privileges) await clubJoinFx();
      nextStep();
    } catch (e) {
      await showErrorAlert();
    }
  }, [child_id, childrenMap, club]);

  const handleEdit = useCallback(
    (child: Children) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setEditChild(child);
      changeViewType('child');
    },
    [],
  );

  const handleAddChild = useCallback(() => {
    setEditChild(null);
    changeViewType('child');
  }, []);

  const handleToggleSaver = useCallback(() => {
    step3ToggleSaver();
  }, []);

  const handlePrevStep = useCallback(() => {
    prevStep();
  }, []);

  //если мы добовили ребенка, то больше добавлять не надо
  const needAddChild = useMemo(() => !childrenList?.find((c) => c.id < 0), [childrenList]);

  return (
    <Step
      title="Выберите ребенка"
      onClickNext={handleNext}
      disabledNext={!child_id}
      withSaver
      needSave={needSave}
      onClickSaver={handleToggleSaver}
      prevStep={handlePrevStep}
    >
      <div className={cl.children}>
        {!!childrenList &&
          childrenList!.map((c) => (
            <ChildrenItem
              key={c.id}
              fio={shortFioFromObj(c)}
              onClick={step3SetChild.bind(null, c.id)}
              onClickEdit={handleEdit(c)}
              selected={child_id === c.id}
              photo={c.photo}
              filled={checkChildrenRequisites(c)}
            />
          ))}
        {needAddChild && <AddChildrenItem onClick={handleAddChild} />}
      </div>
    </Step>
  );
};

interface AddChildrenItemProps {
  onClick: () => void;
}

const AddChildrenItem: React.FC<AddChildrenItemProps> = ({ onClick }) => {
  return (
    <ButtonItem onClick={onClick}>
      <div className={cl.text}>Добавить ребенка</div>
      <div className={cl.addIco}>
        <Icon type="PlusCircle" />
      </div>
    </ButtonItem>
  );
};

interface ChildrenItemProps {
  fio: string;
  selected: boolean;
  onClick: () => void;
  onClickEdit: (e: MouseEvent<HTMLButtonElement>) => void;
  photo?: string | null;
  filled: boolean;
}

const ChildrenItem: React.FC<ChildrenItemProps> = ({ fio, onClick, selected, photo, onClickEdit, filled }) => {
  const avatar = useChildAvatar(photo);
  return (
    <ButtonItem className={cn(cl.child, selected && cl.selected)} onClick={onClick}>
      <div className={cn(cl.extra, filled && cl.filled)}>{filled ? 'Заполнено' : 'Не заполнено'}</div>
      <Avatar size={54} url={avatar} />
      <div className={cl.text}>
        <div className={cl.subtitle}>Ребенок</div>
        <div className={cl.fio}>{fio}</div>
      </div>
      <IconButton className={cl.editBtn} onClick={onClickEdit}>
        <Icon type="Edit" />
      </IconButton>
      <div className={cl.check}>
        <Icon type={selected ? 'CheckCircle' : 'Circle'} />
      </div>
    </ButtonItem>
  );
};
