import React, { lazy, /*lazy,*/ Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoadingPage from './components/page/LoadingPage';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { authorize } from 'api/';
import { authRequired, guestOnly } from './components/auth';
import { setToken } from './state/auth';
import moment from 'moment';
import 'moment/locale/ru';

import Login from 'routes/login/Login';
import Main from './routes/main/Main';
import Sudir from './routes/sudir';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Logout } from './routes/sudir/Logout';
import CookieDialog from 'ui/dialogs/CookieDialog/CookieDialog';

const LoginPage = guestOnly(Login);
// const SudirPage = guestOnly(Sudir);
const SudirPage = Sudir;
const MainPage = authRequired(Main);

// const LoginPage = guestOnly(lazy(() => import('./routes/login')));
// const MainPage = authRequired(lazy(() => import('./routes/main')));
const PrivacyPage = lazy(() => import('./routes/privacy/components/Page'));
const AgreementPage = lazy(() => import('./routes/agreement/components/Page'));

moment.locale('ru');

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#69488D',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: 15,
        textTransform: 'unset',
        lineHeight: '18px',
        fontWeight: 500,
        color: '#F6F7F8',
        borderRadius: 41,
        height: 46,
        position: 'relative',
      },
      containedPrimary: {
        '&$disabled': {
          color: '#fff',
          backgroundColor: '#69488D',
          opacity: 0.5,
        },
      },
      textPrimary: {
        '&$disabled': {
          opacity: 0.5,
        },
      },
      disableElevation: {
        boxShadow: '0px 0px 10px rgba(156, 129, 240, 0.5)',
      },
      sizeLarge: {
        fontSize: 20,
        lineHeight: '23px',
        height: 63,
        borderRadius: 70,
        fontWeight: 'bold',
      },
      text: {
        boxShadow: 'none!important',
        borderRadius: 5,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthSm: {
        maxWidth: 730,
        width: 730,
      },
    },
  },
});

function App() {
  useMemo(
    () => {
      // const token = getItem('accessToken');
      const token = localStorage.getItem('accessToken');
      if (token) {
        authorize(token);
        setToken(token);
      }
    },
    [
      /*dispatch*/
    ],
  );

  const [isOpenCookie, setOpenCookie] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem('firstEnter') !== 'true') setOpenCookie(true);
  }, [isOpenCookie, setOpenCookie]);

  const handleClickCloseCookie = useCallback(() => {
    setOpenCookie(false);
    localStorage.setItem('firstEnter', 'true');
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <Suspense fallback={<LoadingPage />}>
            <Switch>
              <Route path="/login" component={LoginPage} />
              <Route path="/privacy" component={PrivacyPage} />
              <Route path="/agreement" component={AgreementPage} />
              <Route path="/sudir/registration" component={SudirPage} />
              <Route path="/sudir/logout" component={Logout} />
              <Route component={MainPage} />
            </Switch>
          </Suspense>
        </Router>
      </MuiPickersUtilsProvider>
      {isOpenCookie && <CookieDialog handleClickCloseCookie={handleClickCloseCookie} />}
    </ThemeProvider>
  );
}

export default App;
