import { Backdrop } from 'ui/feedback/Backdrop';
import { ApiUnprocessableError } from 'api/errors';
import { useStore } from 'effector-react';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { showAlert, showErrorAlert } from 'ui/dialogs/Alert';
import { Step } from 'ui/edits/Step';
import { TextField } from 'ui/input/TextField';
import { DateMask } from 'ui/masks';
import { dateValidator, lengthValidator } from 'utils/validators';
import { $step4, documentSignFx, nextStep, prevStep, step4Set } from '../../model';
import cl from './Step4.module.scss';

interface ErrorsType {
  applicationNumber: undefined | string;
  applicationDate: undefined | string;
  dop_period: undefined | string;
  dop_uip_dstart: undefined |string;
  dop_uip_dend: undefined | string;
  dop_uip_matdstart: undefined |string;
  dop_uip_matdend: undefined | string;
  dop_plat_sum: undefined | string;          
  dop_plat_dstart: undefined | string;      
  dop_plat_dend: undefined | string;
  dop_club: undefined | string;   
}
interface Props {
  docType?: number
}
const Step4:React.FC<Props> = ({docType}) => {
  const [isFetching, setFetching] = useState(false);

  const [errors, setErrors] = useState<ErrorsType>({
    applicationNumber: undefined,
    applicationDate: undefined,
    dop_period: undefined,
    dop_uip_dstart: undefined,
    dop_uip_dend: undefined,
    dop_uip_matdstart: undefined,
    dop_uip_matdend: undefined,
    dop_plat_sum: undefined,      
    dop_plat_dstart: undefined,
    dop_plat_dend: undefined,
    dop_club: undefined
  });
  const step4 = useStore($step4);

  const handleNext = useCallback(async () => {
    if (dateValidator(step4.applicationDate, true, false) ||
     (dateValidator(step4.dop_period, true, false) && docType === 6) || 
    ((dateValidator(step4.dop_uip_dend, true, false) || dateValidator(step4.dop_uip_dstart, true, false) || dateValidator(step4.dop_uip_matdend, true, false) || dateValidator(step4.dop_uip_matdstart, true, false)) && docType === 8) ||
    ((dateValidator(step4.dop_plat_dend, true, false) || dateValidator(step4.dop_plat_dstart, true, false)) && docType === 9) || (
      lengthValidator(step4.dop_club, 255, true) && docType === 1
    )) {
      if (dateValidator(step4.dop_period, true, false) && docType === 6){
        setErrors((state) => ({ ...state, dop_period: dateValidator(step4.dop_period, true, false) }));
      } 
      if ((dateValidator(step4.dop_uip_dend, true, false) || dateValidator(step4.dop_uip_dstart, true, false) || dateValidator(step4.dop_uip_matdend, true, false) || dateValidator(step4.dop_uip_matdstart, true, false)) && docType === 8){
        setErrors((state) => ({ ...state, dop_uip_dend: dateValidator(step4.dop_uip_dend, true, false) }));
        setErrors((state) => ({ ...state, dop_uip_dstart: dateValidator(step4.dop_uip_dstart, true, false) }));
        setErrors((state) => ({ ...state, dop_uip_matdend: dateValidator(step4.dop_uip_matdend, true, false) }));
        setErrors((state) => ({ ...state, dop_uip_matdstart: dateValidator(step4.dop_uip_matdstart, true, false) }));
      }
      if ((dateValidator(step4.dop_plat_dend, true, false) || dateValidator(step4.dop_plat_dstart, true, false)) && docType === 9){
        setErrors((state) => ({ ...state, dop_plat_dend: dateValidator(step4.dop_plat_dend, true, false) }));
        setErrors((state) => ({ ...state, dop_plat_dstart: dateValidator(step4.dop_plat_dstart, true, false) }));

      }
      setErrors((state) => ({ ...state, applicationDate: dateValidator(step4.applicationDate, true, false) }));
      
    } else {
      try {
        setFetching(true);
        await documentSignFx();
        setFetching(false);
        nextStep();
      } catch (e) {
        setFetching(false);
        if (e instanceof ApiUnprocessableError) await showErrorAlert();
        else
          await showAlert(
            'error',
            'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
            'Ошибка',
          );
      }
    }
  }, [step4, docType]);
  const handlePrevStep = useCallback(() => {
    prevStep();
  }, []);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    step4Set({ ...step4, [name]: value });
  };
  const isDisabledNextExtra = docType === 6 && !step4.dop_period;

  const isDisabledNextForNew = docType === 8 && (!step4.dop_uip_dend || !step4.dop_uip_dstart || !step4.dop_uip_matdend || !step4.dop_uip_matdstart || !step4.dop_uip_sum)
  const isDisabledNextForExtraNew = docType === 9 && (!step4.dop_plat_dend || !step4.dop_plat_dstart || !step4.dop_plat_sum)
  const isDisabledNextDopClub = docType === 11 && !step4.dop_club

  return (
    <Step
      title={docType === 6 ? 'Номер, дата договора. Дата начала занятий' :'Номер и дата договора'}
      disabledNext={!step4.applicationDate || !step4.applicationNumber || isDisabledNextExtra || isDisabledNextForNew || isDisabledNextForExtraNew || isDisabledNextDopClub}
      onClickNext={handleNext}
      prevStep={handlePrevStep}
    >
      <Backdrop open={isFetching} />
      <>
        <div className={cl.container}>
          <TextField
            label="Введите номер договора"
            fullWidth
            name="applicationNumber"
            value={step4.applicationNumber}
            onChange={onChange}
            error={!!errors.applicationNumber}
            helperText={errors.applicationNumber}
          />
          <TextField
            label="Введите дату договора"
            fullWidth
            name="applicationDate"
            InputProps={{ inputComponent: DateMask }}
            value={step4.applicationDate}
            onChange={onChange}
            error={!!errors.applicationDate}
            helperText={errors.applicationDate}
          />
          {docType === 6 && (
            <TextField
            label="Введите дату фактического начала занятий"
            fullWidth
            name="dop_period"
            InputProps={{ inputComponent: DateMask }}
            value={step4.dop_period}
            onChange={onChange}
            error={!!errors.dop_period}
            helperText={errors.dop_period}
          />
          )}
          {docType === 8 && (
            <>
            <TextField
            label="Дата начала действия доп. соглашения"
            fullWidth
            name="dop_uip_dstart"
            InputProps={{ inputComponent: DateMask }}
            value={step4.dop_uip_dstart}
            onChange={onChange}
            error={!!errors.dop_uip_dstart}
            helperText={errors.dop_uip_dstart}
          />
          <TextField
            label="Дата окончания действия доп. соглашения"
            fullWidth
            name="dop_uip_dend"
            InputProps={{ inputComponent: DateMask }}
            value={step4.dop_uip_dend}
            onChange={onChange}
            error={!!errors.dop_uip_dend}
            helperText={errors.dop_uip_dend}
          />
          <TextField
          label="Дата начала периода оплаты мат. капиталом"
          fullWidth
          name="dop_uip_matdstart"
          InputProps={{ inputComponent: DateMask }}
          value={step4.dop_uip_matdstart}
          onChange={onChange}
          error={!!errors.dop_uip_matdstart}
          helperText={errors.dop_uip_matdstart}
        />
        <TextField
        label="Дата окончания периода оплаты мат. капиталом"
        fullWidth
        name="dop_uip_matdend"
        InputProps={{ inputComponent: DateMask }}
        value={step4.dop_uip_matdend}
        onChange={onChange}
        error={!!errors.dop_uip_matdend}
        helperText={errors.dop_uip_matdend}
      />
      <TextField
            label="Сумма"
            fullWidth
            name="dop_uip_sum"
            value={step4.dop_uip_sum}
            onChange={onChange}
          />
            </>
          )}
          {docType === 9 && (
            <>
            <TextField
            label="Сумма"
            fullWidth
            name="dop_plat_sum"
            value={step4.dop_plat_sum}
            onChange={onChange}
          />
          <TextField
            label="Дата начала периода обучения"
            fullWidth
            name="dop_plat_dstart"
            InputProps={{ inputComponent: DateMask }}
            value={step4.dop_plat_dstart}
            onChange={onChange}
            error={!!errors.dop_plat_dstart}
            helperText={errors.dop_plat_dstart}
          />
          <TextField
          label="Дата окончания периода обучения"
          fullWidth
          name="dop_plat_dend"
          InputProps={{ inputComponent: DateMask }}
          value={step4.dop_plat_dend}
          onChange={onChange}
          error={!!errors.dop_plat_dend}
          helperText={errors.dop_plat_dend}
        />
      </>
          )}
          {docType === 11 && (
            <TextField
            label="Наименование кружка"
            fullWidth
            name="dop_club"
            value={step4.dop_club}
            onChange={onChange}
            error={!!errors.dop_club}
            helperText={errors.dop_club}
          />
      
          )}
        </div>
      </>
    </Step>
  );
};

export default Step4;
