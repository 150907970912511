import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import cl from './EmailEdit.module.scss';
import { EditInStep } from 'ui/panels/EditInStep';
import { Profile } from 'api/types/profile';
import { emailValidator, isValid } from 'utils/validators';
import { TextField } from 'ui/input/TextField';
import { updateProfileFx } from 'state/profile';
import api from 'api/request/profile';
import { showErrorAlert } from 'ui/dialogs/Alert';
import { ConfirmDialog } from '../../../routes/main/routes/profile/routes/email/EmailPanel/ConfirmDialog';

interface StepState {
  profile: Profile | undefined;
  needSave: boolean;
}

interface Props {
  state: StepState;
  changeViewType: any;
}

interface Form {
  email: string;
}

interface Errors extends Partial<Record<keyof Form, string | undefined>> {}

const initForm = (profile?: Partial<Profile>): Form => ({
  email: profile?.email || '',
});

export const EmailEdit: React.FC<Props> = (props) => {
  const { state, changeViewType } = props;

  const profile = state.profile as Profile;
  const [form, setFrom] = useState<Form>(initForm(profile));
  const [errors, setErrors] = useState<Errors>({});
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    setFrom(initForm(profile));
  }, [profile]);

  const handleChangeForm = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFrom((form) => ({
      ...form,
      [name]: value,
    }));

    setErrors((e) => ({
      ...e,
      [name]: undefined,
    }));
  }, []);

  const validate = useCallback(() => {
    const errors: Errors = {
      email: emailValidator(form.email, true),
    };
    setErrors(errors);

    return isValid(errors);
  }, [form]);

  const handleCloseConfirm = useCallback(() => {
    setOpenConfirm(false);
    changeViewType('step');
  }, [changeViewType]);

  const handleSave = useCallback(async () => {
    if (!validate()) return;
    try {
      if (form.email !== profile.email) await updateProfileFx({ ...form });

      if (form.email !== profile.email || !profile.email_verified) {
        await api.getVerificationCode();
        setOpenConfirm(true);
      } else {
        changeViewType('step');
      }
    } catch (e) {
      await showErrorAlert();
    }
  }, [validate, form, profile, changeViewType]);

  return (
    <EditInStep
      title="Указать e-mail"
      onClickBack={changeViewType.bind(null, 'step')}
      className={cl.root}
      onClickNext={handleSave}
    >
      <ConfirmDialog open={openConfirm} email={form.email} onClickOk={handleCloseConfirm} />
      <form autoComplete="off" noValidate>
        <TextField
          label="E-mail"
          fullWidth
          value={form.email}
          name="email"
          onChange={handleChangeForm}
          error={!!errors.email}
          helperText={errors.email}
        />
      </form>
    </EditInStep>
  );
};
