import React from 'react';

const Contacts: React.FC = () => (
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.46447 13.4645C2.40215 12.5268 3.67392 12 5 12H12C13.3261 12 14.5979 12.5268 15.5355 13.4645C16.4732 14.4021 17 15.6739 17 17V19C17 19.5523 16.5523 20 16 20C15.4477 20 15 19.5523 15 19V17C15 16.2043 14.6839 15.4413 14.1213 14.8787C13.5587 14.3161 12.7956 14 12 14H5C4.20435 14 3.44129 14.3161 2.87868 14.8787C2.31607 15.4413 2 16.2043 2 17V19C2 19.5523 1.55228 20 1 20C0.447715 20 0 19.5523 0 19V17C0 15.6739 0.526784 14.4021 1.46447 13.4645Z"
      fill="#828282"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 2C6.84315 2 5.5 3.34315 5.5 5C5.5 6.65685 6.84315 8 8.5 8C10.1569 8 11.5 6.65685 11.5 5C11.5 3.34315 10.1569 2 8.5 2ZM3.5 5C3.5 2.23858 5.73858 0 8.5 0C11.2614 0 13.5 2.23858 13.5 5C13.5 7.76142 11.2614 10 8.5 10C5.73858 10 3.5 7.76142 3.5 5Z"
      fill="#828282"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 5C20.5523 5 21 5.44772 21 6V12C21 12.5523 20.5523 13 20 13C19.4477 13 19 12.5523 19 12V6C19 5.44772 19.4477 5 20 5Z"
      fill="#828282"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 9C16 8.44771 16.4477 8 17 8H23C23.5523 8 24 8.44771 24 9C24 9.55228 23.5523 10 23 10H17C16.4477 10 16 9.55228 16 9Z"
      fill="#828282"
    />
  </svg>
);

export default Contacts;
