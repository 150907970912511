import { Dialog, IconButton } from '@material-ui/core';
import { useProfile } from 'hooks/profile';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { TextField } from 'ui/input/TextField';
import { emailValidator } from 'utils/validators';
import api from 'api/request/profile';
import { updateProfileFx } from 'state/profile';
import { showErrorAlert } from '../Alert';
import { Button } from 'ui/button/Button';
import cl from './EmailConfirm.module.scss';
import Icon from 'ui/Icon';

interface Props {
  isOpen: boolean;
  handleCloseEmailConfirmDialog: () => void;
  setOpenConfirm: (openConfirm: boolean) => void;
  email: string;
  setEmail: (email: string) => void;
}

export const EmailConfirm: React.FC<Props> = (props) => {
  const { isOpen, handleCloseEmailConfirmDialog, setOpenConfirm, email, setEmail } = props;

  const { profile } = useProfile();

  const [error, setError] = useState<string | undefined>();

  const validate = useCallback(() => {
    const error = emailValidator(email, true);
    setError(error);
    return !error;
  }, [email]);

  const handleChangeEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setEmail(value);
    },
    [setEmail],
  );

  const handleClickSave = useCallback(async () => {
    if (!validate()) return;

    try {
      if (email !== profile.email) await updateProfileFx({ email });
      if (email !== profile.email || !profile.email_verified) {
        await api.getVerificationCode();
        setOpenConfirm(true);
        handleCloseEmailConfirmDialog();
      }
    } catch (e) {
      await showErrorAlert();
    }
  }, [email, validate, profile, handleCloseEmailConfirmDialog, setOpenConfirm]);

  return (
    <>
      <Dialog open={isOpen} className={cl.root} classes={{ paper: cl.paper }}>
        <IconButton onClick={handleCloseEmailConfirmDialog} className={cl.closeBtn}>
          <Icon type="Close" />
        </IconButton>
        <div className={cl.content}>
          <div className={cl.title}>Чтобы продолжить, заполните ваш e-mail</div>
          <form autoComplete="off" noValidate>
            <TextField
              label="E-mail"
              fullWidth
              value={email}
              name="email"
              onChange={handleChangeEmail}
              error={!!error}
              helperText={error}
            />
          </form>
          <Button className={cl.btnContinue} onClick={handleClickSave}>
            Сохранить
          </Button>
        </div>
      </Dialog>
    </>
  );
};
