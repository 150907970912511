import { PageContent } from 'components/layout';
import { useProfile } from 'hooks/profile';
import React, { useCallback, useEffect, useState } from 'react';
import { EmailPanel } from './EmailPanel';

interface Props {}

const Email: React.FC<Props> = () => {
  const { profile } = useProfile();

  const [editMode, setEditMode] = useState(false);
  const [email, setEmail] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    setEmail(profile.email || '');
  }, [profile.email]);

  const handleClickCancel = useCallback(() => {
    setEditMode(false);
    setEmail(profile.email || '');
    setError(undefined);
  }, [profile.email]);

  const isEditModeForMobile = useCallback(() => {
    if ((email !== profile.email && profile.email !== null) || (email !== '' && profile.email === null)) {
      return true;
    } else return false;
  }, [profile, email]);

  return (
    <PageContent handleClickCancel={handleClickCancel} editMode={isEditModeForMobile()} titleSM="E-mail">
      <EmailPanel
        setEditModeForMobile={isEditModeForMobile()}
        handleClickCancel={handleClickCancel}
        openConfirm={openConfirm}
        setEmail={setEmail}
        setOpenConfirm={setOpenConfirm}
        email={email}
        setError={setError}
        setEditMode={setEditMode}
        error={error}
        editMode={editMode}
        profile={profile}
      />
    </PageContent>
  );
};
export default Email;
