import React from 'react';

const GooglePlay: React.FC = () => (
  <svg width="28" height="31" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9032 14.2685L5.29422 1.68062L21.3368 10.8499L17.9032 14.2685V14.2685ZM2.00343 0.941162C1.26072 1.32796 0.763672 2.03329 0.763672 2.94908V28.0509C0.763672 28.9667 1.26072 29.672 2.00343 30.0588L16.6634 15.4971L2.00343 0.941162ZM26.2958 13.7736L22.9308 11.834L19.1772 15.5028L22.9308 19.1717L26.3644 17.232C27.3928 16.4186 27.3928 14.587 26.2958 13.7736V13.7736ZM5.29422 29.325L21.3368 20.1557L17.9032 16.7372L5.29422 29.325Z"
      fill="white"
    />
  </svg>
);

export default GooglePlay;
