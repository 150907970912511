import { useEffect, useState } from 'react';
import api from 'api/request/profile';
import { useProfile } from './index';

export const useProfileAvatar = () => {
  const { profile } = useProfile();
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    if (profile.photo)
      api.getPhoto(profile.photo).then((e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          setAvatar(reader.result);
        };
      });
  }, [profile.photo]);

  return avatar;
};
