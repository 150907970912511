import { dataOnly, downloadInstance } from 'api/instance';
import { ApiResponse } from 'api/types';
import { Club, ClubCategory, ClubJoinForm, ClubJoinResponseData } from 'api/types/clubs';
import { FulfillDownloadConfig } from '../../utils/on-fulfill-download';

const api = {
  list: () => dataOnly.get<null, ApiResponse<Club[]>>('/api/v1/clubs'),

  categoryList: () => dataOnly.get<null, ApiResponse<ClubCategory[]>>('/api/v1/clubs/categories'),

  join: (form: ClubJoinForm) => {
    const formData = new FormData();
    formData.append('club_id', String(form.club_id));
    if (form.child_id) formData.append('child_id', String(form.child_id));
    formData.append('start_date', form.start_date);
    if (!form.isPrivileges) formData.append('orphan', String(form.orphan));
    if (!form.isPrivileges) formData.append('handicapped', String(form.handicapped));
    if (!form.isPrivileges) formData.append('multi_children', String(form.multi_children));
    formData.append('maternal_capital', String(form.maternal_capital));
    if (!form.isPrivileges) {
      form.file_name?.forEach((f) => {
        formData.append('file_name[]', f.file[0], f.name + '.' + f.file[0].name.split('.').pop());
      });
    }
    formData.append('save_user_data', String(form.save_user_data));
    formData.append('user_last_name', String(form.user_last_name));
    formData.append('user_first_name', String(form.user_first_name));
    formData.append('user_patronymic', String(form.user_patronymic || ''));
    formData.append('user_dob', String(form.user_dob));
    formData.append('user_passport_number', String(form.user_passport_number));
    formData.append('user_passport_issued_by', String(form.user_passport_issued_by));
    formData.append('user_passport_issued_date', String(form.user_passport_issued_date));
    formData.append('user_address', String(form.user_address));

    formData.append('save_child_data', String(form.save_child_data));
    formData.append('child_last_name', String(form.child_last_name));
    formData.append('child_first_name', String(form.child_first_name));
    formData.append('child_patronymic', String(form.child_patronymic || ''));
    formData.append('child_dob', String(form.child_dob));
    formData.append('document_type', String(form.document_type ?? ''));
    formData.append('child_document_number', String(form.child_document_number));
    formData.append('child_document_issued_by', String(form.child_document_issued_by));
    formData.append('child_document_issued_date', String(form.child_document_issued_date));
    formData.append('child_address', String(form.child_address));
    formData.append('child_inila', String(form.child_inila));
    formData.append('child_phone', String(form.child_phone));

    formData.append('adult_type_id', String(form.adult_type_id));

    return dataOnly.post<null, ApiResponse<ClubJoinResponseData>>('/api/v1/clubs/join', formData);
  },

  approve: (id: number) => dataOnly.post<null, any>('/api/v1/clubs/approve', { id }),

  code: (id: number, code: string) => dataOnly.post<null, any>('/api/v1/clubs/code', { id, code }),

  history: () => dataOnly.get<null, any>('/api/v1/clubs/history'),

  historyRejectAct: (id: number, reason: string) =>
    dataOnly.post<null, any>('/api/v1/clubs/acts/reject', { id, reason }),

  approveAct: (id: number) => dataOnly.post<null, any>('/api/v1/clubs/acts/approve', { id }),

  codeAct: (id: number, code: string) => dataOnly.post<null, any>('/api/v1/clubs/acts/code', { id, code }),

  act: (id: number, fileName: string) =>
    downloadInstance.get<null, any>(`/api/v1/clubs/acts/${id}`, { _fileName: fileName } as FulfillDownloadConfig),

  actPreview: (id: number) => dataOnly.get<null, any>(`/api/v1/clubs/acts/preview/${id}`),

  contract: (id: number, fileName: string) =>
    downloadInstance.get<null, any>(`/api/v1/clubs/contract/${id}`, { _fileName: fileName } as FulfillDownloadConfig),

  maternalCapital: (id: number, fileName: string) =>
    downloadInstance.get<null, any>(`/api/v1/clubs/maternal_capital/${id}`, {
      _fileName: fileName,
    } as FulfillDownloadConfig),
};

export default api;
