import React from 'react';

const CloseFilled: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6503 7.3479L7.34614 12.6521L12.6503 7.3479ZM12.6503 12.6521L7.34614 7.34794L12.6503 12.6521Z"
        fill="white"
      />
      <path
        d="M13.7895 7.62298C14.0986 7.31382 13.8392 6.94004 13.2101 6.78812C12.581 6.6362 11.8203 6.76367 11.5112 7.07282L13.7895 7.62298ZM6.207 12.377C5.89785 12.6861 6.15723 13.0599 6.78636 13.2118C7.41549 13.3638 8.17612 13.2363 8.48528 12.9271L6.207 12.377ZM11.5112 12.9272C11.8203 13.2363 12.581 13.3638 13.2101 13.2119C13.8392 13.06 14.0986 12.6862 13.7895 12.377L11.5112 12.9272ZM8.48528 7.07286C8.17612 6.7637 7.41549 6.63624 6.78636 6.78816C6.15723 6.94008 5.89785 7.31386 6.207 7.62301L8.48528 7.07286ZM11.5112 7.07282L6.207 12.377L8.48528 12.9271L13.7895 7.62298L11.5112 7.07282ZM13.7895 12.377L8.48528 7.07286L6.207 7.62301L11.5112 12.9272L13.7895 12.377Z"
        fill="white"
      />
    </svg>
  );
};

export default CloseFilled;
