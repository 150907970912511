import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { PanelEditable } from 'ui/panels';
import cl from './ProfilePanel.module.scss';
import { Field, Header } from 'ui/panels/PanelEditable';
import { Input } from '@material-ui/core';
import { updateProfileFx } from 'state/profile';
import { conformedValue, MASK_PHONE } from 'constants/format/masks';
import { toISODate } from 'constants/date/format';
import { DateMask } from 'ui/masks';
import { dateValidator, isValid, maxValidator } from 'utils/validators';
import { Profile } from 'api/types/profile';
import { showErrorAlert } from 'ui/dialogs/Alert';
import { ProfileAvatar } from 'ui/avatar/ProfileAvatar';
import { useProfileAvatar } from 'hooks/profile/useProfileAvatar';
import { updateProfileAvatarFx } from 'state/profile/avatar';
import { TextField } from 'ui/input/TextField';

interface Form {
  last_name: string;
  first_name: string;
  patronymic: string;
  dob: string;
}
interface Errors extends Partial<Form> {}

interface Props {
  setEditMode: (mode: boolean) => void;
  profile: Partial<Profile>;
  form: Form;
  editMode: boolean;
  setForm: (form: any) => void;
  errors: Errors;
  setErrors: (errors: any) => void;
  setEditModeForMobile: boolean;
  handleClickCancel: () => void;
}

export const ProfilePanel: React.FC<Props> = ({
  handleClickCancel,
  setEditModeForMobile,
  profile,
  setErrors,
  form,
  errors,
  editMode,
  setForm,
  setEditMode,
}) => {
  const avatar = useProfileAvatar();

  const handleClickEdit = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const validate = useCallback(() => {
    const errors: Errors = {
      last_name: maxValidator(form.last_name, 100, true),
      first_name: maxValidator(form.first_name, 100, true),
      patronymic: maxValidator(form.patronymic, 100),
      dob: dateValidator(form.dob, true, true),
    };
    setErrors(errors);
    return isValid(errors);
  }, [form, setErrors]);

  const handleClickSave = useCallback(async () => {
    if (!validate()) return;

    try {
      await updateProfileFx({ ...form, dob: toISODate(form.dob) });
      setEditMode(false);
    } catch (e) {
      await showErrorAlert();
    }
  }, [form, validate, setEditMode]);

  const handleChangeField = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;
      setForm((form: Form) => ({ ...form, [name]: value }));
      setErrors((errors: Errors) => ({ ...errors, [name]: undefined }));
    },
    [setErrors, setForm],
  );

  const handleChangeDOB = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      setForm((form: Form) => ({ ...form, dob: value }));
      setErrors((errors: Errors) => ({ ...errors, dob: undefined }));
    },
    [setForm, setErrors],
  );

  const handleUploadAvatar = useCallback((f: File) => {
    updateProfileAvatarFx(f);
  }, []);

  const phone = useMemo(() => conformedValue(profile.phone || '', MASK_PHONE), [profile]);

  return (
    <>
      <Header
        title="Учетная запись"
        classname={cl.header}
        onClickEdit={handleClickEdit}
        onClickCancel={handleClickCancel}
        showEditBtn={!editMode}
        type="profile"
      />
      <PanelEditable classname={cl.root} editMode={editMode || setEditModeForMobile} onClickSave={handleClickSave}>
        <ProfileAvatar onUpload={handleUploadAvatar} src={avatar} />

        <div className={cl.fields}>
          <Field
            className={cl.inputDesctop}
            label="Фамилия"
            value={form.last_name === '' ? '-' : form.last_name}
            editMode={editMode}
            error={errors.last_name}
          >
            <Input
              fullWidth
              name="last_name"
              onChange={handleChangeField}
              value={form.last_name || ''}
              error={!!errors.last_name}
            />
          </Field>
          <TextField
            className={cl.inputMobile}
            label="Фамилия"
            fullWidth
            value={form.last_name}
            name="last_name"
            onChange={handleChangeField}
            error={!!errors.last_name}
          />
          <Field
            label="Имя"
            className={cl.inputDesctop}
            value={form.first_name === '' ? '-' : form.first_name}
            editMode={editMode}
            error={errors.first_name}
          >
            <Input
              fullWidth
              name="first_name"
              onChange={handleChangeField}
              value={form.first_name}
              error={!!errors.first_name}
            />
          </Field>
          <TextField
            className={cl.inputMobile}
            label="Имя"
            value={form.first_name || ''}
            error={!!errors.first_name}
            fullWidth
            name="first_name"
            onChange={handleChangeField}
          />
          <Field
            label="Отчество"
            className={cl.inputDesctop}
            value={form.patronymic === '' ? '-' : form.patronymic}
            editMode={editMode}
            error={errors.patronymic}
          >
            <Input
              fullWidth
              name="patronymic"
              onChange={handleChangeField}
              value={form.patronymic}
              error={!!errors.patronymic}
            />
          </Field>
          <TextField
            className={cl.inputMobile}
            label="Отчество"
            value={form.patronymic || ''}
            error={!!errors.patronymic}
            fullWidth
            name="patronymic"
            onChange={handleChangeField}
          />
          <Field
            label="Дата рождения"
            className={cl.inputDesctop}
            value={form.dob || '-'}
            editMode={editMode}
            error={errors.dob}
          >
            <Input
              fullWidth
              onChange={handleChangeDOB}
              value={form.dob}
              inputComponent={DateMask}
              error={!!errors.dob}
            />
          </Field>
          <TextField
            className={cl.inputMobile}
            label="Дата рождения"
            fullWidth
            value={form.dob || ''}
            name="dob"
            onChange={handleChangeField}
            error={!!errors.dob}
            InputProps={{ inputComponent: DateMask }}
          />
          <Field label="Номер телефона" className={cl.inputDesctop} value={phone} editMode={editMode} />
          <TextField className={cl.inputMobile} label="Номер телефона" fullWidth value={phone} disabled={true} />
        </div>
      </PanelEditable>
    </>
  );
};
