import React from 'react';

interface Props {
}

const PlusCircle: React.FC<Props> = () => {
  return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 17.5C22.8284 17.5 23.5 16.8285 23.5 16C23.5 15.1716 22.8284 14.5 22 14.5V17.5ZM9.99805 14.5C9.16962 14.5 8.49805 15.1716 8.49805 16C8.49805 16.8285 9.16962 17.5 9.99805 17.5V14.5ZM14.4989 22.001C14.4989 22.8294 15.1705 23.501 15.9989 23.501C16.8274 23.501 17.4989 22.8294 17.4989 22.001H14.4989ZM17.4989 9.99902C17.4989 9.1706 16.8274 8.49902 15.9989 8.49902C15.1705 8.49902 14.4989 9.1706 14.4989 9.99902H17.4989ZM22 14.5H9.99805V17.5H22V14.5ZM17.4989 22.001V9.99902H14.4989V22.001H17.4989Z" fill="currentColor"/>
        <path d="M30.5 16C30.5 24.0081 24.0081 30.5 16 30.5C7.99187 30.5 1.5 24.0081 1.5 16C1.5 7.99187 7.99187 1.5 16 1.5C24.0081 1.5 30.5 7.99187 30.5 16Z" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>

  );
};

export default PlusCircle;


