import { createEffect, createStore, createEvent, guard, sample } from 'effector';
import { ApiResponse } from 'api/types';
import { ApiError } from 'api/errors/apiError';
import { FetchingListState, initFetchingListState, storeAttachFetchList } from '../utils';
import { logout } from 'state/auth';
import { DocumentsType } from 'api/types/documents';
import api from 'api/request/summer';

export interface GartenState extends FetchingListState<DocumentsType> {}

export const getSummerListFx = createEffect<void, ApiResponse<DocumentsType[]>, ApiError<any>>({
  handler: async () => await api.list(),
});

export const fetchSummerListIfNeed = createEvent();

export const $summer = createStore<GartenState>(initFetchingListState<DocumentsType>()).reset(logout);

guard({
  source: sample($summer, fetchSummerListIfNeed),
  filter: $summer.map((state) => !state.isFetching && !state.didFetched),
  target: getSummerListFx,
});

storeAttachFetchList($summer, [getSummerListFx]);
