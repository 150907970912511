import React, { ChangeEvent } from 'react';
import cl from './Upload.module.scss';
import { AVATAR_FILE_SIZE } from 'constants/config';
import { fileExt } from '../../../utils/helpers';

interface Props {
  onUpload?: (file: any, file2: any) => void;
  maxSize?: number;
  onErrorSize?: () => void;
}

const ACCEPT_LIST = ['.png', '.jpg', '.jpeg'];
const ACCEPT = ACCEPT_LIST.join(',');

export const Upload: React.FC<Props> = ({ onUpload, maxSize = AVATAR_FILE_SIZE, onErrorSize }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { files } = e.target;
    const reader = new FileReader();
    if (!files || !files[0]) return;

    const file = files[0];
    if (!ACCEPT_LIST.includes('.' + fileExt(file.name).toLowerCase())) {
      e.target.value = '';
      return;
    }

    if (file.size > maxSize) {
      e.target.value = '';
      onErrorSize?.();
      return;
    }

    reader.readAsDataURL(file);

    reader.onload = () => onUpload?.(file, reader.result);
  };

  return <input type="file" accept={ACCEPT} onChange={handleChange} className={cl.root} title="" />;
};
