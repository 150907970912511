import React from 'react';
const Telegram = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40ZM16.3333 29.1667C16.8198 29.1667 17.0421 28.9503 17.3107 28.6887L17.3333 28.6667L19.7356 26.3308L24.7333 30.0232C25.6531 30.5306 26.3169 30.2679 26.546 29.1692L29.8268 13.7087C30.1627 12.362 29.3135 11.7512 28.4336 12.1507L9.16859 19.5792C7.85357 20.1067 7.86124 20.8403 8.92889 21.1672L13.8727 22.7103L25.3182 15.4894C25.8585 15.1618 26.3544 15.3379 25.9474 15.6992L16.6738 24.0679L16.6737 24.0679L16.6737 24.068L16.6733 24.0683L16.6736 24.0685L16.3333 29.1667Z"
        fill="#69488D"
      />
    </svg>
  );
};
export default Telegram;
