import { createEffect, createStore, createEvent, guard, sample } from 'effector';
import { ApiResponse } from 'api/types';
import { ApiError } from 'api/errors/apiError';
import { FetchingListState, initFetchingListState, storeAttachFetchList } from '../utils';
import { logout } from 'state/auth';
import api from 'api/request/gartens';
import { Gartens } from 'api/types/gartens';

export interface GartenState extends FetchingListState<Gartens> {}

export const getGartensListFx = createEffect<void, ApiResponse<Gartens[]>, ApiError<any>>({
  handler: async () => await api.list(),
});

export const fetchGartensListIfNeed = createEvent();

export const $gartens = createStore<GartenState>(initFetchingListState<Gartens>()).reset(logout);

guard({
  source: sample($gartens, fetchGartensListIfNeed),
  filter: $gartens.map((state) => !state.isFetching && !state.didFetched),
  target: getGartensListFx,
});

storeAttachFetchList($gartens, [getGartensListFx]);

export const setActiveGarten = createEvent<number>();

export const $activeGarten = createStore(-1).on(setActiveGarten, (state, id) => (state = id));
