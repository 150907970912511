import React, { useCallback } from 'react';
import { Button } from 'ui/button/Button';
import { ButtonGroup } from '@material-ui/core';
import cl from './SelectSection.module.scss';
import cn from 'classnames';

interface Props {
  isChildrenRoute: boolean;
  onChange: (i: number) => void;
}

const SelectSection: React.FC<Props> = ({ onChange, isChildrenRoute }) => {
  const handleClick = useCallback(
    (i: number) => () => {
      onChange(i);
    },
    [onChange],
  );

  return (
    <ButtonGroup color="primary" className={cl.root} disableElevation>
      <Button
        variant={isChildrenRoute ? 'outlined' : 'contained'}
        onClick={handleClick(0)}
        className={cn(!isChildrenRoute && cl.isActive)}
      >
        Учетная запись
      </Button>
      <Button
        variant={isChildrenRoute ? 'contained' : 'outlined'}
        onClick={handleClick(1)}
        className={cn(isChildrenRoute && cl.isActive)}
      >
        Дети
      </Button>
    </ButtonGroup>
  );
};

export default SelectSection;
