import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import cl from './ProfileEdit.module.scss';
import { EditInStep } from 'ui/panels/EditInStep';
import { Profile } from 'api/types/profile';
import { DateMask } from 'ui/masks';
import { toISODate, toLocalDate } from 'constants/date/format';
import { dateValidator, isValid, maxValidator } from 'utils/validators';
import { TextField } from 'ui/input/TextField';
import { showErrorAlert } from 'ui/dialogs/Alert';

interface StepState {
  profile: Profile | undefined;
  needSave: boolean;
}

interface Props {
  state: StepState;
  changeViewType: (view: any) => void;
  stepSetProfile: (profile: Profile) => void;
}

interface Form {
  last_name: string;
  first_name: string;
  patronymic: string;
  dob: string;
}

const initForm = (profile?: Partial<Profile>) => ({
  last_name: profile?.last_name || '',
  first_name: profile?.first_name || '',
  patronymic: profile?.patronymic || '',
  dob: toLocalDate(profile?.dob) || '',
});

export const ProfileEdit: React.FC<Props> = (props) => {
  const { state, changeViewType, stepSetProfile } = props;
  // const { profile } = useProfile();
  const { profile } = state;

  const [form, setFrom] = useState<Form>(initForm(profile));
  const [errors, setErrors] = useState<Partial<Form>>({});

  useEffect(() => {
    setFrom(initForm(profile));
  }, [profile]);

  const handleChangeForm = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFrom((form) => ({
      ...form,
      [name]: value,
    }));

    setErrors((e) => ({
      ...e,
      [name]: undefined,
    }));
  }, []);

  const validate = useCallback(() => {
    const errors: Partial<Form> = {
      last_name: maxValidator(form.last_name, 100, true),
      first_name: maxValidator(form.first_name, 100, true),
      patronymic: maxValidator(form.patronymic, 100),
      dob: dateValidator(form.dob, true, true),
    };
    setErrors(errors);

    return isValid(errors);
  }, [form]);

  const handleSave = useCallback(async () => {
    if (!validate()) return;
    try {
      // await updateProfileFx(form);
      stepSetProfile({ ...profile!, ...form, dob: toISODate(form.dob) });
      changeViewType('step');
    } catch (e) {
      await showErrorAlert();
    }
  }, [validate, form, profile, stepSetProfile, changeViewType]);

  return (
    <EditInStep
      title="Редактировать данные"
      onClickBack={changeViewType.bind(null, 'step')}
      className={cl.root}
      onClickNext={handleSave}
    >
      <form autoComplete="off" noValidate>
        <TextField
          label="Фамилия"
          fullWidth
          value={form.last_name}
          name="last_name"
          onChange={handleChangeForm}
          error={!!errors.last_name}
          helperText={errors.last_name}
        />
        <TextField
          label="Имя"
          fullWidth
          value={form.first_name}
          name="first_name"
          onChange={handleChangeForm}
          error={!!errors.first_name}
          helperText={errors.first_name}
        />
        <TextField
          label="Отчество"
          fullWidth
          value={form.patronymic}
          name="patronymic"
          onChange={handleChangeForm}
          error={!!errors.patronymic}
          helperText={errors.patronymic}
        />
        <TextField
          label="Дата рождения"
          fullWidth
          value={form.dob}
          name="dob"
          onChange={handleChangeForm}
          error={!!errors.dob}
          helperText={errors.dob}
          InputProps={{ inputComponent: DateMask }}
        />
      </form>
    </EditInStep>
  );
};
