import { Map as ImmutableMap } from 'immutable';
import { createEffect } from 'effector';
import { Children, ChildrenForm, ChildrenUpdateForm } from 'api/types/children';
import api from 'api/request/children';
import { $profile } from '../profile';
import { FetchingListState } from '../utils';
import { ApiResponse } from '../../api/types';
import { FamilyMember } from 'routes/main/routes/schools/components/ProgrammJoin/model';

export const childrenCreateFX = createEffect({
  handler: async (form: ChildrenForm) => await api.create(form),
});

export const childrenUpdateFX = createEffect<ChildrenUpdateForm, ApiResponse<Children>>({
  handler: async (form: ChildrenUpdateForm) => await api.update(form.id, form),
});

export const childrenDeleteFX = createEffect({
  handler: async (id: number) => await api.delete(id),
});

export const $children = $profile.map<FetchingListState<Children>>((state) => {
  const data = state.data.children || [];
  return {
    ...state,
    data,
    map: ImmutableMap(
      data.map<[number, Children]>((c) => [c.id, c]),
    ),
  };
});

$profile.on(childrenUpdateFX.doneData, (state, { data }) => {
  return {
    ...state,
    data: {
      ...state.data,
      children: state.data.children?.map((item) => (data.id === item.id ? data : item)),
    },
  };
});

$profile.on(childrenCreateFX.doneData, (state, { data }) => {
  const children = state.data.children?.slice() || [];
  children.push(data);
  return {
    ...state,
    data: {
      ...state.data,
      children,
    },
  };
});

export const checkChildrenRequisites = (child?: Partial<Children>) =>
  !(
    !child ||
    !child.last_name ||
    !child.first_name ||
    !child.dob ||
    typeof child.document_type !== 'number' ||
    !child.document_number ||
    !child.document_issued_by ||
    !child.document_issued_date ||
    !child.address ||
    !child.inila ||
    !child.adult_type_id
  );

export const checkMembersRequisites = (member?: Partial<FamilyMember>) =>
  !(!member || !member.last_name || !member.first_name || !member.patronymic || !member.id || !member.classOrGroup);
