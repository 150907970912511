import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { LoginContent } from 'components/layout/LoginLayout/LoginContent';
import { useHistory } from 'react-router';
import cl from './Privacy.module.scss';
import { Button } from 'ui/button/Button';
import { Check } from 'ui/input/Check';
import { v4 as uuidv4 } from 'uuid';
import { MOSRU_BASE_URL, MOSRU_CLIENT_ID, WEB_BASE_URL } from 'constants/config';
import { LoginLocationState } from '../Default/Default';
import { History } from 'history';

interface Props {}

type Options = 'privacy' | 'agreement';

const updateHistory = (history: History, name: string, value: string) => {
  const qs = new URLSearchParams(history.location.search);
  qs.set(name, value);
  history.replace(`?${qs.toString()}`, history.location.state);
};

export const Privacy: React.FC<Props> = () => {
  const history = useHistory<LoginLocationState>();
  const [isPrivacy, setIsPrivacy] = useState(false);
  const [isAgreement, setIsAgreement] = useState(false);

  useEffect(() => {
    const qs = new URLSearchParams(window.location.search);
    if (+(qs.get('privacy') || 0)) setIsPrivacy(true);
    if (+(qs.get('agreement') || 0)) setIsAgreement(true);
  }, []);

  const handleClose = useCallback(() => {
    history.replace('/login');
  }, [history]);

  const loginPhone = useCallback(() => {
    history.push('/login/phone', { redirect: history.location.state.redirect, type: 'phone' });
  }, [history]);

  const loginMosRu = useCallback(() => {
    const state = uuidv4();
    localStorage.setItem('mosru_state', state);
    window.location.href = `${MOSRU_BASE_URL}/sps/oauth/ae?client_id=${MOSRU_CLIENT_ID}&response_type=code&scope=openid+profile+contacts&access_type=online&state=${state}&redirect_uri=${WEB_BASE_URL}/sudir/registration`;
  }, []);

  const handleClickPrivacy = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      updateHistory(history, 'showPrivacy', '1');
      // history.push('/privacy');

      // setVisible(true);
    },
    [history],
  );

  const handleClickAgreement = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      updateHistory(history, 'showAgreement', '1');
      // history.push('/agreement');
    },
    [history],
  );

  const handleCheck = useCallback(
    (option: Options) => () => {
      switch (option) {
        case 'privacy':
          setIsPrivacy((old) => {
            updateHistory(history, option, String(+!old));
            return !old;
          });
          break;
        case 'agreement':
          setIsAgreement((old) => {
            updateHistory(history, option, String(+!old));
            return !old;
          });
          break;
      }
    },
    [history],
  );

  const handleClickNext = useCallback(async () => {
    const { state } = history.location;

    if (state && state.type === 'mosru') {
      loginMosRu();
    } else {
      loginPhone();
    }
  }, [history, loginMosRu, loginPhone]);

  return (
    <LoginContent showFrame onClose={handleClose} className={cl.root}>
      <div className={cl.title}>
        Вход в личный
        <br />
        кабинет
      </div>
      <div className={cl.content}>
        <Check
          label={
            <span>
              Я даю согласие на обработку моих{' '}
              <span className={cl.link} onClick={handleClickPrivacy}>
                персональных данных
              </span>
            </span>
          }
          // onClick={setIsPrivacy.bind(null, !isPrivacy)}
          onClick={handleCheck('privacy')}
          checked={isPrivacy}
        />
        <Check
          label={
            <span>
              Я принимаю условия{' '}
              <span className={cl.link} onClick={handleClickAgreement}>
                пользовательского соглашения
              </span>
            </span>
          }
          // onClick={setIsAgreement.bind(null, !isAgreement)}
          onClick={handleCheck('agreement')}
          checked={isAgreement}
        />
      </div>
      <Button className={cl.btnMobile} onClick={handleClickNext} size="large" disabled={!isPrivacy || !isAgreement}>
        Продолжить
      </Button>
    </LoginContent>
  );
};
