import React from 'react';

interface Props {}

const CheckCircle: React.FC<Props> = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3294 2.52639C11.7179 1.80833 9.91741 1.63045 8.19652 2.01926C6.47563 2.40807 4.92653 3.34275 3.78025 4.6839C2.63396 6.02505 1.95192 7.70081 1.83584 9.46125C1.71975 11.2217 2.17585 12.9725 3.1361 14.4526C4.09636 15.9326 5.50932 17.0626 7.16425 17.674C8.81919 18.2854 10.6274 18.3455 12.3193 17.8453C14.0112 17.345 15.496 16.3113 16.5524 14.8982C17.6087 13.4852 18.18 11.7685 18.181 10.0042V9.16844C18.181 8.66638 18.588 8.25939 19.0901 8.25939C19.5921 8.25939 19.9991 8.66638 19.9991 9.16844V10.0048C19.9979 12.1611 19.2997 14.2598 18.0085 15.9868C16.7174 17.7139 14.9026 18.9774 12.8348 19.5888C10.767 20.2002 8.55688 20.1267 6.53418 19.3795C4.51148 18.6322 2.78453 17.2511 1.61089 15.4421C0.437247 13.6332 -0.120204 11.4933 0.0216758 9.34163C0.163555 7.18997 0.997163 5.14182 2.39817 3.50264C3.79919 1.86346 5.69253 0.721074 7.79585 0.245859C9.89916 -0.229356 12.0997 -0.0119388 14.0694 0.865686C14.528 1.07002 14.7341 1.60743 14.5298 2.06602C14.3254 2.52461 13.788 2.73073 13.3294 2.52639Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7325 2.08966C20.0877 2.44449 20.0879 3.02007 19.7331 3.37526L10.6426 12.4749C10.4722 12.6455 10.2409 12.7414 9.99971 12.7414C9.75854 12.7415 9.52722 12.6457 9.35669 12.4752L6.62954 9.74802C6.27453 9.39302 6.27453 8.81744 6.62954 8.46243C6.98454 8.10743 7.56012 8.10743 7.91513 8.46243L9.99916 10.5465L18.4469 2.09031C18.8017 1.73512 19.3773 1.73484 19.7325 2.08966Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckCircle;
