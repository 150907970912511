import React, { useCallback, useEffect, useState } from 'react';
import cl from './Header.module.scss';
import { Avatar, Button, IconButton, MenuItem, Menu } from '@material-ui/core';
import Icon from 'ui/Icon';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useProfileAvatar } from 'hooks/profile/useProfileAvatar';
import { setActiveGarten } from 'state/garten';
import { setActiveClub } from 'state/club';
import notificationImg from 'assets/img/ic_notification.gif';
import { Alert } from 'ui/dialogs/Alert';
import { $notification, getNotificationFx } from 'state/profile';
import { useStore } from 'effector-react';

interface Props {}

const PATH_CLUBS = '/clubs';
const PATH_SCHOOL = '/schools';
const PATH_GARTENS = '/gartens';

const PATH_HOME = '/home';

const Header: React.FC<Props> = () => {
  const { pathname } = useLocation();
  let avatar = useProfileAvatar();
  const history = useHistory();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [isOpenAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    getNotificationFx();
  }, []);

  const notification = useStore($notification);

  const handleClickClub = useCallback(() => {
    history.push(PATH_CLUBS);
    setMenuAnchorEl(null);
  }, [history]);

  const handleClickHome = useCallback(() => {
    history.push(PATH_HOME);
    setMenuAnchorEl(null);
    setActiveClub(-1);
  }, [history]);

  const handleClickSchool = useCallback(() => {
    history.push(PATH_SCHOOL);
    setMenuAnchorEl(null);
  }, [history]);

  const handleClickGartens = useCallback(() => {
    history.push(PATH_GARTENS);
    setMenuAnchorEl(null);
    setActiveGarten(-1);
  }, [history]);

  const handleClickBtn = () => {
    setOpenAlert(false);
    history.push('/profile/history/clubs');
  };

  const handleClickMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const pending = useStore(getNotificationFx.pending);

  return (
    <header className={cl.header}>
      <div className={cl.content}>
        <Link to="/">
          <Icon type={'Logo'} className={cl.logo} />
          <Icon type={'LogoSmall'} className={cl.logoForMobile} />
        </Link>
        <div className={cl.menu}>
          <Button
            className={cl.club + ' ' + (pathname.indexOf(PATH_HOME) === 0 && cl.active)}
            onClick={handleClickHome}
          >
            Главная
          </Button>
        </div>

        {!!notification.data && !pending && (
          <IconButton onClick={() => setOpenAlert(true)} className={cl.notification}>
            {' '}
            <img alt="" src={notificationImg} />
          </IconButton>
        )}

        <div>
          <Link to="/profile" className={cl.profile}>
            <Avatar className={cl.avatar} src={avatar}>
              <Icon type="NoPhoto" />
            </Avatar>
            <span>Личный кабинет</span>
          </Link>
        </div>
        <IconButton onClick={handleClickMenu} className={cl.menuBtn}>
          <Icon type="Menu" />
        </IconButton>
        <Menu open={!!menuAnchorEl} anchorEl={menuAnchorEl} onClose={setMenuAnchorEl.bind(null, null)} keepMounted>
          <MenuItem selected={pathname.indexOf(PATH_CLUBS) === 0} onClick={handleClickClub}>
            Запись в кружок
          </MenuItem>
          <MenuItem selected={pathname.indexOf(PATH_SCHOOL) === 0} onClick={handleClickSchool}>
            Подача документов в школу
          </MenuItem>
          <MenuItem selected={pathname.indexOf(PATH_GARTENS) === 0} onClick={handleClickGartens}>
            Подача документов в сад
          </MenuItem>
        </Menu>
      </div>
      <Alert isBtnNeed={false} open={isOpenAlert} type="default" onClickClose={() => setOpenAlert(false)}>
        <div className={cl.important}>Важно!</div>
        <div className={cl.text}>
          В истории записи в кружок есть акты <br /> для подписания.
        </div>
        <Button className={cl.btnNext} onClick={() => handleClickBtn()} size="large">
          Перейти
        </Button>
        <Button onClick={() => setOpenAlert(false)} className={cl.btnClose} variant="text">
          Отмена
        </Button>
      </Alert>
    </header>
  );
};

export default Header;
