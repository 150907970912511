import { ProfileRequisites, ProfileRequisitesForm } from 'api/types/profile';
import { PageContent } from 'components/layout';
import { toISODate, toLocalDate } from 'constants/date/format';
import { useProfile } from 'hooks/profile';
import React, { useCallback, useEffect, useState } from 'react';
import { RequisitesPanel } from './RequisitesPanel';

interface Props {}

interface Form extends ProfileRequisitesForm {}

const initForm = (data?: ProfileRequisites): Form => ({
  passport_number: data?.passport_number || '',
  passport_issued_by: data?.passport_issued_by || '',
  passport_issued_date: toLocalDate(data?.passport_issued_date || ''),
  address: data?.address || '',
  actual_address: data?.actual_address || '',
});

const Requisites: React.FC<Props> = () => {
  const {
    profile: { requisites },
  } = useProfile();

  useEffect(() => {
    if (requisites) setForm(initForm(requisites));
  }, [requisites]);

  const [editMode, setEditMode] = useState(false);
  const [form, setForm] = useState<Form>(initForm(requisites));
  const [errors, setErrors] = useState<Partial<ProfileRequisitesForm>>({});

  const handleClickCancel = useCallback(() => {
    setEditMode(false);

    setErrors({});
    setForm(initForm(requisites));
  }, [requisites]);

  const setEditModeForMobile = useCallback(() => {
    if (
      (form.address !== requisites?.address && requisites?.address !== null) ||
      (form.address !== '' && requisites?.address === null) ||
      (form.actual_address !== '' && requisites?.actual_address === null) ||
      (form.passport_issued_by !== requisites?.passport_issued_by && requisites?.passport_issued_by !== null) ||
      (form.passport_issued_by !== '' && requisites?.passport_issued_by === null) ||
      (toISODate(form.passport_issued_date) !== requisites?.passport_issued_date &&
        requisites?.passport_issued_date !== null) ||
      (toISODate(form.passport_issued_date) !== '' && requisites?.passport_issued_date === null) ||
      (form.passport_number !== requisites?.passport_number && requisites?.passport_number !== null) ||
      (form.passport_number !== '' && requisites?.passport_number === null)
    ) {
      return true;
    } else return false;
  }, [form, requisites]);

  return (
    <PageContent handleClickCancel={handleClickCancel} editMode={setEditModeForMobile()} titleSM="Реквизиты">
      <RequisitesPanel
        handleClickCancel={handleClickCancel}
        setEditModeForMobile={setEditModeForMobile()}
        setErrors={setErrors}
        setEditMode={setEditMode}
        setForm={setForm}
        errors={errors}
        editMode={editMode}
        form={form}
        requisites={requisites}
      />
    </PageContent>
  );
};
export default Requisites;
