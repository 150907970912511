import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Step } from 'ui/edits/Step';
import { Panel } from 'ui/panels/Panel';
import cl from './Step5.module.scss';
import { prevStep, resetSteps, $documentSign, sendSignCodeFx, step1SetProfileRep } from '../../model';
import { Alert, showAlert, showErrorAlert } from 'ui/dialogs/Alert';
import { useHistory } from 'react-router-dom';
import { ApiUnprocessableError } from 'api/errors';
import { Backdrop } from 'ui/feedback/Backdrop';
import { CodeDialog } from 'ui/dialogs/CodeDialog';
import { useStore } from 'effector-react';
import api from 'api/request/documents';

interface Props {}

export const Step5: React.FC<Props> = () => {
  const history = useHistory();

  const {
    data: { contract, id },
  } = useStore($documentSign);

  const refIframe = useRef<HTMLIFrameElement>(null);
  const refWrapper = useRef<HTMLDivElement>(null);

  const [openCodeDialog, setOpenCodeDialog] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFirstCodeError, setIsFirstCodeError] = useState(false);

  useEffect(() => {
    const { current: iFrame } = refIframe;
    if (!iFrame || !iFrame.contentDocument || !contract) {
      return;
    }
    iFrame.contentDocument.write(contract);
    iFrame.contentDocument.write(
      '<style>html{overflow-x: hidden;scrollbar-color: #f5f5f5;scrollbar-width: thin;} *::-webkit-scrollbar{width: 6px;height: 6px;background-color: #f5f5f5;border-radius: 3px;} *::-webkit-scrollbar-thumb {background-color: #b0b4b8;border-radius: 3px;}</style>',
    );
    iFrame.contentDocument.close();
  }, [refIframe, contract]);

  const handleNext = useCallback(async () => {
    setLoading(true);
    try {
      await api.approve(id!);
      setOpenCodeDialog(true);
      setLoading(false);
      step1SetProfileRep(undefined);
    } catch (e) {
      setLoading(false);
      if (e instanceof ApiUnprocessableError)
        await showAlert(
          'default',
          'Код с предыдущей операции еще активен. Просьба повторить подписание через 1 минуту.',
          'Ошибка',
        );
      else
        await showAlert(
          'error',
          'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
          'Ошибка',
        );
    }
  }, [id]);

  const handleClickCodeNext = useCallback(
    async (code: string) => {
      setLoading(true);
      try {
        await sendSignCodeFx({ id: id!, code });
        setOpenAlertSuccess(true);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e instanceof ApiUnprocessableError) {
          setIsFirstCodeError(true);
          const { code } = e.data.errors;
          if (!code) {
            await showErrorAlert();
            return;
          }
          if ((code as string[]).includes('Code expired'))
            await showAlert('error', 'Время жизни отправленного кода истекло. Запросите код повторно.', 'Ошибка');
          else await showAlert('error', 'Проверьте правильность введенного кода', 'Неверный код');
        } else {
          await showAlert(
            'error',
            'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
            'Ошибка',
          );
        }
      }
    },
    [id],
  );

  const handleCloseCodeDialog = useCallback(() => {
    setOpenCodeDialog(false);
  }, []);

  const handleSuccess = useCallback(() => {
    setOpenAlertSuccess(false);
    resetSteps();
    history.push('/documents');
  }, [history]);

  const handlePrevStep = useCallback(() => {
    prevStep();
  }, []);

  return (
    <Step title="Подписание документа" prevStep={handlePrevStep} onClickNext={handleNext}>
      <Backdrop open={loading} />
      <div className={cl.wrapper} ref={refWrapper}>
        <Panel classname={cl.page}>
          <iframe ref={refIframe} title="doc" /*style={{ height: docHeight }}*/ />
        </Panel>
      </div>
      <CodeDialog
        open={openCodeDialog}
        onClickNext={handleClickCodeNext}
        onClickClose={handleCloseCodeDialog}
        loading={loading}
        onClickRequest={handleNext}
        firstCodeError={isFirstCodeError}
      />
      <Alert open={openAlertSuccess} type="success" title="Документ отправлен специалисту!" onClickClose={handleSuccess}>
        После успешной обработки документа, Ваша копия будет доступна в личном кабинете и отправлена на Вашу электронную почту.
        <br />
      </Alert>
      <Alert
        open={openAlertError}
        type="error"
        title="Ошибка при записи!"
        onClickClose={setOpenAlertError.bind(null, false)}
      >
        Проверьте правильность заполнения всех полей и попытайтесь снова.
      </Alert>
    </Step>
  );
};
