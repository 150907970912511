import { Backdrop } from 'ui/feedback/Backdrop';
import { ApiUnprocessableError } from 'api/errors';
import { useStore } from 'effector-react';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { showAlert, showErrorAlert } from 'ui/dialogs/Alert';
import { Step } from 'ui/edits/Step';
import { TextField } from 'ui/input/TextField';
import { DateMask } from 'ui/masks';
import { dateValidator, maxValidator } from 'utils/validators';
import { $step4, documentSignFx, nextStep, prevStep, step4Set } from '../../model';
import cl from './StepForSchool4.module.scss';

interface ErrorsType {
  move_from_class: undefined | string;
  move_from_corpus: undefined | string;
  move_from_date: undefined | string;
  move_to_class: undefined | string;
  move_to_corpus: undefined | string;
}

const Step4 = () => {
  const [isFetching, setFetching] = useState(false);

  const [errors, setErrors] = useState<ErrorsType>({
    move_from_class: undefined,
    move_from_corpus: undefined,
    move_from_date: undefined,
    move_to_class: undefined,
    move_to_corpus: undefined,
  });
  const step4 = useStore($step4);

  const handleNext = useCallback(async () => {
    if (dateValidator(step4.move_from_date, true, false) || maxValidator(step4.move_from_class, 32, true) || maxValidator(step4.move_from_corpus, 64, true) || maxValidator(step4.move_to_class, 32, true) || maxValidator(step4.move_to_corpus, 64, true)) {
        setErrors((state) => ({ ...state, move_from_date: dateValidator(step4.move_from_date, true, false) }));
        setErrors((state) => ({ ...state, move_from_class: maxValidator(step4.move_from_class, 32, true) }));
        setErrors((state) => ({ ...state, move_from_corpus: maxValidator(step4.move_from_corpus, 64, true) }));
        setErrors((state) => ({ ...state, move_to_class: maxValidator(step4.move_to_class, 32, true) }));
        setErrors((state) => ({ ...state, move_to_corpus: maxValidator(step4.move_to_corpus, 64, true) })); 
    } else {
      try {
        setFetching(true);
        await documentSignFx();
        setFetching(false);
        nextStep();
      } catch (e) {
        setFetching(false);
        if (e instanceof ApiUnprocessableError) await showErrorAlert();
        else
          await showAlert(
            'error',
            'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
            'Ошибка',
          );
      }
    }
  }, [step4]);
  const handlePrevStep = useCallback(() => {
    prevStep();
  }, []);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    step4Set({ ...step4, [name]: value });
  };


  return (
    <Step
      title={'Перевод в другой класс'}
      disabledNext={!step4.move_from_class || !step4.move_from_corpus || !step4.move_from_date || !step4.move_to_class || !step4.move_to_corpus}
      onClickNext={handleNext}
      prevStep={handlePrevStep}
    >
      <Backdrop open={isFetching} />
        <div className={cl.container}>
          <TextField
            label="Перевод из класса"
            fullWidth
            name="move_from_class"
            value={step4.move_from_class}
            onChange={onChange}
            error={!!errors.move_from_class}
            helperText={errors.move_from_class}
          />
          <TextField
            label="Перевод из корпуса"
            fullWidth
            name="move_from_corpus"
            value={step4.move_from_corpus}
            onChange={onChange}
            error={!!errors.move_from_corpus}
            helperText={errors.move_from_corpus}
          />
          <TextField
            label="Дата перевода"
            fullWidth
            name="move_from_date"
            InputProps={{ inputComponent: DateMask }}
            value={step4.move_from_date}
            onChange={onChange}
            error={!!errors.move_from_date}
            helperText={errors.move_from_date}
          />
          <TextField
            label="Перевод в класс"
            fullWidth
            name="move_to_class"
            value={step4.move_to_class}
            onChange={onChange}
            error={!!errors.move_to_class}
            helperText={errors.move_to_class}
          />
          <TextField
            label="Перевод в корпус"
            fullWidth
            name="move_to_corpus"
            value={step4.move_to_corpus}
            onChange={onChange}
            error={!!errors.move_to_corpus}
            helperText={errors.move_to_corpus}
          />
        </div>
    </Step>
  );
};

export default Step4;
