import React from 'react';
import cl from './Page.module.scss';
import { RouteComponentProps } from 'react-router';
import { Button } from 'ui/button/Button';

interface Props extends Partial<RouteComponentProps> {}

export const Page: React.FC<Props> = ({ history }) => {
  return (
    <div className={cl.root}>
      <h1>Политика конфиденциальности</h1>

      <p>
        ГБОУ «Курчатовская школа», ОГРН 1127746767158, ИНН 7734687492 обязуется соблюдать конфиденциальность и
        безопасность Вашей личной информации. Данная политика конфиденциальности в Интернете («Политика
        конфиденциальности») описывает информацию, которую ГБОУ «Курчатовская школа» собирает, когда Вы получаете доступ
        или используете мобильные и веб-приложения под названием «Школа online» (далее "Приложение") или онлайн и офлайн
        услуги, которые ГБОУ «Курчатовская школа» предоставляет в связи с использованием приложения (далее «Сервисы»).
        При загрузке, доступе или с помощью приложения, или иным образом с использованием Услуг, Вы соглашаетесь с
        настоящей Политикой конфиденциальности.
      </p>
      <p>
        ГБОУ «Курчатовская школа» может периодически обновлять настоящую политику конфиденциальности, чтобы отразить
        существенные изменения в том, как мы собираем, используем или храним Вашу личную или иную информацию. Мы
        рекомендуем вам следить за обновлениями данной политики конфиденциальности на постоянной основе, чтобы быть в
        курсе изменений. Вы соглашаетесь на любые изменения, которые мы вносим в данную политику конфиденциальности,
        если Вы по-прежнему осуществляете доступ или используете приложение, или вебсайт, после получения уведомления об
        изменении или после нашего размещения новой политики конфиденциальности на ресурсах ГБОУ «Курчатовская школа».
      </p>
      <p>
        Используемый в настоящей политике конфиденциальности термин "личная информация" означает информацию, которая
        позволила идентифицировать Вас, в том числе, например, Ваше полное имя, адрес, номер телефона и адрес
        электронной почты.
      </p>

      <div className={cl.title}>Сбор информации</div>
      <p>Ниже перечислены типы собираемой информации:</p>
      <p>
        • Личная информация о профиле: чтобы воспользоваться сервисами и позволить нам связаться с вами в целях,
        описанных ниже, вам необходимо создать учетную запись, указав номер телефона, или войти в приложение, используя
        ваш аккаунт mos.ru.
      </p>
      <p>Ваш номер телефона никогда не будет отображаться другим пользователям Сервиса.</p>
      <p>
        • Другие. Возможно также использование изображений или других данных, идентификаторы для сбора статистической
        информации.
      </p>
      <p>
        Использование Вашей личной информации возможно только как описано в настоящей Политике конфиденциальности и
        Политике обработки персональных данных.
      </p>
      <div className={cl.title}>Защита информации</div>
      <p>
        ГБОУ «Курчатовская школа» принимает разумные меры, чтобы помочь защитить Вашу личную информацию в целях
        предотвращения потери, неправильного использования, несанкционированного доступа, раскрытия, изменения и
        уничтожения. ГБОУ «Курчатовская школа» следует общепринятым стандартам для защиты личной информации, как во
        время передачи, так и при получении. Тем не менее, ни один из методов передачи данных через Интернет, или метод
        электронного хранения, не являются безопасным, и ГБОУ «Курчатовская школа» не может гарантировать абсолютную
        безопасность.
      </p>

      <div className={cl.title}>Жалобы</div>
      <p>
        Если у Вас есть жалобы о нарушении конфиденциальности данных, пожалуйста, свяжитесь с ГБОУ «Курчатовская школа»
        по электронной почте по адресу: kurchat@edu.mos.ru
      </p>
      {!!history && (
        <div className={cl.footer}>
          <Button variant="text" onClick={history.goBack}>
            Закрыть
          </Button>
        </div>
      )}
    </div>
  );
};
