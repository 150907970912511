import React, { useCallback, useEffect, useState } from 'react';
import { Step } from 'ui/edits/Step';
import cl from './Step3.module.scss';
import { $step3, nextStep, prevStep, step3Set } from '../../model';
import { useStore } from 'effector-react';
import { showAlert } from 'ui/dialogs/Alert';
import { UPLOAD_FILE_SIZE } from 'constants/config';
import { Backdrop } from 'ui/feedback/Backdrop';
import { FileLoadPanel } from 'ui/panels/FileLoadPanel';
import { handleFiles } from 'utils/helpers';

interface Props {}

interface DataType {
  id: number;
  name: string;
}

const facilities: DataType[] = [
  {
    id: 1,
    name: 'Сироты и дети, оставшиеся без попечения родителей',
  },
  {
    id: 2,
    name: 'Дети-инвалиды',
  },
  {
    id: 3,
    name: 'Дети из многодетных семей',
  },
];

export const Step3: React.FC<Props> = () => {
  const [isFetching, setFetching] = useState(false);

  const step3Form = useStore($step3);

  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (step3Form.files.length >= 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [step3Form.files.length]);

  const handlePrevStep = useCallback(() => {
    prevStep();
  }, []);

  const handleRemoveFile = useCallback(
    (typeOfFile: string) => {
      let files = step3Form.files;
      files = files.filter((element) => element.name !== typeOfFile);
      step3Set({
        ...step3Form,
        files,
      });
    },
    [step3Form],
  );

  const handleSizeError = useCallback(() => {
    showAlert(
      'error',
      `Максимальный размер файлов для загрузки составляет ${(UPLOAD_FILE_SIZE / 1024 / 1024).toFixed(
        1,
      )}Mb. Файлы большего размера не будут загружены.`,
      'Ошибка',
    );
  }, []);

  const handleNext = useCallback(() => {
    nextStep();
  }, []);

  return (
    <Step
      disabledNext={step3Form.files.length > 0 ? false : true}
      title={'Льготы'}
      onClickNext={handleNext}
      prevStep={handlePrevStep}
    >
      <Backdrop open={isFetching} />
      <>
        <div className={cl.subtitle}>Выбор основания льготы</div>
        <div className={cl.files}>
          {facilities.map((element) => (
            <div key={element.id} className={cl.file}>
              <FileLoadPanel
                data={step3Form.files}
                handleRemoveFile={handleRemoveFile}
                name={element.name}
                onClick={handleFiles.bind(null, setFetching, step3Form, step3Set)}
                onSizeError={handleSizeError}
                disabled={isDisabled}
              />
            </div>
          ))}
        </div>
      </>
    </Step>
  );
};
