import React, { useMemo } from 'react';
import { PanelMini } from 'ui/panels/PanelMini';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import cl from './AddressPanel.module.scss';
import { YANDEX_MAP_API_KEY } from 'constants/config';
import { Address } from 'api/types/common';
import cn from 'classnames';

interface Props {
  address: Address;
  className?: string;
}

export const AddressPanel: React.FC<Props> = ({ address, className }) => {
  const mapState = useMemo(
    () => ({
      center: [address.latitude, address.longitude],
      zoom: 16,
      controls: ['zoomControl', 'fullscreenControl'],
    }),
    [address],
  );

  return (
    <PanelMini title="Адрес">
      {address.text_address}
      <div className={cn(cl.mapWrapper, className)}>
        <YMaps preload query={{ apikey: YANDEX_MAP_API_KEY }}>
          <Map state={mapState} className={cl.map} modules={['control.ZoomControl', 'control.FullscreenControl']}>
            <Placemark geometry={[address.latitude, address.longitude]} />
          </Map>
        </YMaps>
      </div>
    </PanelMini>
  );
};
