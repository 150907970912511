import React from 'react';

const Edit: React.FC = () => {
  return (
    <svg width="342" height="255" viewBox="0 0 342 255" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M342 224.094H0V224.265H342V224.094Z" fill="#EBEBEB" />
      <path
        d="M162.108 193.572H30.0412C29.0054 193.572 28.012 193.16 27.2795 192.427C26.5471 191.694 26.1356 190.7 26.1356 189.664V3.90771C26.1356 2.87132 26.5471 1.87738 27.2795 1.14454C28.012 0.411703 29.0054 0 30.0412 0L162.108 0C163.144 0 164.137 0.411703 164.87 1.14454C165.602 1.87738 166.014 2.87132 166.014 3.90771V189.664C166.014 190.177 165.913 190.685 165.716 191.159C165.52 191.634 165.232 192.064 164.87 192.427C164.507 192.79 164.076 193.078 163.603 193.274C163.129 193.471 162.621 193.572 162.108 193.572ZM30.0412 0.17109C29.0513 0.172899 28.1024 0.567158 27.4024 1.26752C26.7025 1.96788 26.3084 2.91725 26.3066 3.90771V189.664C26.3084 190.654 26.7025 191.604 27.4024 192.304C28.1024 193.005 29.0513 193.399 30.0412 193.401H162.108C163.098 193.399 164.047 193.005 164.747 192.304C165.447 191.604 165.841 190.654 165.843 189.664V3.90771C165.841 2.91725 165.447 1.96788 164.747 1.26752C164.047 0.567158 163.098 0.172899 162.108 0.17109H30.0412Z"
        fill="#EBEBEB"
      />
      <path
        d="M310.064 193.572H177.983C176.948 193.57 175.956 193.158 175.224 192.425C174.492 191.693 174.08 190.7 174.078 189.664V3.90771C174.08 2.87187 174.492 1.87898 175.224 1.14654C175.956 0.414094 176.948 0.00180932 177.983 0L310.064 0C311.097 0.00362179 312.087 0.416924 312.817 1.14937C313.546 1.88181 313.956 2.87368 313.956 3.90771V189.664C313.956 190.698 313.546 191.69 312.817 192.422C312.087 193.155 311.097 193.568 310.064 193.572ZM177.983 0.17109C176.994 0.172899 176.045 0.567158 175.345 1.26752C174.645 1.96788 174.251 2.91725 174.249 3.90771V189.664C174.251 190.654 174.645 191.604 175.345 192.304C176.045 193.005 176.994 193.399 177.983 193.401H310.064C311.054 193.399 312.003 193.005 312.703 192.304C313.403 191.604 313.797 190.654 313.799 189.664V3.90771C313.797 2.91725 313.403 1.96788 312.703 1.26752C312.003 0.567158 311.054 0.172899 310.064 0.17109H177.983Z"
        fill="#EBEBEB"
      />
      <path d="M52.8602 230.609H35.0762V230.78H52.8602V230.609Z" fill="#EBEBEB" />
      <path d="M130.083 228.755H99.2825V228.926H130.083V228.755Z" fill="#EBEBEB" />
      <path d="M95.1243 236.967H53.544V237.138H95.1243V236.967Z" fill="#EBEBEB" />
      <path d="M306.925 235.817H293.771V235.988H306.925V235.817Z" fill="#EBEBEB" />
      <path d="M287.676 235.817H249.858V235.988H287.676V235.817Z" fill="#EBEBEB" />
      <path d="M226.76 232.923H175.822V233.094H226.76V232.923Z" fill="#EBEBEB" />
      <path d="M116.034 164.733H78.7012L76.4371 138.576H113.77L116.034 164.733Z" fill="#E6E6E6" />
      <path d="M117.969 164.733H80.6367L78.3658 138.576H115.698L117.969 164.733Z" fill="#F0F0F0" />
      <path d="M85.7393 159.121L84.4944 144.195H110.596L111.841 159.121H85.7393Z" fill="white" />
      <path d="M139.953 164.733H134.625V224.087H139.953V164.733Z" fill="#E6E6E6" />
      <path d="M141.629 164.733H137.929V224.087H141.629V164.733Z" fill="#F0F0F0" />
      <path d="M90.0488 171.289H141.629V164.739H90.0488V171.289Z" fill="#F0F0F0" />
      <path d="M91.8754 171.289H86.547V224.094H91.8754V171.289Z" fill="#E6E6E6" />
      <path d="M93.5572 171.289H89.8568V224.094H93.5572V171.289Z" fill="#F0F0F0" />
      <path d="M53.9403 171.289H48.612V224.094H53.9403V171.289Z" fill="#E6E6E6" />
      <path d="M55.6232 171.289H51.9227V224.094H55.6232V171.289Z" fill="#F0F0F0" />
      <path d="M106.225 171.289H100.897V224.094H106.225V171.289Z" fill="#E6E6E6" />
      <path d="M107.908 171.289H104.208V224.094H107.908V171.289Z" fill="#F0F0F0" />
      <path d="M90.0487 164.733H48.612V171.282H90.0487V164.733Z" fill="#E6E6E6" />
      <path d="M201.944 18.7925H47.2026V107.609H201.944V18.7925Z" fill="#E6E6E6" />
      <path d="M206.076 18.7925H49.1322V107.609H206.076V18.7925Z" fill="#F0F0F0" />
      <path d="M201.944 107.609H47.2026V115.301H201.944V107.609Z" fill="#E6E6E6" />
      <path d="M212.964 107.609H56.0202V115.301H212.964V107.609Z" fill="#F0F0F0" />
      <path d="M200.289 101.819V24.5891L54.925 24.5891V101.819L200.289 101.819Z" fill="#FAFAFA" />
      <path d="M65.603 101.819L99.5841 24.5891H115.631L81.6428 101.819H65.603Z" fill="white" />
      <path
        d="M57.6074 96.0502C57.5529 96.0511 57.4988 96.0411 57.4482 96.0209C57.3976 96.0007 57.3515 95.9706 57.3126 95.9324C57.2738 95.8941 57.2429 95.8486 57.2219 95.7983C57.2008 95.748 57.19 95.6941 57.19 95.6396V28.36C57.1824 28.3011 57.1874 28.2413 57.2048 28.1846C57.2221 28.1279 57.2514 28.0755 57.2905 28.0309C57.3297 27.9864 57.3779 27.9507 57.432 27.9263C57.486 27.9018 57.5447 27.8892 57.604 27.8892C57.6633 27.8892 57.722 27.9018 57.776 27.9263C57.8301 27.9507 57.8783 27.9864 57.9175 28.0309C57.9567 28.0755 57.9859 28.1279 58.0032 28.1846C58.0206 28.2413 58.0256 28.3011 58.0181 28.36V95.6396C58.0181 95.7485 57.9748 95.8529 57.8978 95.9299C57.8208 96.0069 57.7163 96.0502 57.6074 96.0502Z"
        fill="#F0F0F0"
      />
      <path d="M85.0898 101.819L119.078 24.5891H125.329L91.3484 101.819H85.0898Z" fill="white" />
      <path d="M200.282 101.819V24.5891H199.543V101.819H200.282Z" fill="#E6E6E6" />
      <path opacity="0.6" d="M207.444 33.3215H52.7022L52.1755 26.8406H206.91L207.444 33.3215Z" fill="#EBEBEB" />
      <path opacity="0.6" d="M207.444 43.9565H52.7022L52.1755 37.4756H206.91L207.444 43.9565Z" fill="#EBEBEB" />
      <path opacity="0.6" d="M207.444 54.5915H52.7022L52.1755 48.1106H206.91L207.444 54.5915Z" fill="#EBEBEB" />
      <path opacity="0.6" d="M207.444 65.2265H52.7022L52.1755 58.7388H206.91L207.444 65.2265Z" fill="#EBEBEB" />
      <path opacity="0.6" d="M207.444 75.8547H52.7022L52.1755 69.3738H206.91L207.444 75.8547Z" fill="#EBEBEB" />
      <path opacity="0.6" d="M207.444 86.4897H52.7022L52.1755 80.0088H206.91L207.444 86.4897Z" fill="#EBEBEB" />
      <path d="M249.947 60.4907L291.616 60.4907V18.7994L249.947 18.7994V60.4907Z" fill="#EBEBEB" />
      <path d="M249.948 18.7925H248.1V60.4838H249.948V18.7925Z" fill="#E0E0E0" />
      <path d="M255.597 54.8379L285.974 54.8379V24.4454L255.597 24.4454V54.8379Z" fill="white" />
      <path d="M270.785 49.8128L280.952 39.6409L270.785 29.4689L260.619 39.6409L270.785 49.8128Z" fill="#F5F5F5" />
      <path d="M235.589 115.301H277.259V73.61H235.589V115.301Z" fill="#EBEBEB" />
      <path d="M235.591 73.6099H233.744V115.301H235.591V73.6099Z" fill="#E0E0E0" />
      <path d="M241.24 109.648H271.616V79.2559H241.24V109.648Z" fill="white" />
      <path
        d="M254.263 100.937H258.586C260.97 100.937 262.902 99.0031 262.902 96.6182V92.293C262.902 89.9081 260.97 87.9747 258.586 87.9747H254.263C251.879 87.9747 249.947 89.9081 249.947 92.293V96.6182C249.947 99.0031 251.879 100.937 254.263 100.937Z"
        fill="#F5F5F5"
      />
      <path d="M210.48 135.558H200.09V221.295H210.48V135.558Z" fill="#F0F0F0" />
      <path d="M301.301 221.295H201.533V224.094H301.301V221.295Z" fill="#F0F0F0" />
      <path d="M210.48 221.295H302.738V135.558H210.48V221.295Z" fill="#FAFAFA" />
      <path
        d="M284.681 185.661H228.545C225.894 185.661 223.352 184.607 221.478 182.732C219.604 180.857 218.551 178.314 218.551 175.662C218.551 173.01 219.604 170.467 221.478 168.592C223.352 166.717 225.894 165.664 228.545 165.664H284.681C287.331 165.664 289.873 166.717 291.747 168.592C293.621 170.467 294.674 173.01 294.674 175.662C294.674 178.314 293.621 180.857 291.747 182.732C289.873 184.607 287.331 185.661 284.681 185.661ZM228.545 166.334C227.3 166.3 226.06 166.517 224.9 166.97C223.74 167.424 222.682 168.105 221.79 168.974C220.897 169.843 220.188 170.883 219.703 172.031C219.219 173.179 218.969 174.412 218.969 175.659C218.969 176.905 219.219 178.138 219.703 179.287C220.188 180.435 220.897 181.474 221.79 182.343C222.682 183.212 223.74 183.894 224.9 184.347C226.06 184.801 227.3 185.017 228.545 184.983H284.681C285.926 185.017 287.165 184.801 288.325 184.347C289.485 183.894 290.543 183.212 291.435 182.343C292.328 181.474 293.038 180.435 293.522 179.287C294.006 178.138 294.256 176.905 294.256 175.659C294.256 174.412 294.006 173.179 293.522 172.031C293.038 170.883 292.328 169.843 291.435 168.974C290.543 168.105 289.485 167.424 288.325 166.97C287.165 166.517 285.926 166.3 284.681 166.334H228.545Z"
        fill="#F0F0F0"
      />
      <path
        d="M284.681 210.53H228.545C225.894 210.53 223.352 209.477 221.478 207.602C219.604 205.727 218.551 203.183 218.551 200.532C218.551 197.88 219.604 195.337 221.478 193.462C223.352 191.587 225.894 190.533 228.545 190.533H284.681C287.331 190.533 289.873 191.587 291.747 193.462C293.621 195.337 294.674 197.88 294.674 200.532C294.674 203.183 293.621 205.727 291.747 207.602C289.873 209.477 287.331 210.53 284.681 210.53ZM228.545 191.204C226.072 191.204 223.701 192.187 221.952 193.936C220.204 195.685 219.222 198.058 219.222 200.532C219.222 203.006 220.204 205.378 221.952 207.128C223.701 208.877 226.072 209.86 228.545 209.86H284.681C287.153 209.86 289.524 208.877 291.273 207.128C293.021 205.378 294.003 203.006 294.003 200.532C294.003 198.058 293.021 195.685 291.273 193.936C289.524 192.187 287.153 191.204 284.681 191.204H228.545Z"
        fill="#F0F0F0"
      />
      <path
        d="M265.084 173.506H248.142C247.595 173.495 247.074 173.271 246.69 172.881C246.307 172.491 246.091 171.966 246.09 171.419C246.1 170.878 246.32 170.361 246.702 169.979C247.085 169.596 247.601 169.376 248.142 169.366H265.084C265.631 169.367 266.156 169.583 266.546 169.967C266.935 170.351 267.16 170.872 267.17 171.419C267.167 171.971 266.946 172.5 266.555 172.891C266.165 173.281 265.636 173.503 265.084 173.506Z"
        fill="#EBEBEB"
      />
      <path
        d="M284.681 160.784H228.545C225.894 160.784 223.352 159.73 221.478 157.855C219.604 155.98 218.551 153.437 218.551 150.785C218.551 148.134 219.604 145.59 221.478 143.715C223.352 141.84 225.894 140.787 228.545 140.787H284.681C287.331 140.787 289.873 141.84 291.747 143.715C293.621 145.59 294.674 148.134 294.674 150.785C294.674 153.437 293.621 155.98 291.747 157.855C289.873 159.73 287.331 160.784 284.681 160.784ZM228.545 141.458C227.32 141.458 226.108 141.699 224.977 142.168C223.846 142.636 222.818 143.323 221.952 144.19C221.087 145.056 220.4 146.084 219.931 147.216C219.463 148.347 219.222 149.56 219.222 150.785C219.222 152.01 219.463 153.223 219.931 154.355C220.4 155.487 221.087 156.515 221.952 157.381C222.818 158.247 223.846 158.934 224.977 159.403C226.108 159.872 227.32 160.113 228.545 160.113H284.681C287.153 160.113 289.524 159.13 291.273 157.381C293.021 155.632 294.003 153.259 294.003 150.785C294.003 148.311 293.021 145.939 291.273 144.19C289.524 142.44 287.153 141.458 284.681 141.458H228.545Z"
        fill="#F0F0F0"
      />
      <path
        d="M265.084 148.63H248.142C247.595 148.619 247.074 148.394 246.69 148.004C246.307 147.614 246.091 147.09 246.09 146.542C246.1 146.001 246.32 145.485 246.702 145.102C247.085 144.719 247.601 144.5 248.142 144.489H265.084C265.631 144.491 266.156 144.707 266.546 145.09C266.935 145.474 267.16 145.995 267.17 146.542C267.169 147.095 266.948 147.625 266.557 148.016C266.167 148.407 265.637 148.628 265.084 148.63Z"
        fill="#EBEBEB"
      />
      <path
        d="M265.084 198.376H248.142C247.595 198.365 247.074 198.141 246.69 197.751C246.307 197.361 246.091 196.836 246.09 196.289C246.098 195.747 246.317 195.23 246.7 194.847C247.083 194.464 247.6 194.244 248.142 194.236H265.084C265.631 194.237 266.156 194.453 266.546 194.837C266.935 195.22 267.16 195.742 267.17 196.289C267.169 196.842 266.948 197.372 266.557 197.763C266.167 198.154 265.637 198.374 265.084 198.376Z"
        fill="#EBEBEB"
      />
      <path d="M304.64 131.199H210.487V135.558H304.64V131.199Z" fill="#F0F0F0" />
      <path d="M198.052 135.565H210.48V131.206H198.052V135.565Z" fill="#E6E6E6" />
      <path
        d="M171 255C244.244 255 303.621 251.532 303.621 247.253C303.621 242.975 244.244 239.506 171 239.506C97.7555 239.506 38.3791 242.975 38.3791 247.253C38.3791 251.532 97.7555 255 171 255Z"
        fill="#F5F5F5"
      />
      <path
        d="M282.93 63.8374H179.837C179.837 63.8374 161.267 80.9464 161.267 122.542C161.267 158.314 175.35 206.814 170.063 226.668C166.075 241.648 146.738 244.221 137.47 244.577H235.597C235.597 244.577 267.868 246.528 273.155 226.668C278.443 206.807 264.359 158.314 264.359 122.542C264.359 80.9191 282.93 63.8374 282.93 63.8374Z"
        fill="#69488D"
      />
      <path
        opacity="0.7"
        d="M282.93 63.8374H179.837C179.837 63.8374 161.267 80.9464 161.267 122.542C161.267 158.314 175.35 206.814 170.063 226.668C166.075 241.648 146.738 244.221 137.47 244.577H235.597C235.597 244.577 267.868 246.528 273.155 226.668C278.443 206.807 264.359 158.314 264.359 122.542C264.359 80.9191 282.93 63.8374 282.93 63.8374Z"
        fill="white"
      />
      <g opacity="0.8">
        <path
          opacity="0.8"
          d="M254.886 107.705H172.436C174.769 89.4049 181.055 78.7562 184.523 74.1025H264.024C260.125 82.2464 256.445 93.3399 254.886 107.705Z"
          fill="#69488D"
        />
        <path
          opacity="0.3"
          d="M254.195 117.409H171.629C171.697 115.746 171.793 114.131 171.93 112.57H254.448C254.334 114.145 254.249 115.757 254.195 117.409Z"
          fill="#69488D"
        />
        <path
          opacity="0.3"
          d="M197.518 127.106H171.601C171.556 125.56 171.531 124.038 171.526 122.542V122.275H197.45V122.542C197.45 124.047 197.47 125.573 197.518 127.106Z"
          fill="#69488D"
        />
        <path
          opacity="0.3"
          d="M254.687 136.804H172.183C172.039 135.175 171.923 133.56 171.834 131.972H254.372C254.455 133.574 254.559 135.184 254.687 136.804Z"
          fill="#69488D"
        />
        <path
          opacity="0.3"
          d="M255.597 146.508H173.162C172.97 144.884 172.792 143.271 172.628 141.67H255.132C255.248 143.278 255.419 144.893 255.597 146.508Z"
          fill="#69488D"
        />
        <path
          opacity="0.3"
          d="M256.788 156.205H174.421C174.195 154.59 173.983 152.975 173.778 151.374H256.172C256.364 152.98 256.569 154.59 256.788 156.205Z"
          fill="#69488D"
        />
        <path
          opacity="0.3"
          d="M258.148 165.903H175.787C175.55 164.288 175.322 162.677 175.103 161.071H257.457C257.683 162.68 257.915 164.295 258.148 165.903Z"
          fill="#69488D"
        />
        <path
          opacity="0.3"
          d="M259.605 175.607H177.272C177.142 174.724 177.005 173.828 176.868 172.931L176.54 170.769H258.873C259.119 172.384 259.359 173.999 259.605 175.607Z"
          fill="#69488D"
        />
        <path
          opacity="0.3"
          d="M178.708 185.304C178.48 183.708 178.252 182.097 178.024 180.473H260.343C260.583 182.088 260.829 183.696 261.061 185.304H178.708Z"
          fill="#69488D"
        />
        <path
          opacity="0.3"
          d="M180.015 195.002C179.823 193.421 179.611 191.806 179.392 190.17H248.066C248.298 191.799 248.517 193.414 248.75 195.002H180.015Z"
          fill="#69488D"
        />
        <path
          opacity="0.3"
          d="M181.028 204.706C180.895 203.123 180.745 201.51 180.576 199.868H263.012C263.196 201.524 263.367 203.139 263.511 204.706H181.028Z"
          fill="#69488D"
        />
        <path
          opacity="0.3"
          d="M181.5 214.403C181.5 212.843 181.438 211.228 181.349 209.572H224.845C224.954 211.262 225.016 212.877 225.036 214.403H181.5Z"
          fill="#69488D"
        />
      </g>
      <path
        d="M243.504 239.342C238.271 240.293 232.97 239.568 227.71 239.308C225.188 239.147 222.655 239.215 220.145 239.513C217.683 239.841 215.234 240.485 212.751 240.266C213.57 239.822 214.343 239.297 215.056 238.699C215.088 238.667 215.11 238.627 215.122 238.584C215.134 238.541 215.134 238.495 215.123 238.452C215.112 238.409 215.09 238.369 215.059 238.337C215.028 238.304 214.99 238.28 214.947 238.267C207.224 235.53 200.193 242.942 192.498 241.21C194.68 239.595 197.751 239.568 199.612 237.446C199.646 237.409 199.669 237.364 199.678 237.314C199.687 237.264 199.681 237.213 199.662 237.167C199.642 237.121 199.609 237.081 199.568 237.053C199.526 237.025 199.477 237.009 199.427 237.008C192.539 236.899 186.034 239.575 179.221 240.17C178.893 240.17 178.893 240.711 179.221 240.683C185.822 240.101 192.149 237.583 198.804 237.528C196.814 239.301 193.777 239.369 191.752 241.162C191.721 241.193 191.698 241.232 191.686 241.275C191.674 241.318 191.674 241.363 191.685 241.406C191.696 241.449 191.718 241.488 191.749 241.52C191.78 241.552 191.819 241.575 191.862 241.587C199.618 243.729 206.677 236.331 214.338 238.616C213.538 239.241 212.668 239.772 211.746 240.197C211.703 240.223 211.668 240.262 211.646 240.307C211.624 240.353 211.616 240.403 211.622 240.453C211.629 240.504 211.65 240.551 211.683 240.589C211.716 240.627 211.759 240.655 211.807 240.669C214.331 241.087 216.8 240.553 219.29 240.156C222.078 239.754 224.9 239.641 227.71 239.821C233.025 240.081 238.36 240.793 243.641 239.821C243.976 239.773 243.839 239.28 243.504 239.342Z"
        fill="#69488D"
      />
      <path
        d="M103.885 89.0901C104.316 89.6376 104.87 90.2946 105.377 90.8968C105.883 91.4991 106.437 92.1081 106.984 92.6967C108.085 93.8738 109.2 95.0304 110.404 96.1185C112.636 98.2392 115.172 100.015 117.928 101.388C118.263 101.532 118.612 101.676 118.92 101.792C119.071 101.857 119.226 101.912 119.385 101.956C119.533 102.008 119.684 102.051 119.836 102.086C120.493 102.252 121.164 102.357 121.84 102.401C123.355 102.468 124.872 102.381 126.369 102.141C127.957 101.898 129.531 101.565 131.081 101.142C132.655 100.731 134.262 100.218 135.76 99.6909L137.128 102.428C134.087 104.328 130.794 105.791 127.347 106.774C125.53 107.3 123.655 107.604 121.765 107.677C120.742 107.716 119.717 107.647 118.708 107.472C118.442 107.434 118.18 107.379 117.921 107.308C117.648 107.246 117.388 107.178 117.162 107.102C116.683 106.966 116.218 106.801 115.794 106.63C112.338 105.208 109.153 103.199 106.382 100.69C105.025 99.5132 103.73 98.2659 102.504 96.9534C101.895 96.269 101.3 95.6258 100.725 94.9482C100.151 94.2707 99.6036 93.5795 99.0085 92.7925L103.885 89.0901Z"
        fill="#FFB573"
      />
      <path
        d="M96.9293 87.1532C96.7788 90.9856 98.2973 97.8908 98.2973 97.8908L107.778 96.7411C107.778 96.7411 108.126 86.633 106.286 84.1899C103.174 80.07 97.073 83.5329 96.9293 87.1532Z"
        fill="#69488D"
      />
      <path
        d="M134.488 100.669L138.927 96.6042L139.495 100.984C139.495 100.984 137.655 103.448 135.569 102.414L134.488 100.669Z"
        fill="#FFB573"
      />
      <path d="M141.157 96.8438L143.038 99.3075L139.495 100.984L138.927 96.6042L141.157 96.8438Z" fill="#FFB573" />
      <path
        d="M140.035 85.7638C140.391 85.7431 140.747 85.7431 141.102 85.7638C141.246 85.7545 141.39 85.7545 141.533 85.7638L141.889 85.8117C142.196 85.8528 142.487 85.9705 142.737 86.1539C142.86 86.2949 142.941 86.4682 142.97 86.6535L143.127 87.1736L143.763 89.2267L145.035 93.3808L147.573 101.696C149.249 107.239 150.993 112.762 152.607 118.326C155.924 129.426 159.064 140.568 162.149 151.729C165.234 162.891 168.134 174.094 171.116 185.263C174.098 196.432 176.766 207.717 177.717 219.426C177.751 220.21 177.516 220.981 177.05 221.611C176.583 222.242 175.915 222.693 175.157 222.889C174.398 223.086 173.595 223.015 172.882 222.69C172.169 222.365 171.589 221.805 171.239 221.103C166.383 210.407 163.209 199.272 160.432 188.035C157.655 176.798 154.707 165.595 151.985 154.344C149.262 143.093 146.602 131.821 144.112 120.509C142.833 114.863 141.67 109.183 140.446 103.516L138.633 95.1259L137.723 90.876L137.272 88.7476L137.156 88.2207C137.094 88.0426 137.08 87.8516 137.114 87.6664C137.25 87.3963 137.446 87.1618 137.689 86.982L137.983 86.7698C138.094 86.6814 138.213 86.6035 138.339 86.5372C138.639 86.3465 138.95 86.1729 139.269 86.017C139.504 85.8803 139.765 85.7941 140.035 85.7638Z"
        fill="#263238"
      />
      <path
        d="M177.06 221.192C177.008 221.007 176.886 220.85 176.72 220.754C176.554 220.657 176.356 220.631 176.17 220.679L174.666 221.069L173.161 221.459C172.975 221.508 172.815 221.627 172.717 221.793C172.618 221.958 172.588 222.155 172.634 222.342C173.571 226.647 172.634 229.74 173.633 233.531C174.666 237.521 179.16 239.561 179.16 239.561L179.016 237.863L179.7 239.424C179.7 239.424 182.634 235.454 181.601 231.458C180.63 227.673 178.332 225.415 177.06 221.192Z"
        fill="#69488D"
      />
      <path
        opacity="0.3"
        d="M177.06 221.192C177.008 221.007 176.886 220.85 176.72 220.754C176.554 220.657 176.356 220.631 176.17 220.679L174.666 221.069L173.161 221.459C172.975 221.508 172.815 221.627 172.717 221.793C172.618 221.958 172.588 222.155 172.634 222.342C173.571 226.647 172.634 229.74 173.633 233.531C174.666 237.521 179.16 239.561 179.16 239.561L179.016 237.863L179.7 239.424C179.7 239.424 182.634 235.454 181.601 231.458C180.63 227.673 178.332 225.415 177.06 221.192Z"
        fill="white"
      />
      <path
        d="M178.231 231.088C178.275 231.268 178.265 231.457 178.202 231.631C178.139 231.805 178.026 231.956 177.877 232.066C177.728 232.176 177.55 232.24 177.366 232.249C177.181 232.258 176.997 232.212 176.839 232.116C176.68 232.021 176.553 231.881 176.473 231.714C176.394 231.547 176.366 231.36 176.392 231.177C176.419 230.994 176.499 230.823 176.623 230.685C176.747 230.548 176.909 230.45 177.088 230.404C177.33 230.344 177.586 230.382 177.8 230.51C178.015 230.638 178.169 230.846 178.231 231.088Z"
        fill="#263238"
      />
      <path
        d="M179.071 238.069C179.044 238.076 179.016 238.078 178.988 238.074C178.961 238.07 178.934 238.061 178.91 238.047C178.886 238.033 178.864 238.015 178.847 237.992C178.831 237.97 178.818 237.945 178.811 237.918L177.115 231.376C177.102 231.321 177.11 231.264 177.138 231.216C177.166 231.167 177.212 231.131 177.265 231.115C177.293 231.108 177.321 231.107 177.349 231.111C177.377 231.115 177.404 231.125 177.428 231.139C177.453 231.154 177.474 231.173 177.49 231.196C177.507 231.219 177.519 231.245 177.525 231.273L179.221 237.809C179.229 237.835 179.23 237.864 179.227 237.891C179.223 237.919 179.214 237.946 179.2 237.97C179.186 237.994 179.168 238.015 179.145 238.032C179.123 238.049 179.098 238.061 179.071 238.069Z"
        fill="#263238"
      />
      <path
        d="M173.53 197.685L164.31 200.073C164.058 200.142 163.79 200.109 163.563 199.981C163.336 199.853 163.169 199.64 163.099 199.389C163.033 199.137 163.068 198.87 163.196 198.644C163.323 198.418 163.534 198.251 163.783 198.177L173.051 195.762C173.302 195.696 173.569 195.73 173.795 195.858C174.021 195.986 174.189 196.197 174.262 196.446C174.298 196.575 174.307 196.711 174.289 196.844C174.272 196.977 174.227 197.105 174.159 197.221C174.091 197.337 174 197.437 173.892 197.517C173.784 197.597 173.661 197.654 173.53 197.685Z"
        fill="#69488D"
      />
      <path
        d="M126.725 72.4394C126.561 72.85 126.65 73.2743 126.917 73.3975C127.184 73.5207 127.539 73.2743 127.704 72.8569C127.868 72.4394 127.786 72.022 127.512 71.9056C127.238 71.7893 126.896 72.0288 126.725 72.4394Z"
        fill="#263238"
      />
      <path d="M127.388 71.8579L128.49 72.0016C128.49 72.0016 127.669 72.5423 127.388 71.8579Z" fill="#263238" />
      <path
        d="M126.917 72.9871C126.906 74.0887 127.044 75.1867 127.327 76.2515C127.054 76.3079 126.772 76.3046 126.501 76.242C126.229 76.1794 125.974 76.0589 125.753 75.8888L126.917 72.9871Z"
        fill="#ED893E"
      />
      <path
        d="M128.379 69.9346C128.337 69.9264 128.297 69.9076 128.263 69.8799C128.018 69.653 127.724 69.4866 127.403 69.3939C127.082 69.3013 126.745 69.2849 126.416 69.3461C126.383 69.3553 126.347 69.3576 126.313 69.3529C126.278 69.3482 126.245 69.3365 126.215 69.3186C126.185 69.3007 126.159 69.277 126.138 69.2488C126.118 69.2206 126.103 69.1885 126.095 69.1545C126.078 69.0882 126.088 69.0181 126.123 68.9592C126.157 68.9003 126.214 68.8574 126.28 68.8397C126.691 68.7582 127.115 68.7739 127.519 68.8856C127.922 68.9973 128.294 69.2018 128.605 69.483C128.629 69.5066 128.649 69.5348 128.662 69.5659C128.675 69.597 128.682 69.6305 128.682 69.6643C128.682 69.6981 128.675 69.7316 128.662 69.7627C128.649 69.7939 128.629 69.8221 128.605 69.8457C128.578 69.8787 128.544 69.9043 128.504 69.9199C128.464 69.9355 128.422 69.9406 128.379 69.9346Z"
        fill="#263238"
      />
      <path
        d="M112.224 72.2273C112.518 75.5533 111.41 81.0145 108.893 82.8691C108.893 82.8691 110.788 86.3731 116.855 87.1874C123.524 88.084 120.391 84.3268 120.391 84.3268C116.841 82.9581 117.224 80.3028 118.12 77.8664L112.224 72.2273Z"
        fill="#FFB573"
      />
      <path
        d="M130.397 90.0068C130.924 93.1207 131.574 96.344 132.251 99.4784C132.613 101.052 132.976 102.62 133.4 104.166C133.824 105.713 134.248 107.246 134.72 108.758C134.98 109.497 135.213 110.257 135.486 110.989L135.897 112.084L135.992 112.351V112.433C135.992 112.474 135.992 112.522 136.04 112.557C136.181 112.801 136.412 112.98 136.683 113.056C137.226 113.242 137.793 113.351 138.366 113.378C139.834 113.419 141.302 113.298 142.743 113.015C144.255 112.748 145.849 112.379 147.333 111.961L148.585 114.74C147.798 115.212 147.053 115.588 146.259 115.972C145.466 116.355 144.672 116.656 143.845 116.998C142.122 117.669 140.315 118.099 138.475 118.278C137.394 118.383 136.304 118.309 135.247 118.059C133.892 117.753 132.671 117.019 131.765 115.965C131.531 115.679 131.32 115.375 131.136 115.054C131.04 114.897 130.965 114.74 130.883 114.575L130.753 114.274L130.219 113.077C129.871 112.276 129.535 111.475 129.241 110.668C128.653 109.053 128.092 107.431 127.607 105.802C127.121 104.173 126.683 102.524 126.239 100.888C125.425 97.5896 124.761 94.3252 124.269 90.9102L130.397 90.0068Z"
        fill="#FFB573"
      />
      <path
        d="M146.677 112.153L149.317 110.059L150.507 114.165C150.507 114.165 146.895 116.779 145.931 113.706L146.677 112.153Z"
        fill="#FFB573"
      />
      <path d="M151.704 110.784L152.648 113.748L150.507 114.131L149.324 110.059L151.704 110.784Z" fill="#FFB573" />
      <path d="M102.374 241.21L107.894 241.614L109.529 228.933L104.009 228.529L102.374 241.21Z" fill="#FFB573" />
      <path d="M67.0263 225.1L71.8075 228.009L79.7624 218.072L74.9812 215.163L67.0263 225.1Z" fill="#FFB573" />
      <path
        d="M75.1443 229.473C75.0761 229.48 75.0073 229.48 74.9391 229.473C74.2551 229.473 73.4412 228.399 73.0308 227.769C73.0157 227.748 73.0076 227.723 73.0076 227.697C73.0076 227.671 73.0157 227.646 73.0308 227.625C73.0454 227.605 73.0653 227.589 73.0882 227.58C73.111 227.57 73.1361 227.567 73.1607 227.57C73.3864 227.618 75.3632 228.029 75.6231 228.693C75.651 228.765 75.6593 228.844 75.6473 228.921C75.6353 228.998 75.6034 229.07 75.5547 229.131C75.4607 229.288 75.316 229.409 75.1443 229.473ZM73.4343 227.92C74.0157 228.741 74.5698 229.227 74.946 229.233C75.0273 229.232 75.1069 229.209 75.1774 229.169C75.248 229.128 75.3071 229.07 75.3495 229.001C75.3678 228.972 75.3775 228.939 75.3775 228.905C75.3775 228.871 75.3678 228.838 75.3495 228.809C75.2401 228.46 74.2209 228.111 73.4343 227.892V227.92Z"
        fill="#69488D"
      />
      <path
        d="M74.8495 227.762C74.2813 227.919 73.6856 227.947 73.1053 227.844C73.0835 227.84 73.0635 227.829 73.0477 227.813C73.032 227.797 73.0211 227.777 73.0163 227.755C73.0055 227.737 72.9998 227.715 72.9998 227.694C72.9998 227.672 73.0055 227.651 73.0163 227.632C73.0574 227.584 74.1449 226.469 74.9452 226.503C75.048 226.503 75.1493 226.527 75.2416 226.572C75.334 226.617 75.415 226.682 75.4787 226.763C75.5534 226.837 75.6007 226.934 75.6132 227.039C75.6256 227.143 75.6023 227.248 75.5471 227.338C75.3662 227.551 75.1215 227.699 74.8495 227.762ZM73.4131 227.639C74.0971 227.707 75.1504 227.516 75.3625 227.194C75.3625 227.146 75.4172 227.064 75.3077 226.934C75.2674 226.882 75.2156 226.839 75.1564 226.809C75.0972 226.78 75.032 226.764 74.9657 226.763C74.3689 226.884 73.8256 227.191 73.4131 227.639Z"
        fill="#69488D"
      />
      <path
        d="M73.242 226.941L68.707 222.575C68.6322 222.498 68.5321 222.451 68.4253 222.442C68.3184 222.433 68.212 222.463 68.1256 222.527L64.2542 225.517C64.1609 225.6 64.0862 225.702 64.035 225.816C63.9838 225.93 63.9574 226.053 63.9574 226.178C63.9574 226.303 63.9838 226.426 64.035 226.54C64.0862 226.654 64.1609 226.755 64.2542 226.838C65.8616 228.337 66.7098 228.98 68.7002 230.903C69.9314 232.08 71.6004 233.935 73.2967 235.564C74.993 237.193 76.6483 235.66 76.0874 234.832C73.5566 231.122 73.9328 229.726 73.7071 227.831C73.6589 227.489 73.4946 227.175 73.242 226.941Z"
        fill="#263238"
      />
      <path
        d="M110.288 241.798C109.704 241.784 109.123 241.718 108.551 241.6C108.524 241.595 108.5 241.581 108.482 241.561C108.464 241.542 108.452 241.517 108.448 241.49C108.445 241.463 108.449 241.436 108.461 241.412C108.474 241.387 108.493 241.367 108.516 241.354C108.77 241.224 111.013 240.094 111.67 240.471C111.732 240.505 111.785 240.556 111.822 240.617C111.859 240.678 111.88 240.748 111.882 240.82C111.895 240.938 111.879 241.059 111.835 241.17C111.791 241.281 111.72 241.379 111.629 241.456C111.233 241.719 110.761 241.839 110.288 241.798ZM108.988 241.415C110.206 241.614 111.116 241.559 111.458 241.258C111.518 241.208 111.566 241.144 111.594 241.07C111.623 240.997 111.632 240.918 111.622 240.84C111.624 240.813 111.618 240.785 111.604 240.761C111.591 240.736 111.571 240.717 111.547 240.703C111.177 240.498 109.912 240.977 108.988 241.415Z"
        fill="#69488D"
      />
      <path
        d="M108.578 241.579C108.554 241.589 108.527 241.589 108.503 241.579C108.483 241.568 108.468 241.55 108.458 241.53C108.448 241.509 108.445 241.486 108.448 241.463C108.448 241.36 108.715 239.109 109.953 239.109H110.056C110.418 239.15 110.521 239.328 110.541 239.472C110.644 240.101 109.303 241.292 108.633 241.593C108.614 241.596 108.594 241.591 108.578 241.579ZM109.946 239.342C109.153 239.342 108.838 240.649 108.742 241.21C109.426 240.799 110.336 239.841 110.274 239.485C110.274 239.485 110.274 239.369 110.021 239.342H109.946Z"
        fill="#69488D"
      />
      <path
        d="M108.236 240.827H102.032C101.923 240.827 101.819 240.865 101.736 240.936C101.653 241.006 101.598 241.103 101.58 241.21L100.746 246.117C100.729 246.24 100.739 246.365 100.775 246.484C100.811 246.602 100.871 246.712 100.953 246.805C101.035 246.899 101.135 246.974 101.248 247.025C101.361 247.076 101.484 247.102 101.608 247.102C103.769 247.061 104.816 246.938 107.545 246.938C109.221 246.938 112.689 247.109 115.007 247.109C117.326 247.109 117.511 244.816 116.546 244.611C112.244 243.68 110.575 242.394 109.152 241.189C108.899 240.964 108.574 240.836 108.236 240.827Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M109.529 228.939L108.619 235.988L103.161 235.071L104.009 228.536L109.529 228.939Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M74.9723 215.163L79.7603 218.072L76.4635 222.185L71.5797 219.399L74.9723 215.163Z"
        fill="black"
      />
      <path
        d="M99.5894 116.143L121.936 119.147C130.595 87.7895 128.044 86.9135 128.044 86.9135C127.06 86.4409 126.043 86.0429 125 85.7227C123.947 85.3395 122.882 84.9905 121.806 84.6757C115.464 82.7986 108.846 82.0345 102.243 82.4173C101.808 82.4464 101.385 82.5736 101.006 82.7893C100.627 83.005 100.302 83.3036 100.055 83.6628C99.9615 83.7988 99.8814 83.9431 99.8151 84.0939C99.5719 84.6147 99.4773 85.1926 99.5415 85.7638C101.45 102.271 100.841 107.698 99.5894 116.143Z"
        fill="#69488D"
      />
      <path
        opacity="0.2"
        d="M124.111 96.5495C123.672 99.7941 124.09 103.097 125.322 106.131C126.806 100.012 127.613 95.8651 128.01 92.9908C126.307 92.895 124.535 93.5451 124.111 96.5495Z"
        fill="black"
      />
      <path
        d="M127.846 86.8384C131.677 89.0284 134.338 94.25 135.138 97.2818L126.985 104.755C126.985 104.755 122.757 94.9207 123.099 91.8616C123.441 88.8025 126.403 86.0035 127.846 86.8384Z"
        fill="#69488D"
      />
      <path
        d="M113.435 65.3426C111.718 70.53 110.883 72.6858 112.388 76.3882C114.659 81.952 121.383 83.1223 124.611 78.6055C127.518 74.4993 129.837 66.7318 125.699 62.5778C124.829 61.6525 123.733 60.9701 122.52 60.5981C121.306 60.2261 120.016 60.1772 118.778 60.4564C117.539 60.7356 116.395 61.3331 115.458 62.19C114.521 63.0469 113.824 64.1336 113.435 65.3426Z"
        fill="#FFB573"
      />
      <path
        d="M121.936 69.9483C121.575 70.8999 121.518 71.9398 121.772 72.9253C122.114 74.294 123.14 74.4035 123.776 73.5207C124.412 72.6378 124.843 71.0501 124.159 69.9004C123.475 68.7507 122.38 68.9012 121.936 69.9483Z"
        fill="#FFB573"
      />
      <path
        d="M115.295 58.8891C111.601 56.836 104.953 62.8447 103.284 72.6516C102.087 79.6731 117.073 83.0676 126.54 82.917C125.911 81.2677 121.978 76.2855 124.488 71.4882C126.314 68.0048 128.393 68.8055 128.53 64.7541C128.701 59.6829 121.943 56.2611 115.295 58.8891Z"
        fill="#263238"
      />
      <path
        d="M123.7 61.0926C127.996 60.6751 132.312 64.8976 129.111 69.1065C128.329 68.4791 127.419 68.0313 126.445 67.7948C125.471 67.5583 124.457 67.5388 123.475 67.7377C121.512 68.1757 123.7 61.0926 123.7 61.0926Z"
        fill="#263238"
      />
      <path
        d="M116.04 118.353C116.04 118.353 107.387 155.049 103.283 176.134C99.0013 198.376 78.4745 221.541 78.4745 221.541L71.525 217.627C71.525 217.627 84.439 196.473 88.625 175.71C97.134 133.553 91.361 125.067 99.569 116.115L116.04 118.353Z"
        fill="#263238"
      />
      <path d="M79.186 222.198L70.4787 217.346L71.751 214.766L81.5185 219.262L79.186 222.198Z" fill="#69488D" />
      <path
        opacity="0.2"
        d="M111.423 126.326C104.679 132.184 105.411 153.016 106.115 162.358C108.947 149.033 112.347 134.196 114.323 125.608C113.633 125.074 112.668 125.238 111.423 126.326Z"
        fill="black"
      />
      <path
        d="M121.609 119.161C121.609 119.161 125.514 162.727 123.38 179.351C121.164 196.638 109.892 234.243 109.892 234.243L101.916 233.217C101.916 233.217 108.387 195.946 109.536 178.83C110.788 160.175 105.227 116.964 105.227 116.964L121.609 119.161Z"
        fill="#263238"
      />
      <path d="M111.58 234.695L100.239 233.23L100.41 229.877L112.722 231.075L111.58 234.695Z" fill="#69488D" />
      <path
        d="M99.3912 114.795L98.1737 116.642C98.0779 116.786 98.2489 116.971 98.502 117.005L121.943 120.153C122.148 120.153 122.326 120.105 122.346 119.982L122.763 118.018C122.763 117.888 122.627 117.744 122.408 117.717L99.7742 114.678C99.7059 114.66 99.6339 114.662 99.5663 114.682C99.4987 114.703 99.4381 114.742 99.3912 114.795Z"
        fill="#69488D"
      />
      <path
        opacity="0.5"
        d="M99.3912 114.795L98.1737 116.642C98.0779 116.786 98.2489 116.971 98.502 117.005L121.943 120.153C122.148 120.153 122.326 120.105 122.346 119.982L122.763 118.018C122.763 117.888 122.627 117.744 122.408 117.717L99.7742 114.678C99.7059 114.66 99.6339 114.662 99.5663 114.682C99.4987 114.703 99.4381 114.742 99.3912 114.795Z"
        fill="black"
      />
      <path
        d="M102.169 117.71L101.56 117.628C101.437 117.628 101.354 117.539 101.368 117.464L101.929 114.918C101.929 114.842 102.059 114.795 102.176 114.815L102.785 114.897C102.908 114.897 102.99 114.986 102.976 115.061L102.415 117.6C102.402 117.71 102.285 117.71 102.169 117.71Z"
        fill="#263238"
      />
      <path
        d="M117.477 119.804L116.868 119.729C116.752 119.729 116.663 119.633 116.676 119.558L117.238 117.019C117.238 116.944 117.368 116.896 117.491 116.909L118.093 116.991C118.216 116.991 118.298 117.08 118.285 117.156L117.723 119.702C117.71 119.763 117.593 119.825 117.477 119.804Z"
        fill="#263238"
      />
    </svg>
  );
};

export default Edit;
