import { AxiosResponse } from 'axios';

export default function onFulfillGetData<T = any>(response: AxiosResponse<T>): Promise<T | null> {
  if (response.status === 204) {
    return Promise.resolve(null);
  }

  if (undefined !== response.data) {
    return Promise.resolve(response.data);
  }

  return Promise.reject(new Error('Unexpected success response format'));
}
