import { $schools, fetchSchoolListIfNeed } from 'state/school';
import { useEffect } from 'react';
import { useStore } from 'effector-react';

export const useSchools = () => {
  const schools = useStore($schools);

  useEffect(() => {
    fetchSchoolListIfNeed();
  }, []);

  return schools;
};
