import React, { ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider } from '@material-ui/core';
import { Button } from '../../button/Button';
import cl from './Prompt.module.scss';
import reactModal from '@prezly/react-promise-modal';
import { theme } from '../../../App';

interface Props {
  open: boolean;
  message: string | ReactNode;
  onClickClose?: () => void;
  onClickOk?: () => void;
}

export const Prompt: React.FC<Props> = ({ open, message, onClickClose, onClickOk }) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth={false} classes={{ paper: cl.paper }} onClose={onClickClose}>
      <DialogContent>
        <DialogTitle>{message}</DialogTitle>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClickClose}>
          Отмена
        </Button>
        <Button className={cl.btnOk} onClick={onClickOk}>
          ОК
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const showPrompt = (message: string | ReactNode) =>
  reactModal(({ show, onDismiss, onSubmit }) => (
    <ThemeProvider theme={theme}>
      <Prompt open={show} message={message} onClickClose={onDismiss} onClickOk={onSubmit.bind(null, true)} />
    </ThemeProvider>
  ));
