import React from 'react';
import cl from './Header.module.scss';
import Icon from 'ui/Icon';
import { IconButton } from '@material-ui/core';
import cn from 'classnames';
import { Button } from 'ui/button/Button';

type type = 'profile';

interface Props {
  title: string;
  status?: string;
  onClickEdit?: () => void;
  onClickCancel?: () => void;
  classname?: string;
  showEditBtn?: boolean;
  type?: type;
  disabled?: boolean;
}

export const Header: React.FC<Props> = ({
  title,
  disabled = false,
  status,
  classname,
  onClickEdit,
  onClickCancel,
  showEditBtn = true,
  type = 'children',
}) => {
  return (
    <div className={cn(cl.root, classname)}>
      <div className={cl.titleContainer}>
        <span className={cn(cl.title, type === 'profile' && cl.mainTitle)}>{title} </span>

        {showEditBtn ? (
          type === 'profile' ? (
            <Button className={cl.editPBtn} onClick={onClickEdit}>
              Редактировать
            </Button>
          ) : (
            <IconButton className={cl.editCBtn} onClick={onClickEdit} disabled={!!disabled}>
              <Icon type="Edit" />
            </IconButton>
          )
        ) : (
          type === 'profile' && (
            <Button variant="text" className={cl.cancelBtn} onClick={onClickCancel}>
              Отмена
            </Button>
          )
        )}
      </div>
      {type === 'children' && <div className={cl.subtitle}>Заполняется для записи в школу, в кружки и наград </div>}
      {Boolean(status) && <span className={cl.status}>{status}</span>}
    </div>
  );
};
