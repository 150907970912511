import React from 'react';

interface Props {}

const Back: React.FC<Props> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63991 6.36675C9.03043 5.97622 9.6636 5.97622 10.0541 6.36675C10.4446 6.75727 10.4446 7.39044 10.0541 7.78096L6.83511 11H20.0003C20.5526 11 21.0003 11.4477 21.0003 12C21.0003 12.5523 20.5526 13 20.0003 13H6.8215L10.0585 16.237C10.4491 16.6275 10.4491 17.2607 10.0585 17.6512C9.668 18.0417 9.03484 18.0417 8.64432 17.6512L3.71361 12.7205L3.70696 12.7139C3.31643 12.3234 3.31643 11.6902 3.70696 11.2997L8.63991 6.36675Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Back;
