import React from 'react';
import cl from '../../../privacy/components/Page/Page.module.scss';
import { RouteComponentProps } from 'react-router';
import { Button } from 'ui/button/Button';

interface Props extends Partial<RouteComponentProps> {}

export const Page: React.FC<Props> = ({ history }) => {
  return (
    <div className={cl.root}>
      <h1>Правила использования</h1>

      <div className={cl.title}>ВВЕДЕНИЕ</div>
      <p>
        Следующие Условия предоставления услуг ("Условия") являются юридическим соглашением между Вами и ГБОУ
        «Курчатовская школа», ОГРН 1127746767158, ИНН 7734687492, регулирующие порядок доступа и использования
        мобильного и web-приложения под названием «Школа online» («Приложение») и онлайн и офлайн-сервисы, включая любые
        другие функции, Контент или приложения, предлагаемые время от времени ГБОУ «Курчатовская школа», которые ГБОУ
        «Курчатовская школа» соглашается предоставлять Вам («Услуги»). Вы можете использовать услуги «Школа online», а
        также чтобы находить информацию по общеобразовательным вопросам.
      </p>
      <p>
        Вы можете создать учетную запись пользователя «Школа online» («Ваша учетная запись» или «Учетная запись», или
        «Аккаунт пользователя»), для доступа к услугам «Школа online», для создания, загрузки отзывов о деятельности
        ГБОУ «Курчатовская школа» («Ваш контент»).
      </p>
      <p>
        После создания такой учетной записи Вы можете пользоваться услугами «Школа online», доступными для учетной
        записи.
      </p>
      <p>
        Вы подтверждаете, что не являетесь лицом, которому запрещено использовать услуги в соответствии с
        законодательством Российской Федерации или других применимых юрисдикциях. В том случае, если Вы соглашаетесь с
        настоящими Условиями от имени лица третьей стороны, вы гарантируете, что вы имеете достаточно прав представлять
        третье лицо, в этом случае все ссылки на "Вы" в этих условиях относятся к представляемому лицу. Если вы не
        имеете достаточно прав представлять третье лицо, то в таком случае все права и обязанности, порожденные Вашими
        действиями, вы принимаете на себя лично.
      </p>
      <p>
        ЗАГРУЖАЯ ИЛИ ИСПОЛЬЗУЯ ПРИЛОЖЕНИЕ, ИЛИ ВЕБ-СЕРВИС «ШКОЛА ONLINE», ВЫ АВТОМАТИЧЕСКИ СОГЛАШАЕТЕСЬ С ЭТИМИ
        УСЛОВИЯМИ.
      </p>
      <p>
        В случае возникновения противоречий между положениями настоящих Условий и информации, включенной в любые другие
        материалы, данные условия всегда будут иметь приоритет.
      </p>

      <div className={cl.title}>Использование «Школа online»</div>
      <p>
        • Мы оставляем за собой право изменять, ограничивать доступ или прекращать Услугу, или любые функции Сервиса по
        любой причине без уведомления в любое время, без возмещения каких-либо убытков, потерь, расходов, возникших у
        любых лиц.
      </p>
      <p>
        • Вы понимаете, что «Школа online» это онлайн-служба, которая для своего функционирования требует, чтобы Ваши
        данные были переданы на серверы и базы данных «Школа online» через сетевое соединение.
      </p>
      <p>• Все данные хранятся на серверах, соответствующих 152-ФЗ.</p>
      <p>
        • Вы несете ответственность за любые сборы, взимаемые Вашим поставщиком интернет-услуг или мобильным оператором
        за использование этих Услуг, включая, но не ограничиваясь, плату за передачу данных.
      </p>
      <p>
        • Вы несете ответственность за обеспечение того, что любые сети, которые вы используете для доступа к Службам
        «Школа online», являются безопасными, и Вы не будете перекладывать ответственность на ГБОУ «Курчатовская школа»
        за любые последствия использования Сервиса в незащищенной сети.
      </p>
      <p>
        • Вы понимаете, что «Школа online» использует сторонних поставщиков и хостинг-провайдеров для предоставления
        необходимого оборудования, программного обеспечения, сетей, хранилищ и связанных с ними технологий, необходимых
        для работы Сервиса, соответствующих 152-ФЗ. Несмотря на это, ГБОУ «Курчатовская школа» стремится защитить Вашу
        информацию в соответствии с нашей Политикой конфиденциальности и Политикой обработки персональных данных.
      </p>
      <p>
        • Службы «Школа online» могут отображать объявления. Эти объявления могут быть нацелены на контент, запросы или
        другую информацию, но вся Ваша личная информация будет защищена в соответствии с нашей Политикой
        конфиденциальности и Политикой обработки персональных данных. Принимая во внимание, что ГБОУ «Курчатовская
        школа» предоставляет Вам доступ к Сервисам и их использование, Вы соглашаетесь с тем, что «Школа online» может
        отображать такие объявления, которые Вы не будете блокировать.
      </p>
      <p>
        • Используя API-интерфейсы mos.ru, и другие, все логотипы и знаки, являются собственностью соответствующих
        сторон. Перед совместным использованием обязательно ознакомьтесь с Политикой конфиденциальности и Условиями
        обслуживания каждой из сторон.
      </p>

      <div className={cl.title}>Потребляемый контент</div>
      <p>
        • Ни при каких обстоятельствах ГБОУ «Курчатовская школа» не несет никакой ответственности за какой-либо контент
        или пользовательский контент, включая, помимо прочего, любые ошибки или упущения в любом виде контента, а также
        любые убытки или ущерб любого рода, понесенные в связи с Использованием или публикацией любого контента,
        отправленного по электронной почте, переданного или иным образом доступного через Службу.
      </p>
      <p>• Сервисы могут содержать ссылки на сторонние службы или ресурсы.</p>

      <div className={cl.title}>Создание учетных записей</div>
      <p>
        • Чтобы внести свой контент в «Школа online» или получить доступ к определенным функциям Сервиса, Вы должны
        создать учетную запись пользователя.
      </p>
      <p>
        • Чтобы создать учетную запись, вам может потребоваться предоставить персональную информацию (как определено в
        Политике конфиденциальности «Курчатовская школа» и Политике обработки персональных данных) о себе, включая, но
        не ограничиваясь, Ваш адрес электронной почты и номер телефона.
      </p>
      <p>
        • Вы несете ответственность за то, чтобы любая личная информация, которую вы предоставляете, была точной и
        актуальной.
      </p>
      <p>
        • Вы несете ответственность за создание защищенного пароля и защиту своей учетной записи от несанкционированного
        доступа.
      </p>
      <p>• Вы будете нести ответственность за любые действия, которые происходят с Вашей учетной записью.</p>
      <p>
        • У ГБОУ «Курчатовская школа» нет доступа к вашему паролю после его создания, но «Курчатовская школа» может
        разрешить Вам потребовать сбросить пароль.
      </p>

      <div className={cl.title}>Сопутствующий контент</div>
      <p>
        • Выбирая возможность внести свой контент в «Школа online», Вы подтверждаете и соглашаетесь со следующими
        условиями и нашей Политикой конфиденциальности. Вы должны вносить контент в «Школа online», только если Вы
        согласны с этими условиями.
      </p>
      <p>• Вы несете единоличную ответственность за свой контент и использование служб «Школа online»</p>
      <p>• Ваш контент будет общедоступным во всей Службе «Школа online».</p>

      <div className={cl.title}>Удаление вашей учетной записи и вашего контента</div>
      <p>
        • Вы можете удалить свою учетную запись, отправив «Курчатовская школа» официальное письмо на email:
        kurchat@edu.mos.ru.{' '}
      </p>
      <p>• Вы можете удалять определенные типы своего контента через приложение «Школа online». </p>
      <p>
        • ГБОУ «Курчатовская школа» оставляет за собой право отслеживать и просматривать Вашу учетную запись, ваш
        контент и вашу деятельность на предмет соответствия данным правилам использования.
      </p>
      <p>
        • ГБОУ «Курчатовская школа» оставляет за собой право удалить или отключить вашу учетную запись или ваш контент
        по любой причине, включая, помимо прочего, нарушение правил использования, предполагаемое нарушение, устное,
        физическое, письменное или иное злоупотребление по отношению к пользователю, работнику «Курчатовская школа».
      </p>
      <p>• ГБОУ «Курчатовская школа» не может гарантировать доступ к вашей учетной записи или Вашему контенту.</p>
      <p>
        • ГБОУ «Курчатовская школа» не обязаны хранить или предоставлять копии Вашего Контента. Вы несете
        ответственность за резервное копирование и архивирование Вашего контента.
      </p>

      <div className={cl.title}>Действия при удалении аккаунта</div>
      <p>
        В случае прекращения действия Вашей учетной записи все положения Правил использования остаются в силе после
        прекращения действия вашей учетной записи, включая, помимо прочего, положения о праве собственности, гарантийные
        санкции, возмещение и ограничения ответственности.
      </p>

      <div className={cl.title}>Ограничения на использование</div>
      <p>
        В качестве условия использования Вы обязуетесь не пользоваться Сервисом в любых целях, которые запрещены
        Правилами использования.
      </p>

      <div className={cl.title}>Возмещение убытков</div>
      <p>Вы защищаете и не подвергаете вреду «Школа online».</p>

      <div className={cl.title}>Использование в международной среде</div>
      <p>
        Если вы получаете доступ к Сервису из других мест, Вы делаете это по своей собственной инициативе и отвечаете за
        соблюдение местных законов.
      </p>

      <div className={cl.title}>Интеграция и делимитация</div>
      <p>
        Правила использования - это полное соглашение между Вами и ГБОУ «Курчатовская школа» в отношении Сервиса и
        использования Услуг и заменяет все предыдущие или одновременные сообщения и предложения между Вами и ГБОУ
        «Курчатовская школа» в отношении Услуг.
      </p>

      <div className={cl.title}>Изменения условий</div>
      <p>
        ГБОУ «Курчатовская школа» может изменить эти условия в любое время. Изменения вступают в силу сразу же после
        Вашего первого доступа или использования приложения, или Услуг с момента даты, указанной в верхней части
        настоящих Условий. Продолжение доступа или использование приложения, или Услуг после вступивших в силу изменений
        будет считаться, как Ваше окончательное принятие измененных Условий. Если Вы не согласны с изменениями, то вы не
        должны осуществлять доступ или использовать приложение, или услуги «Курчатовская школа».
      </p>

      <div className={cl.title}>Применимое право и разрешение споров</div>
      <p>Настоящие Условия регулируются законодательством Российской Федерации.</p>

      <div className={cl.title}>Форс-мажор</div>
      <p>
        ГБОУ «Курчатовская школа» не будет нести ответственность за невыполнение или задержку в выполнении своих
        обязательств по настоящему Договору по причине забастовки, бунтов, восстаний, пожаров, наводнений, бурь,
        взрывов, стихийных бедствий, войны, землетрясений, или при любых других обстоятельствах непреодолимой силы.
      </p>

      <div className={cl.title}>Контакты</div>
      <p>
        ГБОУ «Курчатовская школа» расположена в г. Москве, Россия. Любые вопросы, замечания или предложения, в том числе
        любой отчет о нарушении настоящих Условий, должны быть предоставлены следующим образом:
      </p>
      <p>• По электронной почте: kurchat@edu.mos.ru</p>

      {!!history && (
        <div className={cl.footer}>
          <Button variant="text" onClick={history.goBack}>
            Закрыть
          </Button>
        </div>
      )}
    </div>
  );
};
