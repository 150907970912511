import React, { ReactNode } from 'react';
import Icon from '../../Icon';
import cl from './Check.module.scss';
import cn from 'classnames';

interface Props {
  label: ReactNode;
  checked?: boolean;
  onClick: () => void;
}

export const Check: React.FC<Props> = ({ label, checked = false, onClick }) => {
  return (
    <label className={cn(cl.root, checked && cl.checked)} onClick={onClick}>
      <Icon type={checked ? 'CheckCircle' : 'Circle'} />
      {label}
    </label>
  );
};
