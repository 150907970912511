import React from "react";

const Camera: React.FC = () =>
    (<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M22.8334 7.31802H26.995C28.4391 7.31802 29.6207 8.49956 29.6207 9.94367V25.6975C29.6207 27.1416 28.4391 28.3232 26.995 28.3232H5.9899C4.5458 28.3232 3.36426 27.1416 3.36426 25.6975V9.94367C3.36426 8.49956 4.5458 7.31802 5.9899 7.31802H10.1515L12.554 4.69238H20.4309L22.8334 7.31802ZM5.98989 25.6975H26.995V9.94366H21.6781L19.2756 7.31802H13.7093L11.3068 9.94366H5.98989V25.6975ZM16.4925 11.2565C12.8691 11.2565 9.92836 14.1972 9.92836 17.8206C9.92836 21.444 12.8691 24.3847 16.4925 24.3847C20.1158 24.3847 23.0566 21.444 23.0566 17.8206C23.0566 14.1972 20.1158 11.2565 16.4925 11.2565ZM12.554 17.8206C12.554 19.9867 14.3263 21.7591 16.4925 21.7591C18.6586 21.7591 20.4309 19.9867 20.4309 17.8206C20.4309 15.6544 18.6586 13.8821 16.4925 13.8821C14.3263 13.8821 12.554 15.6544 12.554 17.8206Z"
            fill="currentColor"/>
    </svg>)

export default Camera;
