import React, { useEffect, useState, useCallback, ChangeEvent, useMemo } from 'react';
import { getClubsHistoryFx } from 'state/club/history';
import { ClubHistoryItem } from 'api/types/clubs/history';
import api from 'api/request/clubs';
import { useStore } from 'effector-react';
import HistoryPanel from 'ui/panels/HistoryPanel/HistoryPanel';

interface Props {}

const HistoryClubs: React.FC<Props> = () => {
  const [history, setHistory] = useState<ClubHistoryItem[]>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getClubsHistoryFx().then(({ data }) => {
      setHistory(data);
    });
  }, []);

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  const searchedHistory = useMemo(
    () =>
      history.filter(
        ({ club }) =>
          club.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 || club.code.indexOf(searchValue) > -1,
      ),
    [searchValue, history],
  );

  return (
    <HistoryPanel
      application={api.contract}
      loading={useStore(getClubsHistoryFx.pending)}
      searchedHistory={searchedHistory}
      type={'club'}
      handleChangeSearch={handleChangeSearch}
      applicationType={'Договор'}
      maternalCapital={api.maternalCapital}
      titleSM="История записи"
    />
  );
};
export default HistoryClubs;
