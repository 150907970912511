import React from 'react';

interface Props {}

const VK: React.FC<Props> = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20ZM21.0462 26.5634C21.0462 26.5634 21.4312 26.5214 21.6285 26.3135C21.809 26.123 21.8027 25.7635 21.8027 25.7635C21.8027 25.7635 21.7787 24.0848 22.5727 23.8369C23.3553 23.5932 24.3601 25.4603 25.4265 26.1783C26.232 26.7211 26.8434 26.6023 26.8434 26.6023L29.693 26.5634C29.693 26.5634 31.183 26.4733 30.4766 25.3231C30.4182 25.2289 30.0645 24.472 28.3585 22.9172C26.5711 21.2897 26.8111 21.553 28.9626 18.7374C30.2731 17.0229 30.7969 15.9761 30.6331 15.5285C30.4776 15.1004 29.5135 15.2141 29.5135 15.2141L26.3061 15.2336C26.3061 15.2336 26.0682 15.2018 25.8918 15.3053C25.7197 15.4067 25.608 15.6433 25.608 15.6433C25.608 15.6433 25.1009 16.9696 24.4238 18.0983C22.9953 20.4786 22.4246 20.6045 22.1909 20.4571C21.6472 20.1119 21.7829 19.0723 21.7829 18.3339C21.7829 16.0263 22.1397 15.0646 21.089 14.8157C20.7405 14.7327 20.4838 14.6784 19.5917 14.6692C18.4471 14.658 17.4788 14.6733 16.93 14.9365C16.5648 15.1117 16.283 15.5029 16.4552 15.5255C16.667 15.5531 17.147 15.6525 17.4016 15.9925C17.7303 16.4319 17.7188 17.4172 17.7188 17.4172C17.7188 17.4172 17.9076 20.1334 17.2774 20.4704C16.8454 20.7018 16.2528 20.2297 14.9788 18.0696C14.3266 16.9635 13.8341 15.7406 13.8341 15.7406C13.8341 15.7406 13.7392 15.5122 13.5691 15.3892C13.3636 15.2407 13.0766 15.1946 13.0766 15.1946L10.0288 15.2141C10.0288 15.2141 9.57076 15.2264 9.40277 15.422C9.25356 15.5951 9.39129 15.9546 9.39129 15.9546C9.39129 15.9546 11.7776 21.4352 14.48 24.1975C16.9581 26.7293 19.7712 26.5634 19.7712 26.5634H21.0462Z"
        fill="#69488D"
      />
    </svg>
  );
};

export default VK;
