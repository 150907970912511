import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import cl from './RequisitesPanel.module.scss';
import { PanelEditable } from 'ui/panels';
import { Field, Header } from 'ui/panels/PanelEditable';
import { Input, Select, MenuItem } from '@material-ui/core';
import { Children, ChildrenUpdateForm, DOCUMENT_TYPE, DocumentType } from 'api/types/children';
import { childrenUpdateFX } from 'state/children';
import { DateMask, InilaMask, PhoneMask } from 'ui/masks';
import { toISODate, toLocalDate } from 'constants/date/format';
import { dateValidator, inilaValidator, isValid, maxValidator, onlyDigit } from 'utils/validators';
import { showErrorAlert } from 'ui/dialogs/Alert';
import { TextField } from 'ui/input/TextField';

type type = 'edit' | 'add';

interface Props {
  disabled?: boolean;
  onChangeMode?: (editMode: boolean) => void;
  data?: Children;
  type: type;
}

interface Errors extends Partial<Record<keyof Children, string | undefined>> {}

export const RequisitesPanel: React.FC<Props> = ({ disabled = false, onChangeMode, data, type }) => {
  const [editMode, setEditMode] = useState(false);
  const [form, setForm] = useState<Partial<Children>>(data || {});
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    setEditMode(false);
    onChangeMode?.(false);
  }, [data, onChangeMode]);

  useEffect(() => {
    if (data)
      setForm({
        ...data,
        document_issued_date: toLocalDate(data.document_issued_date || ''),
      } as Children);
  }, [data, editMode]);

  const onClickEdit = useCallback(() => {
    setEditMode(true);
    onChangeMode?.(true);
  }, [onChangeMode]);

  const handleClickCancel = useCallback(() => {
    setEditMode(false);
    setForm({ ...data, document_issued_date: toLocalDate(data?.document_issued_date || '') } || {});
    setErrors({});
    onChangeMode?.(false);
  }, [onChangeMode, data]);

  const validate = useCallback(() => {
    const errors: Errors = {
      inila: inilaValidator(form.inila),
      document_issued_by: maxValidator(form.document_issued_by, 500),
      document_number: maxValidator(form.document_number, 20),
      document_issued_date: dateValidator(form.document_issued_date, true, true),
      address: maxValidator(form.address, 500),
      citizenship: maxValidator(form.citizenship, 100),
      actual_address: maxValidator(form.actual_address, 500),
    };
    setErrors(errors);
    return isValid(errors);
  }, [form]);

  const handleClickSave = useCallback(async () => {
    if (!validate()) return;
    try {
      await childrenUpdateFX({
        ...form,
        // document_number: form.document_type === 1 ? onlyDigit(form.document_number || '') : form.document_number,
        inila: onlyDigit(form.inila || ''),
        phone: onlyDigit(form.phone || ''),
        photo: undefined,
      } as ChildrenUpdateForm);
      setEditMode(false);
      onChangeMode?.(false);
    } catch (e) {
      await showErrorAlert();
    }
  }, [onChangeMode, form, validate]);

  const handleChangeForm = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));
  }, []);

  const handleChangeDocType = useCallback((e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setForm((form) => ({
      ...form,
      document_type: e.target.value as DocumentType,
    }));
  }, []);

  const setEditModeForMobile = useCallback(() => {
    if (
      form.document_type !== data?.document_type ||
      form.address !== data?.address ||
      form.document_issued_by !== data?.document_issued_by ||
      (toISODate(form.document_issued_date) !== data?.document_issued_date && data?.document_issued_date !== null) ||
      (toISODate(form.document_issued_date) !== '' && data?.document_issued_date === null) ||
      form.document_number !== data?.document_number ||
      form.citizenship !== data?.citizenship ||
      form.actual_address !== data?.actual_address
    ) {
      return true;
    } else return false;
  }, [form, data]);

  return (
    <>
      {type === 'edit' && (
        <PanelEditable
          onClickSave={handleClickSave}
          type={'children'}
          onClickCancel={handleClickCancel}
          editMode={editMode || setEditModeForMobile()}
          classname={cl.root}
          footerText="*Мы храним ваши данные на серверах, соответствующих Федеральному Закону №152"
        >
          <Header title="Реквизиты" disabled={disabled} onClickEdit={onClickEdit} showEditBtn={!editMode} />

          <div className={cl.fields}>
            <Field
              className={cl.inputDesctop}
              label="Тип документа"
              value={
                form.document_type !== undefined && form.document_type !== null
                  ? DOCUMENT_TYPE[form.document_type]
                  : '-'
              }
              editMode={editMode}
              error={errors.document_type}
            >
              <Select
                value={form.document_type ?? ''}
                fullWidth
                onChange={handleChangeDocType}
                error={!!errors.document_type}
              >
                <MenuItem value={0}>{DOCUMENT_TYPE[0]}</MenuItem>
                <MenuItem value={1}>{DOCUMENT_TYPE[1]}</MenuItem>
              </Select>
            </Field>
            <TextField
              label="Тип документа"
              className={cl.inputMobile}
              value={form.document_type ?? ''}
              fullWidth
              select
              noHelper
              onChange={handleChangeDocType}
              error={!!errors.document_type}
            >
              <MenuItem value={0}>{DOCUMENT_TYPE[0]}</MenuItem>
              <MenuItem value={1}>{DOCUMENT_TYPE[1]}</MenuItem>
            </TextField>
            <Field
              className={cl.inputDesctop}
              label="Серия и номер документа"
              value={form.document_number === null ? '-' : form.document_number}
              editMode={editMode}
              error={errors.document_number}
            >
              <Input
                fullWidth
                name="document_number"
                onChange={handleChangeForm}
                value={form.document_number || ''}
                // inputComponent={form.document_type === 1 ? PassportMask : undefined}
                disabled={typeof form.document_type !== 'number'}
                error={!!errors.document_number}
              />
            </Field>
            <TextField
              className={cl.inputMobile}
              label="Серия и номер документа"
              value={form.document_number || ''}
              error={!!errors.document_number}
              fullWidth
              name="document_number"
              onChange={handleChangeForm}
            />
            <Field
              className={cl.inputDesctop}
              label="Кем выдан"
              value={form.document_issued_by === null ? '-' : form.document_issued_by}
              editMode={editMode}
              error={errors.document_issued_by}
            >
              <Input
                fullWidth
                name="document_issued_by"
                onChange={handleChangeForm}
                value={form.document_issued_by || ''}
                error={!!errors.document_issued_by}
              />
            </Field>
            <TextField
              className={cl.inputMobile}
              label="Кем выдан"
              value={form.document_issued_by || ''}
              error={!!errors.document_issued_by}
              fullWidth
              name="document_issued_by"
              onChange={handleChangeForm}
            />
            <Field
              className={cl.inputDesctop}
              label="Дата выдачи"
              error={errors.document_issued_date}
              value={form.document_issued_date === '' ? '-' : form.document_issued_date}
              editMode={editMode}
            >
              <Input
                fullWidth
                name="document_issued_date"
                onChange={handleChangeForm}
                value={form.document_issued_date || ''}
                inputComponent={DateMask}
              />
            </Field>
            <TextField
              className={cl.inputMobile}
              name="document_issued_date"
              label="Дата выдачи"
              error={!!errors.document_issued_date}
              fullWidth
              value={form.document_issued_date || ''}
              InputProps={{ inputComponent: DateMask }}
              onChange={handleChangeForm}
            />
            <Field
              label="Адрес регистрации"
              className={cl.inputDesctop}
              value={form.address === null ? '-' : form.address}
              editMode={editMode}
              error={errors.address}
            >
              <Input
                fullWidth
                name="address"
                onChange={handleChangeForm}
                value={form.address || ''}
                error={!!errors.address}
              />
            </Field>
            <TextField
              fullWidth
              name="address"
              className={cl.inputMobile}
              label="Адрес регистрации"
              value={form.address || ''}
              error={!!errors.address}
              onChange={handleChangeForm}
            />
            <Field
              className={cl.inputDesctop}
              label="Фактический адрес"
              value={form.actual_address === null ? '-' : form.actual_address}
              editMode={editMode}
              error={errors.actual_address}
            >
              <Input
                fullWidth
                name="actual_address"
                onChange={handleChangeForm}
                value={form.actual_address || ''}
                error={!!errors.actual_address}
              />
            </Field>
            <TextField
              name="actual_address"
              className={cl.inputMobile}
              label="Фактический адрес"
              value={form.actual_address || ''}
              error={!!errors.actual_address}
              fullWidth
              onChange={handleChangeForm}
            />
            <Field
              className={cl.inputDesctop}
              label="Гражданство"
              value={form.citizenship === null ? '-' : form.citizenship}
              editMode={editMode}
              error={errors.citizenship}
            >
              <Input
                fullWidth
                name="citizenship"
                onChange={handleChangeForm}
                value={form.citizenship || ''}
                // inputComponent={form.document_type === 1 ? PassportMask : undefined}
                error={!!errors.citizenship}
              />
              <TextField
                name="citizenship"
                className={cl.inputMobile}
                label="Гражданство"
                value={form.citizenship || ''}
                error={!!errors.citizenship}
                fullWidth
                onChange={handleChangeForm}
              />
            </Field>
            <Field
              label="СНИЛС"
              className={cl.inputDesctop}
              value={form.inila === null ? '-' : form.inila}
              editMode={editMode}
              error={errors.inila}
            >
              <Input
                fullWidth
                name="inila"
                onChange={handleChangeForm}
                value={form.inila || ''}
                inputComponent={InilaMask}
                error={!!errors.inila}
              />
            </Field>
            <TextField
              name="inila"
              className={cl.inputMobile}
              label="Снилс"
              value={form.inila || ''}
              error={!!errors.inila}
              fullWidth
              onChange={handleChangeForm}
              InputProps={{ inputComponent: InilaMask }}
            />
            <Field
              label="Телефон"
              className={cl.inputDesctop}
              value={form.phone === null ? '-' : form.phone}
              editMode={editMode}
            >
              <Input
                fullWidth
                name="phone"
                onChange={handleChangeForm}
                value={form.phone || ''}
                inputComponent={PhoneMask}
              />
            </Field>
            <TextField
              name="phone"
              className={cl.inputMobile}
              label="Телефон"
              value={form.phone || ''}
              error={!!errors.phone}
              fullWidth
              onChange={handleChangeForm}
              InputProps={{ inputComponent: PhoneMask }}
            />
          </div>
        </PanelEditable>
      )}
    </>
  );
};
