import React, { HTMLAttributes } from 'react';
import IconContainer from './IconContainer';

const ICONS_TYPES = [
  'About',
  'ArrowForward',
  'Back',
  'History',
  'Calendar',
  'Chat',
  'Children',
  'CheckCircle',
  'Circle',
  'Close',
  'CloseFilled',
  'Cover',
  'Edit',
  'Profile',
  'Filters',
  'PlusCircle',
  'Camera',
  'Mail',
  'Menu',
  'Mos',
  'NoPhoto',
  'Phone',
  'Search',
  'User',
  'Version',
  'Contacts',
  'EmptySpace',
  'ExitArrow',
  'GartenMenu',
  'ClubMenu',
  'SchoolsMenu',
  'ConnectToSchool',
  'OnlineService',
  'Social',
  'Instagram',
  'VK',
  'Facebook',
  'YouTube',
  'AppQrCode',
  'World',
  'GooglePlay',
  'AppStore',
  'Logo',
  'LogoForLogin',
  'LogoSmall',
  'GiveDocs',
  'ShowHistory',
  'AskQuestion',
  'ReportFromSchool',
  'RegistrationOnEvent',
  'BookOnMfc',
  'ActSign',
  'Documents',
  'Telegram',
  'OK',
  'Yandex',
] as const;

export type IconType = typeof ICONS_TYPES[number];

export const ICON_TYPES: ReadonlyArray<IconType> = ICONS_TYPES;

export interface IIconProps extends HTMLAttributes<HTMLElement> {
  type: IconType;
}

const getTemplate = (type: IconType): React.FC => require(`./templates/${type}`).default;

const Icon: React.FC<IIconProps> = ({ type, ...rest }) => {
  const Template = getTemplate(type);

  return (
    <IconContainer {...rest}>
      <Template />
    </IconContainer>
  );
};

export default Icon;
