import { dataOnly } from 'api/instance';
import { ApiResponse } from 'api/types';
import { Children, ChildrenForm } from 'api/types/children';

const api = {
  create: (form: ChildrenForm) => dataOnly.post<null, ApiResponse<Children>>('/api/v1/children', form),

  update: (id: number, form: ChildrenForm) => {
    const formData = new FormData();

    formData.append('last_name', String(form.last_name));
    formData.append('first_name', String(form.first_name));
    formData.append('patronymic', String(form.patronymic || ''));
    formData.append('document_type', String(form.document_type ?? ''));
    formData.append('document_number', String(form.document_number || ''));
    formData.append('document_issued_by', String(form.document_issued_by || ''));
    formData.append('citizenship', String(form.citizenship || ''));
    formData.append('actual_address', String(form.actual_address || ''));
    formData.append('document_issued_date', String(form.document_issued_date || ''));
    formData.append('address', String(form.address || ''));
    formData.append('inila', String(form.inila || ''));
    formData.append('phone', String(form.phone || ''));
    formData.append('adult_type_id', String(form.adult_type_id ?? ''));
    formData.append('dob', String(form.dob));
    if (form.photo) formData.append('photo', form.photo);

    return dataOnly.post<null, ApiResponse<Children>>(`/api/v1/children/${id}`, formData);
  },
  updatePhoto: (id: number, file: File) => {
    const formData = new FormData();
    formData.append('photo', file);

    return dataOnly.post<null, ApiResponse<{ id: number; photo: string }>>(`/api/v1/children/${id}`, formData);
  },

  delete: (id: number) => dataOnly.delete(`/api/v1/children/${id}`),

  getPhoto: (fileName: string) =>
    dataOnly.get<null, any>(`/api/v1/children/photo/${fileName}`, { responseType: 'blob' }),
};

export default api;
