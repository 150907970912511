import { createEffect, createEvent, createStore, sample, guard } from 'effector';
import { ApiResponse } from 'api/types';
import { ApiError } from 'api/errors/apiError';
import apiProfile from 'api/request/profile';
import { Profile, ProfileForm, ProfileRequisites, ProfileRequisitesForm, ProfileSecond } from 'api/types/profile';
import { FetchingState, initFetchingState, storeAttachFetch, storeAttachSending } from '../utils';
import { logout } from '../auth';

export interface ProfileState extends FetchingState<Profile> {}

export const getProfileFx = createEffect<void, ApiResponse<Profile>, ApiError<any>>({
  handler: async () => await apiProfile.get(),
});

export const updateProfileFx = createEffect<ProfileForm, ApiResponse<Profile>, ApiError<any>>({
  handler: async (params: ProfileForm) => await apiProfile.update(params),
});

export const updateRequisitesFx = createEffect<ProfileRequisitesForm, ApiResponse<Profile>, ApiError<any>>({
  handler: async (params: ProfileRequisitesForm) => await apiProfile.updateRequisites(params),
});

export const fetchProfileIfNeed = createEvent();

export const $profile = createStore<ProfileState>(initFetchingState<Profile>()).reset(logout);

guard({
  source: sample($profile, fetchProfileIfNeed),
  filter: $profile.map((state) => !state.isFetching && !state.didFetched),
  target: getProfileFx,
});

storeAttachFetch($profile, [getProfileFx]);

storeAttachSending($profile, [updateProfileFx, updateRequisitesFx]);

// checkVerificationCodeFx----------
export const checkVerificationCodeFx = createEffect({
  handler: async (code: string) => apiProfile.checkVerificationCode(code),
});
$profile
  .on(checkVerificationCodeFx.pending, (state, pending) =>
    pending
      ? {
          ...state,
          isSending: true,
        }
      : state,
  )
  .on(checkVerificationCodeFx.fail, (state) => ({
    ...state,
    isSending: false,
  }))
  .on(checkVerificationCodeFx.doneData, (state) => ({
    ...state,
    data: {
      ...state.data,
      email_verified: true,
    },
    isSending: false,
  }));

// getVerificationCode---------------------
export const getVerificationCodeFx = createEffect({
  handler: async () => await apiProfile.getVerificationCode(),
});

export const checkProfile = (profile?: Partial<Profile>) =>
  !(!profile || !profile.last_name || !profile.first_name || !profile.dob || !profile.phone);

export const checkSecondRep = (profile?: Partial<ProfileSecond>) =>
  !(
    !profile ||
    !profile.adult_type_id_rep ||
    !profile.last_name_rep ||
    !profile.first_name_rep ||
    !profile.dob_rep ||
    !profile.email_rep
  );

export const checkRequisites = (requisites?: Partial<ProfileRequisites>) =>
  !(
    !requisites ||
    !requisites.address ||
    !requisites.passport_issued_by ||
    !requisites.passport_issued_date ||
    !requisites.passport_number
  );

export const checkRequisitesFilled = (requisites?: Partial<ProfileRequisites>, isActualAddressNeeded?:boolean) => {
  if (isActualAddressNeeded){
    return !(
      !requisites ||
      !requisites.address ||
      !requisites.passport_issued_by ||
      !requisites.passport_issued_date ||
      !requisites.passport_number ||
      !requisites.actual_address
    );
  } else {
    return !(
      !requisites ||
      !requisites.address ||
      !requisites.passport_issued_by ||
      !requisites.passport_issued_date ||
      !requisites.passport_number
    );
  }
}
  
export const invalidateProfile = createEvent();
$profile.on(invalidateProfile, (state) => ({
  ...state,
  didFetched: false,
}));

export const getNotificationFx = createEffect<void, any, ApiError<any>>({
  handler: async () => await apiProfile.actsAmount(),
});

export const $notification = createStore<any>(initFetchingState());

storeAttachFetch($notification, [getNotificationFx]);
