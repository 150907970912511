import { Dialog, IconButton } from '@material-ui/core';
import React from 'react';
import { Button } from 'ui/button/Button';
import Icon from 'ui/Icon';
import cl from './DeleteConfirm.module.scss';

interface Props {
  isOpen: boolean;
  onClickClose: () => void;
  handleClickConfirm: () => void;
}

const DeleteConfirm: React.FC<Props> = ({ isOpen, onClickClose, handleClickConfirm }) => {
  return (
    <Dialog open={isOpen} classes={{ root: cl.root, paper: cl.paper }}>
      <IconButton onClick={onClickClose} className={cl.closeBtn}>
        <Icon type="Close" />
      </IconButton>
      <div className={cl.content}>
        <div className={cl.title}>Вы уверены, что хотите удалить профиль ребенка?</div>
        <div className={cl.subtitle}>Вся введённая вами информация удалится.</div>
        <div className={cl.btns}>
          <Button onClick={handleClickConfirm} className={cl.btnConfirm}>
            Да
          </Button>
          <Button onClick={onClickClose} className={cl.btnNotConfirm}>
            Нет
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteConfirm;
