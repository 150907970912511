import React, { ReactNode } from 'react';
import { Dialog, IconButton, ThemeProvider } from '@material-ui/core';
import cl from './Alert.module.scss';
import { Button } from '../../button/Button';
import cn from 'classnames';
import Icon from '../../Icon';
import reactModal from '@prezly/react-promise-modal';
import { theme } from '../../../App';

type AlertType = 'success' | 'error' | 'default';

interface Props {
  open: boolean;
  type: AlertType;
  title?: string;
  onClickClose?: () => void;
  onClose?: () => void;
  isBtnNeed?: boolean;
}

export const Alert: React.FC<Props> = ({ open, type, title, children, onClickClose, onClose, isBtnNeed = true }) => {
  return (
    <Dialog open={open} classes={{ root: cl.root, paper: cl.paper }} onClose={onClose}>
      <IconButton onClick={onClickClose} className={cl.closeBtn}>
        <Icon type="Close" />
      </IconButton>
      <div className={cn(cl.content, cl[type])}>
        {!!title && <div className={cl.title}>{title}</div>}
        {type !== 'default' && (
          <div className={cl.ico}>
            <Icon type={type === 'success' ? 'CheckCircle' : 'Close'} />
          </div>
        )}
        <div className={cl.text}>{children}</div>
        {isBtnNeed && (
          <Button size="large" onClick={onClickClose}>
            ОК
          </Button>
        )}
      </div>
    </Dialog>
  );
};

export const showAlert = (type: AlertType, text: string | ReactNode, title?: string) =>
  reactModal(({ show, onDismiss }) => (
    <ThemeProvider theme={theme}>
      <Alert open={show} type={type} children={text} onClickClose={onDismiss} title={title} />
    </ThemeProvider>
  ));

export const showErrorAlert = () =>
  showAlert('error', 'Проверьте правильность заполнения всех полей и попытайтесь снова.', 'Ошибка');

export const showErrorNetAlert = () =>
  showAlert('error', 'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.', 'Ошибка');
