const qMin = 0.1;
const qMax = 0.9;

interface INavigatorLanguage {
  readonly languages: readonly string[];
}

export const buildAcceptLanguage = (
  language?: string,
  navigator: INavigatorLanguage = window.navigator,
): string | undefined => {
  const { languages = [] } = navigator;
  const { length } = languages;
  const qDelta = length > 1 ? (qMax - qMin) / (length - 1) : 0;

  return language === undefined
    ? undefined
    : [
        language,
        ...languages
          .filter((lng) => lng !== language)
          .map((lang, i) => {
            return `${lang};q=${(qMax - i * qDelta).toFixed(2)}`;
          }),
      ].join(',');
};
