import React, { ReactNode } from 'react';
import cl from './Stepper.module.scss';
import cn from 'classnames';

export interface Step {
  label: string;
  component: ReactNode;
}

interface Props {
  steps: Step[];
  current?: number;
}

export const Stepper: React.FC<Props> = ({ steps, current = 0 }) => {
  return (
    <div className={cl.root}>
      <div className={cl.header}>
        <span>
          Шаг {current + 1} из {steps.length}
        </span>
        <span>{steps[current].label}</span>
      </div>
      <div className={cl.steps}>
        {steps.map((c, i) => (
          <div key={i} className={cn(cl.step, i <= current && cl.active)} />
        ))}
      </div>
    </div>
  );
};
