import React, { useCallback, useEffect, useState } from 'react';
import cl from './Club.module.scss';
import { useClubs } from 'hooks/clubs';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Button } from 'ui/button/Button';
import { PanelMini } from 'ui/panels/PanelMini';
import moment from 'moment';
import { DATE_MONTH } from 'constants/date/format';
import { AddressPanel, PhotosPanel } from 'ui/panels';
import { ContactPanel } from 'ui/panels/ContactPanel';
import { useProfile } from 'hooks/profile';
import { EmailConfirm } from 'ui/dialogs/EmailConfirm/EmailConfirm';
import { ConfirmDialog } from 'routes/main/routes/profile/routes/email/EmailPanel/ConfirmDialog';
import { IconButton } from '@material-ui/core';
import Icon from 'ui/Icon';

interface Props extends RouteComponentProps<{ id: string }> {}

export const Club: React.FC<Props> = ({
  match,
  match: {
    params: { id },
  },
}) => {
  const { map: clubsMap } = useClubs();
  const history = useHistory();

  const club = clubsMap.get(+id);
  const { title, teachers = [], code, photos = [] } = club || {};

  const handleJoin = useCallback(() => {
    history.push(`${match.url}/join`);
  }, [history, match]);

  const { data: profile } = useProfile();

  const handleClickBack = useCallback(() => {
    history.push(`/clubs`);
  }, [history]);

  //почта отсутсвует
  const [isOpenEmailCreate, setOpenEmailCreate] = useState(false);

  const handleCloseEmailConfirmDialog = useCallback(() => {
    setOpenEmailCreate(false);
  }, []);

  const [email, setEmail] = useState('');

  useEffect(() => {
    setEmail(profile.email || '');
  }, [profile.email]);

  //почта не подтверждена
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleDialogOk = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  //навигация по dialogs
  const handleSignUp = useCallback(() => {
    if (profile.email === null) {
      setOpenEmailCreate(true);
    } else if (!profile.email_verified) {
      setOpenConfirm(true);
    } else {
      handleJoin();
    }
  }, [profile.email, profile.email_verified, handleJoin]);
  return (
    <div className={cl.root}>
      <div className={cl.header}>
        <div className={cl.title}>
          <IconButton className={cl.iconBack} onClick={handleClickBack}>
            <Icon type="Back" />
          </IconButton>
          <h1>{title}</h1>
        </div>
        <Button onClick={() => handleSignUp()} className={cl.buttonForDesctop}>
          Записаться
        </Button>
      </div>
      <ContactPanel data={teachers} label={`КОД ${code}`} title="Преподаватели" />
      <div className={cl.descr}>
        <PanelMini title={club?.contract_template.payment_type === 1 ? "Стоимость одного занятия" :  "Стоимость за месяц"}>
          {club?.contract_template.payment_type === 1 ? club?.cost_per_item : club?.cost}&nbsp;₽
        </PanelMini>
        <PanelMini title="График">{club?.schedule}</PanelMini>
        <PanelMini title="Дата начала и окончания занятий">
          {!!club && (
            <>
              {moment(club.start_date).format(DATE_MONTH)} - {moment(club.end_date).format(DATE_MONTH)}
            </>
          )}
        </PanelMini>
      </div>
      {!!photos.length && <PhotosPanel data={photos} />}
      {!!club?.address && <AddressPanel address={club.address} />}
      <Button onClick={() => handleSignUp()} className={cl.buttonForMobile}>
        Записаться
      </Button>

      <EmailConfirm
        isOpen={isOpenEmailCreate}
        handleCloseEmailConfirmDialog={handleCloseEmailConfirmDialog}
        setOpenConfirm={setOpenConfirm}
        email={email}
        setEmail={setEmail}
      />

      <ConfirmDialog open={openConfirm} email={email} onClickOk={handleDialogOk} />
    </div>
  );
};
