import { dataOnly, downloadInstance } from 'api/instance';
import { ApiResponse } from 'api/types';
import { Gartens, GartenJoinForm, GartensJoinResponseData } from 'api/types/gartens';
import { FulfillDownloadConfig } from 'api/utils/on-fulfill-download';

const api = {
  list: () => dataOnly.get<null, ApiResponse<Gartens[]>>('/api/v1/catalog/kindergartens'),

  join: (form: GartenJoinForm) => {
    const formData = new FormData();

    formData.append('kindergarten_id', String(form.kindergarten_id));

    if (form.child_id !== -1) formData.append('child_id', String(form.child_id));
    form.file_name.concat(form.filesStep4).forEach((f) => {
      formData.append('file_name[]', f.file[0], f.name + '.' + f.file[0].name.split('.').pop());
    });
    formData.append('maternal_capital', String(form.maternal_capital));
    formData.append('save_user_data', String(form.save_user_data));
    formData.append('user_last_name', String(form.user_last_name));
    formData.append('user_first_name', String(form.user_first_name));
    formData.append('user_patronymic', String(form.user_patronymic || ''));
    formData.append('user_dob', String(form.user_dob));
    formData.append('user_passport_number', String(form.user_passport_number));
    formData.append('user_passport_issued_by', String(form.user_passport_issued_by));
    formData.append('user_passport_issued_date', String(form.user_passport_issued_date));
    formData.append('user_address', String(form.user_address));
    formData.append('user_actual_address', String(form.user_actual_address));

    formData.append('save_child_data', String(form.save_child_data));
    formData.append('citizenship', String(form.citizenship));
    formData.append('child_last_name', String(form.child_last_name));
    formData.append('child_first_name', String(form.child_first_name));
    formData.append('child_patronymic', String(form.child_patronymic || ''));
    formData.append('child_dob', String(form.child_dob));
    formData.append('document_type', String(form.document_type ?? ''));
    formData.append('child_document_number', String(form.child_document_number));
    formData.append('child_document_issued_by', String(form.child_document_issued_by));
    formData.append('child_document_issued_date', String(form.child_document_issued_date));
    formData.append('child_actual_address', String(form.child_actual_address));
    formData.append('child_address', String(form.child_address));
    formData.append('child_inila', String(form.child_inila));
    formData.append('child_phone', String(form.child_phone));

    formData.append('adult_type_id', String(form.adult_type_id));

    if (form.partner_last_name) formData.append('partner_last_name', String(form.partner_last_name));
    if (form.partner_first_name) formData.append('partner_first_name', String(form.partner_first_name));
    if (form.partner_patronymic) formData.append('partner_patronymic', String(form.partner_patronymic));
    if (form.partner_dob) formData.append('partner_dob', String(form.partner_dob));
    if (form.partner_phone) formData.append('partner_phone', String(form.partner_phone));
    if (form.partner_email) formData.append('partner_email', String(form.partner_email));
    if (form.partner_adult_type_id) formData.append('partner_adult_type_id', String(form.partner_adult_type_id));

    return dataOnly.post<null, ApiResponse<GartensJoinResponseData>>('/api/v1/kindergartens/join', formData);
  },

  code: (id: number, code: string) => dataOnly.post<null, any>('/api/v1/kindergartens/code', { id, code }),

  approve: (id: number) => dataOnly.post<null, any>('/api/v1/kindergartens/approve', { id }),

  history: () => dataOnly.get<null, any>('/api/v1/kindergartens/history'),

  application: (id: number, fileName: string) =>
    downloadInstance.get<null, any>(`/api/v1/kindergartens/application/${id}`, {
      _fileName: fileName,
    } as FulfillDownloadConfig),

  contract: (id: number, fileName: string) =>
    downloadInstance.get<null, any>(`/api/v1/kindergartens/contract/${id}`, {
      _fileName: fileName,
    } as FulfillDownloadConfig),
};

export default api;
