import React from 'react';

interface Props {}

const Search: React.FC<Props> = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.43604 13.7466C8.0498 13.7466 6.77148 13.2983 5.72559 12.5513L1.79102 16.4858C1.6084 16.6685 1.36768 16.7598 1.11035 16.7598C0.570801 16.7598 0.188965 16.3447 0.188965 15.8135C0.188965 15.5645 0.271973 15.332 0.45459 15.1494L4.36426 11.2314C3.54248 10.1523 3.05273 8.81592 3.05273 7.36328C3.05273 3.85205 5.9248 0.97998 9.43604 0.97998C12.9473 0.97998 15.8193 3.85205 15.8193 7.36328C15.8193 10.8745 12.9473 13.7466 9.43604 13.7466ZM9.43604 12.3687C12.1753 12.3687 14.4414 10.1025 14.4414 7.36328C14.4414 4.62402 12.1753 2.35791 9.43604 2.35791C6.69678 2.35791 4.43066 4.62402 4.43066 7.36328C4.43066 10.1025 6.69678 12.3687 9.43604 12.3687Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default Search;
