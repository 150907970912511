import { Dialog, IconButton } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useState } from 'react';
import Icon from 'ui/Icon';
import cl from './DeclineActConfirm.module.scss';
import { TextField } from 'ui/input/TextField';
import { Button } from 'ui/button/Button';
import { maxValidator } from 'utils/validators';

interface Props {
  isOpen: boolean;
  handleClickClose: () => void;
  setReason: (reason: string) => void;
  reason: string;
  handleDeclineAct: () => void;
}

const DeclineActConfirm: React.FC<Props> = ({ isOpen, handleClickClose, setReason, reason, handleDeclineAct }) => {
  const handleChangeReason = (e: ChangeEvent<HTMLInputElement>) => {
    setReason(e.currentTarget.value);
  };

  const [error, setError] = useState<string | undefined>();

  const validate = useCallback(() => {
    const error = maxValidator(reason, 100);
    setError(error);
    return !error;
  }, [reason]);

  const handleClickSend = useCallback(() => {
    if (!validate()) return;
    if (reason?.trim() !== '') (handleDeclineAct() as any).then(() => handleClickClose());
  }, [validate, handleDeclineAct, handleClickClose, reason]);

  return (
    <Dialog open={isOpen} className={cl.root} classes={{ paper: cl.paper }}>
      <IconButton onClick={handleClickClose} className={cl.closeBtn}>
        <Icon type="Close" />
      </IconButton>
      <div className={cl.content}>
        <div className={cl.title}>Укажите причину отказа</div>
        <form autoComplete="off" noValidate>
          <TextField
            label="Текст отказа"
            fullWidth
            value={reason}
            name="reason"
            onChange={handleChangeReason}
            error={!!error}
            helperText={error}
          />
        </form>
        <Button className={cl.btnContinue} onClick={() => handleClickSend()}>
          Продолжить
        </Button>
      </div>
    </Dialog>
  );
};
export default DeclineActConfirm;
