import { dataOnly, downloadInstance } from 'api/instance';
import { ApiResponse } from 'api/types';
import { DocumentsSignForm, DocumentsSignResponseData, DocumentsType } from 'api/types/documents';
import { FulfillDownloadConfig } from 'api/utils/on-fulfill-download';

const api = {
  list: () => dataOnly.get<null, ApiResponse<DocumentsType[]>>('/api/v1/catalog/freedocs'),

  join: (form: DocumentsSignForm) => {
    const formData = new FormData();
    formData.append('freedoc_id', String(form.freedoc_id));
    if (form.child_id !== -1) formData.append('child_id', String(form.child_id));
    if (form.file_name?.length) formData.append('dop_benefit', String(form.file_name[0].name));
    if (form.dop_sum !== null && form.dop_sum !== undefined) formData.append('dop_sum', String(form.dop_sum));
    if (form.dop_uip_dstart) formData.append('dop_uip_dstart', String(form.dop_uip_dstart));
    if (form.dop_uip_dend) formData.append('dop_uip_dend', String(form.dop_uip_dend));
    if (form.dop_uip_matdstart) formData.append('dop_uip_matdstart', String(form.dop_uip_matdstart));
    if (form.dop_uip_matdend) formData.append('dop_uip_matdend', String(form.dop_uip_matdend));
    if (form.dop_uip_sum) formData.append('dop_uip_sum', String(form.dop_uip_sum));      
    if (form.dop_plat_sum) formData.append('dop_plat_sum', String(form.dop_plat_sum));      
    if (form.dop_plat_dstart) formData.append('dop_plat_dstart', String(form.dop_plat_dstart));      
    if (form.dop_plat_dend) formData.append('dop_plat_dend', String(form.dop_plat_dend));
    if (form.dop_club) formData.append('dop_club', String(form.dop_club)) 
    
    if (form.move_from_class) formData.append('move_from_class', String(form.move_from_class));
    if (form.move_from_corpus) formData.append('move_from_corpus', String(form.move_from_corpus));
    if (form.move_from_date) formData.append('move_from_date', String(form.move_from_date));
    if (form.move_to_class) formData.append('move_to_class', String(form.move_to_class));
    if (form.move_to_corpus) formData.append('move_to_corpus', String(form.move_to_corpus));
    if (form.file_name?.length) formData.append('file_name', form.file_name[0].file[0], form.file_name[0].name + '.' + form.file_name[0].file[0].name.split('.').pop());
    if (form.dop_data) formData.append('dop_data', String(form.dop_data));
    if (form.dop_number) formData.append('dop_number', String(form.dop_number));
    if (form.dop_period) formData.append('dop_period', String(form.dop_period));
    if (form.dop_school) formData.append('dop_school', String(form.dop_school));
    if (form.dop_class) formData.append('dop_class', String(form.dop_class)); 
    formData.append('save_user_data', String(form.save_user_data));
    formData.append('user_last_name', String(form.user_last_name));
    formData.append('user_first_name', String(form.user_first_name));
    formData.append('user_patronymic', String(form.user_patronymic || ''));
    formData.append('user_dob', String(form.user_dob));
    formData.append('user_passport_number', String(form.user_passport_number));
    formData.append('user_passport_issued_by', String(form.user_passport_issued_by));
    formData.append('user_passport_issued_date', String(form.user_passport_issued_date));
    formData.append('user_address', String(form.user_address));

    formData.append('save_child_data', String(form.save_child_data));
    formData.append('child_last_name', String(form.child_last_name));
    formData.append('child_first_name', String(form.child_first_name));
    formData.append('child_patronymic', String(form.child_patronymic || ''));
    formData.append('child_dob', String(form.child_dob));
    formData.append('citizenship', String(form.citizenship));
    formData.append('document_type', String(form.document_type ?? ''));
    formData.append('child_document_number', String(form.child_document_number));
    formData.append('child_document_issued_by', String(form.child_document_issued_by));
    formData.append('child_document_issued_date', String(form.child_document_issued_date));
    formData.append('child_actual_address', String(form.child_actual_address));
    formData.append('child_address', String(form.child_address));
    formData.append('child_inila', String(form.child_inila));
    formData.append('child_phone', String(form.child_phone));

    formData.append('adult_type_id', String(form.adult_type_id));

    if (form.partner_last_name) formData.append('partner_last_name', String(form.partner_last_name));
    if (form.partner_first_name) formData.append('partner_first_name', String(form.partner_first_name));
    if (form.partner_patronymic) formData.append('partner_patronymic', String(form.partner_patronymic));
    if (form.partner_dob) formData.append('partner_dob', String(form.partner_dob));
    if (form.partner_phone) formData.append('partner_phone', String(form.partner_phone));
    if (form.partner_email) formData.append('partner_email', String(form.partner_email));
    if (form.partner_adult_type_id) formData.append('partner_adult_type_id', String(form.partner_adult_type_id));

    return dataOnly.post<null, ApiResponse<DocumentsSignResponseData>>('/api/v1/freedocs/join', formData);
  },
  approve: (id: number) => dataOnly.post<null, any>('/api/v1/freedocs/approve', { id }),

  code: (id: number, code: string) => dataOnly.post<null, any>('/api/v1/freedocs/code', { id, code }),

  history: () => dataOnly.get<null, any>('/api/v1/freedocs/history'),

  contract: (id: number, fileName: string) =>
    downloadInstance.get<null, any>(`/api/v1/freedocs/contract/${id}`, {
      _fileName: fileName,
    } as FulfillDownloadConfig),
};

export default api;
