import React from 'react';

interface Props {}

const ShowHistory: React.FC<Props> = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5830:56623)">
        <path
          d="M5.33333 11.4667H4.79999C4.65273 11.4667 4.53333 11.5861 4.53333 11.7333C4.53333 11.8806 4.65273 12 4.79999 12H5.33333C5.48059 12 5.59999 11.8806 5.59999 11.7333C5.59999 11.5861 5.48059 11.4667 5.33333 11.4667Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M12.8 11.4667H6.40003C6.25276 11.4667 6.13336 11.5861 6.13336 11.7333C6.13336 11.8806 6.25276 12 6.40003 12H12.8C12.9473 12 13.0667 11.8806 13.0667 11.7333C13.0667 11.5861 12.9473 11.4667 12.8 11.4667Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M5.33333 9.8667H4.79999C4.65273 9.8667 4.53333 9.9861 4.53333 10.1334C4.53333 10.2806 4.65273 10.4 4.79999 10.4H5.33333C5.48059 10.4 5.59999 10.2806 5.59999 10.1334C5.59999 9.9861 5.48059 9.8667 5.33333 9.8667Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M12.8 9.8667H6.40003C6.25276 9.8667 6.13336 9.9861 6.13336 10.1334C6.13336 10.2806 6.25276 10.4 6.40003 10.4H12.8C12.9473 10.4 13.0667 10.2806 13.0667 10.1334C13.0667 9.9861 12.9473 9.8667 12.8 9.8667Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M5.33333 8.2666H4.79999C4.65273 8.2666 4.53333 8.386 4.53333 8.53327C4.53333 8.68054 4.65273 8.79994 4.79999 8.79994H5.33333C5.48059 8.79994 5.59999 8.68054 5.59999 8.53327C5.59999 8.386 5.48059 8.2666 5.33333 8.2666Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M12.8 8.2666H6.40003C6.25276 8.2666 6.13336 8.386 6.13336 8.53327C6.13336 8.68054 6.25276 8.79994 6.40003 8.79994H12.8C12.9473 8.79994 13.0667 8.68054 13.0667 8.53327C13.0667 8.386 12.9473 8.2666 12.8 8.2666Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M5.33333 6.66663H4.79999C4.65273 6.66663 4.53333 6.78603 4.53333 6.93329C4.53333 7.08056 4.65273 7.19996 4.79999 7.19996H5.33333C5.48059 7.19996 5.59999 7.08056 5.59999 6.93329C5.59999 6.78603 5.48059 6.66663 5.33333 6.66663Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M12.8 6.66663H6.40003C6.25276 6.66663 6.13336 6.78603 6.13336 6.93329C6.13336 7.08056 6.25276 7.19996 6.40003 7.19996H12.8C12.9473 7.19996 13.0667 7.08056 13.0667 6.93329C13.0667 6.78603 12.9473 6.66663 12.8 6.66663Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M5.33333 5.06665H4.79999C4.65273 5.06665 4.53333 5.18605 4.53333 5.33332C4.53333 5.48058 4.65273 5.59998 4.79999 5.59998H5.33333C5.48059 5.59998 5.59999 5.48058 5.59999 5.33332C5.59999 5.18605 5.48059 5.06665 5.33333 5.06665Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M12.8 5.06665H6.40003C6.25276 5.06665 6.13336 5.18605 6.13336 5.33332C6.13336 5.48058 6.25276 5.59998 6.40003 5.59998H12.8C12.9473 5.59998 13.0667 5.48058 13.0667 5.33332C13.0667 5.18605 12.9473 5.06665 12.8 5.06665Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M14.6632 3.9832C14.6618 3.95983 14.6573 3.93677 14.6496 3.91467C14.6466 3.90613 14.6445 3.89787 14.6408 3.8896C14.6278 3.8606 14.6097 3.83413 14.5874 3.81147L10.8541 0.0781333C10.8314 0.0558333 10.805 0.0377667 10.776 0.0248C10.7677 0.0210667 10.7594 0.0189333 10.7512 0.016C10.7289 0.00833333 10.7056 0.00366667 10.6821 0.00213333C10.6776 0.00293333 10.6725 0 10.6666 0H3.19998C3.05271 0 2.93331 0.1194 2.93331 0.266667V1.06667H1.59998C1.45271 1.06667 1.33331 1.18607 1.33331 1.33333V15.7333C1.33331 15.8806 1.45271 16 1.59998 16H12.8C12.9472 16 13.0666 15.8806 13.0666 15.7333V14.4H14.4C14.5472 14.4 14.6666 14.2806 14.6666 14.1333V4C14.6666 3.99413 14.6637 3.98907 14.6632 3.9832ZM10.9333 0.9104L13.7562 3.73333H10.9333V0.9104ZM12.5333 15.4667H1.86665V1.6H2.93331V14.1333C2.93331 14.2806 3.05271 14.4 3.19998 14.4H12.5333V15.4667ZM14.1333 13.8667H3.46665V0.533333H10.4V4C10.4 4.14727 10.5194 4.26667 10.6666 4.26667H14.1333V13.8667Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M6.39999 2.40002H4.79999C4.65273 2.40002 4.53333 2.51942 4.53333 2.66669V4.26669C4.53333 4.41396 4.65273 4.53336 4.79999 4.53336H6.39999C6.54726 4.53336 6.66666 4.41396 6.66666 4.26669V2.66669C6.66666 2.51942 6.54726 2.40002 6.39999 2.40002ZM6.13333 4.00002H5.06666V2.93336H6.13333V4.00002Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
      </g>
      <defs>
        <clipPath id="clip0_5830:56623">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShowHistory;
