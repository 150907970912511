import React from 'react';
import { ButtonProps } from '@material-ui/core/Button';
import { Button as MuiButton } from '@material-ui/core';

interface Props extends ButtonProps {}

const Button: React.FC<Props> = ({ children, onClick, ...rest }) => {
  return (
    <MuiButton variant="contained" disableElevation color="primary" onClick={onClick} {...rest}>
      {children}
    </MuiButton>
  );
};

export default Button;
