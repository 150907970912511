import React, { useCallback } from 'react';
import cl from './Campus.module.scss';
import ActiveImg from '../../utils/map_Active.jpg';
import { useHistory } from 'react-router';
import { Panel } from 'ui/panels';
import { Address } from 'api/types/common';
import { SchoolPhoto } from 'api/types/schools';

interface Props {
  address: Address;
  name: string;
  photos: SchoolPhoto[];
  id: number;
  parallel: number;
  programmId: number;
  placeholder: string;
}

const Campus: React.FC<Props> = (props) => {
  const history = useHistory();

  const { name, address, id, parallel, programmId, placeholder } = props;

  const handleClickRouteToJoin = useCallback(
    (parallel: number, programmId: number, id: number) => {
      history.push(`/schools/${parallel}/${programmId}/${id}`);
    },
    [history],
  );

  return (
    <div className={cl.campusContainer}>
      <Panel classname={cl.campus}>
        <div className={cl.campusClicker} onClick={() => handleClickRouteToJoin(parallel, programmId, id)}>
          <div className={cl.campusContentContainer}>
            <div className={cl.name}>{name}</div>
            <div className={cl.address}>{address.text_address}</div>
            <div className={cl.imgContainer}>
              <img className={cl.img} src={placeholder === '' ? ActiveImg : placeholder} alt="" />
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default Campus;
