import React, { useCallback, ChangeEvent } from 'react';
import { Panel } from 'ui/panels/Panel';
import cl from './HistoryPanel.module.scss';
import { shortFioFromObj } from 'utils/helpers';
import Icon from 'ui/Icon';
import { ButtonBase } from '@material-ui/core';
import { showAlert } from 'ui/dialogs/Alert';
import pdfImg from 'assets/icons/pdf.svg';
import { Backdrop } from 'ui/feedback/Backdrop';
import { SearchInput } from 'ui/input/SearchInput';
import { SchoolsHistoryItem } from 'api/types/schools/histoty';
import { ClubHistoryItem } from 'api/types/clubs/history';
import { toLocalDate } from 'constants/date/format';
import { PageContent } from 'components/layout/PageContent';
import { GartenHistoryItem } from 'api/types/gartens/history';
import cn from 'classnames';
import { useHistory } from 'react-router';
import api from 'api/request/clubs';

type HistoryType = 'school' | 'club' | 'garten' | 'documents';

interface Props {
  application: (id: number, type: string) => void;
  test_application?: (id: number, type: string) => void;
  regime?: (id: number, type: string) => void;
  loading: boolean;
  searchedHistory: SchoolsHistoryItem[] | ClubHistoryItem[] | GartenHistoryItem[];
  handleChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  applicationType: string;
  type: HistoryType;
  contract?: (id: number, type: string) => void;
  maternalCapital?: (id: number, type: string) => void;
  titleSM?: string;
}

type actConditionType = 'to_sign' | 'rejected' | 'signed';

const isSchoolItem = (item: any, type: HistoryType): item is SchoolsHistoryItem => {
  return type === 'school';
};

const isClubItem = (item: any, type: HistoryType): item is ClubHistoryItem => {
  return type === 'club';
};
const isGartenItem = (item: any, type: HistoryType): item is GartenHistoryItem => {
  return type === 'garten';
};

const isDocumentItem = (item: any, type: HistoryType): item is any => {
  return type === 'documents';
};

const HistoryPanel: React.FC<Props> = ({
  application,
  test_application,
  loading,
  contract,
  searchedHistory,
  maternalCapital,
  handleChangeSearch,
  applicationType,
  regime,
  type,
  titleSM,
}) => {
  const handleClickPdf = useCallback(
    async (id: number, name: string) => {
      try {
        if (type === 'documents') {
          await application(id, name);
        } else {
          await application(id, applicationType);
        }
      } catch (e) {
        await showAlert('error', 'При скачивании документа произошла ошибка');
      }
    },
    [application, applicationType, type],
  );

  const handleClickTestPdf = useCallback(
    async (id: number) => {
      try {
        test_application!(id, 'Заявление на тестирование');
      } catch (e) {
        await showAlert('error', 'При скачивании документа произошла ошибка');
      }
    },
    [test_application],
  );

  const handleClickRedimePdf = useCallback(
    async (id: number) => {
      try {
        regime!(id, 'Согласие');
      } catch (e) {
        await showAlert('error', 'При скачивании документа произошла ошибка');
      }
    },
    [regime],
  );

  const handleClickContractPdf = useCallback(
    async (id: number) => {
      try {
        contract!(id, 'Договор');
      } catch (e) {
        await showAlert('error', 'При скачивании документа произошла ошибка');
      }
    },
    [contract],
  );

  const handleClickMaternalCapital = useCallback(
    async (id: number) => {
      try {
        await maternalCapital!(id, 'Дополнительное соглашение');
      } catch (e) {
        await showAlert('error', 'При скачивании документа произошла ошибка');
      }
    },
    [maternalCapital],
  );

  const history = useHistory();

  const handleClickToSign = useCallback(
    async (type: actConditionType, code: string, id: number, url: string) => {
      if (type === 'to_sign') {
        history.push(`clubs/${code}/tosign/${id}`);
      } else if (type === 'signed') {
        try {
          await api.act(id, 'Акт');
        } catch (e) {
          await showAlert('error', 'При скачивании документа произошла ошибка');
        }
      } else if (type === 'rejected') {
        history.push(`clubs/${code}/rejected/${id}`);
      }
    },
    [history],
  );

  return (
    <PageContent className={cl.root} titleSM={titleSM}>
      <Backdrop open={loading} />
      <SearchInput className={cl.search} onChange={handleChangeSearch} />
      <div className={cl.items}>
        {!!searchedHistory.length ? (
          (searchedHistory as any[]).map((c: SchoolsHistoryItem | ClubHistoryItem | GartenHistoryItem | any) => {
            return (
              <Panel key={c.id} classname={cl.item}>
                {isGartenItem(c, type) ? (
                  ''
                ) : (
                  <div className={cl.label}>
                    {isSchoolItem(c, type)
                      ? `${c.parallel.number} класс`
                      : isClubItem(c, type)
                      ? `КОД ${c.club.code}`
                      : isDocumentItem(c, type)
                      ? 'ДОКУМЕНТ'
                      : ''}{' '}
                  </div>
                )}
                <div
                  className={cn(
                    cl.sectionHeader,
                    type === 'garten' && cl.sectionHeaderHeight,
                    type === 'school' && cl.sectionHeightSchool,
                  )}
                >
                  <div className={cn(cl.title, type === 'garten' && cl.titleHeigth)}>
                    {isSchoolItem(c, type)
                      ? c.program.title
                      : isClubItem(c, type)
                      ? c.club.title
                      : type === 'garten'
                      ? c.kindergarten.title
                      : isDocumentItem(c, type)
                      ? c.freedoc.title
                      : ''}
                  </div>
                  <div className={cl.row}>
                    <div>
                      {isSchoolItem(c, type)
                        ? c.place.title
                        : isClubItem(c, type)
                        ? c.club.address.text_address
                        : type === 'garten'
                        ? c.kindergarten.description
                        : ''}
                    </div>
                    {isSchoolItem(c, type) ? (
                      ''
                    ) : isClubItem(c, type) ? (
                      <div className={cl.date}>
                        <Icon type="Calendar" />
                        {toLocalDate(c.created_at)}
                      </div>
                    ) : type === 'garten' ? (
                      <div className={cl.date}>
                        <Icon type="Calendar" />
                        {toLocalDate(c.updated_at)}
                      </div>
                    ) : isDocumentItem(c, type) ? (
                      <div className={cl.date}>
                        <Icon type="Calendar" />
                        {toLocalDate(c.created_at)}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className={cl.section}>
                  <div className={cn(cl.row, cl.display)}>
                    <div>Родитель</div>
                    <div>{shortFioFromObj(c.user)}</div>
                  </div>
                  <div className={cn(cl.row, cl.display)}>
                    <div>Ребенок</div>
                    <div>{shortFioFromObj(c.child)}</div>
                  </div>
                </div>
                <div className={cl.docSection}>
                  <div>Документы</div>
                  <div className={cn(cl.docs, type === 'documents' && cl.paddingElem)}>
                    <Doc
                      title={applicationType}
                      onClick={handleClickPdf.bind(null, c.id, type === 'documents' ? c.freedoc.title : '')}
                      img={pdfImg}
                    />
                    {isSchoolItem(c, type) ? (
                      <>
                        {!!c.documents.test_application && (
                          <Doc title="Тестир." img={pdfImg} onClick={handleClickTestPdf.bind(null, c.id)} />
                        )}
                        {!!c.documents.changed_regime && (
                          <Doc title="Согласие" img={pdfImg} onClick={handleClickRedimePdf.bind(null, c.id)} />
                        )}
                      </>
                    ) : isClubItem(c, type) ? (
                      <>
                        {!!c.documents.maternal_capital && (
                          <Doc title="Доп. согл." img={pdfImg} onClick={handleClickMaternalCapital.bind(null, c.id)} />
                        )}
                        {c.contract_acts.length > 0 &&
                          c.contract_acts.map((el, key) => {
                            return (
                              <Act
                                key={key}
                                img={pdfImg}
                                title="Акт"
                                act={el.url}
                                onClick={handleClickToSign.bind(null, el.status, c.club.code, el.id, el.url)}
                                actCondition={el.status}
                              />
                            );
                          })}
                      </>
                    ) : isGartenItem(c, type) ? (
                      <Doc title="Договор" img={pdfImg} onClick={handleClickContractPdf.bind(null, c.id)} />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </Panel>
            );
          })
        ) : (
          <div className={cl.emptyDataContainer}>
            <div className={cl.emptyData}>
              <Icon className={cl.iconForEmpty} type="EmptySpace" />
              <div className={cl.textForEmpty}>Заключенные договора и заявления будут отображаться здесь.</div>
            </div>
          </div>
        )}
      </div>
    </PageContent>
  );
};

export default HistoryPanel;
interface DocProps {
  title: string;
  img: string;
  onClick: () => void;
}

const Doc: React.FC<DocProps> = ({ title, img, onClick }) => {
  return (
    <ButtonBase className={cl.doc} component="div" onClick={onClick}>
      <div className={cl.content}>
        <div className={cl.signed}>Подписано</div>
        <div className={cl.imgWrapper}>
          <img alt="" src={img} />
        </div>
        <div>{title}</div>
      </div>
    </ButtonBase>
  );
};

interface ActProps {
  actCondition: actConditionType;
  img: string;
  title: string;
  onClick: () => void;
  act: string | undefined;
}

const Act: React.FC<ActProps> = ({ actCondition, img, title, onClick }) => {
  return (
    <ButtonBase className={cl.doc} onClick={onClick} component="div">
      {actCondition !== 'to_sign' ? (
        <div className={cl.content}>
          {actCondition === 'signed' && <div className={cl.signed}>Подписано</div>}
          {actCondition === 'rejected' && <div className={cl.renouncement}>Отказ</div>}
          <div className={cl.imgWrapper}>
            <img alt="" src={img} />
          </div>
          <div>{title}</div>
        </div>
      ) : (
        <>
          <div className={cn(cl.content, cl.animationFirst)}>
            <div className={cl.needSign}>Подписать</div>
            <div className={cl.imgWrapper}>
              <img alt="" src={img} />
            </div>
            <div>{title}</div>
          </div>
          <div className={cl.actContent}>
            <Icon type={'ActSign'} />
            <div className={cl.needSign}>Необходимо подписать акт</div>
          </div>
        </>
      )}
    </ButtonBase>
  );
};
