import React, { ReactNode, useEffect, useRef, useState } from 'react';
import cl from './LeftSide.module.scss';
import Icon from 'ui/Icon';
import { IconButton } from '@material-ui/core';
import cn from 'classnames';
import SimpleBar from 'simplebar-react';
import { Button } from 'ui/button/Button';

interface Props {
  title: string;
  withFilter?: boolean;
  activeFilter?: boolean;
  showFilter?: boolean;
  onClickFilter?: () => void;
  onCloseFilter?: () => void;
  onResetFilter?: () => void;
  topComponent?: ReactNode;
  bottomLabel?: string | ReactNode;
  isRootPath?: boolean;
}

const LeftSide: React.FC<Props> = ({
  title,
  children,
  withFilter = false,
  activeFilter = false,
  showFilter = false,
  onClickFilter,
  onCloseFilter,
  onResetFilter,
  topComponent,
  bottomLabel,
  isRootPath = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(ref.current?.scrollHeight || 0);
  }, [showFilter]);

  return (
    <aside className={cn(cl.root, isRootPath && cl.rootPath)}>
      <div ref={ref} className={cl.headerWrapper}>
        {!showFilter ? (
          <>
            <h1>
              {title}
              {withFilter && (
                <IconButton className={cn(activeFilter && cl.activeFilter)} onClick={onClickFilter}>
                  <Icon type="Filters" />
                </IconButton>
              )}
            </h1>
            {Boolean(topComponent) && topComponent}
          </>
        ) : (
          <>
            <h1>
              <IconButton onClick={onCloseFilter}>
                <Icon type="Back" />
              </IconButton>
              <Button variant="text" onClick={onResetFilter}>
                Сбросить
              </Button>
            </h1>
            {Boolean(topComponent) && topComponent}
          </>
        )}
      </div>
      <SimpleBar style={{ height: `calc(100% - ${offset}px)` }} className={cl.simpleBar}>
        {children}
      </SimpleBar>
      {!!bottomLabel && <div className={cl.bottomLabel}>{bottomLabel}</div>}
    </aside>
  );
};

export default LeftSide;
