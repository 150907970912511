import { conformToMask, maskArray } from 'react-text-mask';

export const MASK_PHONE = ['+', /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
export const MASK_DATE = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];

export const MASK_PASSPORT = [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]; // "[00] [00] [000000]"
export const MASK_INILA = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/]; //"[000]-[000]-[000]-[00]"
export const MASK_BIRTH_CERTIFICATE = [
  /\w/,
  /\w/,
  /\w/,
  /\w/,
  /\w/,
  /\w/,
  ' ',
  /\w/,
  /\w/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]; //"[Aaaaaa]-[AA] [000000]"

export const conformedValue = (val: string, mask: maskArray | ((value: string) => maskArray)): string =>
  conformToMask(val, mask, {
    guide: false,
  }).conformedValue;
