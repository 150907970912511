import React from 'react';
import { TextField as MuiTextField } from '@material-ui/core';
import cl from './TextField.module.scss';
import { StandardTextFieldProps } from '@material-ui/core/TextField/TextField';
import cn from 'classnames';

interface Props extends StandardTextFieldProps {
  noHelper?: boolean;
  className?: string;
}

export const TextField: React.FC<Props> = ({ noHelper = false, className, ...tail }) => {
  return (
    <MuiTextField
      {...tail}
      className={cn(cl.root, className, noHelper && cl.noHelper)}
      FormHelperTextProps={{ className: cl.helper }}
    />
  );
};
