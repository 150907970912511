import { dataOnly } from 'api/instance';
import { Profile, ProfileForm, ProfileRequisitesForm } from 'api/types/profile';
import { ApiResponse } from '../../types';

const api = {
  get: () => dataOnly.get<null, ApiResponse<Profile>>('/api/v1/profile'),

  update: (params: ProfileForm) => dataOnly.put<null, ApiResponse<Profile>>('/api/v1/profile', params),

  updateRequisites: (params: ProfileRequisitesForm) =>
    dataOnly.put<null, ApiResponse<Profile>>('/api/v1/profile/requisites', params),

  updatePhoto: (file: File) => {
    const formData = new FormData();
    formData.append('photo', file);

    return dataOnly.post<null, ApiResponse<{ photo: string }>>('/api/v1/profile/photo', formData);
  },

  getPhoto: (fileName: string) =>
    dataOnly.get<null, any>(`/api/v1/profile/photo/${fileName}`, { responseType: 'blob' }),

  getVerificationCode: () => dataOnly.get('/api/v1/profile/verification'),

  checkVerificationCode: (code: string) => dataOnly.post('/api/v1/profile/verification', { code }),

  actsAmount: () => dataOnly.get('/api/v1/profile/unsigned_docs'),
};

export default api;
