import React, { useCallback, useEffect, useState } from 'react';
import { ProfilePanel } from './ProfilePanel';
import cl from './Default.module.scss';
import { PageContent } from '../../../../../../components/layout/PageContent';
import { toISODate, toLocalDate } from 'constants/date/format';
import { Profile } from 'api/types/profile';
import { useProfile } from 'hooks/profile';

interface Props {}
interface Form {
  last_name: string;
  first_name: string;
  patronymic: string;
  dob: string;
}

interface Errors extends Partial<Form> {}

const convertForm = (profile: Partial<Profile>): Form => ({
  last_name: profile?.last_name || '',
  first_name: profile?.first_name || '',
  patronymic: profile?.patronymic || '',
  dob: toLocalDate(profile?.dob || ''),
});

const Default: React.FC<Props> = () => {
  const { profile } = useProfile();

  const [editMode, setEditMode] = useState(false);
  const [form, setForm] = useState<Form>(convertForm(profile));
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    if (profile) setForm(convertForm(profile));
  }, [profile]);

  const handleClickCancel = useCallback(() => {
    setEditMode(false);

    setForm(convertForm(profile));
    setErrors({});
  }, [profile]);

  const setEditModeForMobile = useCallback(() => {
    if (
      (form.last_name !== profile.last_name && profile.last_name !== null) ||
      (form.last_name !== '' && profile.last_name === null) ||
      (form.first_name !== profile.first_name && profile.first_name !== null) ||
      (form.first_name !== '' && profile.first_name === null) ||
      (form.patronymic !== profile.patronymic && profile.patronymic !== null) ||
      (form.patronymic !== '' && profile.patronymic === null) ||
      (toISODate(form.dob) !== profile.dob && profile.dob !== null) ||
      (toISODate(form.dob) !== '' && profile.dob === null)
    ) {
      return true;
    } else return false;
  }, [form, profile]);

  return (
    <PageContent
      className={cl.root}
      editMode={setEditModeForMobile()}
      handleClickCancel={handleClickCancel}
      titleSM="Учетная запись"
    >
      <ProfilePanel
        setEditModeForMobile={setEditModeForMobile()}
        handleClickCancel={handleClickCancel}
        setErrors={setErrors}
        setEditMode={setEditMode}
        setForm={setForm}
        errors={errors}
        editMode={editMode}
        form={form}
        profile={profile}
      />
    </PageContent>
  );
};

export default Default;
