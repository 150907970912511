import React from 'react';
import cl from './Field.module.scss';
import cn from 'classnames';

interface Props {
  label: string;
  value: string | null | undefined;
  editMode?: boolean;
  divider?: boolean;
  error?: string;
  className?: string;
}

export const Field: React.FC<Props> = ({
  label,
  value,
  children,
  editMode = false,
  divider = true,
  error,
  className,
}) => {
  return (
    <div className={cn(cl.root, className, editMode && cl.editMode, divider && cl.divider, !!error && cl.error)}>
      <span className={cl.label}>{label}</span>
      <div className={cl.value}>
        {editMode ? children ?? <span>{value}</span> : <span>{value}</span>}
        {editMode && !!error && <div className={cl.errorText}>{error}</div>}
      </div>
    </div>
  );
};
