import { AxiosResponse } from 'axios';
import { ApiError } from './apiError';

export default class ApiServerError<TResult = any> extends Error implements ApiError<TResult> {
  response: AxiosResponse<TResult>;

  constructor(response: AxiosResponse<TResult>, message: string = response.statusText) {
    super(message);

    this.response = response;
  }
}
