import React, { useEffect, useState, useCallback, ChangeEvent, useMemo } from 'react';
import { getClubsHistoryFx } from 'state/club/history';
import { useStore } from 'effector-react';
import HistoryPanel from 'ui/panels/HistoryPanel/HistoryPanel';
import { getGartensHistoryFx } from 'state/garten/history';
import { GartenHistoryItem } from 'api/types/gartens/history';
import api from 'api/request/gartens';

interface Props {}

const HistoryGartens: React.FC<Props> = () => {
  const [history, setHistory] = useState<GartenHistoryItem[]>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getGartensHistoryFx().then(({ data }) => {
      setHistory(data);
    });
  }, []);

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  const searchedHistory = useMemo(
    () =>
      history.filter(({ kindergarten }) => kindergarten.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1),
    [searchValue, history],
  );

  return (
    <HistoryPanel
      application={api.application}
      contract={api.contract}
      loading={useStore(getClubsHistoryFx.pending)}
      searchedHistory={searchedHistory}
      type={'garten'}
      handleChangeSearch={handleChangeSearch}
      applicationType={'Заявление'}
      titleSM="История записи"
    />
  );
};
export default HistoryGartens;
