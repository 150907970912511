import React from 'react';

interface Props {}

const Facebook: React.FC<Props> = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20ZM22.0846 31.7596V20.8785H25.0883L25.4863 17.1288H22.0846L22.0897 15.2521C22.0897 14.2741 22.1827 13.7501 23.5873 13.7501H25.4651V10H22.461C18.8526 10 17.5826 11.819 17.5826 14.878V17.1293H15.3333V20.8789H17.5826V31.7596H22.0846Z"
        fill="#69488D"
      />
    </svg>
  );
};

export default Facebook;
