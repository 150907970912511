import {useEffect} from "react";
import {useStore} from "effector-react";
import {$profile, fetchProfileIfNeed, } from "state/profile";
import {needFetch} from "state/utils";

export const useProfile = () => {
  const profile = useStore($profile);

  useEffect(()=>{
    if (needFetch(profile))
      fetchProfileIfNeed();
  }, [profile]);

  return {...profile, profile: profile.data};
}
