// import fileDownload from 'js-file-download';
import { saveAs } from 'file-saver';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export interface FulfillDownloadConfig extends AxiosRequestConfig {
  _fileName?: string;
}

export default function onFulfillDownload<T = string>({ headers, data, config }: AxiosResponse<Blob>): Promise<T> {
  const { _fileName } = config as FulfillDownloadConfig;
  const content = headers['content-disposition'];
  let fileName = _fileName
    ? _fileName
    : content?.split("filename*=UTF-8''")[1]?.replace(/"/g, '') ?? content?.split('filename=')[1] ?? 'unknownFile';
  fileName = decodeURI(fileName);

  // fileDownload(data, fileName, headers['content-type']);
  saveAs(data, fileName);

  return Promise.resolve(fileName);
}
