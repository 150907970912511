import React from 'react';
import { BaseMenuItem } from 'ui/list/BaseMenuItem';
import cn from 'classnames';
import cl from './GartenItem.module.scss';
import { useStore } from 'effector-react';
import { $activeGarten } from 'state/garten';

interface Props {
  name: string;
  onClick: (id: number) => void;
  isActive: boolean;
  id: number;
  description: string;
}

const GartenItem: React.FC<Props> = ({ name, onClick, isActive, id, description }) => {
  const activeId = useStore($activeGarten);

  const isDisabledProgramms = (id: number) => {
    if (id === -1) {
      return false;
    } else return true;
  };

  return (
    <BaseMenuItem
      isActive={isActive}
      onClick={onClick.bind(null, id)}
      disable={isDisabledProgramms(activeId)}
      classname={cn(cl.root, activeId === -1 ? '' : activeId === id ? '' : cl.notActive)}
    >
      <div className={cl.name}> {name} </div>
      <div className={cl.title}>{description}</div>
    </BaseMenuItem>
  );
};

export default GartenItem;
