import { DefaultContent } from 'components/layout';
import React from 'react';

interface Props {}

export const Default: React.FC<Props> = () => {
  return (
    <DefaultContent>
      Информация о записи <br /> появится после выбора класса
    </DefaultContent>
  );
};
