import { AxiosResponse } from 'axios';
import { ApiError } from './apiError';

export interface ErrorDetails {
  readonly result: false;
  readonly message: string;
}

const isErrorDetails = (data: any): data is ErrorDetails =>
  typeof data === 'object' &&
  null !== data &&
  data.hasOwnProperty('message') &&
  data.hasOwnProperty('result') &&
  data.result === false &&
  typeof data.message === 'string';

export default class ApiClientError<TResult = any> extends Error implements ApiError<TResult> {
  response: AxiosResponse<TResult>;
  readonly details: ErrorDetails | null;

  constructor(response: AxiosResponse<TResult>, message: string = response.statusText) {
    super(message);

    this.response = response;
    this.details = isErrorDetails(response.data) ? response.data : null;
  }

  get data(): TResult {
    return this.response.data;
  }
}
