import { useStore } from 'effector-react';
import React from 'react';
import { $activeProgramm } from 'state/school';
import { BaseMenuItem } from 'ui/list/BaseMenuItem';
import cl from './SchoolItem.module.scss';
import cn from 'classnames';

interface Props {
  name: string;
  parallel: number | null;
  isActive: boolean;
  onClick: (parallel: number, schoolProgammId: number) => void;
  id: number;
}

const SchoolItem: React.FC<Props> = (props) => {
  const { name, parallel, id, isActive, onClick } = props;

  const activeId = useStore($activeProgramm);

  const isDisabledProgramms = (id: number) => {
    if (id === -1) {
      return false;
    } else return true;
  };

  return (
    <BaseMenuItem
      isActive={isActive}
      disable={isDisabledProgramms(activeId)}
      onClick={onClick.bind(null, parallel || 0, id)}
      classname={cn(cl.root, activeId === -1 ? '' : activeId === id ? '' : cl.notActive)}
    >
      <div className={cl.programmsParallel}> {parallel} класс</div>
      <div className={cl.programmsName}>{name}</div>
    </BaseMenuItem>
  );
};

export default SchoolItem;
