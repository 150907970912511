import { useDocuments } from 'hooks/documents';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import cl from './Document.module.scss';
import { Button } from 'ui/button/Button';
import { useProfile } from 'hooks/profile';
import { EmailConfirm } from 'ui/dialogs/EmailConfirm/EmailConfirm';
import Agreement from 'ui/dialogs/Agreement';
import { ConfirmDialog } from 'routes/main/routes/profile/routes/email/EmailPanel/ConfirmDialog';
import Icon from 'ui/Icon';
import { IconButton } from '@material-ui/core';

interface Props extends RouteComponentProps<{ id: string }> {}

const Document: React.FC<Props> = ({
  match: {
    params: { id },
  },
}) => {
  const documents = useDocuments();
  const location = useLocation();
  const pathInfo = location.pathname.split('/')[2];
  const { data: profile } = useProfile();
  const currentDocument = documents.data.find((el) => el.id === +id);
  const [isOpenAgreement, setOpenAgreement] = useState(false);
  const [isOpenEmailCreate, setOpenEmailCreate] = useState(false);
  const [email, setEmail] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    setEmail(profile.email || '');
  }, [profile.email]);
  const history = useHistory();
  const handleClickRouteToDocumentSign = useCallback(() => {
    history.push(`/documents/${pathInfo}/${+id}/sign`);
  }, [history, pathInfo, id]);

  const handleSignUp = useCallback(() => {
    if (profile.email === null) {
      setOpenEmailCreate(true);
    } else if (!profile.email_verified) {
      setOpenConfirm(true);
    } else {
      setOpenAgreement(true);
    }
  }, [profile.email, profile.email_verified]);
  const handleCloseEmailConfirmDialog = useCallback(() => {
    setOpenEmailCreate(false);
  }, []);
  const handleDialogOk = useCallback(() => {
    setOpenConfirm(false);
  }, []);
  const handleClickBack = useCallback(() => {
    history.push(`/documents`);
  }, [history]);
  return (
    <div className={cl.container}>
      <div className={cl.header}>
        <IconButton className={cl.iconBack} onClick={handleClickBack}>
          <Icon type="Back" />
        </IconButton>
        <h1 className={cl.title}>Подписание документа</h1>
        <Button onClick={handleSignUp} className={cl.buttonForDesctop}>
          Подписать
        </Button>
      </div>
      <div className={cl.content}>
        <div className={cl.title}>Описание документа</div>
        <div className={cl.description}>{currentDocument?.description}</div>
      </div>
      <Button onClick={handleSignUp} className={cl.buttonForMobile}>
        Подписать
      </Button>
      <EmailConfirm
        isOpen={isOpenEmailCreate}
        handleCloseEmailConfirmDialog={handleCloseEmailConfirmDialog}
        setOpenConfirm={setOpenConfirm}
        email={email}
        setEmail={setEmail}
      />

      <Agreement
        type="schools"
        isOpenAgreement={isOpenAgreement}
        setOpenAgreement={setOpenAgreement}
        conditions={[]}
        onClickContinue={handleClickRouteToDocumentSign}
      />

      <ConfirmDialog open={openConfirm} email={email} onClickOk={handleDialogOk} />
    </div>
  );
};

export default Document;
