import React, { ChangeEvent, useCallback, useState } from 'react';
import cl from './FamilyMemberAdd.module.scss';
import { EditInStep } from 'ui/panels/EditInStep';
import { isValid, maxValidator } from 'utils/validators';
import { TextField } from 'ui/input/TextField';
import { showErrorAlert } from 'ui/dialogs/Alert';
import { FamilyMember } from 'routes/main/routes/schools/components/ProgrammJoin/model';

interface Props {
  editMember: FamilyMember | null;
  addMember: (FamilyMember: FamilyMember) => void;
  updateMember: (member: FamilyMember) => void;
  changeViewType: (view: any) => void;
  setEditMember: (member: FamilyMember | null) => void;
  idCounter: number;
}

const initForm = (member: FamilyMember | null) => ({
  last_name: member?.last_name || '',
  first_name: member?.first_name || '',
  patronymic: member?.patronymic || '',
  id: member?.id || 0,
  classOrGroup: member?.classOrGroup || '',
});

interface Errors extends Partial<Record<keyof FamilyMember, string>> {}

export const FamilyMemberAdd: React.FC<Props> = (props) => {
  const { editMember, addMember, updateMember, setEditMember, changeViewType, idCounter } = props;
  const [form, setForm] = useState(initForm(editMember));
  const [errors, setErrors] = useState<Errors>({});

  const handleChangeForm = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setErrors((e) => ({
      ...e,
      [name]: undefined,
    }));
  }, []);

  const validate = useCallback(() => {
    const errors: Errors = {
      last_name: maxValidator(form.last_name, 100, true),
      first_name: maxValidator(form.first_name, 100, true),
      patronymic: maxValidator(form.patronymic, 100),
      classOrGroup: maxValidator(form.classOrGroup, 10, true),
    };
    setErrors(errors);

    return isValid(errors);
  }, [form]);

  const handleBack = useCallback(() => {
    setEditMember(null);
    changeViewType('step');
  }, [changeViewType, setEditMember]);

  const handleSave = useCallback(async () => {
    if (!validate()) return;
    try {
      const paramForm = {
        ...form,
        id: form.id as number,
      };

      if (editMember) await updateMember({ ...paramForm, id: editMember.id });
      else await addMember({ ...paramForm, id: idCounter });

      setEditMember(null);
      changeViewType('step');
    } catch (e) {
      await showErrorAlert();
    }
  }, [validate, form, editMember, setEditMember, changeViewType, updateMember, addMember, idCounter]);

  return (
    <EditInStep
      title={editMember ? 'Редактирование члена семьи' : 'Добавление члена семьи'}
      onClickBack={handleBack}
      className={cl.root}
      onClickNext={handleSave}
    >
      <form autoComplete="off" noValidate>
        <TextField
          label="Фамилия"
          fullWidth
          value={form.last_name}
          name="last_name"
          onChange={handleChangeForm}
          error={!!errors.last_name}
          helperText={errors.last_name}
        />
        <TextField
          label="Имя"
          fullWidth
          value={form.first_name}
          name="first_name"
          onChange={handleChangeForm}
          error={!!errors.first_name}
          helperText={errors.first_name}
        />
        <TextField
          label="Отчество"
          fullWidth
          value={form.patronymic}
          name="patronymic"
          onChange={handleChangeForm}
          error={!!errors.patronymic}
          helperText={errors.patronymic}
        />
        <TextField
          label="Класс или группа"
          InputLabelProps={{ shrink: !!form.classOrGroup }}
          fullWidth
          value={form.classOrGroup}
          name="classOrGroup"
          onChange={handleChangeForm}
          error={!!errors.classOrGroup}
          helperText={errors.classOrGroup}
        />
      </form>
    </EditInStep>
  );
};
