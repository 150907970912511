import React from 'react';
import { Panel } from 'ui/panels/Panel';
import cl from './ContactPanel.module.scss';
import { fioFromObj } from 'utils/helpers';
import Icon from 'ui/Icon';
import { Avatar } from 'ui/avatar/Avatar';
import { IconButton } from '@material-ui/core';
import { onlyDigit } from 'utils/validators';

import { Contact, ContactForDepartment } from 'api/types/common';

interface Props {
  title: string;
  label?: string;
  data: Contact[] | ContactForDepartment[];
}
type type = 'fullname' | 'name';

export const ContactPanel: React.FC<Props> = ({ data, label, title }) => {
  return (
    <Panel classname={cl.root}>
      <div className={cl.header}>{title}</div>
      {label && <div className={cl.code}>{label}</div>}
      {(data as any[]).map((item) => (
        <ContactItem type={item.last_name ? 'fullname' : 'name'} key={item.id} data={item} />
      ))}
    </Panel>
  );
};

const isFullItem = (data: any, type: type): data is Contact => {
  return type === 'fullname';
};

const ContactItem: React.FC<{ data: Contact | ContactForDepartment; type: type }> = ({ data, type }) => {
  return (
    <div className={cl.teacher}>
      <div className={cl.avatar}>
        <Avatar url={data.photo} size={60} />
      </div>
      <div className={cl.text}>
        <div className={cl.fio}>{isFullItem(data, type) ? fioFromObj(data) : data.name}</div>
        <div className={cl.phone}>{data.phone}</div>
      </div>
      <IconButton href={`tel:+${onlyDigit(data.phone || '')}`}>
        <Icon type="Phone" className={cl.phoneIco} />
      </IconButton>
    </div>
  );
};
