import React, { ChangeEvent, useCallback } from 'react';
import { PanelEditable } from 'ui/panels';
import cl from './EmailPanel.module.scss';
import { Input } from '@material-ui/core';
import cn from 'classnames';
import { Field, Header } from 'ui/panels/PanelEditable';
import { updateProfileFx } from 'state/profile';
import { ConfirmDialog } from './ConfirmDialog';
import { emailValidator } from 'utils/validators';
import api from 'api/request/profile';
import { showErrorAlert } from 'ui/dialogs/Alert';
import { TextField } from 'ui/input/TextField';
import { Profile } from 'api/types/profile';

interface Props {
  disabled?: boolean;
  setEditMode: (mode: boolean) => void;
  profile: Partial<Profile>;
  editMode: boolean;
  error: string | undefined;
  setError: (errors: any) => void;
  setEditModeForMobile: boolean;
  setOpenConfirm: (isOpenConfirm: boolean) => void;
  openConfirm: boolean;
  email: string;
  setEmail: (email: string) => void;
  handleClickCancel: () => void;
}

export const EmailPanel: React.FC<Props> = ({
  handleClickCancel,
  openConfirm,
  setEmail,
  email,
  setOpenConfirm,
  disabled = false,
  setEditMode,
  profile,
  editMode,
  error,
  setError,
  setEditModeForMobile,
}) => {
  const validate = useCallback(() => {
    const error = emailValidator(email, true);

    setError(error);
    return !error;
  }, [email, setError]);

  const onClickEdit = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const handleChangeEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setEmail(value);
    },
    [setEmail],
  );

  const handleClickSave = useCallback(async () => {
    if (!validate()) return;

    try {
      if (email !== profile.email) await updateProfileFx({ email });
      if (email !== profile.email || !profile.email_verified) {
        await api.getVerificationCode();
        setOpenConfirm(true);
      }

      setEditMode(false);
    } catch (e) {
      await showErrorAlert();
    }
  }, [email, validate, profile, setOpenConfirm, setEditMode]);

  const handleDialogOk = useCallback(() => {
    setOpenConfirm(false);
  }, [setOpenConfirm]);

  return (
    <>
      <Header
        title="Почта"
        classname={cl.header}
        onClickEdit={onClickEdit}
        onClickCancel={handleClickCancel}
        showEditBtn={!editMode}
        type="profile"
      />
      <PanelEditable classname={cn(cl.root)} editMode={editMode || setEditModeForMobile} onClickSave={handleClickSave}>
        <ConfirmDialog open={openConfirm} email={email} onClickOk={handleDialogOk} />
        <div className={cl.fields}>
          <Field
            className={cl.inputDesctop}
            label="E-mail"
            value={profile.email || '-'}
            divider={false}
            editMode={editMode}
            error={error}
          >
            <Input fullWidth value={email} onChange={handleChangeEmail} error={!!error} />
          </Field>
          <TextField
            className={cl.inputMobile}
            label="E-mail"
            value={email}
            error={!!error}
            fullWidth
            onChange={handleChangeEmail}
          />
        </div>
      </PanelEditable>
    </>
  );
};
