import { AxiosRequestConfig } from 'axios';

type RDef = Record<string, string>;

interface ExtraConfig422Aliases<T extends RDef = RDef> {
  _$app_422Aliases: T;
}

interface CustomConfig422Aliases<T extends RDef = RDef>
  extends AxiosRequestConfig,
    ExtraConfig422Aliases<T> {}

export const configureAlias422Fields = <T extends RDef = RDef>(
  aliases: T,
): CustomConfig422Aliases<T> => {
  return {
    _$app_422Aliases: aliases,
  };
};

export const isAlias422Fields = <T extends RDef = RDef>(
  config: any,
): config is ExtraConfig422Aliases<T> =>
  typeof config === 'object' &&
  null !== config &&
  config.hasOwnProperty('_$app_422Aliases') &&
  typeof config._$app_422Aliases === 'object';
