import React from 'react';

interface Props {}

const ReportFromSchool: React.FC<Props> = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M13.6667 4H10.3333C10.1493 4 10 3.85 10 3.66666V0.333344C10 0.149344 9.85066 0 9.66666 0H3C2.44866 0 2 0.448656 2 1V15C2 15.5513 2.44866 16 3 16H13C13.5513 16 14 15.5513 14 15V4.33334C14 4.14934 13.8507 4 13.6667 4ZM13.3333 15C13.3333 15.1833 13.184 15.3333 13 15.3333H3C2.816 15.3333 2.66666 15.1833 2.66666 15V1C2.66666 0.816656 2.816 0.666656 3 0.666656H9.33334V3.66666C9.33334 4.218 9.782 4.66666 10.3333 4.66666H13.3333V15Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M13.902 4.09799L9.902 0.0979883C9.772 -0.0320117 9.56066 -0.0320117 9.43066 0.0979883C9.30066 0.227988 9.30066 0.439332 9.43066 0.569332L13.4307 4.56933C13.496 4.63399 13.5813 4.66668 13.6667 4.66668C13.752 4.66668 13.8373 4.63402 13.902 4.56933C14.032 4.43933 14.032 4.22799 13.902 4.09799Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M11 6H5C4.816 6 4.66666 6.14934 4.66666 6.33334C4.66666 6.51734 4.816 6.66669 5 6.66669H11C11.184 6.66669 11.3333 6.51734 11.3333 6.33334C11.3333 6.14934 11.184 6 11 6Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M11 8.66663H5C4.816 8.66663 4.66666 8.81597 4.66666 8.99997C4.66666 9.18397 4.816 9.33331 5 9.33331H11C11.184 9.33331 11.3333 9.18397 11.3333 8.99997C11.3333 8.81597 11.184 8.66663 11 8.66663Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
        <path
          d="M11 11.3334H5C4.816 11.3334 4.66666 11.4827 4.66666 11.6667C4.66666 11.8507 4.816 12.0001 5 12.0001H11C11.184 12.0001 11.3333 11.8507 11.3333 11.6667C11.3333 11.4827 11.184 11.3334 11 11.3334Z"
          fill="#69488D"
          stroke="#69488D"
          stroke-width="0.15"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReportFromSchool;
