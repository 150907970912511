import React, { useCallback, MouseEvent, useState } from 'react';
import cl from './Step4.module.scss';
import Icon from 'ui/Icon';
import { ButtonItem } from 'ui/list/ButtonItem';
import { handleFiles, shortFioFromObj } from 'utils/helpers';
import { Backdrop } from 'ui/feedback/Backdrop';
import {
  $step4,
  changeViewType,
  nextStep,
  setEditMember,
  prevStep,
  FamilyMember,
  deleteMember,
  schoolJoinFx,
  $step3,
  step3Set,
} from '../../model';
import { useStore } from 'effector-react';
import { showAlert, showErrorAlert } from 'ui/dialogs/Alert';
import { Avatar } from 'ui/avatar/Avatar';
import { IconButton } from '@material-ui/core';
import { Step } from 'ui/edits/Step';
import { ApiUnprocessableError } from 'api/errors';
import { FileLoadPanel } from 'ui/panels/FileLoadPanel';
import { UPLOAD_FILE_SIZE } from 'constants/config';

interface Props {}

interface DataType {
  id: number;
  name: string;
}

const facilities: DataType[] = [
  {
    id: 1,
    name: 'Справка с места работы',
  },
];

export const Step4: React.FC<Props> = () => {
  const loading = useStore(schoolJoinFx.pending);

  const [isFetching, setFetching] = useState(false);

  const { familyMembers } = useStore($step4);

  const step3Form = useStore($step3);

  const handleNext = useCallback(async () => {
    try {
      await schoolJoinFx();
      nextStep();
    } catch (e) {
      if (e instanceof ApiUnprocessableError) await showErrorAlert();
      else
        await showAlert(
          'error',
          'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
          'Ошибка',
        );
    }
  }, []);

  const handleEdit = useCallback(
    (member: FamilyMember) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setEditMember(member);
      changeViewType('familyMember');
    },
    [],
  );

  const handleRemoveFile = useCallback(
    (typeOfFile: string) => {
      let files = step3Form.files;
      files = files.filter((element) => element.name !== typeOfFile);
      step3Set({
        ...step3Form,
        files,
      });
    },
    [step3Form],
  );

  const handleSizeError = useCallback(() => {
    showAlert(
      'error',
      `Максимальный размер файлов для загрузки составляет ${(UPLOAD_FILE_SIZE / 1024 / 1024).toFixed(
        1,
      )}Mb. Файлы большего размера не будут загружены.`,
      'Ошибка',
    );
  }, []);

  const handleAddMember = useCallback(() => {
    setEditMember(null);
    changeViewType('familyMember');
  }, []);

  const handleDeleteMember = useCallback((id: number | undefined) => {
    deleteMember(id);
  }, []);

  const handlePrevStep = useCallback(() => {
    prevStep();
  }, []);

  return (
    <Step
      isFileLoader={true}
      title="Наличие права первоочередного поступления"
      onClickNext={handleNext}
      prevStep={handlePrevStep}
      memberAddAnnounce={true}
    >
      <Backdrop open={loading || isFetching} />
      <div className={cl.members}>
        <div className={cl.subtitle}>Подтверждение места работы</div>
        <div className={cl.files}>
          {facilities.map((element) => (
            <div key={element.id} className={cl.file}>
              <FileLoadPanel
                data={step3Form.files}
                isOpenToolTip={true}
                handleRemoveFile={handleRemoveFile}
                key={element.id}
                name={element.name}
                onClick={handleFiles.bind(null, setFetching, step3Form, step3Set)}
                onSizeError={handleSizeError}
              />
            </div>
          ))}
        </div>
        <div className={cl.saver}>
          *Информациях о гражданах, чьи дети имеют право на первоочередное поступление, согласно{' '}
          <a
            href={'https://kurchat.mskobr.ru/attach_files/upload_users_files/5fe359c92df5a.pdf'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>правилам приема в ГБОУ Курчатовская школа</span>
          </a>
        </div>

        <div className={cl.subtitleNoNeedable}>Выбор родных учащихся в этой школе</div>
        {!!familyMembers &&
          familyMembers!.map((c) => (
            <MemberItem
              key={c.id}
              fio={shortFioFromObj(c)}
              onClickEdit={handleEdit(c)}
              onClickDelete={handleDeleteMember.bind(null, c.id)}
            />
          ))}
        <AddChildrenItem onClick={handleAddMember} />
      </div>
    </Step>
  );
};

interface AddChildrenItemProps {
  onClick: () => void;
}

const AddChildrenItem: React.FC<AddChildrenItemProps> = ({ onClick }) => {
  return (
    <ButtonItem onClick={onClick}>
      <div className={cl.text}>Добавить члена семьи</div>
      <div className={cl.addIco}>
        <Icon type="PlusCircle" />
      </div>
    </ButtonItem>
  );
};

interface ChildrenItemProps {
  fio: string;
  onClickEdit: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickDelete: () => void;
}

const MemberItem: React.FC<ChildrenItemProps> = ({ fio, onClickEdit, onClickDelete }) => {
  return (
    <ButtonItem className={cl.child}>
      <Avatar size={54} />
      <div className={cl.text}>
        <div className={cl.subtitle}>Ребенок</div>
        <div className={cl.fio}>{fio}</div>
      </div>
      <IconButton className={cl.editBtn} onClick={onClickEdit}>
        <Icon type="Edit" />
      </IconButton>
      <IconButton onClick={onClickDelete}>
        <Icon type="CloseFilled" />
      </IconButton>
    </ButtonItem>
  );
};
