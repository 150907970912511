import React, { useCallback, useState } from 'react';
import cl from './PageContent.module.scss';
import { IconButton } from '@material-ui/core';
import Icon from 'ui/Icon';
import { useHistory } from 'react-router';
import { Button } from 'ui/button/Button';
import DeleteConfirm from 'ui/dialogs/DeleteConfirm';
import { childrenDeleteFX } from 'state/children';
import { showErrorAlert } from 'ui/dialogs/Alert';
import { getProfileFx } from 'state/profile';

type type = 'children' | 'profile';

interface Props {
  className?: string;
  titleSM?: string;
  handleClickCancel?: () => void;
  editMode?: boolean;
  type?: type;
  childId?: string | undefined;
}

export const PageContent: React.FC<Props> = ({
  className = '',
  children,
  titleSM,
  handleClickCancel,
  editMode = false,
  type,
  childId,
}) => {
  const { goBack } = useHistory();

  const [isOpenDialog, setOpenDialog] = useState(false);

  const history = useHistory();

  const handleClickConfirm = useCallback(async () => {
    try {
      await childrenDeleteFX(+childId!).then(() => getProfileFx());
      history.push('/profile/children');
      setOpenDialog(false);
    } catch (e) {
      await showErrorAlert();
    }
  }, [childId, history]);

  const handleClickClose = useCallback(() => {
    setOpenDialog(false);
  }, []);

  return (
    <div className={`${cl.root} ${className}`}>
      {titleSM && (
        <div className={cl.titleContainer}>
          <div className={cl.title}>
            <IconButton onClick={goBack}>
              <Icon type="Back" />
            </IconButton>
            <h1>{titleSM}</h1>
          </div>
          {editMode && (
            <Button variant="text" className={cl.cancelBtn} onClick={handleClickCancel}>
              Отмена
            </Button>
          )}
        </div>
      )}
      {children}
      {type === 'children' && childId && (
        <Button variant="text" className={cl.deleteBtn} onClick={() => setOpenDialog(true)}>
          Удалить профиль
        </Button>
      )}
      <DeleteConfirm isOpen={isOpenDialog} handleClickConfirm={handleClickConfirm} onClickClose={handleClickClose} />
    </div>
  );
};
