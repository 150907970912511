import React from 'react';

interface Props {}

const Instagram: React.FC<Props> = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20ZM20.001 9.33333C17.1041 9.33333 16.7405 9.346 15.6027 9.39778C14.4672 9.44978 13.692 9.62956 13.0138 9.89334C12.3123 10.1658 11.7171 10.5302 11.1242 11.1234C10.5309 11.7162 10.1665 12.3114 9.89312 13.0127C9.62867 13.6912 9.44867 14.4665 9.39756 15.6016C9.34667 16.7394 9.33333 17.1032 9.33333 20.0001C9.33333 22.897 9.34622 23.2595 9.39778 24.3973C9.45 25.5328 9.62978 26.308 9.89334 26.9862C10.166 27.6877 10.5305 28.2829 11.1236 28.8758C11.7162 29.4691 12.3114 29.8344 13.0125 30.1069C13.6912 30.3707 14.4665 30.5504 15.6018 30.6024C16.7396 30.6542 17.103 30.6669 19.9997 30.6669C22.8968 30.6669 23.2593 30.6542 24.397 30.6024C25.5326 30.5504 26.3086 30.3707 26.9873 30.1069C27.6886 29.8344 28.2829 29.4691 28.8755 28.8758C29.4689 28.2829 29.8333 27.6877 30.1067 26.9864C30.3689 26.308 30.5489 25.5326 30.6022 24.3975C30.6533 23.2597 30.6667 22.897 30.6667 20.0001C30.6667 17.1032 30.6533 16.7396 30.6022 15.6018C30.5489 14.4663 30.3689 13.6912 30.1067 13.0129C29.8333 12.3114 29.4689 11.7162 28.8755 11.1234C28.2822 10.53 27.6889 10.1656 26.9866 9.89334C26.3066 9.62956 25.5311 9.44978 24.3955 9.39778C23.2577 9.346 22.8955 9.33333 19.9977 9.33333H20.001Z"
        fill="#69488D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0441 11.2556C19.3281 11.2551 19.645 11.2556 20.001 11.2556C22.849 11.2556 23.1866 11.2658 24.3113 11.3169C25.3513 11.3645 25.9157 11.5382 26.2917 11.6842C26.7895 11.8776 27.1444 12.1087 27.5175 12.482C27.8909 12.8554 28.122 13.2109 28.3158 13.7087C28.4618 14.0843 28.6358 14.6487 28.6831 15.6887C28.7342 16.8132 28.7453 17.151 28.7453 19.9977C28.7453 22.8444 28.7342 23.1821 28.6831 24.3066C28.6355 25.3466 28.4618 25.9111 28.3158 26.2866C28.1224 26.7844 27.8909 27.1388 27.5175 27.512C27.1442 27.8853 26.7897 28.1164 26.2917 28.3098C25.9162 28.4564 25.3513 28.6298 24.3113 28.6773C23.1868 28.7284 22.849 28.7395 20.001 28.7395C17.1527 28.7395 16.8152 28.7284 15.6907 28.6773C14.6507 28.6293 14.0863 28.4555 13.71 28.3095C13.2123 28.1162 12.8567 27.8851 12.4834 27.5117C12.11 27.1384 11.8789 26.7837 11.6851 26.2857C11.5391 25.9102 11.3651 25.3457 11.3178 24.3057C11.2667 23.1813 11.2565 22.8435 11.2565 19.995C11.2565 17.1465 11.2667 16.8105 11.3178 15.6861C11.3654 14.6461 11.5391 14.0816 11.6851 13.7056C11.8785 13.2078 12.11 12.8523 12.4834 12.4789C12.8567 12.1056 13.2123 11.8745 13.71 11.6807C14.086 11.534 14.6507 11.3607 15.6907 11.3129C16.6747 11.2685 17.0561 11.2551 19.0441 11.2529V11.2556ZM25.6948 13.0267C24.9882 13.0267 24.4148 13.5994 24.4148 14.3063C24.4148 15.0129 24.9882 15.5863 25.6948 15.5863C26.4015 15.5863 26.9748 15.0129 26.9748 14.3063C26.9748 13.5996 26.4015 13.0267 25.6948 13.0267ZM20.001 14.5223C16.9759 14.5223 14.5232 16.975 14.5232 20.0001C14.5232 23.0253 16.9759 25.4768 20.001 25.4768C23.0261 25.4768 25.4779 23.0253 25.4779 20.0001C25.4779 16.975 23.0261 14.5223 20.001 14.5223Z"
        fill="#69488D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.001 16.4445C21.9646 16.4445 23.5566 18.0363 23.5566 20.0001C23.5566 21.9637 21.9646 23.5557 20.001 23.5557C18.0372 23.5557 16.4454 21.9637 16.4454 20.0001C16.4454 18.0363 18.0372 16.4445 20.001 16.4445Z"
        fill="#69488D"
      />
    </svg>
  );
};

export default Instagram;
