import React from 'react';
import cl from './ProfileMenuItem.module.scss';
import Icon, { IconType } from 'ui/Icon';
import { ButtonBase } from '@material-ui/core';
import cn from 'classnames';

export interface ExtraLabel {
  label: string;
  active: boolean;
}

export interface ProfileMenuItemType {
  title: string;
  subtitle: string;
  iconType: IconType;
  path: string;
  extraLabel?: ExtraLabel;
  screenType?: string;
}

export interface Props extends ProfileMenuItemType {
  onClick: (path: string) => void;
  isActive: boolean;
  disabled?: boolean;
  withArrow?: boolean;
  classname?: string;
}

export const ProfileMenuItem: React.FC<Props> = ({
  title,
  subtitle,
  iconType,
  path,
  onClick,
  isActive,
  extraLabel,
  disabled,
  withArrow = true,
  classname,
}) => {
  return (
    <ButtonBase
      className={cn(cl.root, classname, isActive && cl.active)}
      component="li"
      onClick={onClick.bind(null, path)}
      disabled={disabled}
    >
      <div className={cl.ico}>
        <Icon type={iconType} />
      </div>
      <div className={cl.text}>
        <div className={cl.subtitle}>{subtitle}</div>
        <div className={cl.title}>{title}</div>
      </div>
      {withArrow && (
        <div className={cl.ico}>
          <Icon type="ArrowForward" />
        </div>
      )}
      {Boolean(extraLabel) && (
        <div
          className={cn(
            title === 'Учетная запись' || extraLabel?.label === 'Подтвердите почту'
              ? cl.extraLabel
              : cn(cl.extraLabel, cl.extraLableMain),
            extraLabel?.active && cl.extraLabelActive,
          )}
        >
          {extraLabel?.label}
        </div>
      )}
    </ButtonBase>
  );
};
