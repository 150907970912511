import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import cl from './ProgrammJoin.module.scss';
import { Step, Stepper } from 'ui/stepper/Stepper';
import { Button } from 'ui/button/Button';
import { useStore } from 'effector-react';
import {
  $editChild,
  $step,
  $step1,
  $editMember,
  $viewType,
  addChild,
  addMember,
  changeViewType,
  resetSteps,
  setEditChild,
  step1SetProfile,
  updateChild,
  updateMember,
  setEditMember,
  $step4,
  FamilyMember,
  setCurrentParallelId,
  setCurrentProgrammId,
  setCurrentPlaceId,
  step1SetProfileRep,
  $step1Rep,
  $step2,
  $step3,
  nextStep,
  step1ToggleSaver,
  initChild,
  step2SetChild,
  step2ToggleSaver,
  prevStep,
  step3Set,
} from './model';
import { ProfileEdit } from 'ui/edits/ProfileEdit/ProfileEdit';
import { EmailEdit } from 'ui/edits/EmailEdit/EmailEdit';
import { Step1 } from '../../../../../../ui/commonSteps/Step1/Step1';
import { Step2 } from '../../../../../../ui/commonSteps/Step2/Step2';
import { Step3 } from '../../../../../../ui/commonSteps/Step3/Step3';
import { Step4 } from './steps/Step4/Step4';
import { Step5 } from './steps/Step5/Step5';
import { RequisitesEdit } from 'ui/edits/RequisitesEdit/RequisitesEdit';
import { ChildAdd } from 'ui/edits/ChildAdd/ChildAdd';
import { FamilyMemberAdd } from 'ui/edits/FamilyMemberAdd';
import { setActiveProgramm } from 'state/school';
import { SecondRepresentative } from 'ui/edits/SecondRepresentativeEdit/SecondRepresentative';
import { useSchools } from 'hooks/schools';
import {
  extraFacilitiesForSchools,
  facilitiesForSchools,
  needableFiles,
  needableFilesOlder,
} from 'ui/commonSteps/utils/NeedableFiles';

interface Props extends RouteComponentProps<{ placeId: string; programm: string; parallel: string }> {}

const ProgrammJoin: React.FC<Props> = ({
  match: {
    params: { parallel, programm, placeId },
  },
}) => {
  const { data: schoolsData } = useSchools();

  const { needSave, profile } = useStore($step1);

  const { profileRep } = useStore($step1Rep);

  const children = useStore($step2);

  const step3Form = useStore($step3);

  const STEPS: Step[] = [
    {
      label: 'Реквизиты',
      component: (
        <Step1
          needSave={needSave}
          profile={profile}
          profileRep={profileRep}
          changeViewType={changeViewType}
          nextStep={nextStep}
          step1SetProfile={step1SetProfile}
          step1ToggleSaver={step1ToggleSaver}
        />
      ),
    },
    {
      label: 'Выбор ребенка',
      component: (
        <Step2
          childrenInfo={children}
          changeViewType={changeViewType}
          initChild={initChild}
          nextStep={nextStep}
          setEditChild={setEditChild}
          step2ToggleSaver={step2ToggleSaver}
          step2SetChild={step2SetChild}
          prevStep={prevStep}
          type={'school'}
        />
      ),
    },
    {
      label: 'Загрузка документов',
      component: (
        <Step3
          needableFiles={needableFiles}
          needableFilesOlder={needableFilesOlder}
          facilities={facilitiesForSchools}
          extraFacilities={extraFacilitiesForSchools}
          type={'schools'}
          childrenInfo={children}
          step3Form={step3Form}
          nextStep={nextStep}
          prevStep={prevStep}
          step3Set={step3Set}
        />
      ),
    },
    {
      label: 'Выбор родных',
      component: <Step4 />,
    },
    {
      label: 'Подписание договора',
      component: <Step5 />,
    },
  ];

  const CurrentProgramm = useMemo(
    () => schoolsData.find((element) => element.number === +parallel)?.programs.find((elem) => elem.id === +programm),
    [parallel, programm, schoolsData],
  );

  const CurrentProgrammPlace = useMemo(() => CurrentProgramm?.places.find((elem) => elem.id === +placeId), [
    placeId,
    CurrentProgramm,
  ]);

  const step = useStore($step);

  const profileInfo = useStore($step1);

  const profileRepInfo = useStore($step1Rep);

  const viewType = useStore($viewType);

  const history = useHistory();

  const child = useStore($editChild);

  const editMember = useStore($editMember);

  const { familyMembers } = useStore($step4);

  const currentParallel = schoolsData.find((elem) => elem.number === +parallel);

  setCurrentParallelId(currentParallel?.id || 0);
  setCurrentProgrammId(+programm);
  setCurrentPlaceId(+placeId);

  const getNewId = useCallback((member: FamilyMember) => {
    if (member !== undefined) {
      return member.id + 1;
    } else {
      return 1;
    }
  }, []);

  const handleClickRouteBack = useCallback(() => {
    history.push(`/schools/${+parallel}/${+programm}/${+placeId}`);
  }, [history, parallel, programm, placeId]);

  const handleClickCancel = () => {
    handleClickRouteBack();
    setActiveProgramm(-1);
  };

  const stepRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setActiveProgramm(+programm);
  }, [programm]);

  useEffect(() => {
    if (stepRef.current) {
      const { top } = stepRef.current.getBoundingClientRect();
      setOffset(top + 46);
    }
  }, [stepRef]);

  useEffect(() => {
    return () => {
      resetSteps();
    };
  }, []);

  return (
    <div className={cl.root}>
      <div className={cl.header}>
        <h1>{CurrentProgrammPlace?.title}</h1>
        <Button variant="text" onClick={handleClickCancel}>
          Отменить запись
        </Button>
      </div>
      <Stepper steps={STEPS} current={step} />
      {viewType === 'step' && (
        <div ref={stepRef} style={{ minHeight: `calc(100vh - ${offset}px)` }} className={cl.stepWrapper}>
          {STEPS[step].component}
        </div>
      )}
      {viewType === 'profile' && (
        <ProfileEdit state={profileInfo} changeViewType={changeViewType} stepSetProfile={step1SetProfile} />
      )}
      {viewType === 'email' && <EmailEdit state={profileInfo} changeViewType={changeViewType} />}
      {viewType === 'requisites' && (
        <RequisitesEdit
          state={profileInfo}
          type={'school'}
          changeViewType={changeViewType}
          stepSetProfile={step1SetProfile}
        />
      )}
      {viewType === 'secondRepresentative' && (
        <SecondRepresentative
          state={profileRepInfo}
          changeViewType={changeViewType}
          stepSetProfile={step1SetProfileRep}
        />
      )}
      {viewType === 'child' && (
        <ChildAdd
          childInfo={child}
          addChild={addChild}
          setEditChild={setEditChild}
          updateChild={updateChild}
          changeViewType={changeViewType}
          isNeededExtraFields={true}
        />
      )}
      {viewType === 'familyMember' && (
        <FamilyMemberAdd
          editMember={editMember}
          setEditMember={setEditMember}
          addMember={addMember}
          updateMember={updateMember}
          changeViewType={changeViewType}
          idCounter={getNewId(familyMembers[familyMembers.length - 1])}
        />
      )}
    </div>
  );
};
export default ProgrammJoin;
