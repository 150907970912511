import React from 'react';

interface Props {
}

const History: React.FC<Props> = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g >
          <path id="icon/action/watch_later_24px" fillRule="evenodd" clipRule="evenodd" d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 7H12.5V12.2L17 14.9L16.2 16.2L11 13V7Z" fill="currentColor"/>
        </g>
      </svg>

  );
};

export default History;

