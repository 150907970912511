import React, { useCallback } from 'react';
import cl from './Step.module.scss';
import { Button } from 'ui/button/Button';
import cn from 'classnames';
import { showAlert } from 'ui/dialogs/Alert';
import Icon from '../../Icon';

interface Props {
  title?: string;
  hasPrev?: boolean;
  onClickPrev?: () => void;
  onClickNext?: () => void;
  disabledNext?: boolean;
  className?: string;
  contentClassName?: string;
  withSaver?: boolean;
  onClickSaver?: () => void;
  needSave?: boolean;
  prevStep?: () => void;
  memberAddAnnounce?: boolean;
  isFileLoader?: boolean;
}

const SIGN_TEXT = (
  <>
    В соответствии с федеральным законом от 06.04.2011 N 63-ФЗ «Об электронной подписи» договор будет подписан простой
    электронной подписью.
    <br />
    <br />
    Вы обязуетесь соблюдать конфиденциальность полученного кода.
  </>
);

export const Step: React.FC<Props> = ({
  isFileLoader = false,
  title,
  children,
  hasPrev = true,
  onClickNext,
  disabledNext = false,
  className,
  contentClassName,
  withSaver = false,
  onClickSaver,
  needSave = false,
  prevStep,
  memberAddAnnounce = false,
}) => {
  const handleClickSign = useCallback(() => {
    showAlert('default', SIGN_TEXT, 'Информация');
  }, []);

  return (
    <div className={cn(cl.root, className)}>
      {!!title && <div className={cl.title}>{title}</div>}
      <div className={cn(cl.content, contentClassName)}>
        {children}
        {withSaver && (
          <div className={cn(cl.saver, needSave && cl.needSave)}>
            <label onClick={onClickSaver}>
              <Icon type={needSave ? 'CheckCircle' : 'Circle'} />
              <span>Сохранить для последующего заполнения</span>
            </label>
            *При сохранении реквизитов мы храним ваши данные на серверах, соответствующих ФЗ №152.
          </div>
        )}
        {memberAddAnnounce && (
          <div className={cl.saver}>*Дети, проживающие в одной семье и имеющие общее место жительства.</div>
        )}
      </div>
      <div className={cl.footer}>
        {isFileLoader && (
          <div className={cl.fileLoader}>
            {' '}
            * Для загрузки допустимы следующие <br /> разрешения файлов: pdf, jpeg, jpg, png
          </div>
        )}
        {!hasPrev ? (
          <div className={cl.text}>
            *Продолжая, вы даете свое согласие на подписание договора{' '}
            <span onClick={handleClickSign}>Простой электронной подписью</span>
          </div>
        ) : (
          <Button variant="text" className={cl.btnBack} onClick={prevStep}>
            Назад
          </Button>
        )}

        <Button onClick={onClickNext} className={cl.btnNext} disabled={disabledNext}>
          Продолжить
        </Button>
        {hasPrev ? (
          <Button variant="text" className={cl.btnBackMobile} onClick={prevStep}>
            Назад
          </Button>
        ) : (
          <div className={cl.textForMobile}>
            *Продолжая, вы даете свое согласие на подписание договора{' '}
            <span onClick={handleClickSign}>Простой электронной подписью</span>
          </div>
        )}
        {isFileLoader && (
          <div className={cl.fileLoaderForMobile}>
            {' '}
            * Для загрузки допустимы следующие <br /> разрешения файлов: pdf, jpeg, jpg, png
          </div>
        )}
      </div>
    </div>
  );
};
