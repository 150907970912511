import { ProfileSecond } from 'api/types/profile';
import { toLocalDate } from 'constants/date/format';
import { EditInStep } from 'ui/panels/EditInStep';
import cl from './SecondRepresentative.module.scss';
import { DateMask, PhoneMask } from 'ui/masks';
import { TextField } from 'ui/input/TextField';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { showErrorAlert } from 'ui/dialogs/Alert';
import { dateValidator, emailValidator, isValid, maxValidator, requiredValidator } from 'utils/validators';
import { useAdultTypes } from 'hooks/catalog/adultTypes';
import { MenuItem } from '@material-ui/core';

interface StepState {
  profileRep: ProfileSecond | undefined;
}

interface Props {
  state: StepState;
  changeViewType: (view: any) => void;
  stepSetProfile: (profileRep: ProfileSecond) => void;
}

interface Form {
  adult_type_id_rep: string;
  last_name_rep: string;
  first_name_rep: string;
  patronymic_rep: string;
  dob_rep: string;
  phone_rep: string;
  email_rep: string;
}

const initForm = (profileRep?: Partial<ProfileSecond>) => ({
  adult_type_id_rep: profileRep?.adult_type_id_rep || '',
  last_name_rep: profileRep?.last_name_rep || '',
  first_name_rep: profileRep?.first_name_rep || '',
  patronymic_rep: profileRep?.patronymic_rep || '',
  dob_rep: toLocalDate(profileRep?.dob_rep) || '',
  phone_rep: profileRep?.phone_rep || '',
  email_rep: profileRep?.email_rep || '',
});

export const SecondRepresentative: React.FC<Props> = ({ state, changeViewType, stepSetProfile }: Props) => {
  const { profileRep } = state;
  const [form, setFrom] = useState<Form>(initForm(profileRep));
  const [errors, setErrors] = useState<Partial<Form>>({});
  const { data: adultTypeList } = useAdultTypes();

  useEffect(() => {
    setFrom(initForm(profileRep));
  }, [profileRep]);
  const handleChangeForm = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFrom((form) => ({
      ...form,
      [name]: value,
    }));

    setErrors((e) => ({
      ...e,
      [name]: undefined,
    }));
  }, []);
  const validate = useCallback(() => {
    const errors: Partial<Form> = {
      adult_type_id_rep: requiredValidator(form.adult_type_id_rep),
      last_name_rep: maxValidator(form.last_name_rep, 100, true),
      first_name_rep: maxValidator(form.first_name_rep, 100, true),
      patronymic_rep: maxValidator(form.patronymic_rep, 100),
      dob_rep: dateValidator(form.dob_rep, true, true),
      email_rep: emailValidator(form.email_rep || '', true),
    };
    setErrors(errors);

    return isValid(errors);
  }, [form]);

  const handleSave = useCallback(async () => {
    if (!validate()) return;
    try {
      stepSetProfile({ ...profileRep!, ...form });
      changeViewType('step');
    } catch (e) {
      await showErrorAlert();
    }
  }, [validate, form, profileRep, stepSetProfile, changeViewType]);

  return (
    <EditInStep
      title="Второй представитель"
      onClickBack={changeViewType.bind(null, 'step')}
      className={cl.root}
      onClickNext={handleSave}
    >
      <form autoComplete="off" noValidate>
        <TextField
          fullWidth
          label="Статус законного представителя"
          name="adult_type_id_rep"
          value={+form.adult_type_id_rep}
          select
          onChange={handleChangeForm}
          error={!!errors.adult_type_id_rep}
          helperText={errors.adult_type_id_rep}
        >
          {adultTypeList.map(({ id, title }) => (
            <MenuItem key={id} value={id}>
              {title}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Фамилия"
          fullWidth
          value={form.last_name_rep}
          name="last_name_rep"
          onChange={handleChangeForm}
          error={!!errors.last_name_rep}
          helperText={errors.last_name_rep}
        />
        <TextField
          label="Имя"
          fullWidth
          value={form.first_name_rep}
          name="first_name_rep"
          onChange={handleChangeForm}
          error={!!errors.first_name_rep}
          helperText={errors.first_name_rep}
        />
        <TextField
          label="Отчество"
          fullWidth
          value={form.patronymic_rep}
          name="patronymic_rep"
          onChange={handleChangeForm}
          error={!!errors.patronymic_rep}
          helperText={errors.patronymic_rep}
        />
        <TextField
          label="Дата рождения"
          fullWidth
          value={form.dob_rep}
          name="dob_rep"
          onChange={handleChangeForm}
          error={!!errors.dob_rep}
          helperText={errors.dob_rep}
          InputProps={{ inputComponent: DateMask }}
        />
        <TextField
          label="Телефон"
          fullWidth
          value={form.phone_rep}
          name="phone_rep"
          onChange={handleChangeForm}
          error={!!errors.phone_rep}
          helperText={errors.phone_rep}
          InputProps={{ inputComponent: PhoneMask }}
        />
        <TextField
          label="E-mail"
          fullWidth
          value={form.email_rep}
          name="email_rep"
          onChange={handleChangeForm}
          error={!!errors.email_rep}
          helperText={errors.email_rep}
        />
      </form>
    </EditInStep>
  );
};
