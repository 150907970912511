import React, { useEffect, useState } from 'react';
import { showAlert } from 'ui/dialogs/Alert';
import { Dialog, CircularProgress, DialogContent, DialogContentText } from '@material-ui/core';
import cl from './EmailConfirmation.module.scss';
import { RouteComponentProps } from 'react-router-dom';
import { checkVerificationCodeFx } from 'state/profile';

interface Props extends RouteComponentProps<{ code: string }> {}

export const EmailConfirmation: React.FC<Props> = ({ match: { params }, history }) => {
  const [openProgress, setOpenProgress] = useState(true);

  useEffect(() => {
    checkVerificationCodeFx(params.code)
      .then(() => {
        showAlert('success', '', 'Ваша почта подтверждена!').then(() => {
          history.replace('/');
        });
      })
      .catch(() => {
        showAlert('error', 'Запросите ссылку для подтверждения снова', 'Во время операции произошла ошибка!').then(
          () => {
            history.replace('/');
          },
        );
      })
      .finally(() => {
        setOpenProgress(false);
      });
  }, [params, history]);

  return (
    <>
      <Dialog open={openProgress} classes={{ root: cl.root, paper: cl.paper }}>
        <DialogContent>
          <CircularProgress />
          <DialogContentText>Подтверждение e-mail</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
