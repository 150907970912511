import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $clubs, fetchClubListIfNeed } from 'state/club';

export const useClubs = () => {
  const clubs = useStore($clubs);

  useEffect(() => {
    fetchClubListIfNeed();
  }, []);

  return clubs;
};
