import { ApiError } from 'api/errors/apiError';
import api from 'api/request/documents';
import { ApiResponse } from 'api/types';
import { DocumentsType } from 'api/types/documents';
import { SchoolsHistoryItem } from 'api/types/schools/histoty';
import { createEffect, createEvent, createStore, guard, sample } from 'effector';
import { logout } from 'state/auth';
import { FetchingListState, initFetchingListState, storeAttachFetchList } from 'state/utils';

export interface DocumentsState extends FetchingListState<DocumentsType> {}

export const getDocumentsListFx = createEffect<void, ApiResponse<DocumentsType[]>, ApiError<any>>({
  handler: async () => await api.list(),
});

export const fetchDocumentsListIfNeed = createEvent();

export const $documents = createStore<DocumentsState>(initFetchingListState<DocumentsType>()).reset(logout);

guard({
  source: sample($documents, fetchDocumentsListIfNeed),
  filter: $documents.map((state) => !state.isFetching && !state.didFetched),
  target: getDocumentsListFx,
});

storeAttachFetchList($documents, [getDocumentsListFx]);

export const getDocumentsHistoryFx = createEffect<void, ApiResponse<SchoolsHistoryItem[]>>({
  handler: async () => await api.history(),
});
