import React, { ComponentType, FC } from 'react';
import LoginRedirect from '../LoginRedirect';
import { useStore } from 'effector-react';
import { $auth } from 'state/auth';

const authRequired = <P,>(Component: ComponentType<P>): FC<P> => (props) => {
  const { token } = useStore($auth);
  const isAuthenticated = Boolean(token);

  if (!isAuthenticated) {
    return <LoginRedirect />;
  }

  return <Component {...props} />;
};

export default authRequired;
