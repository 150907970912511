import React, { useCallback, MouseEvent, useEffect, useMemo } from 'react';
import { useChildren } from 'hooks/children';
import cl from './Step2.module.scss';
import Icon from 'ui/Icon';
import { ButtonItem } from 'ui/list/ButtonItem';
import { shortFioFromObj } from 'utils/helpers';
import cn from 'classnames';
import { ViewTypeSchool } from '../../../routes/main/routes/schools/components/ProgrammJoin/model';
import { checkChildrenRequisites } from 'state/children';
import { showErrorAlert } from 'ui/dialogs/Alert';
import { useChildAvatar } from 'hooks/children/useChildAvatar';
import { Avatar } from 'ui/avatar/Avatar';
import { IconButton } from '@material-ui/core';
import { Children } from 'api/types/children';
import { Step } from 'ui/edits/Step';
import { Map as ImmutableMap } from 'immutable';
import { ViewTypeGarten } from 'routes/main/routes/gartens/components/GartenJoin/model';

interface ChildrenInfo {
  child_id?: number;
  needSave: boolean;
  childrenList?: Children[];
  childrenMap?: ImmutableMap<number, Children>;
}
type type = 'school' | 'garten';
interface Props {
  childrenInfo: ChildrenInfo;
  changeViewType: (step: ViewTypeGarten | ViewTypeSchool) => void;
  initChild: (children: Children[]) => void;
  nextStep: any;
  setEditChild: (child: Children | null) => void;
  step2ToggleSaver: any;
  step2SetChild: (childId: number | undefined) => void;
  prevStep: any;
  type: type;
}

export const Step2: React.FC<Props> = ({
  childrenInfo,
  changeViewType,
  initChild,
  nextStep,
  setEditChild,
  step2ToggleSaver,
  step2SetChild,
  prevStep,
  type,
}) => {
  const { child_id, childrenList, childrenMap, needSave } = childrenInfo;
  const { data: globalChildrenList } = useChildren();

  useEffect(() => {
    if (!childrenList) {
      initChild(globalChildrenList || []);
    }
  }, [globalChildrenList, childrenList, initChild]);

  const disableNextButton = useCallback(() => {
    if (type === 'school') {
      if (
        !child_id ||
        !checkChildrenRequisites(childrenMap!.get(child_id!)) ||
        !!childrenMap!.get(child_id!)?.citizenship === false ||
        !!childrenMap!.get(child_id!)?.actual_address === false
      ) {
        return true;
      } else return false;
    } else if (!child_id || !checkChildrenRequisites(childrenMap!.get(child_id!))) {
      return true;
    } else return false;
  }, [child_id, childrenMap, type]);

  const handleNext = useCallback(async () => {
    try {
      nextStep();
    } catch (e) {
      await showErrorAlert();
    }
  }, [nextStep]);

  const handleEdit = useCallback(
    (child: Children) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setEditChild(child);
      changeViewType('child');
    },
    [changeViewType, setEditChild],
  );

  const handleAddChild = useCallback(() => {
    setEditChild(null);
    changeViewType('child');
  }, [changeViewType, setEditChild]);

  const handleToggleSaver = useCallback(() => {
    step2ToggleSaver();
  }, [step2ToggleSaver]);

  const handlePrevStep = useCallback(() => {
    prevStep();
  }, [prevStep]);

  const needAddChild = useMemo(() => !childrenList?.find((c) => c.id < 0), [childrenList]);

  return (
    <Step
      title="Выберите ребенка"
      onClickNext={handleNext}
      disabledNext={disableNextButton()}
      withSaver
      needSave={needSave}
      onClickSaver={handleToggleSaver}
      prevStep={handlePrevStep}
    >
      <div className={cl.children}>
        {!!childrenList &&
          childrenList!.map((c) => (
            <ChildrenItem
              key={c.id}
              fio={shortFioFromObj(c)}
              onClick={step2SetChild.bind(null, c.id)}
              onClickEdit={handleEdit(c)}
              selected={child_id === c.id}
              photo={c.photo}
              filled={
                type === 'school'
                  ? checkChildrenRequisites(c) && !!c.citizenship && !!c.actual_address
                  : checkChildrenRequisites(c)
              }
            />
          ))}
        {needAddChild && <AddChildrenItem onClick={handleAddChild} />}
      </div>
    </Step>
  );
};

interface AddChildrenItemProps {
  onClick: () => void;
}

const AddChildrenItem: React.FC<AddChildrenItemProps> = ({ onClick }) => {
  return (
    <ButtonItem onClick={onClick}>
      <div className={cl.text}>Добавить ребенка</div>
      <div className={cl.addIco}>
        <Icon type="PlusCircle" />
      </div>
    </ButtonItem>
  );
};

interface ChildrenItemProps {
  fio: string;
  selected: boolean;
  onClick: () => void;
  onClickEdit: (e: MouseEvent<HTMLButtonElement>) => void;
  photo?: string | null;
  filled: boolean;
}

const ChildrenItem: React.FC<ChildrenItemProps> = ({ fio, onClick, selected, photo, onClickEdit, filled }) => {
  const avatar = useChildAvatar(photo);
  return (
    <ButtonItem className={cn(cl.child, selected && cl.selected)} onClick={onClick}>
      <div className={cn(cl.extra, filled && cl.filled)}>{filled ? 'Заполнено' : 'Не заполнено'}</div>
      <Avatar size={54} url={avatar} />
      <div className={cl.text}>
        <div className={cl.subtitle}>Ребенок</div>
        <div className={cl.fio}>{fio}</div>
      </div>
      <IconButton className={cl.editBtn} onClick={onClickEdit}>
        <Icon type="Edit" />
      </IconButton>
      <div className={cl.check}>
        <Icon type={selected ? 'CheckCircle' : 'Circle'} />
      </div>
    </ButtonItem>
  );
};
