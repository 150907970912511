import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $adultTypes, fetchAdultTypeIfNeed } from 'state/catalog/adult_types';

export const useAdultTypes = () => {
  const adultTypes = useStore($adultTypes);

  useEffect(() => {
    fetchAdultTypeIfNeed();
  }, []);

  return adultTypes;
};
