import React, { useCallback, useEffect } from 'react';
import cl from './Default.module.scss';
import { useHistory } from 'react-router';
import { LoginContent } from 'components/layout/LoginLayout/LoginContent';
import { Button } from 'ui/button/Button';
import cn from 'classnames';
import Icon from 'ui/Icon';

export interface LoginLocationState {
  type: 'phone' | 'mosru';
  redirect?: string;
}

const Default: React.FC = () => {
  useEffect(() => {
    if (!localStorage.getItem('accessToken')) {
      localStorage.removeItem('mosru_state');
    }
  }, []);

  const history = useHistory<LoginLocationState>();
  const handleClickPhone = useCallback(() => {
    history.push('/login/privacy', { type: 'phone', redirect: history.location.search });
  }, [history]);

  const handleClickMosRu = useCallback(() => {
    history.push('/login/privacy', { type: 'mosru' });
  }, [history]);

  return (
    <LoginContent className={cl.root} contentClassName={cl.wrapper}>
      <h1>Школьная жизнь</h1>
      <h2>МФЦ-онлайн ГБОУ Курчатовской школы</h2>
      <Button size="large" onClick={handleClickPhone} className={cn(cl.btn, cl.phone)}>
        Номер телефона
      </Button>
      {/* <div className={cl.choice}>или войти используя mos.ru</div>
      <Button size="large" onClick={handleClickMosRu} className={cn(cl.btn, cl.mos)} startIcon={<Icon type="Mos" />}>
        mos.ru
      </Button> */}
      <a href={'https://corp.kurchat.com'}>
        <span className={cl.enterForWorkers}>Вход для сотрудников</span>
      </a>
    </LoginContent>
  );
};

export default Default;
