import { RightSide } from 'components/layout/MainLayout/RightSide';
import React, { useMemo } from 'react';
import { Switch } from 'react-router';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Default } from './components/Default';
import Garten from './components/Garten';
import GartenJoin from './components/GartenJoin';
import GartensLeftSide from './components/GartensLeftSide';

interface Props extends RouteComponentProps {}

const Gartens: React.FC<Props> = ({ match, location }) => {
  const isRootPath = useMemo(() => {
    const { pathname } = location;
    const partsQty = pathname.split('/').length;
    if (partsQty <= 2) return true;
    else return false;
  }, [location]);

  return (
    <>
      <GartensLeftSide isRootPath={isRootPath} />
      <RightSide isRootPath={isRootPath} useScroll={location.pathname !== match.url}>
        <Switch>
          <Route path={`${match.url}/:id/join`} component={GartenJoin} />
          <Route path={`${match.url}/:id`} component={Garten} />
          <Route path={match.url} component={Default} />
        </Switch>
      </RightSide>
    </>
  );
};

export default Gartens;
