import { Backdrop } from 'ui/feedback/Backdrop';
import { ApiUnprocessableError } from 'api/errors';
import { useStore } from 'effector-react';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { showAlert, showErrorAlert } from 'ui/dialogs/Alert';
import { Step } from 'ui/edits/Step';
import { TextField } from 'ui/input/TextField';
import { DateMask } from 'ui/masks';
import { dateValidator } from 'utils/validators';
import { $step3, documentSignFx, nextStep, prevStep, step3Set } from '../../model';
import cl from './Step3.module.scss';
import Icon from 'ui/Icon';
import cn from 'classnames'

interface ErrorsType {
  applicationNumber: undefined | string;
  applicationDate: undefined | string;
}

const Step3 = () => {
  const [isFetching, setFetching] = useState(false);

  const [errors, setErrors] = useState<ErrorsType>({
    applicationNumber: undefined,
    applicationDate: undefined,
  });
  const step3 = useStore($step3);

  const handleNext = useCallback(async () => {
    if (dateValidator(step3.applicationDate, true, true)) {
      setErrors((state) => ({ ...state, applicationDate: dateValidator(step3.applicationDate, true, true) }));
    } else {
      try {
        setFetching(true);
        await documentSignFx();
        setFetching(false);
        nextStep();
      } catch (e) {
        setFetching(false);
        if (e instanceof ApiUnprocessableError) await showErrorAlert();
        else
          await showAlert(
            'error',
            'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
            'Ошибка',
          );
      }
    }
  }, [step3.applicationDate]);
  const handlePrevStep = useCallback(() => {
    prevStep();
  }, []);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    step3Set({ ...step3, [name]: value });
  };
  const handleClickItem = (id: number) => {
    step3Set({...step3, dop_sum: id});
  }
  return (
    <Step
      title={'Номер, дата и тип договор'}
      disabledNext={!step3.applicationDate || !step3.applicationNumber || step3.dop_sum === null}
      onClickNext={handleNext}
      prevStep={handlePrevStep}
    >
      <Backdrop open={isFetching} />
      <>
        <div className={cl.container}>
          <TextField
            label="Введите номер договора"
            fullWidth
            name="applicationNumber"
            value={step3.applicationNumber}
            onChange={onChange}
            error={!!errors.applicationNumber}
            helperText={errors.applicationNumber}
          />
          <TextField
            label="Введите дату договора"
            fullWidth
            name="applicationDate"
            InputProps={{ inputComponent: DateMask }}
            value={step3.applicationDate}
            onChange={onChange}
            error={!!errors.applicationDate}
            helperText={errors.applicationDate}
          />
        </div>
        <div className={cl.secondBlock}>
          <div>Выберите тип договора</div>
          <div className={cl.content}>
            <div onClick={() => handleClickItem(1)} className={cn(cl.item, step3.dop_sum !== 1 ? cl.itemNotActive : cl.itemActive)}>
            <Icon type={step3.dop_sum === 1 ? 'CheckCircle' : 'Circle'} />
              Договор полного дня 
              </div>
            <div onClick={() => handleClickItem(0)} className={cn(cl.item, step3.dop_sum !== 0 ? cl.itemNotActive : cl.itemActive)}>
            <Icon type={step3.dop_sum === 0 ? 'CheckCircle' : 'Circle'} />
              Договор ГКП
              </div>

          </div>
        </div>
      </>
    </Step>
  );
};

export default Step3;
