import MaskedInput, { maskArray } from 'react-text-mask';
import React from 'react';
import { MASK_BIRTH_CERTIFICATE, MASK_DATE, MASK_INILA, MASK_PASSPORT, MASK_PHONE } from '../../constants/format/masks';

interface MaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

// export function DateMask(props: MaskCustomProps) {
//   const { inputRef, ...other } = props;
//
//   return (
//     <MaskedInput
//       {...other}
//       ref={(ref: any) => {
//         inputRef(ref ? ref.inputElement : null);
//       }}
//       mask={MASK_DATE}
//       placeholderChar={'\u2000'}
//       showMask={false}
//       guide={false}
//     />
//   );
// }

export const InputMask = (mask: maskArray) => (props: MaskCustomProps) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask={false}
      guide={false}
    />
  );
};

export const DateMask: any = InputMask(MASK_DATE);

export const PassportMask: any = InputMask(MASK_PASSPORT);

export const InilaMask: any = InputMask(MASK_INILA);

export const BirthCertMask: any = InputMask(MASK_BIRTH_CERTIFICATE);

export const PhoneMask: any = InputMask(MASK_PHONE);
