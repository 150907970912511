import { Effect, Store } from 'effector';
import { ApiResponse } from '../api/types';
import { Map as ImmutableMap } from 'immutable';

export interface FetchingBaseState {
  isFetching: boolean;
  isFetchFailed: boolean;
  fetchError: string | null;
  didFetched: boolean;
  didInvalidate: boolean;
  // fetchReqId?: number | null;
  isSending: boolean;
}

export interface FetchingState<Data = any> extends FetchingBaseState {
  data: Partial<Data>;
}

export interface FetchingListState<Data = any, Id = number> extends FetchingBaseState {
  data: Data[];
  map: ImmutableMap<Id, Data>;
}

// export interface FetchingMeta {
//   reqId: number;
// }

export const needFetch = (state: FetchingState): boolean =>
  !state.isFetching && (!state.didFetched || state.didInvalidate);

export const newReqId = (() => {
  let i = 0;

  return () => ++i;
})();

export const initFetchingState = <D>(): FetchingState<D> => ({
  data: {},
  isFetching: false,
  // fetchReqId: null,
  isFetchFailed: false,
  fetchError: null,
  didFetched: false,
  didInvalidate: false,
  isSending: false,
});

export const initFetchingListState = <D>(): FetchingListState<D> => ({
  data: [],
  map: ImmutableMap(),
  isFetching: false,
  // fetchReqId: null,
  isFetchFailed: false,
  fetchError: null,
  didFetched: false,
  didInvalidate: false,
  isSending: false,
});

const attachPending = (store: Store<any>, effects: Effect<any, any, any>[]) => {
  effects.forEach((item) => {
    store
      .on(item.pending, (state, pending) =>
        pending
          ? {
              ...state,
              isFetching: true,
              didInvalidate: false,
            }
          : state,
      )
      .on(item.fail, (state) => ({
        ...state,
        isFetching: false,
        fetchReqId: null,
        isFetchFailed: true,
      }));
  });
};

export const storeAttachFetch = <Data, Params = any, Fail = Error>(
  store: Store<FetchingState<Data>>,
  effects: Effect<Params, ApiResponse<Data>, Fail>[],
) => {
  attachPending(store, effects);
  effects.forEach((item) => {
    store.on(item.doneData, (state, { data }) => ({
      ...state,
      data,
      isFetching: false,
      fetchReqId: null,
      isFetchFailed: false,
      fetchError: null,
      didFetched: true,
    }));
  });
};

export const storeAttachFetchList = <Data extends { id: number }, Params = any, Fail = Error>(
  store: Store<FetchingListState<Data>>,
  effects: Effect<Params, ApiResponse<Data[]>, Fail>[],
) => {
  attachPending(store, effects);
  effects.forEach((item) => {
    store.on(item.doneData, (state, { data }) => ({
      ...state,
      data,
      map: ImmutableMap(data.map((c) => [c.id, c])),
      isFetching: false,
      fetchReqId: null,
      isFetchFailed: false,
      fetchError: null,
      didFetched: true,
    }));
  });
};

export const storeAttachSending = <Data, Fail = Error>(
  store: Store<FetchingState<Data>>,
  effects: Effect<any, ApiResponse<Data>, Fail>[],
) => {
  effects.forEach((item) => {
    store
      .on(item.pending, (state) => ({
        ...state,
        isSending: true,
      }))
      .on(item.fail, (state) => ({
        ...state,
        isSending: false,
      }))
      .on(item.doneData, (state, { data }) => ({
        ...state,
        data,
        isSending: false,
      }));
  });
};
