import React, { useCallback, useState } from 'react';
import { Dialog } from '@material-ui/core';
import cl from './ConfirmDialog.module.scss';
import { Button } from 'ui/button/Button';
import { showErrorAlert } from 'ui/dialogs/Alert';
import { Timer } from '../../../../../../../login/components/Code/Timer';

import { getVerificationCodeFx } from 'state/profile';
import { useStore } from 'effector-react';

interface Props {
  open: boolean;
  email: string;
  onClickOk: () => void;
}

export const ConfirmDialog: React.FC<Props> = ({ open, email, onClickOk }) => {
  const [sent, setSent] = useState(false);
  const pending = useStore(getVerificationCodeFx.pending);

  const handleRepeat = useCallback(async () => {
    try {
      await getVerificationCodeFx();
      setSent(true);
    } catch (e) {
      await showErrorAlert();
    }
  }, []);

  return (
    <Dialog open={open} maxWidth={false} classes={{ paper: cl.root }}>
      <div className={cl.content}>
        <div className={cl.header}>Проверьте почту для подтверждения вашего E-mail</div>
        <div className={cl.email}>{email}</div>
        <div>Не получили запрос на подтверждение?</div>
        <div className={cl.send}>
          {sent ? (
            <Timer length={60} onEnd={setSent.bind(null, false)} />
          ) : (
            <Button variant="text" className={cl.repeatBtn} onClick={handleRepeat} disabled={pending}>
              Повторить
            </Button>
          )}
        </div>
        <Button size="large" onClick={onClickOk}>
          ОК
        </Button>
      </div>
    </Dialog>
  );
};
