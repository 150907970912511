import cl from './Phone.module.scss';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { TextField } from '@material-ui/core';
import { LoginContent } from 'components/layout/LoginLayout/LoginContent';
import { useHistory } from 'react-router';
import { loginFx } from 'state/auth';
import { Button } from 'ui/button/Button';
import MaskedInput from 'react-text-mask';
import { ApiUnprocessableError } from 'api/errors';
import { showAlert } from 'ui/dialogs/Alert';
import { LoginLocationState } from '../Default/Default';

interface Props {}

const ERR_PHONE = 'Введите корректный номер телефона';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+', '7', ' ', '(', /[9-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
      // placeholderChar={'\u2000'}
      placeholderChar={'x'}
      showMask
      // guide={false}
    />
  );
}

const convertPhone = (phone: string) => phone.replace(/[^\d]+/g, '');
const checkPhone = (phone: string) => convertPhone(phone).length === 11;

const Phone: React.FC<Props> = () => {
  const history = useHistory<LoginLocationState | undefined>();
  const [phone, setPhone] = useState('');
  const [error, setError] = useState<undefined | string>();

  const handleClose = useCallback(() => {
    history.replace('/login');
  }, [history]);

  const handleChangePhone = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setError(undefined);
    setPhone(value);
  }, []);

  const handleClickNext = useCallback(async () => {
    if (!checkPhone(phone)) {
      setError(ERR_PHONE);
      return;
    }
    loginFx({ phone: convertPhone(phone) })
      .then(() => {
        const { state } = history.location;
        history.replace('/login/code', state);
      })
      .catch((e) => {
        if (e instanceof ApiUnprocessableError && e.data.errors.code) {
          showAlert(
            'default',
            'Код с предыдущей операции еще активен. Просьба повторить запрос через 1 минуту.',
            'Ошибка',
          );
          return;
        }
        setError(ERR_PHONE);
      });
  }, [phone, history]);

  const isValidPhone = checkPhone(phone);

  return (
    <LoginContent showFrame onClose={handleClose} className={cl.root}>
      <div className={cl.title}>Введите ваш номер телефона</div>
      <TextField
        autoFocus
        onChange={handleChangePhone}
        error={Boolean(error)}
        helperText={error}
        value={phone}
        InputProps={{
          inputComponent: TextMaskCustom as any,
        }}
      />
      <div className={cl.descr}>
        После того как вы на нажмете “Продолжить”, мы отправим вам сообщение с кодом подтверждения
      </div>
      <Button className={cl.btnMobile} onClick={handleClickNext} size="large" disabled={!isValidPhone}>
        Продолжить
      </Button>
    </LoginContent>
  );
};

export default Phone;
