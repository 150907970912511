import React, { useEffect } from 'react';
import cl from './Registry.module.scss';

const Registry = () => {
    useEffect(() => {
        //@ts-ignore
        //eslint-disable-next-line  @typescript-eslint/no-unused-expressions
        !function () { var e = document.getElementsByClassName('rubitime-project-iframe')[0]; if (e) { var t = document.getElementsByClassName('rubitime-project-iframe__inited').length, i = document.createElement('iframe'); i.src = e.getAttribute('href') + '?id=' + t + '&rvtype=static', i.scrolling = 'no', i.style.width = '100%', i.style.height = '600px', i.style.border = '0', i.id = 'rbt-iframe__' + t, i.className = 'rubitime-project-iframe__inited', e.parentNode.replaceChild(i, e) } }(); (function () { if (typeof (rubitimeIsLoaded) != 'boolean') { var script = document.createElement('script'); script.type = 'text/javascript'; script.async = true; script.src = 'https://kurchat.rubitime.ru/widget/get-code/off'; document.getElementsByTagName('head')[0].appendChild(script); } var rubitimeEls = document.getElementsByClassName('rubitime-project-static'); var ri = 0; while (rubitimeEls.length > ri) { rubitimeEls[ri].style.display = 'none'; ri++; } })(); var rubitimeIsLoaded = true;
    }, [])
    return (
        <div className={cl.root}>
           <a href="https://kurchat.rubitime.ru/widget" className="rubitime-project-iframe">Записаться</a>
        </div>
    )
};

export default Registry;