import { Backdrop } from 'ui/feedback/Backdrop';
import { ApiUnprocessableError } from 'api/errors';
import { useStore } from 'effector-react';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { showAlert, showErrorAlert } from 'ui/dialogs/Alert';
import { Step } from 'ui/edits/Step';
import { TextField } from 'ui/input/TextField';
import { $step4, documentSignFx, nextStep, prevStep, step4Set } from '../../model';
import cl from './Step4.module.scss';

interface ErrorsType {
  dop_class: undefined | string;
  dop_school: undefined | string;
}

const Step4 = () => {
  const [isFetching, setFetching] = useState(false);

  const [errors, setErrors] = useState<ErrorsType>({
    dop_school: undefined,
    dop_class: undefined,
  });
  const step4 = useStore($step4);

  const handleNext = useCallback(async () => {
      try {
        setFetching(true);
        await documentSignFx();
        setFetching(false);
        nextStep();
      } catch (e) {
        setFetching(false);
        if (e instanceof ApiUnprocessableError) await showErrorAlert();
        else
          await showAlert(
            'error',
            'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
            'Ошибка',
          );
      }
  }, [ ]);
  const handlePrevStep = useCallback(() => {
    prevStep();
  }, []);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    step4Set({ ...step4, [name]: value });
  };
  return (
    <Step
      title={'Заявление о предоставлении платного питания'}
      disabledNext={!step4.dop_class || !step4.dop_school}
      onClickNext={handleNext}
      prevStep={handlePrevStep}
    >
      <Backdrop open={isFetching} />
      <>
        <div className={cl.container}>
          <TextField
            label="Школа ученика"
            fullWidth
            name="dop_school"
            value={step4.dop_school}
            onChange={onChange}
            error={!!errors.dop_school}
            helperText={errors.dop_school}
          />
            <TextField
            label="Класс ученика"
            fullWidth
            name="dop_class"
            value={step4.dop_class}
            onChange={onChange}
            error={!!errors.dop_class}
            helperText={errors.dop_class}
          />
        </div>
      </>
    </Step>
  );
};

export default Step4;
