import React from 'react';

interface Props {}

const SchoolsMenu: React.FC<Props> = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M128.321 96.4728H-8.32074V96.5411H128.321V96.4728Z" fill="#EBEBEB" />
      <path d="M114.629 100.87H105.578V100.938H114.629V100.87Z" fill="#EBEBEB" />
      <path d="M82.1961 101.613H79.8212V101.682H82.1961V101.613Z" fill="#EBEBEB" />
      <path d="M105.305 98.334H100.061V98.4023H105.305V98.334Z" fill="#EBEBEB" />
      <path d="M17.8188 98.793H6.01569V98.8613H17.8188V98.793Z" fill="#EBEBEB" />
      <path d="M21.9836 98.793H20.2537V98.8613H21.9836V98.793Z" fill="#EBEBEB" />
      <path d="M53.2089 99.9464H27.6078V100.015H53.2089V99.9464Z" fill="#EBEBEB" />
      <path
        d="M56.4473 84.2844H3.67911C3.26573 84.2837 2.86953 84.119 2.57748 83.8264C2.28543 83.5339 2.1214 83.1374 2.1214 82.724V8.54678C2.125 8.13577 2.29061 7.74278 2.58228 7.45316C2.87394 7.16355 3.26809 7.0007 3.67911 7H56.4473C56.8612 7 57.2581 7.1644 57.5507 7.45704C57.8434 7.74968 58.0078 8.14659 58.0078 8.56045V82.724C58.0078 83.1378 57.8434 83.5348 57.5507 83.8274C57.2581 84.12 56.8612 84.2844 56.4473 84.2844ZM3.67911 7.05466C3.28385 7.05538 2.90502 7.21291 2.62579 7.49265C2.34655 7.7724 2.18972 8.15152 2.18972 8.54678V82.724C2.18972 83.1192 2.34655 83.4984 2.62579 83.7781C2.90502 84.0579 3.28385 84.2154 3.67911 84.2161H56.4473C56.8428 84.2154 57.2219 84.058 57.5016 83.7783C57.7813 83.4986 57.9387 83.1195 57.9394 82.724V8.54678C57.9387 8.15127 57.7813 7.77216 57.5016 7.49249C57.2219 7.21282 56.8428 7.05538 56.4473 7.05466H3.67911Z"
        fill="#EBEBEB"
      />
      <path
        d="M115.561 84.2844H62.7902C62.3765 84.2837 61.9801 84.1191 61.6876 83.8266C61.3951 83.5341 61.2305 83.1376 61.2297 82.724V8.54678C61.234 8.13552 61.4003 7.74253 61.6924 7.45299C61.9845 7.16345 62.3789 7.0007 62.7902 7H115.561C115.972 7.00142 116.365 7.16458 116.656 7.45412C116.947 7.74366 117.113 8.13624 117.116 8.54678V82.724C117.116 83.1369 116.952 83.533 116.661 83.8255C116.37 84.1179 115.974 84.283 115.561 84.2844ZM62.7902 7.05466C62.3947 7.05538 62.0156 7.21282 61.7359 7.49249C61.4562 7.77216 61.2988 8.15127 61.2981 8.54678V82.724C61.2988 83.1195 61.4562 83.4986 61.7359 83.7783C62.0156 84.058 62.3947 84.2154 62.7902 84.2161H115.561C115.957 84.2154 116.336 84.058 116.615 83.7783C116.895 83.4986 117.053 83.1195 117.053 82.724V8.54678C117.053 8.15127 116.895 7.77216 116.615 7.49249C116.336 7.21282 115.957 7.05538 115.561 7.05466H62.7902Z"
        fill="#EBEBEB"
      />
      <path
        d="M112.156 19.0652C112.972 15.6059 110.83 12.1396 107.37 11.3231C103.911 10.5067 100.445 12.6491 99.6283 16.1085C98.8118 19.5678 100.954 23.0341 104.414 23.8506C107.873 24.667 111.339 22.5246 112.156 19.0652Z"
        fill="#E0E0E0"
      />
      <path
        d="M111.305 18.4666C111.789 15.4825 109.763 12.6708 106.779 12.1864C103.795 11.702 100.983 13.7284 100.499 16.7125C100.014 19.6965 102.041 22.5083 105.025 22.9927C108.009 23.477 110.82 21.4506 111.305 18.4666Z"
        fill="#F0F0F0"
      />
      <path d="M105.638 13.8321V17.6581L103.102 16.2233" fill="#F0F0F0" />
      <path
        d="M105.638 13.8321V17.6581L103.102 16.2233"
        stroke="#E0E0E0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M27.3864 21.7736H8.63373V55.8302H27.3864V21.7736Z" fill="#E6E6E6" />
      <path d="M26.1129 23.1236H9.9892V54.5511H26.1129V23.1236Z" fill="#F0F0F0" />
      <path d="M17.9445 23.1236L9.9892 52.9169V36.7877L13.6293 23.1236H17.9445Z" fill="#FAFAFA" />
      <path d="M21.4999 23.1236L13.1101 54.5511H11.5278L19.9176 23.1236H21.4999Z" fill="#FAFAFA" />
      <path d="M26.7961 45.093H9.16937V46.1861H26.7961V45.093Z" fill="#E6E6E6" />
      <path
        d="M107.622 65.9553C107.622 65.9553 108.051 63.1132 105.983 61.2931C103.914 59.473 104.092 58.3854 104.685 58.246C105.278 58.1066 105.824 59.9267 106.734 60.4323C106.734 60.4323 106.764 57.8361 105.838 56.8796C104.911 55.9231 104.428 53.4581 105.308 53.4171C106.188 53.3761 106.278 54.8791 106.461 55.9477C106.644 57.0162 107.625 58.5193 107.625 58.5193C107.625 58.5193 107.816 56.1964 108.415 55.2399C109.013 54.2834 107.417 52.0971 108.032 51.1406C108.647 50.1841 109.74 52.0069 109.65 54.0566C109.56 56.1062 108.191 58.2925 108.511 60.4323C108.511 60.4323 109.114 58.9292 109.656 58.7926C110.197 58.6559 110.749 57.6612 111.107 57.2922C111.465 56.9233 111.927 58.1995 111.107 59.3856C110.287 60.5716 108.191 63.7363 108.511 66.2969L107.622 65.9553Z"
        fill="#E6E6E6"
      />
      <path
        d="M106.382 64.6628L105.704 68.9206C105.654 69.2298 105.673 69.546 105.757 69.8474C105.842 70.1489 105.991 70.4284 106.194 70.6666C106.397 70.9048 106.65 71.0962 106.934 71.2274C107.218 71.3586 107.528 71.4265 107.841 71.4266H108.565C108.879 71.4269 109.188 71.3593 109.473 71.2282C109.757 71.0972 110.01 70.9059 110.214 70.6676C110.417 70.4294 110.566 70.1497 110.651 69.8481C110.736 69.5464 110.754 69.23 110.705 68.9206L110.027 64.6628H106.382Z"
        fill="#E6E6E6"
      />
      <path d="M109.202 71.9155H107.622V96.7242H109.202V71.9155Z" fill="#E0E0E0" />
      <path d="M103.594 71.9155H102.015V96.7242H103.594V71.9155Z" fill="#E6E6E6" />
      <path d="M114.807 71.9155H113.227V96.7242H114.807V71.9155Z" fill="#E6E6E6" />
      <path d="M115.744 70.3307H101.077V72.9706H115.744V70.3307Z" fill="#E6E6E6" />
      <path
        d="M60 108.814C89.2638 108.814 112.987 107.429 112.987 105.721C112.987 104.012 89.2638 102.627 60 102.627C30.7362 102.627 7.01318 104.012 7.01318 105.721C7.01318 107.429 30.7362 108.814 60 108.814Z"
        fill="#F5F5F5"
      />
      <path
        d="M87.3283 95.1394V64.9362H84.3222V95.1394L82.4092 95.727V96.637H89.2413V95.727L87.3283 95.1394Z"
        fill="#2E353A"
      />
      <path
        d="M49.0687 64.9362H46.0626V95.1394L44.1496 95.727V96.637H50.9817V95.727L49.0687 95.1394V64.9362Z"
        fill="#2E353A"
      />
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M87.3283 68.4889H84.3222V95.1394L82.4092 95.727V96.637H89.2413V95.727L87.3283 95.1394V68.4889Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M49.0687 68.489H46.0626V95.1395L44.1496 95.7271V96.6371H50.9817V95.7271L49.0687 95.1395V68.489Z"
          fill="white"
        />
      </g>
      <path d="M96.6199 66.0293V17.3849L36.7709 17.3849V66.0293H96.6199Z" fill="#2E353A" />
      <path opacity="0.2" d="M96.6199 66.0293V17.3849L36.7709 17.3849V66.0293H96.6199Z" fill="white" />
      <path d="M95.289 18.7349H38.1018V64.6519H95.289V18.7349Z" fill="#69488D" />
      <path opacity="0.3" d="M95.289 18.7349H38.1018V64.6519H95.289V18.7349Z" fill="white" />
      <path d="M44.5595 22.8506V35.285H64.3753L44.5595 22.8506Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M45.5542 35.3176V34.301H44.5376" stroke="#263238" stroke-width="0.793303" stroke-miterlimit="10" />
      <path
        d="M57.1332 28.9873C57.5521 28.9873 57.9202 29.0984 58.2375 29.3205C58.5612 29.5426 58.8087 29.8409 58.98 30.2153C59.1514 30.5834 59.2371 30.9833 59.2371 31.4148C59.2371 31.8591 59.1419 32.2684 58.9515 32.6429C58.7674 33.0109 58.5136 33.3029 58.1899 33.5187C57.8726 33.7344 57.5204 33.8423 57.1332 33.8423C56.8476 33.8423 56.5716 33.7852 56.305 33.671C56.0385 33.5504 55.8322 33.3854 55.6862 33.176L55.5911 33.7471H54.8295V26.6074H55.6862V29.6251C55.8576 29.4284 56.0702 29.2729 56.3241 29.1587C56.5843 29.0444 56.854 28.9873 57.1332 28.9873ZM57.0285 33.0808C57.4283 33.0808 57.7488 32.9221 57.99 32.6048C58.2375 32.2811 58.3613 31.8845 58.3613 31.4148C58.3613 30.9325 58.2375 30.5358 57.99 30.2249C57.7488 29.9075 57.4283 29.7489 57.0285 29.7489C56.635 29.7489 56.305 29.8949 56.0385 30.1868C55.7719 30.4724 55.6386 30.8817 55.6386 31.4148C55.6386 31.967 55.7688 32.3826 56.029 32.6619C56.2955 32.9411 56.6287 33.0808 57.0285 33.0808Z"
        fill="#263238"
      />
      <path
        d="M47.5218 47.7647C47.9407 47.7647 48.3088 47.8757 48.6261 48.0978C48.9498 48.32 49.1973 48.6182 49.3687 48.9927C49.54 49.3608 49.6257 49.7606 49.6257 50.1922C49.6257 50.6364 49.5305 51.0458 49.3401 51.4202C49.156 51.7883 48.9022 52.0802 48.5785 52.296C48.2612 52.5118 47.909 52.6197 47.5218 52.6197C47.2363 52.6197 46.9602 52.5626 46.6936 52.4483C46.4271 52.3277 46.2208 52.1627 46.0749 51.9533L45.9797 52.5245H45.2181V45.3847H46.0749V48.4025C46.2462 48.2057 46.4588 48.0502 46.7127 47.936C46.9729 47.8218 47.2426 47.7647 47.5218 47.7647ZM47.4171 51.8581C47.817 51.8581 48.1374 51.6994 48.3786 51.3821C48.6261 51.0585 48.7499 50.6618 48.7499 50.1922C48.7499 49.7098 48.6261 49.3132 48.3786 49.0022C48.1374 48.6849 47.817 48.5262 47.4171 48.5262C47.0236 48.5262 46.6936 48.6722 46.4271 48.9641C46.1605 49.2497 46.0273 49.6591 46.0273 50.1922C46.0273 50.7443 46.1574 51.16 46.4176 51.4392C46.6841 51.7185 47.0173 51.8581 47.4171 51.8581Z"
        fill="#263238"
      />
      <path
        d="M54.2536 44.3172C53.7458 44.3172 53.3079 44.2125 52.9399 44.0031C52.5781 43.7937 52.3052 43.5081 52.1212 43.1463C51.9371 42.7782 51.8451 42.3689 51.8451 41.9183C51.8451 41.1821 52.0514 40.5887 52.4639 40.1381C52.8827 39.6875 53.5015 39.4622 54.3202 39.4622C54.8025 39.4622 55.2277 39.5542 55.5958 39.7383L55.4626 40.4903C55.0754 40.3126 54.6883 40.2238 54.3012 40.2238C53.7934 40.2238 53.4031 40.3698 53.1302 40.6617C52.8573 40.9473 52.7209 41.3535 52.7209 41.8802C52.7209 42.407 52.851 42.8195 53.1112 43.1178C53.3778 43.416 53.7681 43.5652 54.2821 43.5652C54.6946 43.5652 55.0913 43.47 55.4721 43.2796L55.5863 43.965C55.4467 44.0602 55.2531 44.1427 55.0056 44.2125C54.7644 44.2823 54.5138 44.3172 54.2536 44.3172Z"
        fill="#263238"
      />
      <path
        d="M47.3695 56.4374C46.8618 56.4374 46.4239 56.3327 46.0558 56.1233C45.6941 55.9138 45.4212 55.6283 45.2371 55.2665C45.0531 54.8984 44.9611 54.4891 44.9611 54.0385C44.9611 53.3023 45.1673 52.7089 45.5798 52.2583C45.9987 51.8077 46.6175 51.5824 47.4362 51.5824C47.9185 51.5824 48.3437 51.6744 48.7118 51.8585L48.5785 52.6105C48.1914 52.4328 47.8043 52.344 47.4171 52.344C46.9094 52.344 46.5191 52.4899 46.2462 52.7819C45.9733 53.0675 45.8369 53.4736 45.8369 54.0004C45.8369 54.5271 45.967 54.9397 46.2272 55.2379C46.4937 55.5362 46.884 55.6854 47.3981 55.6854C47.8106 55.6854 48.2073 55.5902 48.588 55.3998L48.7023 56.0852C48.5627 56.1804 48.3691 56.2629 48.1216 56.3327C47.8804 56.4025 47.6297 56.4374 47.3695 56.4374Z"
        fill="#263238"
      />
      <path
        d="M44.0227 36.8049C43.5912 36.8049 43.2421 36.678 42.9756 36.4241C42.7154 36.1703 42.5853 35.8466 42.5853 35.4531C42.5853 34.8629 42.8645 34.4409 43.423 34.187C43.9815 33.9332 44.632 33.7999 45.3745 33.7872C45.3745 33.3683 45.292 33.0859 45.127 32.9399C44.9684 32.7876 44.7113 32.7115 44.3559 32.7115C44.1592 32.7115 43.9625 32.7432 43.7657 32.8067C43.569 32.8638 43.3564 32.9717 43.1279 33.1303L43.0041 32.4354C43.1945 32.2894 43.4294 32.172 43.7086 32.0832C43.9878 31.9943 44.2798 31.9499 44.5844 31.9499C44.9842 31.9499 45.3016 32.0197 45.5364 32.1593C45.7775 32.299 45.9521 32.5306 46.06 32.8543C46.1742 33.1779 46.2313 33.6222 46.2313 34.187V35.139C46.2313 35.3928 46.2408 35.5864 46.2599 35.7197C46.2852 35.8466 46.336 35.945 46.4122 36.0148C46.4883 36.0846 46.6057 36.1195 46.7644 36.1195H46.8501L46.7168 36.8049H46.6502C46.3836 36.8049 46.1742 36.7795 46.0219 36.7288C45.8696 36.678 45.7522 36.605 45.6696 36.5098C45.5935 36.4146 45.5205 36.2845 45.4507 36.1195C45.0572 36.5764 44.5812 36.8049 44.0227 36.8049ZM44.1846 36.1195C44.4131 36.1195 44.632 36.0624 44.8414 35.9481C45.0572 35.8339 45.2349 35.6816 45.3745 35.4912V34.3774C44.6891 34.4028 44.1973 34.4948 43.899 34.6535C43.6071 34.8058 43.4611 35.0565 43.4611 35.4055C43.4611 35.6467 43.5246 35.8276 43.6515 35.9481C43.7848 36.0624 43.9625 36.1195 44.1846 36.1195Z"
        fill="#263238"
      />
      <path
        d="M46.3128 48.7991C45.8813 48.7991 45.5322 48.6722 45.2657 48.4183C45.0055 48.1645 44.8754 47.8408 44.8754 47.4473C44.8754 46.8571 45.1546 46.4351 45.7131 46.1812C46.2716 45.9274 46.9221 45.7941 47.6646 45.7814C47.6646 45.3625 47.5821 45.0801 47.4171 44.9341C47.2585 44.7818 47.0014 44.7057 46.646 44.7057C46.4493 44.7057 46.2526 44.7374 46.0558 44.8009C45.8591 44.858 45.6465 44.9659 45.418 45.1245L45.2942 44.4296C45.4846 44.2836 45.7195 44.1662 45.9987 44.0774C46.2779 43.9885 46.5699 43.9441 46.8745 43.9441C47.2743 43.9441 47.5917 44.0139 47.8265 44.1535C48.0676 44.2932 48.2422 44.5248 48.3501 44.8485C48.4643 45.1721 48.5214 45.6164 48.5214 46.1812V47.1332C48.5214 47.387 48.5309 47.5806 48.55 47.7139C48.5754 47.8408 48.6261 47.9392 48.7023 48.009C48.7784 48.0788 48.8958 48.1137 49.0545 48.1137H49.1402L49.0069 48.7991H48.9403C48.6737 48.7991 48.4643 48.7737 48.312 48.723C48.1597 48.6722 48.0423 48.5992 47.9597 48.504C47.8836 48.4088 47.8106 48.2787 47.7408 48.1137C47.3473 48.5706 46.8713 48.7991 46.3128 48.7991ZM46.4747 48.1137C46.7032 48.1137 46.9221 48.0566 47.1315 47.9423C47.3473 47.8281 47.525 47.6758 47.6646 47.4854V46.3716C46.9792 46.397 46.4874 46.489 46.1891 46.6477C45.8972 46.8 45.7512 47.0507 45.7512 47.3997C45.7512 47.6409 45.8147 47.8218 45.9416 47.9423C46.0749 48.0566 46.2526 48.1137 46.4747 48.1137Z"
        fill="#263238"
      />
      <path d="M69.0183 22.9871H79.9496" stroke="#263238" stroke-miterlimit="10" />
      <path d="M69.0183 25.1735H75.0305" stroke="#263238" stroke-miterlimit="10" />
      <path d="M69.0183 26.8132H77.4901" stroke="#263238" stroke-miterlimit="10" />
      <path d="M69.0183 28.7261H76.1237" stroke="#263238" stroke-miterlimit="10" />
      <path d="M69.0183 30.9125H79.6763" stroke="#263238" stroke-miterlimit="10" />
      <path d="M69.0183 32.8254H79.4031" stroke="#263238" stroke-miterlimit="10" />
      <path d="M69.0183 34.7383H76.1237" stroke="#263238" stroke-miterlimit="10" />
      <path d="M81.316 51.4088H88.6947" stroke="#263238" stroke-miterlimit="10" />
      <path d="M81.316 53.595H85.4153" stroke="#263238" stroke-miterlimit="10" />
      <path d="M81.316 55.2346H87.055" stroke="#263238" stroke-miterlimit="10" />
      <path d="M81.316 57.1475H86.2351" stroke="#263238" stroke-miterlimit="10" />
      <path d="M43.8763 54.9613H51.2549" stroke="#263238" stroke-miterlimit="10" />
      <path d="M43.8763 57.1475H47.9755" stroke="#263238" stroke-miterlimit="10" />
      <path d="M43.8763 58.7871H49.6152" stroke="#263238" stroke-miterlimit="10" />
      <path d="M43.8763 60.7003H48.7954" stroke="#263238" stroke-miterlimit="10" />
      <path d="M83.5023 26.8132H90.6077" stroke="#263238" stroke-miterlimit="10" />
      <path d="M83.5023 28.7261H90.6077" stroke="#263238" stroke-miterlimit="10" />
      <path d="M83.5023 30.6393H88.4214" stroke="#263238" stroke-miterlimit="10" />
      <path d="M62.4595 54.9613H69.5649" stroke="#263238" stroke-miterlimit="10" />
      <path d="M62.4595 56.8742H69.5649" stroke="#263238" stroke-miterlimit="10" />
      <path d="M62.4595 58.7871H67.3786" stroke="#263238" stroke-miterlimit="10" />
      <path d="M49.0687 42.3903H64.9191" stroke="#263238" stroke-miterlimit="10" />
      <path d="M49.0687 45.9431H64.9191" stroke="#263238" stroke-miterlimit="10" />
      <path d="M49.0687 49.4956H64.9191" stroke="#263238" stroke-miterlimit="10" />
      <path
        d="M75.4323 51.4251C78.1868 51.4251 80.4197 49.1922 80.4197 46.4377C80.4197 43.6833 78.1868 41.4503 75.4323 41.4503C72.6778 41.4503 70.4449 43.6833 70.4449 46.4377C70.4449 49.1922 72.6778 51.4251 75.4323 51.4251Z"
        stroke="#263238"
        stroke-width="0.793303"
        stroke-miterlimit="10"
      />
      <path d="M75.4405 41.4337V46.4894H80.4962" stroke="#263238" stroke-miterlimit="10" />
      <path opacity="0.6" d="M95.289 18.7349H38.1018V64.6519H95.289V18.7349Z" fill="white" />
      <path
        d="M73.7352 43.5162L69.5157 41.0567C68.843 41.1069 68.1668 41.0757 67.5016 40.9637C66.4085 40.7752 66.1734 40.7643 66.3046 39.9034C66.4358 39.0426 66.2527 37.8948 66.5779 37.7745C66.9031 37.6543 69.2042 38.6244 69.6031 39.2831L73.8609 40.8052L73.7352 43.5162Z"
        fill="#FFBE9D"
      />
      <path
        d="M86.2351 39.3487V44.2678C86.2351 44.2678 85.1857 44.6149 83.8822 44.9783C82.3873 45.3937 80.559 45.831 79.608 45.7982C77.8317 45.7107 71.8195 42.5898 71.8195 42.5898L72.9126 40.5074C72.9126 40.5074 79.4714 42.7429 80.2912 42.5816C81.1111 42.4204 86.2351 39.3487 86.2351 39.3487Z"
        fill="#69488D"
      />
      <path
        opacity="0.2"
        d="M86.2352 41.7453V44.2704C86.2352 44.2704 85.1858 44.6175 83.8822 44.981C84.3632 43.5162 85.5629 42.3219 86.2352 41.7453Z"
        fill="black"
      />
      <path
        d="M87.3283 56.8578C87.3283 56.8578 84.3659 72.6426 83.5761 79.1249C82.5431 87.5803 78.3209 99.9491 78.3209 99.9491L80.0098 100.386C80.0098 100.386 85.9619 89.8758 87.1151 80.4175C91.8429 70.7515 92.2228 58.8473 92.2228 58.8473L87.3283 56.8578Z"
        fill="#FFBE9D"
      />
      <path
        d="M75.189 103.775C75.4619 103.649 75.7556 103.575 76.0553 103.556C76.0553 103.556 77.0938 102.944 78.1241 100.572H79.2801C79.4754 100.575 79.6653 100.637 79.8241 100.751C79.9828 100.865 80.103 101.025 80.1683 101.209C80.1873 101.261 80.1983 101.315 80.2011 101.37C80.1891 101.645 80.1599 101.919 80.1136 102.19C79.9422 103.114 79.7159 104.026 79.4359 104.923L79.2391 104.871L79.5971 103.146C79.7146 102.559 79.0997 103.125 78.8128 103.718C78.5258 104.311 78.0886 104.904 76.8123 104.611C75.3229 104.259 74.7217 104.043 75.189 103.775Z"
        fill="#2E353A"
      />
      <path
        d="M90.4136 56.6065C90.4136 56.6065 90.7825 72.6619 91.3509 79.1687C92.0888 87.6542 90.5147 100.63 90.5147 100.63L92.2582 100.706C92.2582 100.706 95.9066 89.1927 95.0867 79.6989C97.713 69.2622 95.6333 57.5384 95.6333 57.5384L90.4136 56.6065Z"
        fill="#FFBE9D"
      />
      <path
        d="M87.8038 104.305C88.0618 104.15 88.3462 104.044 88.6428 103.991C88.6428 103.991 89.6102 103.272 90.3754 100.802L91.5259 100.671C91.7215 100.652 91.9181 100.695 92.0884 100.792C92.2588 100.89 92.3945 101.039 92.4769 101.217C92.5014 101.266 92.518 101.319 92.5261 101.373C92.5439 101.652 92.5439 101.933 92.5261 102.212C92.4562 103.145 92.3303 104.073 92.149 104.991L91.9467 104.961L92.1162 103.207C92.1708 102.611 91.6188 103.242 91.3974 103.86C91.1761 104.477 90.8071 105.117 89.5036 104.967C87.9896 104.77 87.3693 104.622 87.8038 104.305Z"
        fill="#2E353A"
      />
      <path
        d="M96.3002 29.2945C96.3002 29.2945 97.1665 29.341 97.8962 30.7648C98.6258 32.1886 97.713 33.7709 97.5299 34.5279C97.3468 35.2849 96.4368 35.9544 97.9399 37.0995C99.4429 38.2446 100.345 39.4607 99.1259 40.6986C97.9071 41.9366 99.2462 42.8849 97.4862 43.8031C95.7263 44.7214 89.2413 43.3467 88.4214 41.5704C87.6016 39.7941 88.5909 38.444 88.6428 37.9139C88.6947 37.3837 87.6016 36.8399 87.3283 35.1045C87.055 33.3692 88.4542 32.9975 88.6428 32.205C88.8313 31.4125 87.9541 30.4123 88.6428 28.7261C89.3315 27.0399 96.2073 26.6765 96.3002 29.2945Z"
        fill="#2E353A"
      />
      <path
        d="M100.664 52.9361C99.6287 53.07 98.4318 51.0477 97.5655 49.1621C96.8249 47.5634 96.3083 46.0603 96.3083 46.0603L96.8549 44.519L98.2815 40.4963C98.2815 40.4963 99.2789 41.8873 100.779 47.1835C101.02 48.0362 101.309 48.9899 101.575 50.0557C101.572 50.325 101.656 50.5881 101.812 50.8072C101.812 51.608 101.908 52.7749 100.664 52.9361Z"
        fill="#69488D"
      />
      <path
        opacity="0.2"
        d="M97.5463 49.1622C96.8085 47.5635 96.3002 46.0605 96.3002 46.0605L96.8467 44.5192C97.2293 45.585 97.7896 47.5171 97.5463 49.1622Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M101.812 50.8074C101.266 51.1682 100.695 51.1627 100.375 50.5041C99.7271 49.1732 100.353 47.856 100.796 47.181C101.039 48.0336 101.318 48.9874 101.583 50.0532C101.574 50.323 101.655 50.5882 101.812 50.8074Z"
        fill="black"
      />
      <path
        d="M85.5082 52.4116C85.5082 52.4116 90.9739 54.3683 96.1827 54.0049C96.1827 54.0049 95.7099 47.5554 92.9771 46.4076C90.2442 45.2598 85.5082 46.8995 85.5082 52.4116Z"
        fill="#69488D"
      />
      <path
        opacity="0.2"
        d="M85.5082 52.4116C85.5082 52.4116 90.9739 54.3683 96.1827 54.0049C96.1827 54.0049 95.7099 47.5554 92.9771 46.4076C90.2442 45.2598 85.5082 46.8995 85.5082 52.4116Z"
        fill="black"
      />
      <path
        d="M86.2816 52.8708L95.2999 53.6496C95.3877 51.4141 95.7092 49.1939 96.2591 47.0253C96.475 46.1617 96.7456 45.197 97.0927 44.082C97.2785 43.4862 97.4835 42.8468 97.713 42.169C97.8852 41.6471 98.071 41.0923 98.276 40.5075C97.0513 40.1165 95.8001 39.8142 94.532 39.6029C93.1937 39.3652 91.8403 39.2228 90.4819 39.1766C88.7275 39.1766 86.7106 39.3597 86.7106 39.3597C86.7106 39.3597 86.4237 39.428 86.2433 41.2891C86.0821 43.0244 85.9208 46.517 86.2816 52.8708Z"
        fill="#69488D"
      />
      <path
        d="M86.3253 54.2235L95.3436 54.9669C95.4857 52.7259 95.5923 49.698 96.2673 47.0253C96.4832 46.1617 96.7538 45.197 97.1008 44.082C97.2867 43.4862 97.4916 42.8468 97.7212 42.169C97.8934 41.6471 98.0792 41.0923 98.2842 40.5075C97.0595 40.1165 95.8083 39.8142 94.5402 39.6029C93.2019 39.3652 91.8485 39.2228 90.4901 39.1766C88.7357 39.1766 86.7188 39.3597 86.7188 39.3597C86.7188 39.3597 86.4319 39.428 86.2515 41.2891C86.0821 43.0244 85.9618 47.8697 86.3253 54.2235Z"
        fill="#2E353A"
      />
      <path
        d="M91.228 41.1605C93.4525 40.8873 94.1193 39.9253 94.1193 39.9253C92.7092 38.5944 93.8816 36.2879 93.8816 36.2879L91.2061 36.788C91.2689 37.1611 91.3037 37.5384 91.31 37.9167C91.3318 39.54 90.5967 39.5564 90.5967 39.5564C90.1267 40.4336 91.228 41.1605 91.228 41.1605Z"
        fill="#FFBE9D"
      />
      <path
        opacity="0.2"
        d="M91.2062 36.7878C91.269 37.1609 91.3037 37.5382 91.31 37.9165C93.1164 37.9165 93.8816 36.2768 93.8816 36.2768L91.2062 36.7878Z"
        fill="black"
      />
      <path
        d="M92.6846 29.3027C90.359 29.177 89.5419 30.3821 89.2413 32.7679C88.8641 35.7522 89.4107 37.9166 92.354 37.4356C96.3466 36.7879 96.5489 29.5158 92.6846 29.3027Z"
        fill="#FFBE9D"
      />
      <path
        d="M90.7498 32.7215C90.545 33.0587 90.3 33.3698 90.0201 33.6479C90.0834 33.748 90.1729 33.8287 90.279 33.8812C90.385 33.9337 90.5035 33.9561 90.6214 33.9458L90.7498 32.7215Z"
        fill="#EB996E"
      />
      <path
        d="M92.1846 32.6094C92.1846 32.8007 92.2611 32.9619 92.3895 32.9729C92.518 32.9838 92.63 32.8362 92.6464 32.6449C92.6628 32.4536 92.5699 32.2924 92.4415 32.2815C92.313 32.2705 92.201 32.4154 92.1846 32.6094Z"
        fill="#2E353A"
      />
      <path d="M92.496 32.295L92.0916 32.0982C92.0916 32.0982 92.2719 32.4644 92.496 32.295Z" fill="#2E353A" />
      <path
        d="M89.8152 32.3825C89.8015 32.5738 89.8917 32.7378 90.0202 32.746C90.1486 32.7542 90.2607 32.6093 90.2771 32.418C90.2935 32.2268 90.2005 32.0655 90.0748 32.0546C89.9491 32.0437 89.8316 32.1912 89.8152 32.3825Z"
        fill="#2E353A"
      />
      <path d="M90.1267 32.0685L89.7223 31.8745C89.7223 31.8745 89.9027 32.2406 90.1267 32.0685Z" fill="#2E353A" />
      <path
        d="M92.2966 31.0025C92.4817 30.9901 92.6672 31.0203 92.8387 31.0909C93.0102 31.1615 93.1632 31.2706 93.2859 31.4097"
        stroke="#2E353A"
        stroke-width="0.72468"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M90.6076 30.814C90.451 30.7654 90.2853 30.7527 90.1231 30.7768C89.9608 30.801 89.8061 30.8613 89.6703 30.9534"
        stroke="#2E353A"
        stroke-width="0.72468"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M90.9082 34.875C91.0038 35.1423 91.0797 35.4163 91.135 35.6948C91.186 35.6975 91.2371 35.6975 91.2881 35.6948C91.9685 35.6648 92.2719 35.4215 92.4058 35.1701C92.4677 35.0435 92.4977 34.9037 92.4932 34.7629C92.4954 34.6835 92.4853 34.6042 92.4632 34.5279C92.0566 34.7356 91.6103 34.854 91.1542 34.875C90.9984 34.875 90.9082 34.875 90.9082 34.875Z"
        fill="#2E353A"
      />
      <path
        d="M91.1543 34.8749L91.2281 35.1154C91.8703 35.0853 92.3212 34.976 92.5071 34.7738C92.5093 34.6944 92.4991 34.6151 92.477 34.5388C92.0654 34.7446 91.6142 34.8593 91.1543 34.8749Z"
        fill="white"
      />
      <path
        d="M91.2881 35.6948C91.9686 35.6647 92.2719 35.4215 92.4058 35.1701C92.1473 35.1863 91.8938 35.2483 91.657 35.3532C91.5013 35.4256 91.3722 35.5451 91.2881 35.6948Z"
        fill="#DE5753"
      />
      <path
        d="M95.013 33.3637C95.013 33.3637 94.0292 31.3933 94.2751 29.9258C94.2751 29.9258 89.7687 27.7914 89.285 32.4564C89.285 32.4564 89.0609 28.2779 93.0645 28.4036C97.0681 28.5293 96.7319 34.3803 94.5703 36.0091C94.5703 36.0091 95.6197 34.6017 95.013 33.3637Z"
        fill="#2E353A"
      />
      <path
        d="M94.6058 33.8337C94.6239 33.7009 94.6681 33.5729 94.7358 33.4571C94.8034 33.3414 94.8933 33.2401 95.0002 33.1591C95.107 33.0781 95.2288 33.019 95.3586 32.9852C95.4883 32.9514 95.6235 32.9435 95.7563 32.9619C96.4723 33.0795 96.7347 34.577 94.9365 34.8367C94.6604 34.8749 94.5293 34.6016 94.6058 33.8337Z"
        fill="#FFBE9D"
      />
      <path
        d="M96.1826 40.1712C96.1826 40.1712 93.57 42.2892 91.1542 42.2536C88.7384 42.2181 87.8748 39.488 87.8748 39.488C87.8748 39.488 91.627 38.3703 96.1826 40.1712Z"
        fill="#FFBE9D"
      />
      <path
        d="M95.9586 39.7721C95.9586 39.7721 91.6735 47.4459 96.1826 54.0047C96.1826 54.0047 96.7948 45.9128 98.2787 40.4963L95.9586 39.7721Z"
        fill="#69488D"
      />
      <path
        d="M88.0962 39.1655C88.0962 39.1655 88.0579 44.5956 87.2764 46.6124C86.4948 48.6293 85.5028 52.4115 85.5028 52.4115C85.5028 52.4115 84.7485 43.3658 86.2297 39.3486L88.0962 39.1655Z"
        fill="#69488D"
      />
      <path
        d="M94.2751 29.9256C94.2751 29.9256 92.5425 29.3271 91.0176 29.9256C89.4926 30.5241 89.908 32.1119 89.1346 32.4015"
        stroke="#2E353A"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M108.029 38.1872L107.313 37.7773L104.682 38.496L101.129 37.5751L100.309 40.2724C100.309 40.2724 100.14 43.2785 100.309 43.314C100.479 43.3496 104.376 43.5518 104.376 43.5518L106.357 42.2892L108.029 38.1872Z"
        fill="#E0E0E0"
      />
      <path
        d="M100.173 37.7774L98.6012 44.0001L103.452 44.5439L106.8 43.6202L108.508 37.7774L104.682 38.7694L100.173 37.7774Z"
        fill="#69488D"
      />
      <path
        opacity="0.4"
        d="M104.682 38.7694L103.452 44.5439L98.6012 44.0001L100.173 37.7774L104.682 38.7694Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M104.682 38.7694L103.452 44.5439L106.8 43.6202L108.508 37.7774L104.682 38.7694Z"
        fill="white"
      />
      <path
        d="M100.424 50.1788L102.679 45.85C102.597 45.1802 102.597 44.503 102.679 43.8332C102.815 42.74 102.815 42.4968 103.679 42.587C104.542 42.6772 105.685 42.4422 105.821 42.7619C105.958 43.0817 105.092 45.4237 104.455 45.8527L103.129 50.1788H100.424Z"
        fill="#FFBE9D"
      />
      <path
        d="M99.6943 52.5565C101.129 53.7781 102.769 52.7287 103.679 49.2225L100.856 48.859L99.6943 52.5565Z"
        fill="#69488D"
      />
      <path d="M101.001 48.0884L100.424 49.105L103.998 49.586V48.5393L101.001 48.0884Z" fill="#2E353A" />
      <path d="M71.6746 42.8413L72.6639 43.4644L73.3116 39.9172L72.2649 39.868L71.6746 42.8413Z" fill="#2E353A" />
      <path
        d="M86.3253 54.2235L80.6411 87.6187C80.6411 87.6187 94.3981 89.2584 99.0603 88.3019C99.0603 88.3019 100.205 64.3951 95.3382 54.9614L86.3253 54.2235Z"
        fill="#69488D"
      />
      <path
        opacity="0.4"
        d="M86.3253 54.2235L80.6411 87.6187C80.6411 87.6187 94.3981 89.2584 99.0603 88.3019C99.0603 88.3019 100.205 64.3951 95.3382 54.9614L86.3253 54.2235Z"
        fill="white"
      />
      <path
        d="M67.8678 39.5426L66.4522 38.0806C65.6077 37.1979 64.46 36.0419 63.3204 34.8804L61.6342 33.1861L60.1612 31.7076L59.12 30.6637C58.9943 30.538 58.8932 30.4396 58.8276 30.3713C58.799 30.3556 58.7675 30.3461 58.735 30.3432C58.7026 30.3404 58.6699 30.3444 58.639 30.3549L59.0244 29.9668C59.0139 29.9976 59.0099 30.0303 59.0127 30.0628C59.0156 30.0953 59.0251 30.1268 59.0408 30.1554L59.3359 30.4478L60.3853 31.4835L61.8665 32.9511L63.5718 34.629C64.7387 35.7631 65.9002 36.9055 66.7856 37.7472L68.2559 39.1546L67.8678 39.5426Z"
        fill="#2E353A"
      />
      <path
        d="M78.3317 99.9491C78.3317 99.9491 76.8888 102.972 76.0553 103.556C76.0553 103.556 77.1703 104.221 78.3317 102.737C79.8348 100.824 80.201 101.37 80.201 101.37C80.1712 101.04 80.1108 100.713 80.0207 100.395C79.7938 99.7687 78.3317 99.9491 78.3317 99.9491Z"
        fill="#FFBE9D"
      />
      <path
        d="M90.6924 99.9163C90.6924 99.9163 90.2825 101.037 89.7359 102.267C89.4856 102.904 89.1144 103.487 88.6428 103.983C88.6428 103.983 89.5938 104.292 90.1048 103.696C90.6159 103.1 91.4931 100.963 92.4769 101.198C92.4247 101.023 92.3513 100.855 92.2583 100.698C92.1107 100.463 90.6924 99.9163 90.6924 99.9163Z"
        fill="#FFBE9D"
      />
      <path d="M44.8383 105.109L45.0624 71.1533H42.8406L43.062 105.109H44.8383Z" fill="#69488D" />
      <path
        opacity="0.5"
        d="M43.062 105.109H44.8411L45.0187 77.98L45.0624 71.2217H42.8406L42.8844 77.98L43.062 105.109Z"
        fill="white"
      />
      <path opacity="0.2" d="M45.0624 71.2217L45.0187 78.0537H42.8844L42.8406 71.2217H45.0624Z" fill="black" />
      <path d="M62.0961 105.109L60.1012 71.1533H57.8794L60.3198 105.109H62.0961Z" fill="#69488D" />
      <path
        opacity="0.5"
        d="M60.3171 105.109H62.0961L60.2214 73.2358L60.1012 71.2217H57.8794L58.027 73.2358L60.3171 105.109Z"
        fill="white"
      />
      <path opacity="0.2" d="M60.2214 73.1347H58.027L57.8794 71.2217H60.1012L60.2214 73.1347Z" fill="black" />
      <path d="M27.5832 105.109L30.0209 71.1533H27.7991L25.8041 105.109H27.5832Z" fill="#69488D" />
      <path
        opacity="0.3"
        d="M27.5832 105.109L29.8733 73.2358L30.0209 71.2217H27.7991L27.6789 73.2358L25.8041 105.109H27.5832Z"
        fill="white"
      />
      <path opacity="0.2" d="M27.7991 71.2217H30.0209L29.8733 73.1347H27.6788L27.7991 71.2217Z" fill="black" />
      <path
        d="M26.0392 70.1066H61.8611V71.6124C61.8611 71.6899 61.8459 71.7667 61.8162 71.8383C61.7865 71.9099 61.743 71.975 61.6882 72.0298C61.6334 72.0846 61.5683 72.1281 61.4967 72.1578C61.4251 72.1874 61.3484 72.2027 61.2708 72.2027H26.6295C26.552 72.2027 26.4752 72.1874 26.4036 72.1578C26.332 72.1281 26.2669 72.0846 26.2121 72.0298C26.1573 71.975 26.1138 71.9099 26.0841 71.8383C26.0545 71.7667 26.0392 71.6899 26.0392 71.6124V70.1066Z"
        fill="#69488D"
      />
      <path
        opacity="0.4"
        d="M26.0392 70.1066H61.8611V71.6124C61.8611 71.6899 61.8459 71.7667 61.8162 71.8383C61.7865 71.9099 61.743 71.975 61.6882 72.0298C61.6334 72.0846 61.5683 72.1281 61.4967 72.1578C61.4251 72.1874 61.3484 72.2027 61.2708 72.2027H26.6295C26.552 72.2027 26.4752 72.1874 26.4036 72.1578C26.332 72.1281 26.2669 72.0846 26.2121 72.0298C26.1573 71.975 26.1138 71.9099 26.0841 71.8383C26.0545 71.7667 26.0392 71.6899 26.0392 71.6124V70.1066Z"
        fill="white"
      />
      <path opacity="0.2" d="M61.8611 70.1066H26.0392V71.156H61.8611V70.1066Z" fill="black" />
      <path
        d="M62.8996 70.3801H25.0007C24.8439 70.3794 24.6937 70.3166 24.5831 70.2054C24.4725 70.0943 24.4104 69.9439 24.4104 69.7871C24.4104 69.6305 24.4726 69.4804 24.5833 69.3697C24.694 69.259 24.8441 69.1968 25.0007 69.1968H62.8996C63.0561 69.1968 63.2063 69.259 63.317 69.3697C63.4277 69.4804 63.4899 69.6305 63.4899 69.7871C63.4899 69.9439 63.4278 70.0943 63.3171 70.2054C63.2065 70.3166 63.0564 70.3794 62.8996 70.3801Z"
        fill="#69488D"
      />
      <path
        opacity="0.4"
        d="M62.8996 70.3801H25.0007C24.8439 70.3794 24.6937 70.3166 24.5831 70.2054C24.4725 70.0943 24.4104 69.9439 24.4104 69.7871C24.4104 69.6305 24.4726 69.4804 24.5833 69.3697C24.694 69.259 24.8441 69.1968 25.0007 69.1968H62.8996C63.0561 69.1968 63.2063 69.259 63.317 69.3697C63.4277 69.4804 63.4899 69.6305 63.4899 69.7871C63.4899 69.9439 63.4278 70.0943 63.3171 70.2054C63.2065 70.3166 63.0564 70.3794 62.8996 70.3801Z"
        fill="white"
      />
      <path
        d="M41.8951 70.1066V71.2681C41.8951 71.3908 41.9193 71.5124 41.9662 71.6257C42.0132 71.7391 42.082 71.8422 42.1688 71.929C42.2556 72.0158 42.3586 72.0846 42.472 72.1316C42.5854 72.1785 42.707 72.2027 42.8297 72.2027H61.5414C61.6982 72.2027 61.8486 72.1406 61.9598 72.03C62.0709 71.9194 62.1337 71.7692 62.1344 71.6124V70.1066H41.8951Z"
        fill="#69488D"
      />
      <path
        opacity="0.6"
        d="M41.8951 70.1066V71.2681C41.8951 71.3908 41.9193 71.5124 41.9662 71.6257C42.0132 71.7391 42.082 71.8422 42.1688 71.929C42.2556 72.0158 42.3586 72.0846 42.472 72.1316C42.5854 72.1785 42.707 72.2027 42.8297 72.2027H61.5414C61.6982 72.2027 61.8486 72.1406 61.9598 72.03C62.0709 71.9194 62.1337 71.7692 62.1344 71.6124V70.1066H41.8951Z"
        fill="white"
      />
      <path opacity="0.2" d="M62.1344 70.1066H41.8951V71.156H62.1344V70.1066Z" fill="black" />
      <path
        d="M63.1373 69.1966H41.3922C41.2354 69.1966 41.085 69.2587 40.9738 69.3693C40.8627 69.4799 40.7999 69.6301 40.7992 69.7869C40.7992 69.9442 40.8617 70.095 40.9729 70.2062C41.0841 70.3174 41.2349 70.3799 41.3922 70.3799H63.1701C63.253 70.38 63.335 70.3626 63.4107 70.3289C63.4864 70.2952 63.5542 70.246 63.6096 70.1844C63.665 70.1228 63.7069 70.0502 63.7324 69.9713C63.7579 69.8925 63.7665 69.8091 63.7577 69.7267C63.737 69.5778 63.6625 69.4416 63.5481 69.3439C63.4338 69.2462 63.2877 69.1938 63.1373 69.1966Z"
        fill="#69488D"
      />
      <path
        opacity="0.6"
        d="M63.1373 69.1966H41.3922C41.2354 69.1966 41.085 69.2587 40.9738 69.3693C40.8627 69.4799 40.7999 69.6301 40.7992 69.7869C40.7992 69.9442 40.8617 70.095 40.9729 70.2062C41.0841 70.3174 41.2349 70.3799 41.3922 70.3799H63.1701C63.253 70.38 63.335 70.3626 63.4107 70.3289C63.4864 70.2952 63.5542 70.246 63.6096 70.1844C63.665 70.1228 63.7069 70.0502 63.7324 69.9713C63.7579 69.8925 63.7665 69.8091 63.7577 69.7267C63.737 69.5778 63.6625 69.4416 63.5481 69.3439C63.4338 69.2462 63.2877 69.1938 63.1373 69.1966Z"
        fill="white"
      />
      <path d="M46.2156 64.2938L43.8243 68.71" stroke="#2E353A" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M23.6889 59.4704C23.6889 59.4704 25.8478 55.6253 26.2031 54.8738C26.5584 54.1223 27.5149 48.8261 27.5149 48.8261C27.4272 48.5722 27.3694 48.309 27.3427 48.0417C27.3427 47.7684 27.5149 46.5031 27.5149 46.5031C27.5149 46.5031 27.5149 46.402 28.1981 46.1971C28.8813 45.9921 29.6328 45.7188 29.7011 46.0249C29.7695 46.331 29.5973 48.4517 29.3923 48.6566C29.233 48.8275 29.0868 49.0102 28.9551 49.2032C28.9551 49.2032 28.3784 54.8492 28.1161 55.68C27.8537 56.5108 25.703 60.9571 25.703 60.9571L23.6889 59.4704Z"
        fill="#E4897B"
      />
      <path
        d="M33.7648 64.2365C33.7648 64.2365 39.1485 66.5758 39.9274 66.849C40.7062 67.1223 43.0455 67.177 43.0455 67.177C43.2916 67.0688 43.5493 66.989 43.8135 66.9392C44.0867 66.9146 45.3575 66.9775 45.3575 66.9775C45.3575 66.9775 45.4614 66.9775 45.7237 67.6334C45.9861 68.2892 46.3222 69.0216 46.0216 69.1146C45.721 69.2075 43.5976 69.2211 43.3762 69.0353L43.1548 68.8467C43.1548 68.8467 40.1487 69.5627 39.3016 69.3742C38.4544 69.1856 32.4695 67.4612 32.4695 67.4612L33.7648 64.2365Z"
        fill="#E4897B"
      />
      <path
        d="M26.5693 60.1345C26.3761 61.094 25.9719 61.9985 25.386 62.7826C24.2327 64.4223 22.6067 65.4881 22.1667 63.8019C21.4972 61.2549 24.3366 58.063 24.3366 58.063L26.5693 60.1345Z"
        fill="#69488D"
      />
      <path
        opacity="0.2"
        d="M26.5693 60.1346C26.3761 61.0942 25.9719 61.9987 25.386 62.7828C25.256 62.6549 25.1558 62.5 25.0925 62.329C25.0292 62.158 25.0044 61.9751 25.0198 61.7935C25.1783 60.6539 26.5693 60.1346 26.5693 60.1346Z"
        fill="black"
      />
      <path
        d="M32.2891 58.2243C32.1352 58.785 31.8219 59.2891 31.3873 59.6754C30.7439 60.252 29.9145 60.5779 29.0507 60.5936C28.1965 60.6143 27.3669 60.3061 26.7333 59.7328C25.4789 58.6014 25.5172 57.3006 25.3669 55.087C25.2166 52.8734 27.6214 51.19 30.0564 52.0809C32.4914 52.9718 32.8357 56.2375 32.2891 58.2243Z"
        fill="#E4897B"
      />
      <path
        d="M29.2966 50.6651C29.2966 50.6651 30.9992 50.0421 32.1934 51.0341C33.3877 52.0261 33.3221 54.4146 32.1934 55.5432C32.1934 55.5432 32.5022 55.1333 31.6141 54.1413C30.7259 53.1493 29.2966 50.6651 29.2966 50.6651Z"
        fill="#2E353A"
      />
      <path
        opacity="0.2"
        d="M31.3872 59.6754C30.7438 60.252 29.9145 60.5779 29.0507 60.5936C29.2109 60.2236 29.4711 59.9056 29.8022 59.6754C30.316 59.9377 30.9527 59.8147 31.3872 59.6754Z"
        fill="black"
      />
      <path
        d="M31.8245 95.8855L31.5348 97.6974L31.2697 99.3698H28.8457L29.0561 97.23L29.2556 95.1804L31.8245 95.8855Z"
        fill="#E4897B"
      />
      <path
        d="M31.1331 98.4212L28.6736 98.2655C28.6531 98.2632 28.6324 98.265 28.6126 98.2708C28.5928 98.2766 28.5744 98.2863 28.5584 98.2992C28.5424 98.3122 28.5291 98.3282 28.5193 98.3463C28.5095 98.3645 28.5034 98.3844 28.5014 98.4049L28.2582 100.345C28.2522 100.447 28.2867 100.548 28.3542 100.624C28.4218 100.701 28.5169 100.748 28.6189 100.755C29.477 100.796 29.8897 100.771 30.9719 100.832C31.636 100.87 32.945 101.012 33.8632 100.922C34.7814 100.832 34.6667 99.9298 34.2704 99.886C33.333 99.7795 32.0841 99.0908 31.502 98.5661C31.3982 98.4788 31.2686 98.4279 31.1331 98.4212Z"
        fill="#2E353A"
      />
      <path
        d="M31.8873 98.8423C31.6898 98.8249 31.4946 98.7864 31.3053 98.7275C31.296 98.7249 31.2876 98.7196 31.2813 98.7123C31.275 98.705 31.271 98.6961 31.2697 98.6865C31.2686 98.6767 31.27 98.6668 31.2738 98.6577C31.2777 98.6486 31.2838 98.6406 31.2916 98.6346C31.3654 98.5908 32.0131 98.2055 32.2754 98.3258C32.3048 98.3403 32.3302 98.3619 32.3492 98.3886C32.3683 98.4153 32.3805 98.4463 32.3847 98.4788C32.3979 98.5272 32.3974 98.5784 32.3835 98.6266C32.3695 98.6748 32.3425 98.7182 32.3055 98.7521C32.1822 98.8331 32.0331 98.8653 31.8873 98.8423ZM32.2317 98.4296C32.0923 98.364 31.7179 98.5253 31.4528 98.6701C31.8381 98.7712 32.1278 98.7767 32.2371 98.6838C32.2599 98.6603 32.2763 98.6314 32.2849 98.5999C32.2935 98.5684 32.294 98.5352 32.2863 98.5034C32.2841 98.4875 32.278 98.4724 32.2684 98.4595C32.2589 98.4466 32.2462 98.4363 32.2317 98.4296Z"
        fill="#69488D"
      />
      <path
        d="M31.3052 98.7274C31.2982 98.7301 31.2904 98.7301 31.2834 98.7274C31.2703 98.7173 31.2615 98.7027 31.2588 98.6864C31.2588 98.6673 31.2588 98.0251 31.4829 97.8256C31.5134 97.8006 31.5491 97.7825 31.5873 97.7726C31.6255 97.7627 31.6654 97.7612 31.7042 97.7682C31.7479 97.7718 31.789 97.79 31.8212 97.8197C31.8534 97.8494 31.8747 97.8889 31.8819 97.9321C31.9147 98.1754 31.5594 98.6126 31.3353 98.7274C31.3307 98.7298 31.3255 98.731 31.3203 98.731C31.315 98.731 31.3099 98.7298 31.3052 98.7274ZM31.6605 97.8748C31.64 97.8734 31.6195 97.8764 31.6002 97.8834C31.5809 97.8905 31.5633 97.9015 31.5485 97.9157C31.4157 98.118 31.3507 98.3573 31.3626 98.5989C31.5747 98.4438 31.7224 98.2163 31.778 97.9595C31.778 97.9294 31.7589 97.8912 31.6878 97.8775L31.6605 97.8748Z"
        fill="#69488D"
      />
      <path
        opacity="0.2"
        d="M31.8245 95.8855L31.5348 97.6974L29.0562 97.23L29.2556 95.1804L31.8245 95.8855Z"
        fill="black"
      />
      <path
        d="M34.7678 82.1201C33.6446 80.6389 30.7396 79.8874 28.403 79.513L27.616 85.3749C29.0562 85.6728 29.6547 86.4681 29.7339 86.7987C30.1138 88.3674 28.2992 96.5467 28.2992 96.5467L32.0732 97.4568C32.0732 97.4568 36.771 84.7628 34.7678 82.1201Z"
        fill="#2E353A"
      />
      <path
        opacity="0.2"
        d="M34.7678 82.1201C33.6446 80.6389 30.7396 79.8874 28.403 79.513L27.616 85.3749C29.0562 85.6728 29.6547 86.4681 29.7339 86.7987C30.1138 88.3674 28.2992 96.5467 28.2992 96.5467L32.0732 97.4568C32.0732 97.4568 36.771 84.7628 34.7678 82.1201Z"
        fill="black"
      />
      <path
        d="M38.6566 95.8855L38.3669 97.6974L38.1018 99.3698H35.6778L35.8882 97.23L36.0877 95.1804L38.6566 95.8855Z"
        fill="#E4897B"
      />
      <path
        d="M37.9652 98.4212L35.5057 98.2655C35.4852 98.2632 35.4645 98.265 35.4447 98.2708C35.4249 98.2766 35.4065 98.2863 35.3905 98.2992C35.3745 98.3122 35.3612 98.3282 35.3514 98.3463C35.3416 98.3645 35.3355 98.3844 35.3335 98.4049L35.0903 100.345C35.0843 100.447 35.1188 100.548 35.1863 100.624C35.2539 100.701 35.349 100.748 35.451 100.755C36.3091 100.796 36.7218 100.771 37.804 100.832C38.468 100.87 39.7771 101.012 40.6953 100.922C41.6135 100.832 41.4988 99.9298 41.1025 99.886C40.1651 99.7795 38.9162 99.0908 38.3341 98.5661C38.2303 98.4788 38.1007 98.4279 37.9652 98.4212Z"
        fill="#2E353A"
      />
      <path
        d="M38.7194 98.8423C38.5219 98.8249 38.3267 98.7864 38.1373 98.7275C38.1281 98.7249 38.1197 98.7196 38.1134 98.7123C38.1071 98.705 38.1031 98.6961 38.1018 98.6865C38.1007 98.6767 38.1021 98.6668 38.1059 98.6577C38.1098 98.6486 38.1159 98.6406 38.1237 98.6346C38.1975 98.5908 38.8451 98.2055 39.1075 98.3258C39.1369 98.3403 39.1623 98.3619 39.1813 98.3886C39.2004 98.4153 39.2126 98.4463 39.2168 98.4788C39.23 98.5272 39.2295 98.5784 39.2156 98.6266C39.2016 98.6748 39.1746 98.7182 39.1376 98.7521C39.0143 98.8331 38.8652 98.8653 38.7194 98.8423ZM39.0638 98.4296C38.9244 98.364 38.55 98.5253 38.2849 98.6701C38.6702 98.7712 38.9599 98.7767 39.0692 98.6838C39.092 98.6603 39.1084 98.6314 39.117 98.5999C39.1256 98.5684 39.1261 98.5352 39.1184 98.5034C39.1162 98.4875 39.1101 98.4724 39.1005 98.4595C39.091 98.4466 39.0783 98.4363 39.0638 98.4296Z"
        fill="#69488D"
      />
      <path
        d="M38.1373 98.7274C38.1303 98.7301 38.1225 98.7301 38.1155 98.7274C38.1024 98.7173 38.0936 98.7027 38.0909 98.6864C38.0909 98.6673 38.0909 98.0251 38.315 97.8256C38.3455 97.8006 38.3812 97.7825 38.4194 97.7726C38.4576 97.7627 38.4975 97.7612 38.5363 97.7682C38.58 97.7718 38.6211 97.79 38.6533 97.8197C38.6855 97.8494 38.7068 97.8889 38.714 97.9321C38.7468 98.1754 38.3915 98.6126 38.1674 98.7274C38.1628 98.7298 38.1576 98.731 38.1524 98.731C38.1471 98.731 38.142 98.7298 38.1373 98.7274ZM38.4926 97.8748C38.4721 97.8734 38.4516 97.8764 38.4323 97.8834C38.413 97.8905 38.3954 97.9015 38.3806 97.9157C38.2478 98.118 38.1828 98.3573 38.1947 98.5989C38.4068 98.4438 38.5545 98.2163 38.6101 97.9595C38.6101 97.9294 38.591 97.8912 38.5199 97.8775L38.4926 97.8748Z"
        fill="#69488D"
      />
      <path
        opacity="0.2"
        d="M38.6566 95.8855L38.3669 97.6974L35.8882 97.23L36.0877 95.1804L38.6566 95.8855Z"
        fill="black"
      />
      <path
        d="M26.7852 59.3693C26.7852 60.6702 26.6321 61.9054 25.8314 62.4848C26.9388 63.43 28.1756 64.2123 29.5043 64.8077C30.9254 63.7993 30.2695 62.9657 30.2695 62.9657C29.0261 62.2853 29.4142 60.692 29.8022 59.6863C29.8022 59.6863 29.4961 59.1917 27.7526 59.1862C26.9792 59.1726 26.7852 59.3693 26.7852 59.3693Z"
        fill="#E4897B"
      />
      <path
        d="M20.0761 78.1055C20.0761 78.1055 17.5346 85.2929 24.7383 85.2929C31.942 85.2929 32.5241 82.4835 31.9147 79.1768L20.0761 78.1055Z"
        fill="#2E353A"
      />
      <path
        d="M31.9147 79.1469C31.9147 79.1469 39.5939 79.4694 41.5998 82.112C43.6057 84.7547 38.9053 97.4569 38.9053 97.4569L35.1312 96.5469C35.1312 96.5469 37.2055 87.8865 36.4239 86.766C35.6423 85.6456 31.3955 85.5773 25.6565 85.2767C19.9176 84.976 31.9147 79.1469 31.9147 79.1469Z"
        fill="#2E353A"
      />
      <path
        d="M25.1237 62.2715L25.8397 60.5908C25.8397 60.5908 29.9252 60.7384 31.1659 62.5995C32.4067 64.4605 25.1237 62.2715 25.1237 62.2715Z"
        fill="#69488D"
      />
      <path
        d="M33.456 64.8295C33.5789 66.931 33.456 71.2489 31.9147 79.1768L20.076 78.1056C20.1526 76.4303 22.6586 70.6887 22.1257 63.1297C22.1059 62.8619 22.1392 62.5928 22.2237 62.3379C22.3081 62.083 22.442 61.8472 22.6176 61.6442C22.7933 61.4411 23.0073 61.2746 23.2474 61.1544C23.4875 61.0341 23.7489 60.9624 24.0168 60.9434H24.0851C24.6672 60.9215 28.2171 61.9709 29.8842 62.2224C30.5264 62.3399 31.1495 62.4956 31.6796 62.6459C32.1664 62.7794 32.5992 63.0616 32.9177 63.4531C33.2362 63.8446 33.4244 64.3258 33.456 64.8295Z"
        fill="#69488D"
      />
      <path
        opacity="0.2"
        d="M33.456 64.8295C33.5789 66.931 33.456 71.2489 31.9147 79.1768L20.076 78.1056C20.1526 76.4303 22.6586 70.6887 22.1257 63.1297C22.1059 62.8619 22.1392 62.5928 22.2237 62.3379C22.3081 62.083 22.442 61.8472 22.6176 61.6442C22.7933 61.4411 23.0073 61.2746 23.2474 61.1544C23.4875 61.0341 23.7489 60.9624 24.0168 60.9434H24.0851C24.6672 60.9215 28.2171 61.9709 29.8842 62.2224C30.5264 62.3399 31.1495 62.4956 31.6796 62.6459C32.1664 62.7794 32.5992 63.0616 32.9177 63.4531C33.2362 63.8446 33.4244 64.3258 33.456 64.8295Z"
        fill="black"
      />
      <path
        d="M31.6797 62.6459C30.3789 62.3727 30.2341 66.1658 31.1796 67.2589C32.1252 68.3521 37.1672 69.1719 37.1672 69.1719L38.1019 65.7559C38.1019 65.7559 33.9562 63.1378 31.6797 62.6459Z"
        fill="#69488D"
      />
      <path
        d="M26.2742 59.2352C26.2742 59.2352 24.6099 58.7788 23.8365 55.207C23.0631 51.6352 26.4655 50.0419 30.3215 50.8153C34.1775 51.5887 32.2919 57.1008 28.9359 59.2352C28.0744 59.5372 27.1357 59.5372 26.2742 59.2352Z"
        fill="#2E353A"
      />
      <path
        d="M30.4854 56.3358C30.5779 56.7788 30.836 57.1698 31.2069 57.429C31.6797 57.7405 32.0459 57.265 32.0268 56.6529C32.0049 56.1063 31.7344 55.2728 31.2069 55.2126C30.6795 55.1525 30.3543 55.7565 30.4854 56.3358Z"
        fill="#E4897B"
      />
      <path
        d="M25.897 105.109H27.605L27.9384 90.7805L28.0368 86.5255H25.468L25.5663 90.7805L25.897 105.109Z"
        fill="#69488D"
      />
      <path
        opacity="0.4"
        d="M25.897 105.109H27.605L27.9384 90.7805L28.0368 86.5255H25.468L25.5663 90.7805L25.897 105.109Z"
        fill="white"
      />
      <path opacity="0.2" d="M28.0368 86.5255L27.9384 90.898H25.5663L25.468 86.5255H28.0368Z" fill="black" />
      <path d="M35.779 105.109L34.6995 86.826H32.4668L33.9781 105.109H35.779Z" fill="#69488D" />
      <path
        opacity="0.5"
        d="M33.9753 105.109H35.779L34.8225 88.903L34.6995 86.7988H32.4668L32.639 88.903L33.9753 105.109Z"
        fill="white"
      />
      <path opacity="0.2" d="M32.4668 86.7988L32.639 88.985H34.8225L34.6995 86.7988H32.4668Z" fill="black" />
      <path d="M19.5241 105.109L21.0353 86.826H18.8026L17.7259 105.109H19.5241Z" fill="#69488D" />
      <path
        opacity="0.5"
        d="M19.5296 105.109L20.8632 88.903L21.0353 86.7988H18.8026L18.6797 88.903L17.7259 105.109H19.5296Z"
        fill="white"
      />
      <path opacity="0.2" d="M21.0353 86.7988L20.8632 88.985H18.6796L18.8026 86.7988H21.0353Z" fill="black" />
      <path
        d="M18.9748 84.4294H34.5301C34.7095 84.4294 34.8872 84.4647 35.053 84.5334C35.2188 84.6021 35.3694 84.7027 35.4963 84.8296C35.6232 84.9565 35.7238 85.1071 35.7925 85.2729C35.8612 85.4387 35.8965 85.6164 35.8965 85.7958V87.6186H17.6084V85.7903C17.6098 85.4289 17.7544 85.0827 18.0105 84.8277C18.2666 84.5726 18.6134 84.4294 18.9748 84.4294Z"
        fill="#69488D"
      />
      <path d="M24.7824 66.8485L23.4286 67.0339L26.1108 86.6149L27.4646 86.4294L24.7824 66.8485Z" fill="#69488D" />
      <path d="M16.3098 66.8476L14.956 67.0331L17.6383 86.614L18.992 86.4286L16.3098 66.8476Z" fill="#69488D" />
      <g opacity="0.4">
        <path
          opacity="0.4"
          d="M24.7824 66.8486L23.4286 67.0341L26.1108 86.615L27.4646 86.4296L24.7824 66.8486Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        <path
          opacity="0.4"
          d="M16.3098 66.8476L14.956 67.0331L17.6383 86.614L18.992 86.4286L16.3098 66.8476Z"
          fill="white"
        />
      </g>
      <path
        d="M36.2217 86.0281H17.2804C16.9771 86.0281 16.7311 86.2741 16.7311 86.5774V87.0748C16.7311 87.3782 16.9771 87.6241 17.2804 87.6241H36.2217C36.5251 87.6241 36.771 87.3782 36.771 87.0748V86.5774C36.771 86.2741 36.5251 86.0281 36.2217 86.0281Z"
        fill="#69488D"
      />
      <path
        opacity="0.6"
        d="M36.2217 86.0281H17.2804C16.9771 86.0281 16.7311 86.2741 16.7311 86.5774V87.0748C16.7311 87.3782 16.9771 87.6241 17.2804 87.6241H36.2217C36.5251 87.6241 36.771 87.3782 36.771 87.0748V86.5774C36.771 86.2741 36.5251 86.0281 36.2217 86.0281Z"
        fill="white"
      />
      <path d="M26.6732 73.4872H14.5148L13.5419 65.9008H25.7003L26.6732 73.4872Z" fill="#69488D" />
      <path opacity="0.6" d="M26.6732 73.4872H14.5148L13.5419 65.9008H25.7003L26.6732 73.4872Z" fill="white" />

      <defs>
        <clipPath id="clip0">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SchoolsMenu;
