import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cl from './ClubJoin.module.scss';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useClubs } from 'hooks/clubs';
import { Button } from 'ui/button/Button';
import { Step, Stepper } from 'ui/stepper/Stepper';
import { Step1 } from './steps/Step1/Step1';
import { Step2 } from './steps/Step2/Step2';
import { Step4 } from './steps/Step4/Step4';
import { Step3 } from './steps/Step3/Step3';
import { Step5 } from './steps/Step5/Step5';
import { useStore } from 'effector-react';
import {
  $step,
  $step2,
  $viewType,
  changeViewType,
  resetSteps,
  step2SetProfile,
  $editChild,
  addChild,
  setEditChild,
  updateChild,
} from './model';
import { ProfileEdit } from 'ui/edits/ProfileEdit';
import { ChildAdd } from 'ui/edits/ChildAdd';
import { EmailEdit } from 'ui/edits/EmailEdit';
import { RequisitesEdit } from 'ui/edits/RequisitesEdit';
import { setActiveClub } from 'state/club';

interface Props extends RouteComponentProps<{ id: string }> {}

const STEPS: Step[] = [
  {
    label: 'Выбор даты',
    component: <Step1 />,
  },
  {
    label: 'Реквизиты',
    component: <Step2 />,
  },
  {
    label: 'Выбор ребенка',
    component: <Step3 />,
  },
  {
    label: 'Выбор льгот',
    component: <Step4 />,
  },
  {
    label: 'Подписание договора',
    component: <Step5 />,
  },
];

export const ClubJoin: React.FC<Props> = ({
  match: {
    params: { id },
  },
}) => {
  const { map: clubsMap } = useClubs();
  const club = clubsMap.get(+id);
  const history = useHistory();

  const step = useStore($step);

  const profile = useStore($step2);

  const child = useStore($editChild);

  const stepRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  const viewType = useStore($viewType);

  useEffect(() => {
    if (stepRef.current) {
      const { top } = stepRef.current.getBoundingClientRect();
      setOffset(top + 46);
    }
  }, [stepRef]);

  useEffect(() => {
    return () => {
      resetSteps();
    };
  }, []);

  useEffect(() => {
    setActiveClub(+id);
  }, [id]);

  const handleCancel = useCallback(() => {
    history.push(`/clubs/${id}`);
    setActiveClub(-1);
  }, [history, id]);

  const steps = useMemo(() => (club?.privileges || club?.maternal_capital ? STEPS : STEPS.filter((c, i) => i !== 3)), [
    club,
  ]);

  return (
    <div className={cl.root}>
      <div className={cl.header}>
        <h1>
          <span className={cl.title}>Запись в кружок </span> {club?.title}
        </h1>
        <Button variant="text" onClick={handleCancel}>
          Отмена
        </Button>
      </div>
      <Stepper steps={steps} current={step} />
      {viewType === 'step' && (
        <div
          className={cl.stepWrapper}
          ref={stepRef}
          style={offset !== 0 ? { minHeight: `calc(100vh - ${offset}px)` } : {}}
        >
          {steps[step].component}
        </div>
      )}
      {viewType === 'profile' && (
        <ProfileEdit state={profile} changeViewType={changeViewType} stepSetProfile={step2SetProfile} />
      )}
      {viewType === 'requisites' && (
        <RequisitesEdit
          state={profile}
          type={'club'}
          changeViewType={changeViewType}
          stepSetProfile={step2SetProfile}
        />
      )}
      {viewType === 'child' && (
        <ChildAdd
          childInfo={child}
          addChild={addChild}
          setEditChild={setEditChild}
          updateChild={updateChild}
          changeViewType={changeViewType}
        />
      )}
      {viewType === 'email' && <EmailEdit state={profile} changeViewType={changeViewType} />}
    </div>
  );
};
