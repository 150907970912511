import { FetchingListState, initFetchingListState, storeAttachFetchList } from '../utils';
import { createEffect, createEvent, createStore, guard, sample } from 'effector';
import { ApiResponse } from 'api/types';
import { ApiError } from 'api/errors/apiError';
import { AdultType } from 'api/types/catalog/adult_types';
import api from 'api/request/catalog';

export interface AdultTypesState extends FetchingListState<AdultType> {}

export const fetchAdultTypeIfNeed = createEvent();

export const getAdultTypesFx = createEffect<void, ApiResponse<AdultType[]>, ApiError<any>>({
  handler: async () => await api.adultTypes(),
});

export const $adultTypes = createStore<AdultTypesState>(initFetchingListState<AdultType>());

guard({
  source: sample($adultTypes, fetchAdultTypeIfNeed),
  filter: $adultTypes.map((state) => !state.isFetching && !state.didFetched),
  target: getAdultTypesFx,
});

storeAttachFetchList($adultTypes, [getAdultTypesFx]);
