import React, { useCallback, useMemo, useState } from 'react';
import { LeftSide } from 'components/layout/MainLayout/LeftSide';
import cl from './SchoolsLeftSide.module.scss';
import Icon from 'ui/Icon';
import SchoolItem from '../SchoolItem';
import { useHistory, useLocation } from 'react-router';
import cn from 'classnames';
import { setActiveProgramm } from 'state/school';
import SimpleBar from 'simplebar-react';
import { useSchools } from 'hooks/schools';

interface Props {
  isRootPath: boolean;
}
const SchoolsLeftSide: React.FC<Props> = ({ isRootPath }) => {
  const history = useHistory();

  const [isSelected, setSelect] = useState(false);

  const { data: schoolsData } = useSchools();

  const [currentSchoolParallel, setCurrentSchoolParallel] = useState(-1);
  const [currentProgrammId, setCurrentSchoolProgrammId] = useState(-1);

  const location = useLocation();

  let parallelForRoute: number;
  let programmIdForRoute: number;

  if (location.pathname.split('/')[2] && location.pathname.split('/')[2] !== 'empty') {
    parallelForRoute = +location.pathname.split('/')[2];
  } else {
    parallelForRoute = currentSchoolParallel;
  }
  if (location.pathname.split('/')[3]) {
    programmIdForRoute = +location.pathname.split('/')[3];
  } else {
    programmIdForRoute = currentProgrammId;
  }

  const handleClickRouteToProgramm = useCallback(
    (parallel: number, schoolProgammId: number) => {
      history.push(`/schools/${parallel}/${schoolProgammId}`);
    },
    [history],
  );
  const handleClickRouteToEmpty = useCallback(
    (parallel: number) => {
      history.push(`/schools/${parallel}/empty`);
    },
    [history],
  );

  const handleClickRouteToSchools = useCallback(
    (parallel: number) => {
      history.push(`/schools/${parallel}`);
    },
    [history],
  );

  const handleClickValue = (parallel: number) => {
    setCurrentSchoolParallel(parallel);
    handleClickRouteToSchools(parallel);
    setCurrentSchoolProgrammId(-1);
    setActiveProgramm(-1);
    setSelect(!isSelected);
  };

  const allSchoolsItems = useMemo(() => schoolsData.find((element) => element.number === parallelForRoute), [
    parallelForRoute,
    schoolsData,
  ]);

  const handleClickItem = (parallel: number, schoolProgammId: number) => {
    if (allSchoolsItems?.programs.find((elem) => elem.id === schoolProgammId)?.places.length) {
      setCurrentSchoolProgrammId(schoolProgammId);
      handleClickRouteToProgramm(parallel, schoolProgammId);
    } else {
      handleClickRouteToEmpty(parallel);
    }
  };

  return (
    <LeftSide
      isRootPath={isRootPath}
      title={'Выбор класса'}
      topComponent={
        <div className={cl.filter}>
          <div
            className={cn(cl.select, isSelected && cl.activeSelect)}
            tabIndex={1}
            onBlur={() => setSelect(false)}
            onClick={() => setSelect(!isSelected)}
          >
            <div className={cn(cl.icon, isSelected ? cl.invertedIcon : '')}>
              <Icon type={'Cover'} />
            </div>
            {parallelForRoute && parallelForRoute !== -1 ? `${parallelForRoute} класс` : 'Выберите класс'}
            {isSelected ? (
              <div className={cl.classesContainer}>
                <SimpleBar className={cl.classes}>
                  {schoolsData.map((element) => (
                    <div onClick={() => handleClickValue(element.number)} className={cl.class} key={element.id}>
                      {element.number} класс
                    </div>
                  ))}
                </SimpleBar>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      }
    >
      <ul className={cl.menu}>
        {parallelForRoute !== -1 && JSON.stringify(allSchoolsItems) !== '{}' ? (
          allSchoolsItems?.programs.map((element) => (
            <SchoolItem
              isActive={element.id === programmIdForRoute}
              onClick={handleClickItem}
              id={element.id}
              key={element.title + '' + element.id}
              parallel={parallelForRoute}
              name={element.title}
            />
          ))
        ) : (
          <div className={cl.default}>Выберите из списка класс, в который хотели бы записаться</div>
        )}
      </ul>
    </LeftSide>
  );
};

export default SchoolsLeftSide;
