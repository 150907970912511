import React from 'react';

interface Props {}

const AppQrCode: React.FC<Props> = () => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M100 0H0V100H100V0Z" fill="white" />
      <path d="M12.7586 10H10V12.7586H12.7586V10Z" fill="black" />
      <path d="M15.5172 10H12.7585V12.7586H15.5172V10Z" fill="black" />
      <path d="M18.276 10H15.5173V12.7586H18.276V10Z" fill="black" />
      <path d="M21.0345 10H18.2759V12.7586H21.0345V10Z" fill="black" />
      <path d="M23.793 10H21.0344V12.7586H23.793V10Z" fill="black" />
      <path d="M26.5518 10H23.7932V12.7586H26.5518V10Z" fill="black" />
      <path d="M29.3104 10H26.5518V12.7586H29.3104V10Z" fill="black" />
      <path d="M37.5863 10H34.8276V12.7586H37.5863V10Z" fill="black" />
      <path d="M45.8621 10H43.1035V12.7586H45.8621V10Z" fill="black" />
      <path d="M51.3792 10H48.6206V12.7586H51.3792V10Z" fill="black" />
      <path d="M56.8966 10H54.1379V12.7586H56.8966V10Z" fill="black" />
      <path d="M62.4139 10H59.6553V12.7586H62.4139V10Z" fill="black" />
      <path d="M65.1724 10H62.4138V12.7586H65.1724V10Z" fill="black" />
      <path d="M73.4483 10H70.6897V12.7586H73.4483V10Z" fill="black" />
      <path d="M76.2069 10H73.4482V12.7586H76.2069V10Z" fill="black" />
      <path d="M78.9656 10H76.207V12.7586H78.9656V10Z" fill="black" />
      <path d="M81.7242 10H78.9656V12.7586H81.7242V10Z" fill="black" />
      <path d="M84.4827 10H81.7241V12.7586H84.4827V10Z" fill="black" />
      <path d="M87.2413 10H84.4827V12.7586H87.2413V10Z" fill="black" />
      <path d="M90.0001 10H87.2415V12.7586H90.0001V10Z" fill="black" />
      <path d="M12.7586 12.7587H10V15.5173H12.7586V12.7587Z" fill="black" />
      <path d="M29.3104 12.7587H26.5518V15.5173H29.3104V12.7587Z" fill="black" />
      <path d="M34.8277 12.7587H32.0691V15.5173H34.8277V12.7587Z" fill="black" />
      <path d="M37.5863 12.7587H34.8276V15.5173H37.5863V12.7587Z" fill="black" />
      <path d="M40.3448 12.7587H37.5862V15.5173H40.3448V12.7587Z" fill="black" />
      <path d="M48.6207 12.7587H45.8621V15.5173H48.6207V12.7587Z" fill="black" />
      <path d="M51.3792 12.7587H48.6206V15.5173H51.3792V12.7587Z" fill="black" />
      <path d="M56.8966 12.7587H54.1379V15.5173H56.8966V12.7587Z" fill="black" />
      <path d="M67.931 12.7587H65.1724V15.5173H67.931V12.7587Z" fill="black" />
      <path d="M73.4483 12.7587H70.6897V15.5173H73.4483V12.7587Z" fill="black" />
      <path d="M90.0001 12.7587H87.2415V15.5173H90.0001V12.7587Z" fill="black" />
      <path d="M12.7586 15.5173H10V18.2759H12.7586V15.5173Z" fill="black" />
      <path d="M18.276 15.5173H15.5173V18.2759H18.276V15.5173Z" fill="black" />
      <path d="M21.0345 15.5173H18.2759V18.2759H21.0345V15.5173Z" fill="black" />
      <path d="M23.793 15.5173H21.0344V18.2759H23.793V15.5173Z" fill="black" />
      <path d="M29.3104 15.5173H26.5518V18.2759H29.3104V15.5173Z" fill="black" />
      <path d="M37.5863 15.5173H34.8276V18.2759H37.5863V15.5173Z" fill="black" />
      <path d="M43.1033 15.5173H40.3447V18.2759H43.1033V15.5173Z" fill="black" />
      <path d="M45.8621 15.5173H43.1035V18.2759H45.8621V15.5173Z" fill="black" />
      <path d="M48.6207 15.5173H45.8621V18.2759H48.6207V15.5173Z" fill="black" />
      <path d="M51.3792 15.5173H48.6206V18.2759H51.3792V15.5173Z" fill="black" />
      <path d="M54.138 15.5173H51.3794V18.2759H54.138V15.5173Z" fill="black" />
      <path d="M62.4139 15.5173H59.6553V18.2759H62.4139V15.5173Z" fill="black" />
      <path d="M67.931 15.5173H65.1724V18.2759H67.931V15.5173Z" fill="black" />
      <path d="M73.4483 15.5173H70.6897V18.2759H73.4483V15.5173Z" fill="black" />
      <path d="M78.9656 15.5173H76.207V18.2759H78.9656V15.5173Z" fill="black" />
      <path d="M81.7242 15.5173H78.9656V18.2759H81.7242V15.5173Z" fill="black" />
      <path d="M84.4827 15.5173H81.7241V18.2759H84.4827V15.5173Z" fill="black" />
      <path d="M90.0001 15.5173H87.2415V18.2759H90.0001V15.5173Z" fill="black" />
      <path d="M12.7586 18.2759H10V21.0346H12.7586V18.2759Z" fill="black" />
      <path d="M18.276 18.2759H15.5173V21.0346H18.276V18.2759Z" fill="black" />
      <path d="M21.0345 18.2759H18.2759V21.0346H21.0345V18.2759Z" fill="black" />
      <path d="M23.793 18.2759H21.0344V21.0346H23.793V18.2759Z" fill="black" />
      <path d="M29.3104 18.2759H26.5518V21.0346H29.3104V18.2759Z" fill="black" />
      <path d="M34.8277 18.2759H32.0691V21.0346H34.8277V18.2759Z" fill="black" />
      <path d="M43.1033 18.2759H40.3447V21.0346H43.1033V18.2759Z" fill="black" />
      <path d="M45.8621 18.2759H43.1035V21.0346H45.8621V18.2759Z" fill="black" />
      <path d="M48.6207 18.2759H45.8621V21.0346H48.6207V18.2759Z" fill="black" />
      <path d="M54.138 18.2759H51.3794V21.0346H54.138V18.2759Z" fill="black" />
      <path d="M59.6551 18.2759H56.8965V21.0346H59.6551V18.2759Z" fill="black" />
      <path d="M65.1724 18.2759H62.4138V21.0346H65.1724V18.2759Z" fill="black" />
      <path d="M67.931 18.2759H65.1724V21.0346H67.931V18.2759Z" fill="black" />
      <path d="M73.4483 18.2759H70.6897V21.0346H73.4483V18.2759Z" fill="black" />
      <path d="M78.9656 18.2759H76.207V21.0346H78.9656V18.2759Z" fill="black" />
      <path d="M81.7242 18.2759H78.9656V21.0346H81.7242V18.2759Z" fill="black" />
      <path d="M84.4827 18.2759H81.7241V21.0346H84.4827V18.2759Z" fill="black" />
      <path d="M90.0001 18.2759H87.2415V21.0346H90.0001V18.2759Z" fill="black" />
      <path d="M12.7586 21.0346H10V23.7932H12.7586V21.0346Z" fill="black" />
      <path d="M18.276 21.0346H15.5173V23.7932H18.276V21.0346Z" fill="black" />
      <path d="M21.0345 21.0346H18.2759V23.7932H21.0345V21.0346Z" fill="black" />
      <path d="M23.793 21.0346H21.0344V23.7932H23.793V21.0346Z" fill="black" />
      <path d="M29.3104 21.0346H26.5518V23.7932H29.3104V21.0346Z" fill="black" />
      <path d="M34.8277 21.0346H32.0691V23.7932H34.8277V21.0346Z" fill="black" />
      <path d="M37.5863 21.0346H34.8276V23.7932H37.5863V21.0346Z" fill="black" />
      <path d="M40.3448 21.0346H37.5862V23.7932H40.3448V21.0346Z" fill="black" />
      <path d="M43.1033 21.0346H40.3447V23.7932H43.1033V21.0346Z" fill="black" />
      <path d="M48.6207 21.0346H45.8621V23.7932H48.6207V21.0346Z" fill="black" />
      <path d="M51.3792 21.0346H48.6206V23.7932H51.3792V21.0346Z" fill="black" />
      <path d="M54.138 21.0346H51.3794V23.7932H54.138V21.0346Z" fill="black" />
      <path d="M56.8966 21.0346H54.1379V23.7932H56.8966V21.0346Z" fill="black" />
      <path d="M59.6551 21.0346H56.8965V23.7932H59.6551V21.0346Z" fill="black" />
      <path d="M62.4139 21.0346H59.6553V23.7932H62.4139V21.0346Z" fill="black" />
      <path d="M65.1724 21.0346H62.4138V23.7932H65.1724V21.0346Z" fill="black" />
      <path d="M73.4483 21.0346H70.6897V23.7932H73.4483V21.0346Z" fill="black" />
      <path d="M78.9656 21.0346H76.207V23.7932H78.9656V21.0346Z" fill="black" />
      <path d="M81.7242 21.0346H78.9656V23.7932H81.7242V21.0346Z" fill="black" />
      <path d="M84.4827 21.0346H81.7241V23.7932H84.4827V21.0346Z" fill="black" />
      <path d="M90.0001 21.0346H87.2415V23.7932H90.0001V21.0346Z" fill="black" />
      <path d="M12.7586 23.793H10V26.5516H12.7586V23.793Z" fill="black" />
      <path d="M29.3104 23.793H26.5518V26.5516H29.3104V23.793Z" fill="black" />
      <path d="M43.1033 23.793H40.3447V26.5516H43.1033V23.793Z" fill="black" />
      <path d="M48.6207 23.793H45.8621V26.5516H48.6207V23.793Z" fill="black" />
      <path d="M59.6551 23.793H56.8965V26.5516H59.6551V23.793Z" fill="black" />
      <path d="M62.4139 23.793H59.6553V26.5516H62.4139V23.793Z" fill="black" />
      <path d="M67.931 23.793H65.1724V26.5516H67.931V23.793Z" fill="black" />
      <path d="M73.4483 23.793H70.6897V26.5516H73.4483V23.793Z" fill="black" />
      <path d="M90.0001 23.793H87.2415V26.5516H90.0001V23.793Z" fill="black" />
      <path d="M12.7586 26.5517H10V29.3103H12.7586V26.5517Z" fill="black" />
      <path d="M15.5172 26.5517H12.7585V29.3103H15.5172V26.5517Z" fill="black" />
      <path d="M18.276 26.5517H15.5173V29.3103H18.276V26.5517Z" fill="black" />
      <path d="M21.0345 26.5517H18.2759V29.3103H21.0345V26.5517Z" fill="black" />
      <path d="M23.793 26.5517H21.0344V29.3103H23.793V26.5517Z" fill="black" />
      <path d="M26.5518 26.5517H23.7932V29.3103H26.5518V26.5517Z" fill="black" />
      <path d="M29.3104 26.5517H26.5518V29.3103H29.3104V26.5517Z" fill="black" />
      <path d="M34.8277 26.5517H32.0691V29.3103H34.8277V26.5517Z" fill="black" />
      <path d="M40.3448 26.5517H37.5862V29.3103H40.3448V26.5517Z" fill="black" />
      <path d="M45.8621 26.5517H43.1035V29.3103H45.8621V26.5517Z" fill="black" />
      <path d="M51.3792 26.5517H48.6206V29.3103H51.3792V26.5517Z" fill="black" />
      <path d="M56.8966 26.5517H54.1379V29.3103H56.8966V26.5517Z" fill="black" />
      <path d="M62.4139 26.5517H59.6553V29.3103H62.4139V26.5517Z" fill="black" />
      <path d="M67.931 26.5517H65.1724V29.3103H67.931V26.5517Z" fill="black" />
      <path d="M73.4483 26.5517H70.6897V29.3103H73.4483V26.5517Z" fill="black" />
      <path d="M76.2069 26.5517H73.4482V29.3103H76.2069V26.5517Z" fill="black" />
      <path d="M78.9656 26.5517H76.207V29.3103H78.9656V26.5517Z" fill="black" />
      <path d="M81.7242 26.5517H78.9656V29.3103H81.7242V26.5517Z" fill="black" />
      <path d="M84.4827 26.5517H81.7241V29.3103H84.4827V26.5517Z" fill="black" />
      <path d="M87.2413 26.5517H84.4827V29.3103H87.2413V26.5517Z" fill="black" />
      <path d="M90.0001 26.5517H87.2415V29.3103H90.0001V26.5517Z" fill="black" />
      <path d="M34.8277 29.3103H32.0691V32.0689H34.8277V29.3103Z" fill="black" />
      <path d="M37.5863 29.3103H34.8276V32.0689H37.5863V29.3103Z" fill="black" />
      <path d="M43.1033 29.3103H40.3447V32.0689H43.1033V29.3103Z" fill="black" />
      <path d="M45.8621 29.3103H43.1035V32.0689H45.8621V29.3103Z" fill="black" />
      <path d="M65.1724 29.3103H62.4138V32.0689H65.1724V29.3103Z" fill="black" />
      <path d="M15.5172 32.0689H12.7585V34.8276H15.5172V32.0689Z" fill="black" />
      <path d="M21.0345 32.0689H18.2759V34.8276H21.0345V32.0689Z" fill="black" />
      <path d="M23.793 32.0689H21.0344V34.8276H23.793V32.0689Z" fill="black" />
      <path d="M26.5518 32.0689H23.7932V34.8276H26.5518V32.0689Z" fill="black" />
      <path d="M29.3104 32.0689H26.5518V34.8276H29.3104V32.0689Z" fill="black" />
      <path d="M34.8277 32.0689H32.0691V34.8276H34.8277V32.0689Z" fill="black" />
      <path d="M40.3448 32.0689H37.5862V34.8276H40.3448V32.0689Z" fill="black" />
      <path d="M54.138 32.0689H51.3794V34.8276H54.138V32.0689Z" fill="black" />
      <path d="M56.8966 32.0689H54.1379V34.8276H56.8966V32.0689Z" fill="black" />
      <path d="M62.4139 32.0689H59.6553V34.8276H62.4139V32.0689Z" fill="black" />
      <path d="M65.1724 32.0689H62.4138V34.8276H65.1724V32.0689Z" fill="black" />
      <path d="M67.931 32.0689H65.1724V34.8276H67.931V32.0689Z" fill="black" />
      <path d="M70.6895 32.0689H67.9309V34.8276H70.6895V32.0689Z" fill="black" />
      <path d="M73.4483 32.0689H70.6897V34.8276H73.4483V32.0689Z" fill="black" />
      <path d="M78.9656 32.0689H76.207V34.8276H78.9656V32.0689Z" fill="black" />
      <path d="M81.7242 32.0689H78.9656V34.8276H81.7242V32.0689Z" fill="black" />
      <path d="M87.2413 32.0689H84.4827V34.8276H87.2413V32.0689Z" fill="black" />
      <path d="M12.7586 34.8276H10V37.5862H12.7586V34.8276Z" fill="black" />
      <path d="M18.276 34.8276H15.5173V37.5862H18.276V34.8276Z" fill="black" />
      <path d="M23.793 34.8276H21.0344V37.5862H23.793V34.8276Z" fill="black" />
      <path d="M32.0689 34.8276H29.3103V37.5862H32.0689V34.8276Z" fill="black" />
      <path d="M34.8277 34.8276H32.0691V37.5862H34.8277V34.8276Z" fill="black" />
      <path d="M40.3448 34.8276H37.5862V37.5862H40.3448V34.8276Z" fill="black" />
      <path d="M45.8621 34.8276H43.1035V37.5862H45.8621V34.8276Z" fill="black" />
      <path d="M48.6207 34.8276H45.8621V37.5862H48.6207V34.8276Z" fill="black" />
      <path d="M51.3792 34.8276H48.6206V37.5862H51.3792V34.8276Z" fill="black" />
      <path d="M59.6551 34.8276H56.8965V37.5862H59.6551V34.8276Z" fill="black" />
      <path d="M67.931 34.8276H65.1724V37.5862H67.931V34.8276Z" fill="black" />
      <path d="M76.2069 34.8276H73.4482V37.5862H76.2069V34.8276Z" fill="black" />
      <path d="M78.9656 34.8276H76.207V37.5862H78.9656V34.8276Z" fill="black" />
      <path d="M81.7242 34.8276H78.9656V37.5862H81.7242V34.8276Z" fill="black" />
      <path d="M87.2413 34.8276H84.4827V37.5862H87.2413V34.8276Z" fill="black" />
      <path d="M12.7586 37.5862H10V40.3448H12.7586V37.5862Z" fill="black" />
      <path d="M15.5172 37.5862H12.7585V40.3448H15.5172V37.5862Z" fill="black" />
      <path d="M21.0345 37.5862H18.2759V40.3448H21.0345V37.5862Z" fill="black" />
      <path d="M23.793 37.5862H21.0344V40.3448H23.793V37.5862Z" fill="black" />
      <path d="M29.3104 37.5862H26.5518V40.3448H29.3104V37.5862Z" fill="black" />
      <path d="M32.0689 37.5862H29.3103V40.3448H32.0689V37.5862Z" fill="black" />
      <path d="M34.8277 37.5862H32.0691V40.3448H34.8277V37.5862Z" fill="black" />
      <path d="M43.1033 37.5862H40.3447V40.3448H43.1033V37.5862Z" fill="black" />
      <path d="M45.8621 37.5862H43.1035V40.3448H45.8621V37.5862Z" fill="black" />
      <path d="M48.6207 37.5862H45.8621V40.3448H48.6207V37.5862Z" fill="black" />
      <path d="M54.138 37.5862H51.3794V40.3448H54.138V37.5862Z" fill="black" />
      <path d="M59.6551 37.5862H56.8965V40.3448H59.6551V37.5862Z" fill="black" />
      <path d="M62.4139 37.5862H59.6553V40.3448H62.4139V37.5862Z" fill="black" />
      <path d="M73.4483 37.5862H70.6897V40.3448H73.4483V37.5862Z" fill="black" />
      <path d="M76.2069 37.5862H73.4482V40.3448H76.2069V37.5862Z" fill="black" />
      <path d="M81.7242 37.5862H78.9656V40.3448H81.7242V37.5862Z" fill="black" />
      <path d="M12.7586 40.3448H10V43.1035H12.7586V40.3448Z" fill="black" />
      <path d="M15.5172 40.3448H12.7585V43.1035H15.5172V40.3448Z" fill="black" />
      <path d="M18.276 40.3448H15.5173V43.1035H18.276V40.3448Z" fill="black" />
      <path d="M21.0345 40.3448H18.2759V43.1035H21.0345V40.3448Z" fill="black" />
      <path d="M23.793 40.3448H21.0344V43.1035H23.793V40.3448Z" fill="black" />
      <path d="M32.0689 40.3448H29.3103V43.1035H32.0689V40.3448Z" fill="black" />
      <path d="M37.5863 40.3448H34.8276V43.1035H37.5863V40.3448Z" fill="black" />
      <path d="M45.8621 40.3448H43.1035V43.1035H45.8621V40.3448Z" fill="black" />
      <path d="M51.3792 40.3448H48.6206V43.1035H51.3792V40.3448Z" fill="black" />
      <path d="M54.138 40.3448H51.3794V43.1035H54.138V40.3448Z" fill="black" />
      <path d="M56.8966 40.3448H54.1379V43.1035H56.8966V40.3448Z" fill="black" />
      <path d="M59.6551 40.3448H56.8965V43.1035H59.6551V40.3448Z" fill="black" />
      <path d="M70.6895 40.3448H67.9309V43.1035H70.6895V40.3448Z" fill="black" />
      <path d="M81.7242 40.3448H78.9656V43.1035H81.7242V40.3448Z" fill="black" />
      <path d="M90.0001 40.3448H87.2415V43.1035H90.0001V40.3448Z" fill="black" />
      <path d="M18.276 43.1035H15.5173V45.8621H18.276V43.1035Z" fill="black" />
      <path d="M21.0345 43.1035H18.2759V45.8621H21.0345V43.1035Z" fill="black" />
      <path d="M23.793 43.1035H21.0344V45.8621H23.793V43.1035Z" fill="black" />
      <path d="M26.5518 43.1035H23.7932V45.8621H26.5518V43.1035Z" fill="black" />
      <path d="M29.3104 43.1035H26.5518V45.8621H29.3104V43.1035Z" fill="black" />
      <path d="M34.8277 43.1035H32.0691V45.8621H34.8277V43.1035Z" fill="black" />
      <path d="M40.3448 43.1035H37.5862V45.8621H40.3448V43.1035Z" fill="black" />
      <path d="M56.8966 43.1035H54.1379V45.8621H56.8966V43.1035Z" fill="black" />
      <path d="M65.1724 43.1035H62.4138V45.8621H65.1724V43.1035Z" fill="black" />
      <path d="M67.931 43.1035H65.1724V45.8621H67.931V43.1035Z" fill="black" />
      <path d="M70.6895 43.1035H67.9309V45.8621H70.6895V43.1035Z" fill="black" />
      <path d="M73.4483 43.1035H70.6897V45.8621H73.4483V43.1035Z" fill="black" />
      <path d="M76.2069 43.1035H73.4482V45.8621H76.2069V43.1035Z" fill="black" />
      <path d="M81.7242 43.1035H78.9656V45.8621H81.7242V43.1035Z" fill="black" />
      <path d="M87.2413 43.1035H84.4827V45.8621H87.2413V43.1035Z" fill="black" />
      <path d="M21.0345 45.8621H18.2759V48.6208H21.0345V45.8621Z" fill="black" />
      <path d="M23.793 45.8621H21.0344V48.6208H23.793V45.8621Z" fill="black" />
      <path d="M34.8277 45.8621H32.0691V48.6208H34.8277V45.8621Z" fill="black" />
      <path d="M37.5863 45.8621H34.8276V48.6208H37.5863V45.8621Z" fill="black" />
      <path d="M43.1033 45.8621H40.3447V48.6208H43.1033V45.8621Z" fill="black" />
      <path d="M51.3792 45.8621H48.6206V48.6208H51.3792V45.8621Z" fill="black" />
      <path d="M56.8966 45.8621H54.1379V48.6208H56.8966V45.8621Z" fill="black" />
      <path d="M59.6551 45.8621H56.8965V48.6208H59.6551V45.8621Z" fill="black" />
      <path d="M62.4139 45.8621H59.6553V48.6208H62.4139V45.8621Z" fill="black" />
      <path d="M73.4483 45.8621H70.6897V48.6208H73.4483V45.8621Z" fill="black" />
      <path d="M78.9656 45.8621H76.207V48.6208H78.9656V45.8621Z" fill="black" />
      <path d="M84.4827 45.8621H81.7241V48.6208H84.4827V45.8621Z" fill="black" />
      <path d="M90.0001 45.8621H87.2415V48.6208H90.0001V45.8621Z" fill="black" />
      <path d="M12.7586 48.6208H10V51.3794H12.7586V48.6208Z" fill="black" />
      <path d="M18.276 48.6208H15.5173V51.3794H18.276V48.6208Z" fill="black" />
      <path d="M26.5518 48.6208H23.7932V51.3794H26.5518V48.6208Z" fill="black" />
      <path d="M29.3104 48.6208H26.5518V51.3794H29.3104V48.6208Z" fill="black" />
      <path d="M37.5863 48.6208H34.8276V51.3794H37.5863V48.6208Z" fill="black" />
      <path d="M45.8621 48.6208H43.1035V51.3794H45.8621V48.6208Z" fill="black" />
      <path d="M48.6207 48.6208H45.8621V51.3794H48.6207V48.6208Z" fill="black" />
      <path d="M54.138 48.6208H51.3794V51.3794H54.138V48.6208Z" fill="black" />
      <path d="M59.6551 48.6208H56.8965V51.3794H59.6551V48.6208Z" fill="black" />
      <path d="M65.1724 48.6208H62.4138V51.3794H65.1724V48.6208Z" fill="black" />
      <path d="M70.6895 48.6208H67.9309V51.3794H70.6895V48.6208Z" fill="black" />
      <path d="M81.7242 48.6208H78.9656V51.3794H81.7242V48.6208Z" fill="black" />
      <path d="M87.2413 48.6208H84.4827V51.3794H87.2413V48.6208Z" fill="black" />
      <path d="M90.0001 48.6208H87.2415V51.3794H90.0001V48.6208Z" fill="black" />
      <path d="M12.7586 51.3792H10V54.1378H12.7586V51.3792Z" fill="black" />
      <path d="M26.5518 51.3792H23.7932V54.1378H26.5518V51.3792Z" fill="black" />
      <path d="M32.0689 51.3792H29.3103V54.1378H32.0689V51.3792Z" fill="black" />
      <path d="M34.8277 51.3792H32.0691V54.1378H34.8277V51.3792Z" fill="black" />
      <path d="M37.5863 51.3792H34.8276V54.1378H37.5863V51.3792Z" fill="black" />
      <path d="M54.138 51.3792H51.3794V54.1378H54.138V51.3792Z" fill="black" />
      <path d="M56.8966 51.3792H54.1379V54.1378H56.8966V51.3792Z" fill="black" />
      <path d="M59.6551 51.3792H56.8965V54.1378H59.6551V51.3792Z" fill="black" />
      <path d="M62.4139 51.3792H59.6553V54.1378H62.4139V51.3792Z" fill="black" />
      <path d="M65.1724 51.3792H62.4138V54.1378H65.1724V51.3792Z" fill="black" />
      <path d="M81.7242 51.3792H78.9656V54.1378H81.7242V51.3792Z" fill="black" />
      <path d="M84.4827 51.3792H81.7241V54.1378H84.4827V51.3792Z" fill="black" />
      <path d="M87.2413 51.3792H84.4827V54.1378H87.2413V51.3792Z" fill="black" />
      <path d="M12.7586 54.1378H10V56.8965H12.7586V54.1378Z" fill="black" />
      <path d="M26.5518 54.1378H23.7932V56.8965H26.5518V54.1378Z" fill="black" />
      <path d="M29.3104 54.1378H26.5518V56.8965H29.3104V54.1378Z" fill="black" />
      <path d="M34.8277 54.1378H32.0691V56.8965H34.8277V54.1378Z" fill="black" />
      <path d="M40.3448 54.1378H37.5862V56.8965H40.3448V54.1378Z" fill="black" />
      <path d="M43.1033 54.1378H40.3447V56.8965H43.1033V54.1378Z" fill="black" />
      <path d="M48.6207 54.1378H45.8621V56.8965H48.6207V54.1378Z" fill="black" />
      <path d="M51.3792 54.1378H48.6206V56.8965H51.3792V54.1378Z" fill="black" />
      <path d="M54.138 54.1378H51.3794V56.8965H54.138V54.1378Z" fill="black" />
      <path d="M56.8966 54.1378H54.1379V56.8965H56.8966V54.1378Z" fill="black" />
      <path d="M59.6551 54.1378H56.8965V56.8965H59.6551V54.1378Z" fill="black" />
      <path d="M65.1724 54.1378H62.4138V56.8965H65.1724V54.1378Z" fill="black" />
      <path d="M67.931 54.1378H65.1724V56.8965H67.931V54.1378Z" fill="black" />
      <path d="M76.2069 54.1378H73.4482V56.8965H76.2069V54.1378Z" fill="black" />
      <path d="M81.7242 54.1378H78.9656V56.8965H81.7242V54.1378Z" fill="black" />
      <path d="M90.0001 54.1378H87.2415V56.8965H90.0001V54.1378Z" fill="black" />
      <path d="M12.7586 56.8965H10V59.6551H12.7586V56.8965Z" fill="black" />
      <path d="M23.793 56.8965H21.0344V59.6551H23.793V56.8965Z" fill="black" />
      <path d="M26.5518 56.8965H23.7932V59.6551H26.5518V56.8965Z" fill="black" />
      <path d="M32.0689 56.8965H29.3103V59.6551H32.0689V56.8965Z" fill="black" />
      <path d="M37.5863 56.8965H34.8276V59.6551H37.5863V56.8965Z" fill="black" />
      <path d="M40.3448 56.8965H37.5862V59.6551H40.3448V56.8965Z" fill="black" />
      <path d="M48.6207 56.8965H45.8621V59.6551H48.6207V56.8965Z" fill="black" />
      <path d="M56.8966 56.8965H54.1379V59.6551H56.8966V56.8965Z" fill="black" />
      <path d="M62.4139 56.8965H59.6553V59.6551H62.4139V56.8965Z" fill="black" />
      <path d="M67.931 56.8965H65.1724V59.6551H67.931V56.8965Z" fill="black" />
      <path d="M70.6895 56.8965H67.9309V59.6551H70.6895V56.8965Z" fill="black" />
      <path d="M78.9656 56.8965H76.207V59.6551H78.9656V56.8965Z" fill="black" />
      <path d="M81.7242 56.8965H78.9656V59.6551H81.7242V56.8965Z" fill="black" />
      <path d="M84.4827 56.8965H81.7241V59.6551H84.4827V56.8965Z" fill="black" />
      <path d="M12.7586 59.6551H10V62.4138H12.7586V59.6551Z" fill="black" />
      <path d="M15.5172 59.6551H12.7585V62.4138H15.5172V59.6551Z" fill="black" />
      <path d="M18.276 59.6551H15.5173V62.4138H18.276V59.6551Z" fill="black" />
      <path d="M23.793 59.6551H21.0344V62.4138H23.793V59.6551Z" fill="black" />
      <path d="M26.5518 59.6551H23.7932V62.4138H26.5518V59.6551Z" fill="black" />
      <path d="M29.3104 59.6551H26.5518V62.4138H29.3104V59.6551Z" fill="black" />
      <path d="M32.0689 59.6551H29.3103V62.4138H32.0689V59.6551Z" fill="black" />
      <path d="M37.5863 59.6551H34.8276V62.4138H37.5863V59.6551Z" fill="black" />
      <path d="M43.1033 59.6551H40.3447V62.4138H43.1033V59.6551Z" fill="black" />
      <path d="M54.138 59.6551H51.3794V62.4138H54.138V59.6551Z" fill="black" />
      <path d="M67.931 59.6551H65.1724V62.4138H67.931V59.6551Z" fill="black" />
      <path d="M76.2069 59.6551H73.4482V62.4138H76.2069V59.6551Z" fill="black" />
      <path d="M84.4827 59.6551H81.7241V62.4138H84.4827V59.6551Z" fill="black" />
      <path d="M90.0001 59.6551H87.2415V62.4138H90.0001V59.6551Z" fill="black" />
      <path d="M12.7586 62.4138H10V65.1724H12.7586V62.4138Z" fill="black" />
      <path d="M15.5172 62.4138H12.7585V65.1724H15.5172V62.4138Z" fill="black" />
      <path d="M18.276 62.4138H15.5173V65.1724H18.276V62.4138Z" fill="black" />
      <path d="M26.5518 62.4138H23.7932V65.1724H26.5518V62.4138Z" fill="black" />
      <path d="M34.8277 62.4138H32.0691V65.1724H34.8277V62.4138Z" fill="black" />
      <path d="M43.1033 62.4138H40.3447V65.1724H43.1033V62.4138Z" fill="black" />
      <path d="M48.6207 62.4138H45.8621V65.1724H48.6207V62.4138Z" fill="black" />
      <path d="M51.3792 62.4138H48.6206V65.1724H51.3792V62.4138Z" fill="black" />
      <path d="M62.4139 62.4138H59.6553V65.1724H62.4139V62.4138Z" fill="black" />
      <path d="M70.6895 62.4138H67.9309V65.1724H70.6895V62.4138Z" fill="black" />
      <path d="M73.4483 62.4138H70.6897V65.1724H73.4483V62.4138Z" fill="black" />
      <path d="M84.4827 62.4138H81.7241V65.1724H84.4827V62.4138Z" fill="black" />
      <path d="M12.7586 65.1724H10V67.931H12.7586V65.1724Z" fill="black" />
      <path d="M15.5172 65.1724H12.7585V67.931H15.5172V65.1724Z" fill="black" />
      <path d="M18.276 65.1724H15.5173V67.931H18.276V65.1724Z" fill="black" />
      <path d="M21.0345 65.1724H18.2759V67.931H21.0345V65.1724Z" fill="black" />
      <path d="M23.793 65.1724H21.0344V67.931H23.793V65.1724Z" fill="black" />
      <path d="M29.3104 65.1724H26.5518V67.931H29.3104V65.1724Z" fill="black" />
      <path d="M34.8277 65.1724H32.0691V67.931H34.8277V65.1724Z" fill="black" />
      <path d="M37.5863 65.1724H34.8276V67.931H37.5863V65.1724Z" fill="black" />
      <path d="M43.1033 65.1724H40.3447V67.931H43.1033V65.1724Z" fill="black" />
      <path d="M48.6207 65.1724H45.8621V67.931H48.6207V65.1724Z" fill="black" />
      <path d="M54.138 65.1724H51.3794V67.931H54.138V65.1724Z" fill="black" />
      <path d="M59.6551 65.1724H56.8965V67.931H59.6551V65.1724Z" fill="black" />
      <path d="M67.931 65.1724H65.1724V67.931H67.931V65.1724Z" fill="black" />
      <path d="M70.6895 65.1724H67.9309V67.931H70.6895V65.1724Z" fill="black" />
      <path d="M73.4483 65.1724H70.6897V67.931H73.4483V65.1724Z" fill="black" />
      <path d="M76.2069 65.1724H73.4482V67.931H76.2069V65.1724Z" fill="black" />
      <path d="M78.9656 65.1724H76.207V67.931H78.9656V65.1724Z" fill="black" />
      <path d="M84.4827 65.1724H81.7241V67.931H84.4827V65.1724Z" fill="black" />
      <path d="M87.2413 65.1724H84.4827V67.931H87.2413V65.1724Z" fill="black" />
      <path d="M34.8277 67.9311H32.0691V70.6897H34.8277V67.9311Z" fill="black" />
      <path d="M40.3448 67.9311H37.5862V70.6897H40.3448V67.9311Z" fill="black" />
      <path d="M54.138 67.9311H51.3794V70.6897H54.138V67.9311Z" fill="black" />
      <path d="M62.4139 67.9311H59.6553V70.6897H62.4139V67.9311Z" fill="black" />
      <path d="M67.931 67.9311H65.1724V70.6897H67.931V67.9311Z" fill="black" />
      <path d="M78.9656 67.9311H76.207V70.6897H78.9656V67.9311Z" fill="black" />
      <path d="M81.7242 67.9311H78.9656V70.6897H81.7242V67.9311Z" fill="black" />
      <path d="M84.4827 67.9311H81.7241V70.6897H84.4827V67.9311Z" fill="black" />
      <path d="M12.7586 70.6897H10V73.4483H12.7586V70.6897Z" fill="black" />
      <path d="M15.5172 70.6897H12.7585V73.4483H15.5172V70.6897Z" fill="black" />
      <path d="M18.276 70.6897H15.5173V73.4483H18.276V70.6897Z" fill="black" />
      <path d="M21.0345 70.6897H18.2759V73.4483H21.0345V70.6897Z" fill="black" />
      <path d="M23.793 70.6897H21.0344V73.4483H23.793V70.6897Z" fill="black" />
      <path d="M26.5518 70.6897H23.7932V73.4483H26.5518V70.6897Z" fill="black" />
      <path d="M29.3104 70.6897H26.5518V73.4483H29.3104V70.6897Z" fill="black" />
      <path d="M37.5863 70.6897H34.8276V73.4483H37.5863V70.6897Z" fill="black" />
      <path d="M45.8621 70.6897H43.1035V73.4483H45.8621V70.6897Z" fill="black" />
      <path d="M48.6207 70.6897H45.8621V73.4483H48.6207V70.6897Z" fill="black" />
      <path d="M54.138 70.6897H51.3794V73.4483H54.138V70.6897Z" fill="black" />
      <path d="M59.6551 70.6897H56.8965V73.4483H59.6551V70.6897Z" fill="black" />
      <path d="M62.4139 70.6897H59.6553V73.4483H62.4139V70.6897Z" fill="black" />
      <path d="M65.1724 70.6897H62.4138V73.4483H65.1724V70.6897Z" fill="black" />
      <path d="M67.931 70.6897H65.1724V73.4483H67.931V70.6897Z" fill="black" />
      <path d="M73.4483 70.6897H70.6897V73.4483H73.4483V70.6897Z" fill="black" />
      <path d="M78.9656 70.6897H76.207V73.4483H78.9656V70.6897Z" fill="black" />
      <path d="M84.4827 70.6897H81.7241V73.4483H84.4827V70.6897Z" fill="black" />
      <path d="M12.7586 73.4483H10V76.207H12.7586V73.4483Z" fill="black" />
      <path d="M29.3104 73.4483H26.5518V76.207H29.3104V73.4483Z" fill="black" />
      <path d="M34.8277 73.4483H32.0691V76.207H34.8277V73.4483Z" fill="black" />
      <path d="M40.3448 73.4483H37.5862V76.207H40.3448V73.4483Z" fill="black" />
      <path d="M43.1033 73.4483H40.3447V76.207H43.1033V73.4483Z" fill="black" />
      <path d="M45.8621 73.4483H43.1035V76.207H45.8621V73.4483Z" fill="black" />
      <path d="M48.6207 73.4483H45.8621V76.207H48.6207V73.4483Z" fill="black" />
      <path d="M51.3792 73.4483H48.6206V76.207H51.3792V73.4483Z" fill="black" />
      <path d="M54.138 73.4483H51.3794V76.207H54.138V73.4483Z" fill="black" />
      <path d="M59.6551 73.4483H56.8965V76.207H59.6551V73.4483Z" fill="black" />
      <path d="M65.1724 73.4483H62.4138V76.207H65.1724V73.4483Z" fill="black" />
      <path d="M67.931 73.4483H65.1724V76.207H67.931V73.4483Z" fill="black" />
      <path d="M78.9656 73.4483H76.207V76.207H78.9656V73.4483Z" fill="black" />
      <path d="M81.7242 73.4483H78.9656V76.207H81.7242V73.4483Z" fill="black" />
      <path d="M90.0001 73.4483H87.2415V76.207H90.0001V73.4483Z" fill="black" />
      <path d="M12.7586 76.207H10V78.9656H12.7586V76.207Z" fill="black" />
      <path d="M18.276 76.207H15.5173V78.9656H18.276V76.207Z" fill="black" />
      <path d="M21.0345 76.207H18.2759V78.9656H21.0345V76.207Z" fill="black" />
      <path d="M23.793 76.207H21.0344V78.9656H23.793V76.207Z" fill="black" />
      <path d="M29.3104 76.207H26.5518V78.9656H29.3104V76.207Z" fill="black" />
      <path d="M34.8277 76.207H32.0691V78.9656H34.8277V76.207Z" fill="black" />
      <path d="M37.5863 76.207H34.8276V78.9656H37.5863V76.207Z" fill="black" />
      <path d="M45.8621 76.207H43.1035V78.9656H45.8621V76.207Z" fill="black" />
      <path d="M56.8966 76.207H54.1379V78.9656H56.8966V76.207Z" fill="black" />
      <path d="M59.6551 76.207H56.8965V78.9656H59.6551V76.207Z" fill="black" />
      <path d="M62.4139 76.207H59.6553V78.9656H62.4139V76.207Z" fill="black" />
      <path d="M65.1724 76.207H62.4138V78.9656H65.1724V76.207Z" fill="black" />
      <path d="M67.931 76.207H65.1724V78.9656H67.931V76.207Z" fill="black" />
      <path d="M70.6895 76.207H67.9309V78.9656H70.6895V76.207Z" fill="black" />
      <path d="M73.4483 76.207H70.6897V78.9656H73.4483V76.207Z" fill="black" />
      <path d="M76.2069 76.207H73.4482V78.9656H76.2069V76.207Z" fill="black" />
      <path d="M78.9656 76.207H76.207V78.9656H78.9656V76.207Z" fill="black" />
      <path d="M90.0001 76.207H87.2415V78.9656H90.0001V76.207Z" fill="black" />
      <path d="M12.7586 78.9656H10V81.7242H12.7586V78.9656Z" fill="black" />
      <path d="M18.276 78.9656H15.5173V81.7242H18.276V78.9656Z" fill="black" />
      <path d="M21.0345 78.9656H18.2759V81.7242H21.0345V78.9656Z" fill="black" />
      <path d="M23.793 78.9656H21.0344V81.7242H23.793V78.9656Z" fill="black" />
      <path d="M29.3104 78.9656H26.5518V81.7242H29.3104V78.9656Z" fill="black" />
      <path d="M34.8277 78.9656H32.0691V81.7242H34.8277V78.9656Z" fill="black" />
      <path d="M37.5863 78.9656H34.8276V81.7242H37.5863V78.9656Z" fill="black" />
      <path d="M45.8621 78.9656H43.1035V81.7242H45.8621V78.9656Z" fill="black" />
      <path d="M56.8966 78.9656H54.1379V81.7242H56.8966V78.9656Z" fill="black" />
      <path d="M59.6551 78.9656H56.8965V81.7242H59.6551V78.9656Z" fill="black" />
      <path d="M70.6895 78.9656H67.9309V81.7242H70.6895V78.9656Z" fill="black" />
      <path d="M76.2069 78.9656H73.4482V81.7242H76.2069V78.9656Z" fill="black" />
      <path d="M81.7242 78.9656H78.9656V81.7242H81.7242V78.9656Z" fill="black" />
      <path d="M87.2413 78.9656H84.4827V81.7242H87.2413V78.9656Z" fill="black" />
      <path d="M12.7586 81.7241H10V84.4827H12.7586V81.7241Z" fill="black" />
      <path d="M18.276 81.7241H15.5173V84.4827H18.276V81.7241Z" fill="black" />
      <path d="M21.0345 81.7241H18.2759V84.4827H21.0345V81.7241Z" fill="black" />
      <path d="M23.793 81.7241H21.0344V84.4827H23.793V81.7241Z" fill="black" />
      <path d="M29.3104 81.7241H26.5518V84.4827H29.3104V81.7241Z" fill="black" />
      <path d="M40.3448 81.7241H37.5862V84.4827H40.3448V81.7241Z" fill="black" />
      <path d="M43.1033 81.7241H40.3447V84.4827H43.1033V81.7241Z" fill="black" />
      <path d="M48.6207 81.7241H45.8621V84.4827H48.6207V81.7241Z" fill="black" />
      <path d="M56.8966 81.7241H54.1379V84.4827H56.8966V81.7241Z" fill="black" />
      <path d="M59.6551 81.7241H56.8965V84.4827H59.6551V81.7241Z" fill="black" />
      <path d="M62.4139 81.7241H59.6553V84.4827H62.4139V81.7241Z" fill="black" />
      <path d="M67.931 81.7241H65.1724V84.4827H67.931V81.7241Z" fill="black" />
      <path d="M70.6895 81.7241H67.9309V84.4827H70.6895V81.7241Z" fill="black" />
      <path d="M78.9656 81.7241H76.207V84.4827H78.9656V81.7241Z" fill="black" />
      <path d="M81.7242 81.7241H78.9656V84.4827H81.7242V81.7241Z" fill="black" />
      <path d="M84.4827 81.7241H81.7241V84.4827H84.4827V81.7241Z" fill="black" />
      <path d="M87.2413 81.7241H84.4827V84.4827H87.2413V81.7241Z" fill="black" />
      <path d="M90.0001 81.7241H87.2415V84.4827H90.0001V81.7241Z" fill="black" />
      <path d="M12.7586 84.4827H10V87.2413H12.7586V84.4827Z" fill="black" />
      <path d="M29.3104 84.4827H26.5518V87.2413H29.3104V84.4827Z" fill="black" />
      <path d="M34.8277 84.4827H32.0691V87.2413H34.8277V84.4827Z" fill="black" />
      <path d="M45.8621 84.4827H43.1035V87.2413H45.8621V84.4827Z" fill="black" />
      <path d="M51.3792 84.4827H48.6206V87.2413H51.3792V84.4827Z" fill="black" />
      <path d="M54.138 84.4827H51.3794V87.2413H54.138V84.4827Z" fill="black" />
      <path d="M67.931 84.4827H65.1724V87.2413H67.931V84.4827Z" fill="black" />
      <path d="M70.6895 84.4827H67.9309V87.2413H70.6895V84.4827Z" fill="black" />
      <path d="M73.4483 84.4827H70.6897V87.2413H73.4483V84.4827Z" fill="black" />
      <path d="M78.9656 84.4827H76.207V87.2413H78.9656V84.4827Z" fill="black" />
      <path d="M81.7242 84.4827H78.9656V87.2413H81.7242V84.4827Z" fill="black" />
      <path d="M84.4827 84.4827H81.7241V87.2413H84.4827V84.4827Z" fill="black" />
      <path d="M90.0001 84.4827H87.2415V87.2413H90.0001V84.4827Z" fill="black" />
      <path d="M12.7586 87.2413H10V89.9999H12.7586V87.2413Z" fill="black" />
      <path d="M15.5172 87.2413H12.7585V89.9999H15.5172V87.2413Z" fill="black" />
      <path d="M18.276 87.2413H15.5173V89.9999H18.276V87.2413Z" fill="black" />
      <path d="M21.0345 87.2413H18.2759V89.9999H21.0345V87.2413Z" fill="black" />
      <path d="M23.793 87.2413H21.0344V89.9999H23.793V87.2413Z" fill="black" />
      <path d="M26.5518 87.2413H23.7932V89.9999H26.5518V87.2413Z" fill="black" />
      <path d="M29.3104 87.2413H26.5518V89.9999H29.3104V87.2413Z" fill="black" />
      <path d="M37.5863 87.2413H34.8276V89.9999H37.5863V87.2413Z" fill="black" />
      <path d="M43.1033 87.2413H40.3447V89.9999H43.1033V87.2413Z" fill="black" />
      <path d="M48.6207 87.2413H45.8621V89.9999H48.6207V87.2413Z" fill="black" />
      <path d="M62.4139 87.2413H59.6553V89.9999H62.4139V87.2413Z" fill="black" />
      <path d="M67.931 87.2413H65.1724V89.9999H67.931V87.2413Z" fill="black" />
      <path d="M70.6895 87.2413H67.9309V89.9999H70.6895V87.2413Z" fill="black" />
      <path d="M76.2069 87.2413H73.4482V89.9999H76.2069V87.2413Z" fill="black" />
      <path d="M78.9656 87.2413H76.207V89.9999H78.9656V87.2413Z" fill="black" />
      <path d="M81.7242 87.2413H78.9656V89.9999H81.7242V87.2413Z" fill="black" />
    </svg>
  );
};

export default AppQrCode;
