import React, { useCallback, useEffect } from 'react';
import { Step } from 'ui/edits/Step';
import { Item } from 'ui/list/Item';
import cl from './Step2.module.scss';
import Icon, { IconType } from 'ui/Icon';
import { IconButton } from '@material-ui/core';
import { useProfile } from 'hooks/profile';
import { checkProfile, checkRequisites } from 'state/profile';
import cn from 'classnames';
import { $step2, changeViewType, nextStep, prevStep, step2SetProfile, step2ToggleSaver } from '../../model';
import { Profile } from 'api/types/profile';
import { useStore } from 'effector-react';

interface Props {}

export const Step2: React.FC<Props> = () => {
  const { profile: globalProfile } = useProfile();
  const { needSave, profile } = useStore($step2);

  useEffect(() => {
    if (!profile) step2SetProfile(globalProfile as Profile);
  }, [globalProfile, profile]);

  const handleNext = useCallback(() => {
    nextStep();
  }, []);

  const handleToggleSaver = useCallback(() => {
    step2ToggleSaver();
  }, []);

  const handlePrevStep = useCallback(() => {
    prevStep();
  }, []);

  const filledProfile = checkProfile(profile);
  const filledRequisites = checkRequisites(profile?.requisites);
  const filledEmail = !!profile?.email && !!profile?.email_verified;

  return (
    <Step
      title="Реквизиты законного представителя"
      onClickNext={handleNext}
      disabledNext={!filledProfile || !filledRequisites || !filledEmail}
      withSaver
      onClickSaver={handleToggleSaver}
      needSave={needSave}
      prevStep={handlePrevStep}
    >
      <div className={cl.items}>
        <DataItem
          title="Учетная запись"
          subtitle="Данные"
          icon="NoPhoto"
          filled={filledProfile}
          onClick={changeViewType.bind(null, 'profile')}
        />
        <DataItem
          title={profile?.email || ''}
          subtitle="E-mail"
          icon="Mail"
          filled={filledEmail}
          unFilledText={!!profile?.email && !profile?.email_verified ? 'Не подтвержден' : undefined}
          onClick={changeViewType.bind(null, 'email')}
        />
        <DataItem
          title="Реквизиты паспорта"
          subtitle="Данные для договора"
          icon="User"
          filled={filledRequisites}
          onClick={changeViewType.bind(null, 'requisites')}
        />
      </div>
    </Step>
  );
};

interface ItemProps {
  title: string;
  subtitle: string;
  icon: IconType;
  filled: boolean;
  unFilledText?: string;
  onClick: () => void;
}

const DataItem: React.FC<ItemProps> = ({ title, subtitle, icon, filled, onClick, unFilledText = 'Не заполнено' }) => {
  return (
    <Item classname={cl.item}>
      <div className={cn(cl.extra, filled && cl.filled)}>{filled ? 'Заполнено' : unFilledText}</div>
      <Icon type={icon} />
      <div className={cl.text}>
        <div>{subtitle}</div>
        <div className={cl.title}>{title}&nbsp;</div>
      </div>
      <IconButton className={cl.editBtn} onClick={onClick}>
        <Icon type="Edit" />
      </IconButton>
    </Item>
  );
};
